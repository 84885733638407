import http from "./httpservices";
// import GetCookieByName from '../hooks/GetCookie';

export async function FetchInfluencers(params) {
  const { data } = await http.post("seo/", params);
  return data;
}

export default {
  FetchInfluencers,
};
