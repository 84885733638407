import * as React from 'react';
import { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
// import SidebarComponent from '../components/common/sidebar/Sidebar';
import DashboardHeaderSection from '../components/common/dashboardheader/DashboardHeader';
import Profile from '../components/userprofile/profile/Profile';

function UserProfilePage(){
    useEffect(() => {
        document.title = "Profile";
    }, []);

    return(
        <>
            <CssBaseline/>
            <DashboardHeaderSection/>
            <Profile/>
            {/* <Grid 
                container
            >
                <Grid item xs={ 2 }>
                    <SidebarComponent/>
                </Grid>
                <Grid 
                    item 
                    xs={12}
                >
                    <DashboardHeaderSection/>
                    <Profile/>
                </Grid>
            </Grid> */}
        </>
    );
}

export default UserProfilePage;