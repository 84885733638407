import * as React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  ButtonGroup,
  Tabs,
  Tab,
} from "@mui/material";
import GirlImage from "../../../../src/girlImage.png";
import WomenImage from "../../../../src/womenImage.png";
import SmartDashBoard from "./SmartDashboard";
import Extension from "./Extension";
import { useState } from "react";

function LandingWorksSection() {
  const [linkOne, setLinkOne] = useState(true);
  const [linkTwo, setLinkTwo] = useState(false);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setLinkOne(true);
      setLinkTwo(false);
    } else {
      setLinkTwo(true);
      setLinkOne(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ marginY: 20 }}>
      <Typography
        variant="h4"
        component={"h1"}
        sx={{
          textAlign: "center",
          fontSize: "60px",
          fontWeight: "700",
        }}
      >
        How it works
      </Typography>
      <Box component={"div"} width={"100%"} sx={{ marginY: 5 }}>
        <Tabs
          onChange={handleChange}
          value={value}
          aria-label="Tabs where each tab needs to be selected manually"
        >
          <Tab
            label="Extension"
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              textTransform: "uppercase",
              marginRight: 5,
            }}
            style={{
              boxShadow: value === 0 ? '0 2px 5px rgba(0, 0, 0, 0.5)' : 'none',
            }}
          />
          <Tab
            label="Smart DashBoard"
            sx={{
              fontSize: "30px",
              fontWeight: "700",
              textTransform: "uppercase",
              marginRight: 5,
            }}
            style={{
              boxShadow: value === 1 ? '0 2px 5px rgba(0, 0, 0, 0.5)' : 'none',
            }}
          />
        </Tabs>
      </Box>
      <Grid
        container
        sx={{ marginTop: 5 }}
        rowSpacing={4}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
      >
        {linkOne && <Extension />}
        {linkTwo && <SmartDashBoard />}
      </Grid>
    </Container>
  );
}

export default LandingWorksSection;
