import * as React from 'react';
import { Button, FormControl, FormHelperText, TextField, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import usersEndpoints from '../../../../services/userServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPasswordInput(props){
    const { register, handleSubmit, watch, formState: { errors }, setError, clearErrors } = useForm();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(value['user_new_password'] !== value['confirm_new_password']){
                setError('confirm_new_password', {
                    type: 'custom',
                    message: 'Passwords do not match'
                });
            }
            else{
                clearErrors('confirm_new_password');
            }
        });

        return () => { subscription.unsubscribe() }
    }, [watch]);

    const onSubmit = (data) => {
        if(data.user_new_password === data.confirm_new_password){
            changePassword(data);
        }
        else{
            setError(
                "confirm_new_password",
                {
                    type: "custom",
                    message: "Passwords do not match"
                },
                {
                    shouldFocus: true
                }
            );
        }
    };

    const showInvalidValues = (data) => {};

    const changePassword = async (data) => {
        let postdata = {
            updated_password: data.user_new_password,
            confirm_password: data.confirm_new_password,
            email: props.useremail
        };
        setLoading(true);
        const response = await usersEndpoints.resetUserPassword(postdata);
        setLoading(false);

        if(response['success'] === true){
            // alert('Your password has been successfully reset');
            toast.success("Your password has been successfully reset");
            window.location.assign("/authenticate/signin");
        }
        else{
            setErrorMessage(response['message']);
        }
    };

    return (
        <>
            <form
                onSubmit={ handleSubmit(onSubmit, showInvalidValues) }
            >
                <FormControl sx={{ width: '100%', marginBottom: 2 }}>
                    <FormHelperText
                        sx={{ color: 'info.main', marginLeft: '5px', marginBottom: '16px' }}
                    >
                        Password should be a combination of atleast 1 digit, 1 Lowercase letter, 1 Uppercase letter and
                        atleast 8 characters minimum
                    </FormHelperText>
                    <TextField
                        variant='outlined'
                        type={ "password" }
                        placeholder="Enter new password"
                        {
                            ...register(
                                "user_new_password",
                                {
                                    required: "Please enter your new password",
                                    pattern: {
                                        value: new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])'),
                                        message: 'Please follow the password format'
                                    },
                                    minLength: {
                                        value: 8,
                                        message: 'Password should have atleast 8 characters'
                                    }
                                }
                            )
                        }
                        error={ Boolean(errors.user_new_password) }
                        helperText={ errors.user_new_password?.message }
                    />
                </FormControl>
                <FormControl sx={{ width: '100%', marginBottom: 0.5 }}>
                    <TextField
                        variant='outlined'
                        type={ "text" }
                        placeholder="Confirm new password"
                        {
                            ...register(
                                "confirm_new_password",
                                {
                                    required: 'Please re-enter your new password',
                                    pattern: {
                                        value: new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])'),
                                        message: 'Please follow the password format'
                                    },
                                    minLength: {
                                        value: 8,
                                        message: 'Password should have atleast 8 characters'
                                    }
                                }
                            )
                        }
                        error={ Boolean(errors.confirm_new_password) }
                        helperText={ errors.confirm_new_password?.message }
                    />
                </FormControl>
                <FormHelperText error sx={{ marginLeft: '0.25rem', marginBottom: '0.5rem' }}>
                    { errorMessage }
                </FormHelperText>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    type="submit"
                    sx={{ 
                        paddingX: 4, 
                        textTransform: "capitalize",
                        fontSize: '1rem',
                        borderRadius: '10px'
                    }}
                    endIcon={
                        <CircularProgress 
                            size={ loading ? "0.9rem" : "0rem" }
                            color="light" 
                        />
                    }
                >
                    Reset Password
                </Button>
            </form>

            <ToastContainer
                style={{
                    fontSize: '0.85rem'
                }}
                hideProgressBar={true}
                autoClose={2000}
            />
        </>
    )
}

export default ResetPasswordInput;