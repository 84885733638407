import Vendor from "./Vendor";
import Campaign from "./Campaign";
import { Grid, Box } from "@mui/material";

export default function CategoryCards({ updateLatestCampaign, totalCampaign, totalVendor }) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Campaign updateLatestCampaign={updateLatestCampaign} totalCampaign={totalCampaign} />
        <Vendor totalVendor={totalVendor} />
      </Grid>
    </Box>
  );
}
