import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Avatar,
  Badge,
  Stack,
  Tooltip,
  IconButton,
  Divider,
  Paper,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Image from "material-ui-image";
import {
  FacebookRounded,
  Instagram,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import HowToViewContact from "../howtouse/HowToViewContact";
import { useCookies } from "react-cookie";
import usersEndpoints from "../../../services/userServices";
import removeMediaFromUrl from "../../../hooks/loadImage";

function Dashboard() {
  const [userdetails, setUserDetails] = useState({});
  const [users, setUsers] = useState([]);
  const [activeUser, setActiveUser] = useState({});
  const [cookies] = useCookies(["user"]);
  const [showLowCreditsAlert, setShowLowCreditsAlert] = useState(false);
  const [loading, setLoading] = useState({
    viewed_contacts: false,
    user_details: false,
  });

  const numberFormatter = (value) => {
    let formatter = Intl.NumberFormat("en", { notation: "compact" });
    let convertedValue = formatter.format(value);
    return convertedValue;
  };

  async function fetchViewedContacts() {
    let contacts = [];
    let getViewedData = "_id=" + cookies.user._id;
    const response = await usersEndpoints.viewedInfluencersList(getViewedData);

    setLoading({ ...loading, ...{ viewed_contacts: false } });

    let viewedInfluencersList = [];
    if (response["success"] && response["success"] === true) {
      viewedInfluencersList = response["data"];
      if (viewedInfluencersList && viewedInfluencersList.length > 0) {
        viewedInfluencersList.map((contact) => {
          let manager_details = {};
          if (contact.manager) {
            for (let i = 0; i < contact.manager.length; i++) {
              if (
                (contact.manager[i].manager_name ||
                  contact.manager[i].agency_name) &&
                contact.manager[i].isActive === true
              ) {
                manager_details = contact.manager[i];
                break;
              }
            }
          }

          let influencer = {
            name: contact.full_name ? contact.full_name : contact.username,
            channelName: contact.username,
            email: contact.email ? contact.email : null,
            phone: manager_details["manager_number"]
              ? manager_details["manager_number"]
              : contact.number,
            picture: contact.profile_pic_url_hd
              ? contact.profile_pic_url_hd
              : null,
            is_verified: contact.is_verified ? contact.is_verified : null,
            biography: contact.biography ? contact.biography : null,
            followers: contact.edge_followed_by
              ? contact.edge_followed_by
              : null,
            following: contact.edge_follow ? contact.edge_follow : null,
            external_url: contact.external_url ? contact.external_url : null,
            youtube: contact.youtube ? contact.youtube : false,
            instagram: contact.instagram ? contact.instagram : false,
            twitter: contact.twitter ? contact.twitter : false,
            facebook: contact.facebook ? contact.facebook : false,
            manager_name: manager_details["manager_name"]
              ? manager_details["manager_name"]
              : "",
            agency_name: manager_details["agency_name"]
              ? manager_details["agency_name"]
              : "",
          };
          
          if (contact.number_feedback.length > 0) {
            let feedback = contact.number_feedback[0];
            let phone_number_feedback = "";
            // if (feedback["phoneNumberFeedback"]) {
            //   for (let i = 0; i < feedback["phoneNumberFeedback"].length; i++) {
            //     if (
            //       influencer.phone.match(
            //         feedback["phoneNumberFeedback"][i]["phone"]
            //       ) &&
            //       feedback["phoneNumberFeedback"][i]["status"].length > 0
            //     ) {
            //       phone_number_feedback += `${feedback["phoneNumberFeedback"][i]["phone"]
            //         }:${feedback["phoneNumberFeedback"][i]["status"].join("|")};`;
            //     }
            //   }
            // }
            influencer.phoneFeedback = phone_number_feedback;
          } else {
            influencer.phoneFeedback = "";
          }
          contacts.push(influencer);
        });

        setUsers(contacts);
        setActiveUser(contacts[0]);
      }
    }
  }

  async function fetchUserDetails() {
    const response = await usersEndpoints.getUserDetails({});
    setLoading({ ...loading, ...{ user_details: false } });
    if (response["success"] === true) {
      let user = response["user"];
      setUserDetails({
        name: user.fullName ? user.fullName : "",
        balance: response["credits"] ? response["credits"] : 0,
        email: user.email ? user.email : "",
      });

      if (response["credits"] === 0) {
        setShowLowCreditsAlert(true);
      }
    }
  }

  const handleClickContact = (index) => {
    setActiveUser(users[index]);
  };

  useEffect(() => {
    if (cookies.user) {
      setLoading({
        ...loading,
        ...{
          viewed_contacts: true,
          user_details: true,
        },
      });

      fetchViewedContacts();
      fetchUserDetails();
    }
  }, []);

  return (
    <>
      <Box
        width={{ xs: "100%", lg: `calc(100% - 80px)` }}
        marginLeft={{ xs: "0px", lg: `80px` }}
        marginTop={8}
      >
        <Stack
          padding={2}
          sx={{ display: showLowCreditsAlert === true ? "flex" : "none" }}
        >
          <Alert
            severity="error"
            action={
              <Stack spacing={1}>
                <Button
                  color="error"
                  size="small"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                  href={"/#pricing_container"}
                >
                  Buy Credits
                </Button>

                <Button
                  color="inherit"
                  size="small"
                  variant="outlined"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setShowLowCreditsAlert(false);
                  }}
                >
                  Dismiss
                </Button>
              </Stack>
            }
          >
            <AlertTitle>
              <strong>No Credits</strong>
            </AlertTitle>
            You do not have any credits, Please Buy credits in order to use our
            services
          </Alert>
        </Stack>
        {users.length === 0 ? (
          <>
            <Typography
              marginTop={3}
              color={"primary"}
              fontWeight={600}
              fontSize={"1rem"}
              textAlign={"center"}
            >
              Loading...
            </Typography>
          </>
        ) : users.length > 0 ? (
          <Grid container>
            <Grid item xs={12} md={5} sx={{ bgcolor: "#F0F2F5" }}>
              <Box
                sx={{
                  marginY: 2,
                  paddingX: 2,
                }}
              >
                <Typography component={"h4"} variant={"h4"}>
                  Contacts Dashboard
                </Typography>

                <Typography component={"p"}>
                  Welcome, {cookies.user ? cookies.user.name : ""}
                </Typography>
                <Divider />
              </Box>

              <Typography variant={"p"} sx={{ paddingX: 2 }}>
                Your Contacts({users.length})
              </Typography>

              <SimpleBar
                autoHide={true}
                style={{
                  maxHeight: "600px",
                }}
              >
                <List component={"div"}>
                  {users.map((user, index) => (
                    <ListItem
                      key={`user_contact_${index}`}
                      disablePadding
                      selected={user.channelName === activeUser.channelName}
                    >
                      <ListItemButton
                        sx={{ paddingX: 2, paddingY: 2 }}
                        disableRipple
                        onClick={() => handleClickContact(index)}
                      >
                        <ListItemAvatar>
                          <Badge
                            badgeContent={
                              <VerifiedIcon
                                color="success"
                                sx={{ fontSize: "1.1rem" }}
                              />
                            }
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <Avatar
                              src={removeMediaFromUrl(user.picture)}
                            ></Avatar>
                          </Badge>
                        </ListItemAvatar>
                        <Box component={"div"}>
                          <Stack direction={"column"}>
                            <ListItemText primary={user.name} />
                            <Typography sx={{ fontSize: "0.8rem" }}>
                              {`@${user.channelName}`}
                            </Typography>
                          </Stack>
                        </Box>
                        <Stack direction={"row"} gap={1} marginLeft={"auto"}>
                          <Tooltip
                            title={
                              user.phone && typeof user.phone === "string" ? (
                                user.phone.split(",").map((number, index) => {
                                  return (
                                    <>
                                      <Typography
                                        key={`${user.channelName}_list_phone_${index}`}
                                        fontSize={"0.65rem"}
                                      >
                                        {number}
                                      </Typography>
                                    </>
                                  );
                                })
                              ) : user.phone &&
                                typeof user.phone === "number" ? (
                                <>
                                  <Typography fontSize={"0.65rem"}>
                                    {user.phone}
                                  </Typography>
                                </>
                              ) : (
                                "Phone number not found"
                              )
                            }
                          >
                            <Box
                              component={"div"}
                              boxShadow={1}
                              borderRadius={5}
                            >
                              <IconButton size={"small"} color={"primary"}>
                                <CallIcon fontSize={"small"} />
                              </IconButton>
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={
                              user.email
                                ? user.email.split(",").map((email, index) => {
                                  return (
                                    <>
                                      <Typography
                                        key={`${user.channelName}_list_email_${index}`}
                                        fontSize={"0.65rem"}
                                      >
                                        {email}
                                      </Typography>
                                    </>
                                  );
                                })
                                : "Email address not found"
                            }
                          >
                            <Box
                              component={"div"}
                              boxShadow={1}
                              borderRadius={5}
                            >
                              <IconButton size={"small"} color={"primary"}>
                                <MailOutlineIcon fontSize={"small"} />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        </Stack>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </SimpleBar>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                background: "linear-gradient(45deg, transparent, #ffede0)",
              }}
            >
              {activeUser.picture !== undefined ? (
                <Stack
                  spacing={0.5}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ height: "calc(100vh - 4rem)", paddingX: 10 }}
                >
                  <Stack direction={"row"} spacing={3}>
                    <Box
                      component={"div"}
                      sx={{
                        width: "108px",
                        height: "108px",
                        borderRadius: "20px",
                      }}
                      boxShadow={3}
                    >
                      <Image
                        src={removeMediaFromUrl(activeUser.picture)}
                        imageStyle={{ borderRadius: "20px" }}
                        style={{ borderRadius: "20px" }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src =
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
                        }}
                      />
                    </Box>

                    <Stack>
                      <Typography component={"p"} sx={{ fontWeight: "600" }}>
                        {activeUser.name}
                      </Typography>

                      <Typography component={"p"} sx={{ opacity: 0.75 }}>
                        {activeUser.channelName}
                      </Typography>

                      <Stack
                        direction={"row"}
                        sx={{ marginTop: "auto" }}
                        spacing={1}
                      >
                        <Button
                          variant={"contained"}
                          size={"small"}
                          sx={{ textTransform: "unset" }}
                        >
                          <MailOutlineIcon
                            sx={{ marginRight: 0.5 }}
                            fontSize={"small"}
                          />
                          Message
                        </Button>

                        <Box
                          component={"div"}
                          borderRadius={1}
                          boxShadow={1}
                          sx={{ bgcolor: "primary.main" }}
                        >
                          <IconButton size={"small"}>
                            <CallIcon fontSize={"small"} color="light" />
                          </IconButton>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ textAlign: "end" }}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                      >
                        Bio
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography component={"p"} sx={{ fontSize: "0.9rem" }}>
                        {activeUser.biography ? activeUser.biography : "N.A"}
                      </Typography>
                    </Grid>
                  </Grid>

                  {activeUser.manager_name ? (
                    <Grid container spacing={2}>
                      <Grid item xs={2} sx={{ textAlign: "end" }}>
                        <Typography
                          component={"p"}
                          sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                        >
                          Manager
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component={"p"} sx={{ fontSize: "0.9rem" }}>
                          {activeUser.manager_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {activeUser.agency_name ? (
                    <Grid container spacing={2}>
                      <Grid item xs={2} sx={{ textAlign: "end" }}>
                        <Typography
                          component={"p"}
                          sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                        >
                          Agency
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography component={"p"} sx={{ fontSize: "0.9rem" }}>
                          {activeUser.agency_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ textAlign: "end" }}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                      >
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography component={"p"} sx={{ fontSize: "0.9rem" }}>
                        {activeUser.email
                          ? activeUser.email.split(",").map((email, index) => {
                            return (
                              email && (
                                <span key={`active_user_email_${index}`}>
                                  <span>{email}</span>
                                  <br />
                                </span>
                              )
                            );
                          })
                          : "Email address not found"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ textAlign: "end" }}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                      >
                        Phone
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography component={"div"} sx={{ fontSize: "0.9rem" }}>
                        {activeUser.phone
                          ? activeUser.phone
                            .split(",")
                            .map((number, numberIndex) => {
                              if (number) {
                                let index =
                                  activeUser.phoneFeedback.indexOf(number);
                                let positive = 0;
                                let negative = 0;

                                if (index > -1) {
                                  let feedback = "";
                                  feedback = activeUser.phoneFeedback
                                    .slice(index)
                                    .split(";")[0]
                                    .split(":");

                                  feedback[1].split("|").map((status) => {
                                    return status === "active"
                                      ? positive++
                                      : negative++;
                                  });
                                }

                                return (
                                  <Stack
                                    key={`active_user_phone_${numberIndex}`}
                                    direction={"row"}
                                    spacing={2}
                                  >
                                    <span>{number}</span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        display:
                                          positive > 0
                                            ? "inline-flex"
                                            : "none",
                                        gap: "0.25rem",
                                      }}
                                    >
                                      <SentimentVerySatisfiedIcon
                                        color="success"
                                        sx={{ fontSize: "1.25rem" }}
                                      />
                                      <span>{positive}</span>
                                    </span>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        display:
                                          negative > 0
                                            ? "inline-flex"
                                            : "none",
                                        gap: "0.25rem",
                                      }}
                                    >
                                      <SentimentVeryDissatisfiedIcon
                                        color="error"
                                        sx={{ fontSize: "1.25rem" }}
                                      />
                                      {negative}
                                    </span>
                                    <br />
                                  </Stack>
                                );
                              }
                            })
                          : "Phone number not found"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} alignItems={"baseline"}>
                    <Grid item xs={2} sx={{ textAlign: "end" }}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                      >
                        Social
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <IconButton
                        color={activeUser.instagram ? "primary" : "dark"}
                      >
                        <Instagram sx={{ fontSize: "1.5rem" }} />
                      </IconButton>

                      <IconButton
                        color={activeUser.facebook ? "primary" : "dark"}
                      >
                        <FacebookRounded sx={{ fontSize: "1.5rem" }} />
                      </IconButton>

                      <IconButton
                        color={activeUser.twitter ? "primary" : "dark"}
                      >
                        <Twitter sx={{ fontSize: "1.5rem" }} />
                      </IconButton>

                      <IconButton
                        color={activeUser.youtube ? "primary" : "dark"}
                      >
                        <YouTube sx={{ fontSize: "1.5rem" }} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ textAlign: "end" }}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                      >
                        Stats
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ display: "flex", gap: 2 }}>
                      {!activeUser.followers && !activeUser.following ? (
                        <Typography component={"p"} sx={{ fontSize: "0.9rem" }}>
                          Stats not Available
                        </Typography>
                      ) : (
                        ""
                      )}
                      <Typography component={"p"} sx={{ fontSize: "0.9rem" }}>
                        {activeUser.followers ? (
                          <>
                            <strong>
                              {numberFormatter(activeUser.followers.count)}
                            </strong>
                            &nbsp;followers
                          </>
                        ) : (
                          <></>
                        )}
                      </Typography>
                      <Typography component={"p"} sx={{ fontSize: "0.9rem" }}>
                        {activeUser.following ? (
                          <>
                            <strong>
                              {numberFormatter(activeUser.following.count)}
                            </strong>
                            &nbsp;following
                          </>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={2} sx={{ textAlign: "end" }}>
                      <Typography
                        component={"p"}
                        sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                      >
                        Links
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sx={{
                        display: "flex",
                        gap: 2,
                        paddingTop: "15px !important",
                      }}
                    >
                      {activeUser.external_url ? (
                        <Button
                          variant="text"
                          href={activeUser.external_url}
                          sx={{
                            textTransform: "lowercase",
                            paddingY: 0,
                            paddingLeft: 0,
                          }}
                        >
                          {activeUser.external_url}
                        </Button>
                      ) : (
                        <Typography
                          component={"p"}
                          sx={{
                            fontSize: "0.9rem",
                          }}
                        >
                          No Links Mentioned
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              ) : (
                <Typography component={"p"}>
                  No contact selected to display
                </Typography>
              )}
            </Grid>
          </Grid>
        ) : (
          <Stack
            marginX={"auto"}
            alignItems={"center"}
            justifyContent={"center"}
            paddingY={loading.viewed_contacts || loading.user_details ? 10 : 0}
            direction={
              loading.viewed_contacts || loading.user_details ? "row" : "column"
            }
          >
            {loading.viewed_contacts || loading.user_details ? (
              <>
                <Typography
                  marginRight={1}
                  color={"primary"}
                  fontWeight={600}
                  fontSize={"1rem"}
                >
                  Loading Saved Contacts...
                </Typography>
                <CircularProgress size={"1rem"} />
              </>
            ) : (
              <>
                <Paper
                  sx={{
                    paddingY: 2,
                    marginBottom: 2,
                  }}
                >
                  <HowToViewContact />
                </Paper>

                <Alert severity="info" sx={{ width: "97%" }}>
                  <AlertTitle>
                    <strong>No contacts viewed</strong>
                  </AlertTitle>
                  When you view contacts on Meesha you will see their details
                  here
                </Alert>
              </>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
}

export default Dashboard;
