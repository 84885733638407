import React, { useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CampaignCard from "./common/CampaignCard";
import StatusCards from "./common/StatusCards";
import StatusTable from "./common/StatusTable";
import BackButton from "../common/BackButton";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Details({
  campaignDetails,
  handleLinkGenerate,
  campaignInfo,
}) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderLabel = (label) => {
    let name = "";
    switch (label) {
      case "P":
        name = "Pending";
        break;
      case "W":
        name = "Waiting";
        break;
      case "A":
        name = "Applied";
        break;
      case "F":
        name = "Failed";
        break;
      case "R":
        name = "Rejected";
        break;
    }

    return name;
  };

  return (
    <Box
      width={{ xs: "100%", lg: `calc(100% - 80px)` }}
      marginLeft={{ xs: "0px", lg: `80px` }}
      marginTop={8}
      padding={2}
      backgroundColor={"#F0F2F5"}
      minHeight={"100vh"}
      height={"100%"}
    >
      <Stack spacing={3}>
        <BackButton label={campaignDetails.name} />
        <Box>
          <Grid container spacing={2}>
            <CampaignCard
              campaignDetails={campaignDetails}
              handleLinkGenerate={handleLinkGenerate}
            />
            <StatusCards campaignInfo={campaignInfo} />
          </Grid>
        </Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {campaignInfo.map((c) => (
                <Tab
                  label={c._id === "P" ? "Pending" : "W"}
                  {...a11yProps(0)}
                />
              ))}
              <Tab label="Applied" {...a11yProps(1)} />
              <Tab label="Waiting" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <StatusTable />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <StatusTable />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <StatusTable />
          </CustomTabPanel>
        </Box>
      </Stack>
    </Box>
  );
}
