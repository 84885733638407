import React from "react";
import {
  Box,
  Card,
  Chip,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  CurrencyRupee,
  Delete,
  Edit,
  Interests,
  Person,
  TextSnippet,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import IdToDate from "../common/date/IdToDate";
import BackButton from "../common/BackButton";

const styles = {
  iconWrapper: {
    display: "flex",
  },
  icon: {
    fontSize: "30px",
    cursor: "pointer",
    // borderRadius: "50%", // Make it circular
    padding: "6px", // Add padding for better appearance
  },
  addIcon: {
    backgroundColor: "#0AC380",
    color: "#fff", // Set text color to make it visible
  },
  editIcon: {
    backgroundColor: "#FFD206",
    color: "#fff", // Set text color to make it visible
  },
  deleteIcon: {
    backgroundColor: "#ff0000", // You can set your desired color
    color: "#fff", // Set text color to make it visible
  },
};

export default function Home({
  loading,
  campaigns,
  handleLinkGenerate,
  handleChange,
  editCampaign,
  handleDelete,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <Box
        width={{ xs: "100%", lg: `calc(100% - 80px)` }}
        marginLeft={{ xs: "0px", lg: `80px` }}
        marginTop={8}
        paddingTop={1}
        backgroundColor={"#F0F2F5"}
        minHeight={"100vh"}
        height={"100%"}
      >
        <BackButton label={`Campaigns(${campaigns.length})`} />
        <Box sx={{ padding: 2 }}>
          <Stack spacing={4}>
            <Box sx={{ textAlign: "center" }}>
              <TextField
                sx={{ width: 550 }}
                id="outlined-search"
                label="Search campaign..."
                type="search"
                onChange={(e) => handleChange(e.target.value)}
              />
            </Box>
            <Box>
              <Grid container spacing={2}>
                {loading ? (
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Typography variant="subtitle1" color={"primary"}>
                      Loading Campaigns...
                    </Typography>
                  </Grid>
                ) : (
                  campaigns.length > 0 &&
                  campaigns.map((campaign, index) => (
                    <Grid item xs={6} key={index}>
                      <Card
                        sx={{
                          padding: 3,
                          borderRadius: 0,
                          boxShadow: 0,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Box
                              sx={{
                                alignSelf: "center",
                                color: "#fff",
                                backgroundColor: "#e34f4f",
                                paddingX: 1,
                                paddingTop: 1,
                                paddingBottom: 0.4,
                              }}
                            >
                              <TextSnippet />
                            </Box>
                            <Box
                              sx={{ marginLeft: 1, cursor: "pointer" }}
                              onClick={() => handleLinkGenerate(campaign, true)}
                            >
                              <Typography variant="subtitle2">
                                {campaign.name}
                              </Typography>
                              <Typography variant="caption" color={"secondary"}>
                                {campaign.category[0].name}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Box marginRight={1}>
                              <Typography variant="subtitle2">
                              <IdToDate id={campaign._id} />
                              </Typography>
                            </Box>
                            <Box sx={styles.iconWrapper}>
                              <Tooltip title="Create Link">
                                <Add
                                  sx={{
                                    ...styles.icon,
                                    ...styles.addIcon,
                                    marginRight: 0.5,
                                  }}
                                  onClick={() => handleLinkGenerate(campaign)}
                                />
                              </Tooltip>
                              <Tooltip title="Edit Campaign Budget">
                                <Edit
                                  sx={{
                                    ...styles.icon,
                                    ...styles.editIcon,
                                    marginRight: 0.5,
                                  }}
                                  onClick={() => editCampaign(campaign._id)}
                                />
                              </Tooltip>
                              <Tooltip title="Delete Campaign">
                                <Delete
                                  color="error"
                                  sx={{ ...styles.icon, ...styles.deleteIcon }}
                                  onClick={() =>
                                    handleDelete(campaign._id, campaign.name)
                                  }
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ padding: 3 }}>
                          <Typography variant="subtitle2">
                            {campaign.deliverables}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "end" }}>
                            <Tooltip title="">
                              <Person
                                color="secondary"
                                sx={{ fontSize: "20px" }}
                              />
                            </Tooltip>
                            <Typography variant="subtitle2">
                              {campaign.createdby.fullName}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Box sx={{ marginRight: 1 }}>
                              <Typography variant="subtitle2">
                                {campaign.totalPayment}{" "}
                                <CurrencyRupee
                                  color="secondary"
                                  sx={{ fontSize: "15px" }}
                                />
                              </Typography>
                              <Typography variant="subtitle2">
                                Budget
                              </Typography>
                            </Box>
                            <Box sx={{ marginRight: 1 }}>
                              <Typography variant="subtitle2">
                                {campaign.paymentLimit}{" "}
                                <CurrencyRupee
                                  color="secondary"
                                  sx={{ fontSize: "15px" }}
                                />
                              </Typography>
                              <Typography variant="subtitle2" color={"error"}>
                                Limit
                              </Typography>
                            </Box>
                            <Box sx={{ marginRight: 1 }}>
                              <Typography variant="subtitle2">
                                {campaign.platform[0].name}{" "}
                                <Interests
                                  color="secondary"
                                  sx={{ fontSize: "15px" }}
                                />
                              </Typography>
                              <Typography variant="subtitle2">
                                Platform
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Box>
    </div>
  );
}
