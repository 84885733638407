import { Clear } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function Percentage({ setValue, getValues, control }) {
  const data = Array.from(Array(getValues("percentage")).keys()).map((i) => ({
    label: `${i + 1}%`,
    value: i + 1,
  }));

  const handlePercentage = (_, { value }) => {
    if (value === "0") return;

    const totalAmount = getValues("totalAmount");

    if (Boolean(totalAmount)) {
      setValue(
        "payableAmount",
        Math.round((parseInt(value) / 100) * parseInt(totalAmount)).toString()
      );
      setValue("percentage", value);
    }
  };

  const handleClear = () => {
    setValue("payableAmount", 0);
    setValue("percentage", 100);
    console.log(getValues("percentage"), "getValue");
  };

  return (
    <Controller
      name="percentage"
      control={control}
      render={({ field }) => (
        <Autocomplete
          sx={{ width: "30%" }}
          disablePortal
          id="percentage-dropdown"
          options={data}
          clearIcon={<Clear onClick={handleClear} />}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="%"
              label="Percentage"
            />
          )}
          value={getValues("percentage")}
          onChange={handlePercentage}
        />
      )}
    />
  );
}

export default Percentage;
