import { Typography, Grid, Box, Button, Stack } from '@mui/material';
import * as React from 'react';
import OurStory from './images/our-story.jpg';

function AboutUsBody() {
    const styles = {
        lineHeight: '1.75rem'
    };
    return (
        <>
            <Grid
                container
                sx={{ minHeight: '100vh', background: '#fcfbfb' }}
            >
                <Grid
                    item
                    xs={12}
                    lg={6}
                >
                    <Box
                    >
                        <img
                            src={OurStory}
                            alt='Our Story'
                            style={{
                                width: '100%',
                                opacity: '0.65',
                                height: '100%'
                            }}
                        />
                    </Box>
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={6}
                >
                    <Stack
                        paddingX={3}
                        paddingY={3}
                    >
                        <Typography
                            lineHeight={styles.lineHeight}
                            textAlign={'center'}
                            marginBottom={1}
                            fontWeight={'600'}
                            fontSize={'1.2rem'}
                        >
                            About us
                        </Typography>

                        <Typography
                            lineHeight={styles.lineHeight}
                            marginBottom={1}
                        >
                            It's {new Date().getFullYear()}, connectivity is the key
                        </Typography>

                        <Typography
                            lineHeight={styles.lineHeight}
                            marginBottom={1}
                        >
                            You come across a profile on <strong>Instagram</strong> or a channel on <strong>Youtube</strong> that you'd like to connect with, simply DMing doesn't always work. In an industry full of representatives, agencies and other middlemen… we are here to simplify things!
                        </Typography>

                        <Typography
                            lineHeight={styles.lineHeight}
                            marginBottom={1}
                        >
                            When you download the <strong>MEESHA</strong> extension, you get access to a simple and inexpensive way of gaining contact details.
                            Apart from the vast pool of data containing contact details of Influencers, <strong>MEESHA</strong> is here with a variety of features at less cost.
                            <br />
                            Post download, you can select from one of the three plans available on our website to buy credits and  these can be used in gaining accurate contact details, 1 credit for 1 phone number & 1 e-mail address.&nbsp;
                            <strong>MEESHA</strong> also lets users save the leads from Instagram on to the Dashboard, which can be organized and exported in spreadsheets.
                        </Typography>

                        <Typography
                            lineHeight={styles.lineHeight}
                            marginBottom={1.75}
                        >
                            The idea is to untangle the complex communication processes involved in marketing campaigns today, and help brands save time, effort and money.
                        </Typography>

                        <Button
                            variant='contained'
                            color='primary'
                            sx={{ borderRadius: '1.2rem', textAlign: 'center', marginX: 'auto' }}
                            href="https://chrome.google.com/webstore/detail/meesha-connect-with-influ/hfjiamlingkieffkgcikgjomhdbgoald"
                            target={"_blank"}
                        >
                            Download Chrome Extension
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default AboutUsBody;