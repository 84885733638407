export default function GetCookieByName(cookiename){
    let cookieArr = document.cookie.split(";");
    for(let i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(cookiename === cookiePair[0].trim()) {
            if(cookiePair[1] !== 'undefined'){
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }
    
    // Return null if not found
    return {};
}