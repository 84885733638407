import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@mui/material/Skeleton';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  skeletonCell: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MuiTableSkeleton = ({row=10, cols=8}) => {
  const classes = useStyles();

  // Generate dummy data for the table
  const rows = [...Array(row)].map((_, rowIndex) => {
    return [...Array(cols)].map(
      (_, colIndex) => `Row ${rowIndex + 1}, Col ${colIndex + 1}`
    );
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="mui table skeleton">
        <TableHead>
          <TableRow>
            {[...Array(8)].map((_, colIndex) => (
              <TableCell key={colIndex} className={classes.skeletonCell}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, colIndex) => (
                <TableCell key={colIndex} className={classes.skeletonCell}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MuiTableSkeleton;
