import React from "react";
import { Box, Skeleton } from "@mui/material";

function LatestCampaignSkelton({ count = 10 }) {
  return (
    <>
      {Array.from(Array(count).keys()).map((a) => (
        <Box sx={{ pt: 0.5 }} key={a}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      ))}
    </>
  );
}

export default LatestCampaignSkelton;
