import * as React from "react";
import {
  AppBar,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Stack,
  Typography,
  Drawer,
  Badge,
  Chip,
} from "@mui/material";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
// import person from './images/person.png';
import ContactsIcon from "@mui/icons-material/Contacts";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
// import { useNavigate } from 'react-router-dom';

import usersEndpoints from "../../../services/userServices";
import validator from "validator";
import ShowFilteredList from "./FilteredLists";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SidebarComponent from "../sidebar/Sidebar";

/*global chrome*/
function DashboardHeaderSection({
  isSearchOpen = true,
  updateCredits = false,
  inputRef,
  handleEnterKeyPress,
  isYoutube = false,
}) {
  const [cookies, removeCookie] = useCookies(["user", "auth"]);
  const [userDetails, setUserDetails] = useState({
    name: "",
    balance: "Loading...",
  });
  const [searchedCreator, setSearchedCreator] = useState("");
  const [foundCreators, setFoundCreators] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [extensionInstalled, setExtensionInstalled] = useState(false);
  useEffect(async () => {
    if (cookies.user !== "undefined" && cookies.user !== undefined) {
      const response = await usersEndpoints.getUserDetails({});
      checkExtensionVersion();
      if (response["success"] && response["success"] === true) {
        let user = response["user"];
        setUserDetails({
          name: user.fullName ? user.fullName : "",
          balance: response["credits"] ? response["credits"] : 0,
          email: user.email ? user.email : "",
          image: user.image ? user.image : "",
        });
      } else if (response["success"] === false && response["logout"] === true) {
        toast.success("Your session has expired, Try logging in back again");
        removeCookie("auth", "", {
          path: "/",
          expires: new Date(1691479246),
        });

        removeCookie("auth", "", {
          path: "/filter",
          expires: new Date(1691479246),
        });

        removeCookie("user", "", {
          path: "/",
          expires: new Date(1691479246),
        });
        try {
          chrome.runtime.sendMessage(
            process.env.REACT_APP_EXTENSION_ID,
            {
              message: "logout_user",
            },
            function (responseFromExtension) {
              responseFromExtension = responseFromExtension || {};
            }
          );
        } catch (error) {
          console.log(error);
        }
        window.location.href = "/";
      }
    } else {
      removeCookie("auth", "", {
        path: "/",
        expires: new Date(1691479246),
      });

      removeCookie("auth", "", {
        path: "/filter",
        expires: new Date(1691479246),
      });

      removeCookie("user", "", {
        path: "/",
        expires: new Date(1691479246),
      });
      try {
        chrome.runtime.sendMessage(
          process.env.REACT_APP_EXTENSION_ID,
          {
            message: "logout_user",
          },
          function (responseFromExtension) {
            responseFromExtension = responseFromExtension || {};
          }
        );
      } catch (error) {
        console.log(error);
      }
      window.location.href = "/";
    }
  }, [updateCredits]);

  useEffect(() => {
    searchInfluencer();
  }, [searchedCreator]);

  const handleSearchInputChange = (event) => {
    setSearchedCreator(event.target.value);
  };

  const searchInfluencer = async () => {
    if (searchedCreator.length > 2) {
      let isLink = validator.isURL(searchedCreator);
      let username = "";
      let type = "text";
      if (isLink) {
        try {
          let url = new URL(searchedCreator);
          if (url.host.indexOf("instagram") === -1) {
            throw new Error("Not a proper instagram url");
          }
          username = url.pathname.replaceAll("/", "");
          type = "url";
        } catch (error) {
          toast.error(error.message);
          return;
        }
      } else {
        username = searchedCreator;
      }

      let postdata = {
        searched: username,
        searchtype: type,
      };
      const response = await usersEndpoints.searchInfluencer(postdata);
      if (response["success"] === true) {
        setFoundCreators(response["data"]);
      } else {
        toast.error(response["message"]);
      }
    } else {
      setFoundCreators([]);
    }
  };

  function checkExtensionVersion() {
    try {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID,
        { message: "version" },
        function (response) {
          if (response && response["status"] === "ok") {
            // extension is installed
            setExtensionInstalled(true);
          } else {
            setExtensionInstalled(false);
          }
        }
      );
    } catch (error) {
      setExtensionInstalled(false);
    }
  }

  return (
    <Box
      width={{ xs: "100%", lg: `calc(100% - 80px)` }}
      marginLeft={{ xs: "0px", lg: `80px` }}
      sx={{ position: "fixed", top: 0, zIndex: 100 }}
    >
      <AppBar
        position={"sticky"}
        sx={{
          bgcolor: "#fff",
          color: "dark.main",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "end" }}>
          <Tooltip
            title={"Click to show options"}
            sx={{
              marginRight: 2,
              display: { xs: "block", lg: "none" },
            }}
          >
            <IconButton
              onClick={() => {
                setShowMenu(true);
              }}
            >
              <MenuOpenIcon />
            </IconButton>
          </Tooltip>

          {isSearchOpen ? (
            <Box sx={{ flexGrow: 1 }} component={"div"}>
              <FormControl
                sx={{
                  width: {
                    md: "50%",
                  },
                }}
              >
                <OutlinedInput
                  id="inputWithIconAdornment"
                  endAdornment={
                    <InputAdornment position={"start"}>
                      {foundCreators.length > 0 ? (
                        <HighlightOffIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setSearchedCreator("");
                          }}
                        />
                      ) : (
                        <PersonSearchIcon />
                      )}
                    </InputAdornment>
                  }
                  placeholder="Search for a influencer"
                  inputProps={{
                    style: { paddingTop: "9px", paddingBottom: "9px" },
                  }}
                  onChange={handleSearchInputChange}
                  onDrop={handleSearchInputChange}
                  value={searchedCreator}
                />
              </FormControl>
              {foundCreators.length > 0 ? (
                <ShowFilteredList
                  users={foundCreators}
                  installed={extensionInstalled}
                />
              ) : (
                <></>
              )}
            </Box>
          ) : !isYoutube ? (
            <Box sx={{ flexGrow: 1 }} component={"div"}>
              <FormControl
                sx={{
                  width: {
                    md: "50%",
                  },
                }}
              >
                <OutlinedInput
                  id="inputWithIconAdornment_"
                  endAdornment={<PersonSearchIcon />}
                  placeholder="Search for user names (e.g. @username) and keywords from bios"
                  inputProps={{
                    style: { paddingTop: "9px", paddingBottom: "9px" },
                  }}
                  inputRef={inputRef}
                  onKeyPress={handleEnterKeyPress}
                  autoComplete={"off"}
                />
              </FormControl>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 1 }} component={"div"}>
              <FormControl
                sx={{
                  width: {
                    md: "50%",
                  },
                }}
              >
                <OutlinedInput
                  id="inputWithIconAdornment_"
                  endAdornment={<PersonSearchIcon />}
                  placeholder="Search for channel names (e.g. @channelname) and keywords from about"
                  inputProps={{
                    style: { paddingTop: "9px", paddingBottom: "9px" },
                  }}
                  inputRef={inputRef}
                  onKeyPress={handleEnterKeyPress}
                  autoComplete={"off"}
                />
              </FormControl>
            </Box>
          )}
          <Stack
            direction={"row"}
            sx={{
              flexGrow: 0,
              alignItems: "center",
            }}
            spacing={1}
          >
            <Stack>
              <Typography
                variant={"p"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MonetizationOnIcon sx={{ color: "#D73800" }} />
                <Typography
                  variant="body1"
                  display="block"
                  sx={{ color: "#D73800" }}
                >
                  {userDetails.balance}
                </Typography>
              </Typography>
              <Typography>
                <a
                  href="/#pricing_container"
                  style={{
                    textDecoration: "none",
                    color: "#D73800",
                    fontSize: "0.7rem",
                  }}
                >
                  Buy Coins
                </a>
              </Typography>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <ToastContainer
        style={{
          fontSize: "0.85rem",
        }}
        hideProgressBar={true}
        autoClose={2000}
        position={"top-center"}
      />
      <Drawer
        variant="temporary"
        anchor="left"
        open={showMenu}
        onClose={() => {
          setShowMenu(false);
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 80 },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SidebarComponent />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 80 },
        }}
        open
      >
        <SidebarComponent />
      </Drawer>
    </Box>
  );
}

export default DashboardHeaderSection;
