import { TextField, Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import paymentdashboardservice from "../../../services/paymentdashboardservice";
import { useState, useEffect } from "react";

function Category({ getValues, setValue, errors, control }) {
  const [categories, setCategories] = useState([]);

  const Init = async () => {
    let categories = await paymentdashboardservice.FetchCampaignCategories();
    categories = categories.map((obj) => ({ ...obj, label: obj.name }));
    setCategories(categories);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <Controller
      name="category"
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <Autocomplete
          id="category-dropdown"
          options={categories}
          getOptionLabel={(option) => option.name}
          sx={{ width: 350 }}
          value={
            categories.find((cat) => cat._id === getValues("category")) || null
          }
          onChange={(_, newValue) =>
            setValue("category", newValue?._id || null)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              error={errors.category ? true : false}
              helperText={errors.category?.message}
              label="Category"
            />
          )}
        />
      )}
    />
  );
}

export default Category;
