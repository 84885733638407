import React from "react";
import { Box, Stack, Chip } from "@mui/material";

function ShowFilters({ filters, handleDelete }) {
  function hasValue(object) {
    return Object.values(object).some((value) => value);
  }

  return (
    hasValue(filters) && (
      <Box sx={{ padding: 1 }}>
        <Stack direction="row" flexWrap="wrap" spacing={1}>
          {Object.keys(filters).map((item) => {
            if (item === "search" && Array.isArray(filters[item])) {
              return filters[item].map((subItem, subIndex) => (
                <Chip
                  label={subItem.toUpperCase()}
                  variant="filled"
                  color="primary"
                  onDelete={() => handleDelete(item, subIndex)}
                  key={`${item}-${subIndex}`}
                  sx={{ marginBottom: 1 }}
                />
              ));
            } else if (filters[item] !== null) {
              return (
                <Chip
                  label={
                    {
                      followers: filters[item]["range"],
                      age: filters[item]["agerange"],
                      reelCost: filters[item]["reelcostrange"],
                      gender: filters[item] === "M" ? "Male" : filters[item] === "F" ? "Female" : "Other",
                      platform:
                        filters[item] === "Instagram" ? "Instagram" : "Youtube",
                    }[item] || filters[item]
                  }
                  variant="filled"
                  onDelete={() => handleDelete(item)}
                  key={item}
                  sx={{ marginBottom: 1 }}
                />
              );
            }
            return null; // Return null for empty or invalid filters
          })}
        </Stack>
      </Box>
    )
  );
}

export default ShowFilters;
