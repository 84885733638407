import React, { useEffect, useState } from 'react';
import { Chip, CssBaseline } from '@mui/material';
import Home from '../components/finance/Home';
import DashboardHeaderSection from '../components/common/dashboardheader/DashboardHeader';
import paymentdashboardservice from '../services/paymentdashboardservice';
import Swal from 'sweetalert2';
import FinanceHeader from '../components/finance/common/FinanceHeader';

export default function Finance() {
  const fontSize = { fontSize: '0.7rem' };
  const fontStyle = { fontSize: '0.7rem', fontWeight: 'bold' };

  const [vendors, setVendors] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [paymentVendorData, setPaymentVendorData] = useState([]);
  const [paymentInfluencerData, setPaymentInfluencerData] = useState([]);
  const [financialVendorPayment, setFinancialVendorPayment] = useState([]);
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const [activePaymentIndex, setActivePaymentIndex] = useState(-1);
  const [showInfluencerPaymentDetails, setShowInfluencerPaymentDetails] = useState(false);
  const [showVendorPaymentDetails, setShowVendorPaymentDetails] = useState(false);
  const [searchCampaign, setSearchCampaign] = useState("");
  const [searchVendor, setSearchVendor] = useState("");

  const handleVendorSearch = async (value) => {
    if (value) setSearchVendor(value);
    else window.location.reload();

    const response = !searchVendor
      ? vendors
      : vendors.filter((item) =>
        item.name[0].toLowerCase().includes(searchVendor.toLowerCase())
      );

    setVendors(response);
  };

  const handleCampaignSearch = async (value) => {
    if (value) setSearchCampaign(value);
    else window.location.reload();

    const response = !searchCampaign
      ? campaigns
      : campaigns.filter((item) =>
        item.campaignName[0]
          .toLowerCase()
          .includes(searchCampaign.toLowerCase())
      );

    setCampaigns(response);
  };

  async function handleShow(index, id) {
    const tableRows = document.getElementsByTagName("tr");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }

    const elemnt = document.getElementById(index);
    elemnt.classList.add("tableActive");
    const resp = await paymentdashboardservice.getByCampaignId({ id: id });
    setPaymentInfluencerData(resp);

    const options = document.getElementsByTagName("option");
    var temp = 0;
    for (let j = 0; j < resp.length; j++) {
      const currentStatus = resp[j].status;
      for (let i = temp; i < options.length; i++) {
        const value = options[i].value;
        const finalValue = value.split("_")[1];
        if (finalValue === currentStatus) {
          options[i].setAttribute("selected", "");
          break;
        }
      }
      temp += 5;
    }

    if (showInfluencerPaymentDetails) {
      setShowInfluencerPaymentDetails(false);
    }

    if (showVendorPaymentDetails) {
      setShowVendorPaymentDetails(false);
    }

    // if (toggleCompanyList) {
    //     setToggleCompanyList(false);
    // }
  }

  async function handleVendorShow(index, name, id) {
    const tableRows = document.getElementsByTagName("tr");
    for (var i = 0; i < tableRows.length; i++) {
      if (tableRows[i].classList.contains("tableActive"))
        tableRows[i].classList.remove("tableActive");
    }

    const elemnt = document.getElementById(index);
    elemnt.classList.add("tableActive");
    const resp = await paymentdashboardservice.getByVendorId({ id: id });
    setPaymentVendorData(resp);

    const options = document.getElementsByTagName("option");
    var temp = 0;
    for (let j = 0; j < resp.length; j++) {
      const currentStatus = resp[j].status;
      for (let i = temp; i < options.length; i++) {
        const value = options[i].value;
        const finalValue = value.split("_")[1];
        if (finalValue === currentStatus) {
          options[i].setAttribute("selected", "");
          break;
        }
      }
      temp += 5;
    }

    if (showInfluencerPaymentDetails) {
      setShowInfluencerPaymentDetails(false);
    }

    if (showVendorPaymentDetails) {
      setShowVendorPaymentDetails(false);
    }
  }

  const uploadGstScreenshot = async (id) => {
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload GST Screenshot",
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Swal.fire({
          title: "Your Uploaded Picture",
          imageUrl: e.target.result,
          imageAlt: "The Uploaded Picture",
        });
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      await paymentdashboardservice.uploadGstScreenshot(formData);
    }
  };

  const handleUpload = async (id) => {
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload Payment Screenshot",
      },
    });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        Swal.fire({
          title: "Your Uploaded Picture",
          imageUrl: e.target.result,
          imageAlt: "The Uploaded Picture",
        });
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      await paymentdashboardservice.getUploadScreenshot(formData);
    }
  };

  const handleFail = async (id, isCompany, payment_Status) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Fail it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Submit the Remarks",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: async (msg) => {
              if (isCompany) {
                setPaymentVendorData(
                  paymentVendorData.filter((item) => item._id !== id)
                );
                if (showVendorPaymentDetails) {
                  setShowVendorPaymentDetails(false);
                }
              } else {
                setPaymentInfluencerData(paymentInfluencerData.filter((item) => item._id !== id));
                if (showInfluencerPaymentDetails) {
                  setShowInfluencerPaymentDetails(false);
                }
              }
              await paymentdashboardservice.failRemarks({
                id: id,
                remarks: msg,
                isCompany: isCompany,
                payment_Status: payment_Status,
              });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              swalWithBootstrapButtons.fire(
                "Failed!",
                "Your data has been failed.",
                "success"
              );
            }
          });
        }
      });
  };

  const handleGstPay = async (id, isGstPay) => {
    const element = document.getElementById(id);
    if (element.checked) {
      Swal.fire({
        title: "Do you want to pay GST?",
        showDenyButton: true,
        confirmButtonText: "Now",
        denyButtonText: `Later`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire("Done!", "", "success");
          if (!isGstPay)
            await paymentdashboardservice.updateIsGstPay({ _id: id.split("-")[0] });
        } else if (result.isDenied) {
          Swal.fire("Please, Pay GST later", "", "info");
          element.checked = false;
        }
      });
    }
  };

  const handleDelete = async (id, isCompany) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Submit the Remarks",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            preConfirm: async (msg) => {
              if (isCompany) {
                setPaymentVendorData(
                  paymentVendorData.filter((item) => item._id !== id)
                );
                if (showVendorPaymentDetails) {
                  setShowVendorPaymentDetails(false);
                }
              } else {
                setPaymentInfluencerData(paymentInfluencerData.filter((item) => item._id !== id));
                if (showInfluencerPaymentDetails) {
                  setShowInfluencerPaymentDetails(false);
                }
              }
              await paymentdashboardservice.deleteRemarks({
                id: id,
                remarks: msg,
                isCompany: isCompany,
              });
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              );
            }
          });
        }
      });
  };

  const handleSubmit = async (
    id,
    isCompany,
    elm_id,
    payment_Status,
    isGstRequested
  ) => {
    await paymentdashboardservice.addPaymentStatus({
      _id: id,
      isCompany: isCompany,
      payment_Status: payment_Status,
      isGstRequested: isGstRequested,
    });

    if (payment_Status === "Done")
      document.getElementById(elm_id).innerHTML = `${payment_Status}(${new Date(
        Date.now()
      ).toLocaleDateString(undefined, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      })})`;

    Swal.fire({
      icon: "success",
      title: "Updated!",
      showConfirmButton: false,
      timer: 2000,
    });

    window.location.reload();
  };

  const handleRadioButton = async (id, typeOfPayment) => {
    await paymentdashboardservice.updateTypeOfPayment({
      id: id,
      typeOfPayment: typeOfPayment,
    });
  };

  const renderFinancialPayment = (id) => {
    let filterFinancialPayment = financialVendorPayment.filter(
      (item) => item._id === id
    );

    if (filterFinancialPayment.length > 0)
      return parseInt(filterFinancialPayment[0]["TotalCost"]);

    return null;
  };

  const handleFinancialPayment = (id) => {
    let financialPay = null;
    if (id) financialPay = renderFinancialPayment(id);

    return financialPay ? (
      <Chip label={financialPay} />
    ) : (
      "---"
    );
  };

  const handleTDSAmount = (amount, pancard) => {
    let finalResult = null;
    if (pancard.charAt(3) === "P" || pancard.charAt(3) === "H") {
      finalResult = `${amount * 0.01} (1%)`;
    } else if (pancard.charAt(3) === "F" || pancard.charAt(3) === "C") {
      finalResult = `${amount * 0.02} (2%)`;
    }
    return finalResult;
  };

  const handleTDS = (amount, pancard, fpayment) => {
    let finalResult = null;

    if (fpayment >= 100000) {
      finalResult = handleTDSAmount(amount, pancard);
    }

    if (amount >= 30000) {
      finalResult = handleTDSAmount(amount, pancard);
    }

    return finalResult ? (
      <Chip label={finalResult} />
    ) : (
      "---"
    );
  };

  async function handleDownload(id, name, isCompany) {
    const resp = await paymentdashboardservice.downloadCampaigns({
      id: id,
      name: name,
      isCompany: isCompany,
    });
    const url = process.env.REACT_APP_BASE_URL + "/" + resp.name;
    window.open(url, "_blank", "noopener,noreferrer");
  }

  const Init = async () => {
    const campaigns = await paymentdashboardservice.getAllByCampaigns();
    setCampaigns(campaigns);
    const vendors = await paymentdashboardservice.getAllCampaignsByVendor();
    setVendors(vendors);

    const influencerFilterData = await paymentdashboardservice.getSelectedStatus({
      status: "",
      isCompany: false,
    });

    const vendorFilterData = await paymentdashboardservice.getSelectedStatus({
      status: "",
      isCompany: true,
    });

    setPaymentVendorData(vendorFilterData.payment);
    setPaymentInfluencerData(influencerFilterData.payment);
    // setInitialPaymentCompanyData(vendorFilterData.payment);
    setFinancialVendorPayment(vendorFilterData.financialPayment);
    setPaymentInfluencerData(influencerFilterData.payment);
    // setInitialPaymentData(influencerFilterData.payment);
    // setFinancialPayment(influencerFilterData.financialPayment);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <FinanceHeader />
      <Home
        fontSize={fontSize}
        fontStyle={fontStyle}
        vendors={vendors}
        campaigns={campaigns}
        paymentInfluencerData={paymentInfluencerData}
        paymentVendorData={paymentVendorData}
        handleTDS={handleTDS}
        renderFinancialPayment={renderFinancialPayment}
        handleFinancialPayment={handleFinancialPayment}
        handleDownload={handleDownload}
        handleRadioButton={handleRadioButton}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        handleGstPay={handleGstPay}
        handleFail={handleFail}
        handleUpload={handleUpload}
        uploadGstScreenshot={uploadGstScreenshot}
        handleShow={handleShow}
        handleVendorShow={handleVendorShow}
        searchCampaign={searchCampaign}
        handleCampaignSearch={handleCampaignSearch}
        searchVendor={searchVendor}
        handleVendorSearch={handleVendorSearch}
        setShowInfluencerPaymentDetails={setShowInfluencerPaymentDetails}
      />
    </>
  )
}
