import React, { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import Home from "../components/payments/Home";
import "../components/payments/css/Payments.css";
import DashboardHeaderSection from "../components/common/dashboardheader/DashboardHeader";

export const PaymentDashboard = () => {
  const Init = async () => {};

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <CssBaseline />
      <DashboardHeaderSection />
      <Home />
    </>
  );
};

export default PaymentDashboard;
