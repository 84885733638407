import http from "./httpservices";
import GetCookieByName from "../hooks/GetCookie";

const endPoint = "payment-dashboard/";

export async function FetchDashboardStats() {
  const { data } = await http.get(endPoint, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchLatestCampaigns() {
  const { data } = await http.get(`${endPoint}campaign/latest`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchTop10Links() {
  const { data } = await http.get(`${endPoint}top10link`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchCampaignCategories() {
  const { data } = await http.get(`${endPoint}campaign/categories`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchVendorCategories() {
  const { data } = await http.get(`${endPoint}vendor/categories`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchPlatforms() {
  const { data } = await http.get(`${endPoint}platforms`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchVendors() {
  const { data } = await http.get(`${endPoint}vendors`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchCampaigns() {
  const { data } = await http.get(`${endPoint}campaigns`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchCampaignSearch(params) {
  const { data } = await http.post(`${endPoint}campaign/search`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function CampaignDetailStatus(params) {
  const { data } = await http.post(`${endPoint}campaign/detail/status`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function updateCampaignBudget(params) {
  const { data } = await http.post(`${endPoint}campaign/edit/budget`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function deleteCampaign(params) {
  const { data } = await http.post(`${endPoint}campaign/delete`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function FetchVendorSearch(params) {
  const { data } = await http.post(`${endPoint}vendor/search`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function getAllByCampaigns() {
  const { data } = await http.get(`${endPoint}finance/allcampaigns`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function downloadCampaigns(params) {
  const { data } = await http.post(`${endPoint}finance/download`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function getAllCampaignsByVendor() {
  const { data } = await http.get(`${endPoint}finance/allcampaignsbyvendor`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function getSelectedStatus(params) {
  const { data } = await http.post(`${endPoint}finance/statusfilter`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function addPaymentStatus(params) {
  const { data } = await http.post(`${endPoint}finance/addstatus`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function getByCampaignId(params) {
  const { data } = await http.post(`${endPoint}finance/getbycampaignid`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function getByVendorId(params) {
  const { data } = await http.post(`${endPoint}finance/getbyvendorid`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function getPaymentDetailsById(params) {
  const { data } = await http.post(`${endPoint}finance/detailbyid`, params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export default {
  FetchDashboardStats,
  FetchLatestCampaigns,
  FetchTop10Links,
  FetchCampaignCategories,
  FetchVendorCategories,
  FetchPlatforms,
  FetchVendors,
  FetchCampaigns,
  FetchCampaignSearch,
  CampaignDetailStatus,
  updateCampaignBudget,
  deleteCampaign,
  FetchVendorSearch,
  getAllByCampaigns,
  downloadCampaigns,
  getAllCampaignsByVendor,
  getSelectedStatus,
  addPaymentStatus,
  getByCampaignId,
  getByVendorId,
  getPaymentDetailsById
};
