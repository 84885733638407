import { Box } from '@mui/material';
import * as React from 'react';
import HeaderSection from '../components/common/header/Header';
import InfluencerPageBanner from '../components/influencer/PageBanner';
import SubscribeThroughEmail from '../components/common/subscribe/SubscribeEmail';

function Influencer(){
    return (
        <Box>
            <HeaderSection showNav={true} />
            <InfluencerPageBanner />
            <SubscribeThroughEmail />
        </Box>
    )
}

export default Influencer;