const ColorPalette = {
    palette: {
        primary: {
            main: "#FF5200",
            contrastText: "#FFF"
        },
        secondary: {
            main: "#FA9905",
            contrastText: "#FFF"
        },
        light: {
            main: "#ECEFF1"
        },
        dark: {
            main: "#160647"
        },
        text: {
            primary: '#160647',
            grey: '#8d8989'
        },
        badgeprimary: {
            main: 'rgba(255, 81, 0, 0.15)',
            contrastText: 'rgb(255, 81, 0)'
        },
        success:{
            main: "#09AC31",
            contrastText: "#FFF"
        },
        danger:{
            main: "#E34C14",
            contrastText: "#FFF"
        },
        instagram:{
            main: "#CD486B",
            contrastText: '#FFF'
        }
    }
};

export default ColorPalette;