import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Card, Chip, Grid, Tooltip, Typography } from "@mui/material";
import {
  Add,
  CurrencyRupee,
  Delete,
  Edit,
  Interests,
  People,
  Person,
  TextSnippet,
} from "@mui/icons-material";
import IdToDate from "../../common/date/IdToDate";

export default function CampaignCard({ campaignDetails, handleLinkGenerate }) {
  const navigate = useNavigate();
  const styles = {
    iconWrapper: {
      display: "flex",
    },
    icon: {
      fontSize: "30px",
      cursor: "pointer",
      //   borderRadius: "50%", // Make it circular
      padding: "6px", // Add padding for better appearance
    },
    addIcon: {
      backgroundColor: "#0AC380",
      color: "#fff", // Set text color to make it visible
    },
    editIcon: {
      backgroundColor: "#FFD206",
      color: "#fff", // Set text color to make it visible
    },
    deleteIcon: {
      backgroundColor: "#ff0000", // You can set your desired color
      color: "#fff", // Set text color to make it visible
    },
  };
  return (
    <>
      <Grid item xs={6}>
        <Card
          sx={{
            padding: 3,
            // borderRadius: 3,
            // border: "1px solid #B8B3C7",
            height: "100%",
            boxShadow: 0,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  alignSelf: "center",
                  color: "#fff",
                  backgroundColor: "#e34f4f",
                  //   borderRadius: "50%",
                  paddingX: 1,
                  paddingTop: 1,
                  paddingBottom: 0.4,
                }}
              >
                <TextSnippet />
              </Box>
              <Box sx={{ marginLeft: 1 }}>
                <Typography variant="subtitle2">
                  {campaignDetails.name}
                </Typography>
                <Typography variant="caption" color={"secondary"}>
                  {campaignDetails.category}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box marginRight={1}>
                <Typography variant="subtitle2">
                <IdToDate id={campaignDetails.id} />
                </Typography>
              </Box>
              <Box sx={styles.iconWrapper}>
                <Tooltip title="Create Link">
                  <Add
                    sx={{ ...styles.icon, ...styles.addIcon, marginRight: 0.5 }}
                    onClick={() => handleLinkGenerate(campaignDetails)}
                  />
                </Tooltip>
                <Tooltip title="Edit Campaign Budget">
                  <Edit
                    sx={{
                      ...styles.icon,
                      ...styles.editIcon,
                      marginRight: 0.5,
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete Campaign">
                  <Delete
                    color="error"
                    sx={{ ...styles.icon, ...styles.deleteIcon }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: 3 }}>
            <Typography variant="subtitle2">
              {campaignDetails.deliverables}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <Tooltip title="">
                <Person color="secondary" sx={{ fontSize: "20px" }} />
              </Tooltip>
              <Typography variant="subtitle2">
                {campaignDetails.createdby}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ marginRight: 1 }}>
                <Typography variant="subtitle2">
                  {campaignDetails.totalPayment}{" "}
                  <CurrencyRupee color="secondary" sx={{ fontSize: "15px" }} />
                </Typography>
                <Typography variant="subtitle2">Budget</Typography>
              </Box>
              <Box sx={{ marginRight: 1 }}>
                <Typography variant="subtitle2">
                  {campaignDetails.paymentLimit}{" "}
                  <CurrencyRupee color="secondary" sx={{ fontSize: "15px" }} />
                </Typography>
                <Typography variant="subtitle2" color={"error"}>
                  Limit
                </Typography>
              </Box>
              <Box sx={{ marginRight: 1 }}>
                <Typography variant="subtitle2">
                  {campaignDetails.platform}{" "}
                  <Interests color="secondary" sx={{ fontSize: "15px" }} />
                </Typography>
                <Typography variant="subtitle2">Platform</Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
}
