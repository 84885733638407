import * as React from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  TableBody,
  TableContainer,
  TableCell,
  Paper,
  Table,
  TableRow,
} from "@mui/material/";
import SwipeableViews from "react-swipeable-views";
import { YouTube, Instagram, Done, Close } from "@mui/icons-material";
import { TableHead } from "@material-ui/core";

export default function PostTable({ loading, reports }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="large"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Platform
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Post
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Likes
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Views
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Reshare
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Comments
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Last Update
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  reports.map((r, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="right">{r.platform}</TableCell>
                        <TableCell align="right">{r.link}</TableCell>
                        <TableCell align="right">{r.like_count}</TableCell>
                        <TableCell align="right">{r.views}</TableCell>
                        <TableCell align="right">{r.reshare_count}</TableCell>
                        <TableCell align="right">{r.comment_count}</TableCell>
                        <TableCell align="right">
                          {new Date(parseInt(r.timeStamp)).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="right">
                          {r.apiHit ? <Done /> : <Close color="primary" />}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon label tabs example"
      >
        <Tab
          icon={<Instagram />}
          sx={{ padding: 0, margin: 0 }}
          iconPosition="start"
          label="INSTAGRAM"
        />
        <Tab icon={<YouTube />} iconPosition="start" label="YOUTUBE" />
      </Tabs>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} />
        <TabPanel value={value} index={1} />
      </SwipeableViews>
    </>
  );
}
