import http from "./httpservices";
import GetCookieByName from '../hooks/GetCookie';
// import jwtDecode from "jwt-decode";

// const tokenKey = "etoken";

export async function getPayments() {
	const { data } = await http.get("payment/");
	return data;
}

export async function createRazorPayOrder(params) {
	const { data } = await http.post(
		"payment/create/orderId", 
		params,
		{ headers: { 'x-auth-token': JSON.parse(GetCookieByName('auth'))['token'] } }
	);
	return data;
}

export async function verifyOrder(params) {
	try {
		const { data } = await http.post(
			"payment/verify",
			params,
			{ headers: { 'x-auth-token': JSON.parse(GetCookieByName('auth'))['token'] } }
		);
		return data;
  	} 
	catch (error) {
		return "error";
	}
}

async function cancelOrder(postdata){
	const { data } = await http.post(
		"payment/cancelorder", 
		postdata, 
		{ headers: { 'x-auth-token': JSON.parse(GetCookieByName('auth'))['token']  } }
	)
	return data;
}

async function fetchInvoiceDetails(postdata){
	const { data } = await http.post(
		"payment/get_invoice_details",
		postdata,
		{ headers: { 'x-auth-token': JSON.parse(GetCookieByName('auth'))['token']  } }
	);
	return data;
}

const paymentservice = {
	getPayments,
	verifyOrder,
	createRazorPayOrder,
	cancelOrder,
	fetchInvoiceDetails
};

export default paymentservice;