import React, { useEffect, useState } from "react";
import Content from "./InfluencerContent";
import influencerservice from "../../services/influencerservice";
import categoryservice from "../../services/categoryservice";

import {
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  OutlinedInput,
  Button,
} from "@mui/material";

export const InfluencerSearch = () => {
  const [searchContent, setSearchContent] = useState(false);
  const [inputText, setInputText] = useState("");
  const [categoryCount, setCategoryCount] = useState([]);
  const [influencerCount, setInfluencerCount] = useState([]);

  const openSearch = () => {
    setSearchContent(!searchContent);
  };

  const inputHandler = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const getInit = async () => {
    const responseInfluencerContent =
      await influencerservice.getInfluencersCount();
    setInfluencerCount(responseInfluencerContent.count);

    const responseCategoryContent = await categoryservice.getCategoriesCount();
    setCategoryCount(responseCategoryContent.count);
  };

  useEffect(() => {
    getInit();
  }, []);



  return (
    <Box
      width={{ xs: "100%", lg: `calc(100% - 80px)` }}
      marginLeft={{ xs: "0px", lg: `80px` }}
      marginTop={5}
    >
      <Typography variant="h4" textAlign={"center"}>
        Search from the{" "}
        <span style={{ color: "#E03A00" }}>Largest Database</span> of
        Influencers
      </Typography>
      <Typography
        variant="p"
        component={"p"}
        marginTop={1}
        textAlign={"center"}
      >
        Access over {influencerCount}&nbsp;
        <span style={{ fontWeight: "bold" }}>Influencers</span> from {categoryCount}&nbsp;
        <span style={{ fontWeight: "bold" }}>Categories</span>
      </Typography>
      <Box marginTop={3} textAlign={"center"}>
        <FormControl
          sx={{
            width: "20ch",
          }}
        >
          <InputLabel id="demo-select-small-label">Platform</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            sx={{ borderRadius: "50px 0px 0px 50px" }}
            // value={platform}
            label="Platform"
          // onChange={handleChange}
          >
            <MenuItem value={"Instagram"}>Instagram</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: "75ch" }}>
          <OutlinedInput
            placeholder="Type names, city, categories keywords"
            sx={{ borderRadius: "0px 50px 50px 0px" }}
            onClick={openSearch}
            onChange={inputHandler}
            onKeyUp={openSearch}
          />
        </FormControl>
        {searchContent && <Content inputText={inputText} />}
      </Box>
      <Box marginX={"50%"} marginY={2}>
        <Button
          variant="contained"
          size="large"
          sx={{ borderRadius: "50px" }}
          onClick={() => (window.location.href = "/filter/influencers")}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default InfluencerSearch;
