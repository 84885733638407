import * as React from 'react';
import { Button, Typography, Container, Stack, CardContent, Card, Divider, Grid } from '@mui/material';
import { MailOutlineRounded, PhoneForwardedOutlined } from '@mui/icons-material';

function ContactDetails() {
    return (
        <>
            <Stack
                sx={{
                    minHeight: '15rem',
                    background: '#f5f7fa'
                }}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography
                    variant='h5'
                    fontWeight={600}
                >
                    Contact Us
                </Typography>

                <Typography
                    variant='p'
                    fontSize={'0.95rem'}
                    sx={{ opacity: 0.7, letterSpacing: '0.5px' }}
                >
                    Last updated : October 2022
                </Typography>
            </Stack>

            <Container
                maxWidth={'md'}
                sx={{
                    textAlign: 'center',
                    paddingY: 5,
                    height: "60vh"
                }}
            >
                <Grid
                    container
                    justifyContent={'center'}
                    columnGap={2}
                >
                    <Grid item xs={12} md={5}>
                        <Card
                            variant='outlined'
                        >
                            <CardContent>
                                <Typography
                                    marginBottom={2}
                                >
                                    Have a query?
                                    <br />
                                    Reach out to us by filling the following form
                                </Typography>

                                <Button
                                    variant='contained'
                                    color='primary'
                                    href='https://forms.gle/7PGr6zwphBvWC3HT9'
                                    sx={{ textTransform: 'capitalize' }}
                                >
                                    send query
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Divider
                        orientation='vertical'
                        flexItem
                    >
                        OR
                    </Divider>

                    <Grid item xs={12} md={5}>
                        <Card
                            sx={{
                                minHeight: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            variant='outlined'
                        >
                            <CardContent>
                                <Typography
                                    textAlign={'left'}
                                    fontSize={'1rem'}
                                >
                                    Reach out to us
                                </Typography>

                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    alignItems={'center'}
                                    marginY={1}
                                >
                                    <PhoneForwardedOutlined
                                        color='primary'
                                    />

                                    <Typography
                                        color={'primary.main'}
                                        fontWeight={'bold'}
                                    >
                                        +91 8142051271
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                    alignItems={'center'}
                                >
                                    <MailOutlineRounded
                                        color='primary'
                                    />

                                    <Typography
                                        color={'primary.main'}
                                        fontWeight={'bold'}
                                    >
                                        support@meesha.co.in
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

        </>
    )
}

export default ContactDetails;