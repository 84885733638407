import {
  CurrencyRupee,
  Facebook,
  Instagram,
  Interests,
  LinkedIn,
  Telegram,
  Twitter,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Button,
  Chip,
  Grid,
  Box,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import paymentdashboardservice from "../../../services/paymentdashboardservice";
import LatestCampaignSkelton from "../../../CustomSkelton/LatestCampaignSkelton";

export default function CampaignsList({ loading, campaigns }) {
  const navigate = useNavigate();

  const PlatformIconByName = {
    Instagram: <Instagram color="error" fontSize="small" />,
    Youtube: <YouTube color="error" fontSize="small" />,
    Twitter: <Twitter color="error" fontSize="small" />,
    Facebook: <Facebook color="error" fontSize="small" />,
    WhatsApp: <WhatsApp color="error" fontSize="small" />,
    LinkedIn: <LinkedIn color="error" fontSize="small" />,
    Telegram: <Telegram color="error" fontSize="small" />,
    Other: <Interests color="error" fontSize="small" />,
  };

  return (
    <Grid item xs={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            {loading ? (
              <LatestCampaignSkelton />
            ) : campaigns.length > 0 ? (
              <List
                sx={{ bgcolor: "background.paper" }}
                subheader={
                  <ListSubheader
                    sx={{
                      fontSize: "1.3rem",
                      color: "black",
                    }}
                    id="nested-list-subheader"
                  >
                    Latest Campaigns ({campaigns.length})
                  </ListSubheader>
                }
              >
                <Divider />
                {campaigns.map((d, index) => (
                  <>
                    <ListItem
                      key={index}
                      alignItems="flex-start"
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/payments/campaigns")}
                    >
                      <ListItemText
                        primary={
                          <div
                            style={{
                              display: "flex",
                              fontSize: "9px",
                              alignItems: "center",
                            }}
                          >
                            {
                              PlatformIconByName[
                                d.platform.map((platform) => platform.name)
                              ]
                            }
                            <span style={{ margin: "0 4px" }}>|</span>
                            <Chip label={d.name} size="small" sx={{fontSize: "10px"}} />
                            <span style={{ margin: "0 4px" }}>|</span>
                            {d.user.map((user) => user)}
                            
                          </div>
                        }
                        primaryTypographyProps={{ variant: "overline" }}
                        secondary={
                          <>
                            <Box>
                              <b>
                                <CurrencyRupee fontSize="" />
                                {d.totalPayment}
                              </b>{" "}
                              | {d.paymentLimit}
                            </Box>
                          </>
                        }
                        secondaryTypographyProps={{ variant: "overline" }}
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))}
                <ListItem>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => navigate("/payments/campaigns")}
                  >
                    View All
                  </Button>
                </ListItem>
              </List>
            ) : (
              <ListSubheader
                sx={{ fontSize: "1.5rem", color: "red" }}
                id="nested-list-subheader"
              >
                No Campaigns
              </ListSubheader>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
