import { Box } from '@mui/material';
import * as React from 'react';
import HeaderSection from '../components/common/header/Header';
import AboutUsBody from '../components/aboutus/Aboutus';
import SubscribeThroughEmail from '../components/common/subscribe/SubscribeEmail';

function AboutUsPage(){
    return (
        <Box>
            <HeaderSection showNav={ true } />
            <AboutUsBody />
            <SubscribeThroughEmail/>
        </Box>
    )
}

export default AboutUsPage;