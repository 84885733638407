import http from "./httpservices";
import GetCookieByName from "../../hooks/GetCookie";

const endPoint = "filter/influencers";

export async function FetchInfluencers(filter, sorting, page, isContacts) {
  return http.get(`${endPoint}/filters`, {
    params: { filter, sorting, page, isContacts },
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
}

export async function FetchInfluencersCount(filter, isContacts) {
  return http.get(`${endPoint}/count`, {
    params: { filter, isContacts },
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
}

export default {
  FetchInfluencers,
  FetchInfluencersCount,
};
