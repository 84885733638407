import React, { useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Link,
  Avatar,
  Checkbox,
  Card
} from "@mui/material";
import {
  FileUpload,
  Visibility,
  Error,
  CheckCircle,
  Delete,
  ArrowDownward,
} from "@mui/icons-material";
import IdToDate from "../common/date/IdToDate";
import paymentdashboardservice from "../../services/paymentdashboardservice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function FinanceInfluencerDetailsTable({
  fontSize,
  fontStyle,
  paymentInfluencerData,
  handleTDS,
  renderFinancialPayment,
  handleFinancialPayment,
  handleRadioButton,
  handleSubmit,
  handleDelete,
  handleFail,
  handleUpload,
  uploadGstScreenshot,
  handleGstPay,
}) {
  const [paymentInfluencerDetails, setPaymentInfluencerDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleInfluencerView = async (id) => {
    const response = await paymentdashboardservice.getPaymentDetailsById({
      id: id,
    });
    setPaymentInfluencerDetails(response);
  };

  const toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    handleInfluencerView(id);
    setState({ ...state, [anchor]: open });
  };

  const anchor = "right";

  const list = (anchor) => (
    <>
      <Box
        sx={{
          width: anchor === "top" || anchor === "bottom" ? "auto" : 400,
          padding: 2,
        }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          Details
        </Typography>
        <Divider />
        <TableContainer sx={{ marginTop: 2 }}>
          <Table aria-label="simple table" size="medium">
            <TableBody>
              {paymentInfluencerDetails && paymentInfluencerDetails.map((payment) => (
                <>
                  <TableRow key={payment._id}>
                    <TableCell sx={{ fontStyle }}>Influencer</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.influencer ? payment.influencer.username : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Platform</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.platform.length > 0 ? payment.platform.map((p) => p.name + " ") : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Campaign</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.campaign && payment.campaign.name ? payment.campaign.name : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Deliverables</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.deliverables ? payment.deliverables : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Comment</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.comment ? payment.comment : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>POC</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.createdby ? payment.createdby.fullName : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Approved By</TableCell>
                    {payment.approvedby ? (
                      <TableCell sx={{ fontSize }}>
                        {payment.approvedby.split(",")[0]
                          ? payment.approvedby.split(",")[0]
                          : "---"}
                        &nbsp;
                        {payment.approvedby.split(",")[1] ? (
                          <Typography variant="h6" sx={{ display: 'inline' }}>
                            <Chip label={`${new Date(
                              parseInt(payment.approvedby.split(",")[1])
                            ).toLocaleDateString(undefined, {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                              }`} />
                          </Typography>
                        ) : (
                          <TableCell>---</TableCell>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell sx={{ fontSize }}>---</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Total Amount</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.cost ? payment.cost : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>GST Amount</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.gstAmount ? payment.gstAmount : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Payable Amount</TableCell>
                    <TableCell sx={{ fontSize }}>
                      {payment.payableAmount ? payment.payableAmount : "---"}
                      {" "}
                      {payment.isClub && <Tooltip title="View Club Links">
                        <Visibility fontSize="small" onClick={handleOpen} sx={{ verticalAlign: 'middle', cursor: 'pointer' }} />
                      </Tooltip>}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>TDS</TableCell>
                    <TableCell sx={{ fontSize }}>
                      {handleTDS(
                        parseInt(payment.payableAmount),
                        payment.pancard,
                        renderFinancialPayment(payment.influencer._id)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Financial</TableCell>
                    <TableCell sx={{ fontSize }}>
                      {handleFinancialPayment(payment.influencer?._id)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Email</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.influencer.email ? payment.influencer.email : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Phone</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.influencer.number ? payment.influencer.number : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>DOB</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.influencer.dob ? payment.influencer.dob : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>State</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.influencer.state ? payment.influencer.state : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Account Name</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.accountName ? payment.accountName : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Account Number</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.accountNo ? payment.accountNo : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>IFSC Code</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.ifscCode ? payment.ifscCode : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Pancard</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.pancard ? payment.pancard : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Paytm</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.paytmNumber ? payment.paytmNumber : "---"}</TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </>
  );

  return (
    <>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
      <Card>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontStyle }}>#</TableCell>
                <TableCell sx={{ fontStyle }}>Date</TableCell>
                <TableCell sx={{ fontStyle }}>Creator</TableCell>
                <TableCell sx={{ fontStyle }}>Campaign</TableCell>
                <TableCell sx={{ fontStyle }}>Total</TableCell>
                {/* <TableCell sx={{ fontStyle }}>TDS</TableCell> */}
                <TableCell sx={{ fontStyle }}>GST</TableCell>
                <TableCell sx={{ fontStyle }}>Percentage</TableCell>
                <TableCell sx={{ fontStyle }}>Payable</TableCell>
                {/* <TableCell sx={{ fontStyle }}>Financial</TableCell> */}
                <TableCell sx={{ fontStyle }}>POC</TableCell>
                <TableCell sx={{ fontStyle }}>Invoice</TableCell>
                <TableCell sx={{ fontStyle }}>GST</TableCell>
                <TableCell sx={{ fontStyle }}>Status</TableCell>
                <TableCell sx={{ fontStyle }}>Type</TableCell>
                <TableCell sx={{ fontStyle }}>Action</TableCell>
                {/* <TableCell sx={{ fontStyle }}></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-search"
                  label="Campaign..."
                  type="search"
                  size='small'
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-search"
                  label="POC..."
                  type="search"
                  size='small'
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow> */}
              {paymentInfluencerData && paymentInfluencerData.map((payment, index) => (
                <TableRow key={payment._id} id={`${index}-paymentInfluencerData`}>
                  <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{index + 1}</TableCell>
                  <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}><IdToDate id={payment._id} /></TableCell>
                  {payment.influencer ? (
                    <TableCell
                      title={payment.influencer.username}
                      onClick={toggleDrawer(anchor, true, payment._id)}
                      sx={{ ...fontSize, cursor: 'pointer' }}
                    >
                      {payment.influencer.username}
                    </TableCell>
                  ) : (
                    <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>---</TableCell>
                  )}
                  {
                    payment.campaign ?
                      <TableCell title={payment.campaign.name} onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                        {payment.campaign.name.substring(0, 5) + '...'}
                      </TableCell>
                      :
                      <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                        ---
                      </TableCell>
                  }
                  <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                    {payment.cost ? payment.cost : "---"}
                  </TableCell>
                  {/* <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                  {handleTDS(
                    parseInt(payment.payableAmount),
                    payment.pancard,
                    renderFinancialPayment(payment.influencer._id)
                  )}
                </TableCell> */}
                  <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{payment.gstAmount ? payment.gstAmount : "---"}</TableCell>
                  <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{payment.percentage ? payment.percentage : "---"}</TableCell>
                  <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{payment.payableAmount ? payment.payableAmount : "---"}</TableCell>
                  {/* <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                  {handleFinancialPayment(payment.influencer?._id)}
                </TableCell> */}
                  {payment.createdby && payment.createdby.fullName ?
                    <TableCell title={payment.createdby.fullName} onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                      {payment.createdby.fullName.substring(0, 6) + '...'}
                    </TableCell>
                    : <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>---</TableCell>
                  }
                  <TableCell sx={{ fontSize }}>
                    {payment.invoice ? (
                      <Link
                        href={process.env.REACT_APP_BASE_URL + "/" + payment.invoice}
                        className="text-primary"
                        target="_blank"
                        title="Download Invoice"
                        download
                      >
                        <ArrowDownward fontSize="small" color="success" />
                      </Link>
                    ) : (
                      <ArrowDownward fontSize="small" color="secondary" />
                    )}
                  </TableCell>
                  <TableCell sx={{ fontSize }}>
                    {payment.gstFile ? (
                      <Link
                        href={process.env.REACT_APP_BASE_URL + "/" + payment.gstFile}
                        className="text-primary"
                        target="_blank"
                        title="Download GST File"
                        download
                      >
                        <Avatar
                          alt="GST File"
                          src={process.env.REACT_APP_BASE_URL + "/" + payment.gstFile}
                          sx={{
                            width: 25,
                            height: 25,
                            border: "2px solid #DC3545", // Border color (assuming you want red border)
                            borderRadius: "50%", // Make it a circle
                          }}
                        />
                      </Link>
                    ) : (
                      "---"
                    )}
                  </TableCell>
                  {payment.payment_Status && !payment.payment_Status.split(",") ? (
                    <TableCell
                      onClick={toggleDrawer(anchor, true, payment._id)}
                      sx={{ ...fontSize, cursor: 'pointer' }}
                    >
                      {payment.payment_Status}
                    </TableCell>
                  ) : payment.payment_Status && payment.payment_Status.split(",") ? (
                    <TableCell
                      onClick={toggleDrawer(anchor, true, payment._id)}
                      sx={{ ...fontSize, cursor: 'pointer' }}
                    >
                      {payment.payment_Status.split(",")[0]}
                      <br />
                      {payment.payment_Status.split(",")[1]}
                    </TableCell>
                  ) : (
                    <TableCell onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }}>---</TableCell>
                  )}
                  <TableCell sx={{ fontSize }}>
                    {payment.typeOfPayment === "P" ? (
                      "P"
                    ) : payment.typeOfPayment === "L" ||
                      (payment.paytmNumber !== "" && payment.accountNo === "") ? (
                      "L"
                    ) : (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name={`${"typeOfPayment"}_${index}`}
                        >
                          <FormControlLabel value="P" onClick={() => handleRadioButton(payment._id, "P")} control={<Radio size="small" />} label="P" />
                          <FormControlLabel value="L" onClick={() => handleRadioButton(payment._id, "L")} control={<Radio size="small" />} label="L" />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </TableCell>
                  {/* <TableCell>
                    {payment.accountNo ? (
                      <Chip onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }} label={"Bank"} />
                    ) : (
                      <Chip onClick={toggleDrawer(anchor, true, payment._id)} sx={{ ...fontSize, cursor: 'pointer' }} label={"Paytm"} />
                    )}
                  </TableCell> */}
                  <TableCell sx={{ fontSize }}>
                    {payment.payment_Status === "" ? (
                      <>
                        <Tooltip title="Approve">
                          <CheckCircle fontSize="small" color="success" onClick={() =>
                            handleSubmit(
                              payment._id,
                              true,
                              `${index}-paymentInfluencerData_status`,
                              "Under Process",
                              payment.isGstRequested
                            )
                          } />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Delete fontSize="small" color="error" onClick={() => handleDelete(payment._id, payment.isCompany)} />
                        </Tooltip>
                        {payment.isGst && (
                          <>
                            &nbsp;&nbsp;
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className="form-check-input isGstPay"
                                  style={{ cursor: 'pointer' }}
                                  title="GST Pay"
                                  id={`${payment._id}-gstPay`}
                                  onChange={() => handleGstPay(`${payment._id}-gstPay`, payment.isGstPay)}
                                />
                              }
                              label="GST Pay"
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Tooltip title="Approve">
                          <CheckCircle fontSize="small" onClick={() =>
                            handleSubmit(
                              payment._id,
                              true,
                              `${index}-paymentInfluencerData_status`,
                              "Done",
                              payment.isGstRequested
                            )
                          } />
                        </Tooltip>
                        <Tooltip title="Fail">
                          <Error onClick={() =>
                            handleFail(payment._id, payment.isCompany, "Failed")
                          } />
                        </Tooltip>
                        {payment.paymentScreenshot ? (
                          <Link
                            href={`${process.env.REACT_APP_BASE_URL}/${payment.paymentScreenshot}`}
                            className="text-primary"
                            target="_blank"
                            title="Download Screenshot"
                            download
                          >
                            <Avatar
                              src={`${process.env.REACT_APP_BASE_URL}/${payment.paymentScreenshot}`}
                              alt="Payment Screenshot"
                              sx={{
                                width: 25,
                                height: 25,
                                marginRight: '10px',
                                border: '2px solid #dc3545', // You can customize the border color
                              }}
                            />
                          </Link>
                        ) : (
                          <Tooltip title="Upload Payment Screenshot">
                            <FileUpload fontSize="small" onClick={() => handleUpload(payment._id)} />
                          </Tooltip>
                        )}
                        {payment.gstScreenshot ? (
                          <Link
                            href={`${process.env.REACT_APP_BASE_URL}/${payment.paymentScreenshot}`}
                            className="text-primary"
                            target="_blank"
                            title="Download Screenshot"
                            download
                          >
                            <Avatar
                              src={`${process.env.REACT_APP_BASE_URL}/${payment.paymentScreenshot}`}
                              alt="Payment Screenshot"
                              sx={{
                                width: 25,
                                height: 25,
                                marginRight: '10px',
                                border: '2px solid #dc3545', // You can customize the border color
                              }}
                            />
                          </Link>
                        ) : payment.isGstRequested ? (
                          <>
                            <Tooltip title="Upload GST Screenshot">
                              <FileUpload fontSize="small" onClick={() => uploadGstScreenshot(payment._id)} />
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
