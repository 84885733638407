import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, Stack, Typography, FormLabel, FormControl, CircularProgress } from '@mui/material';

// import Person from '../../common/dashboardheader/images/person.png';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VerifiedIcon from "@mui/icons-material/Verified";
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import usersEndpoints from '../../../services/userServices';

function UserDetailsCard(props){
    const [userDetail, setuserDetail]  = useState({});
    const [message, setMessage] = useState(null);
    const [msgColor, setMsgColor] = useState(null);
    const [loader, setLoader] = useState({
        verifyEmail: "none",
        verifyPhone: "none"
    });

    const colors = {
        SUCCESS: "success.main",
        ERROR: "error"
    };

    useEffect(() => {
        if(props.details){
            setuserDetail(props.details);
            if(props.details.mailSendStatus === true){
                setMessage("Verification Mail Sent");
                setMsgColor(colors.SUCCESS);
            }
        }
    }, [props]);

    const sendVerificationMail = async () => {
        let updatedValue = { verifyEmail: "block" };
        setLoader({ ...loader, ...updatedValue });
        const mailSend = await usersEndpoints.sendVerificationMail({});
        updatedValue = { verifyEmail: "none" };
        setLoader({ ...loader, ...updatedValue });
        if(mailSend['success'] === true){
            setuserDetail({
                ...userDetail,
                ...{ "mailSendStatus": true }
            });
            setMessage("Verification Mail has been successfully sent");
            setMsgColor(colors.SUCCESS);
        }
        else{
            setMessage(mailSend['message']);
            setMsgColor(colors.ERROR);
        }
    }

    return(
        <>
            <Stack
                spacing={ 2 }
                sx={{
                    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
                    paddingX: 2, paddingY: 3  
                }}
                marginTop={ 2 }
            >
                <Box 
                    component={ "div" }
                    sx={{ height: '128px', borderRadius: '10px' }}
                >
                    <img 
                        src={ userDetail.image || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" }
                        style={{ 
                            width: '128px', height: '128px', borderRadius: '10px',
                            display: 'flex', margin: '0 auto'
                        }}
                        alt={ "user profile" }
                    />
                </Box>
                <Box
                    sx={{ textAlign: 'center' }}
                >
                    <Typography
                        component={ "p" }
                        variant={ "p" }
                    >
                        { userDetail && userDetail.name ? userDetail.name : '' }
                    </Typography>
                    <Typography 
                        component={ "p" }
                        variant={ "p" }
                        sx={{ color: 'text.grey' }}
                    >
                        { userDetail && userDetail.companyName ? userDetail.companyName : '' }
                    </Typography>
                </Box>
                <Stack
                    spacing={ 2 }
                >
                    <FormControl>
                        <FormLabel 
                            sx={{ 
                                fontSize: '0.8rem',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <span>Email address</span>
                            <Box 
                                component={ "span" }
                                sx={{ marginLeft: "5px" }}
                            >
                                { 
                                    userDetail.emailStatus ?
                                    <VerifiedIcon
                                        color="success"
                                        sx={{ fontSize: "1rem", display: "flex" }}
                                    /> :
                                    <UnpublishedIcon
                                        color="error"
                                        sx={{ fontSize: "1rem", display: "flex" }}
                                    />
                                }
                            </Box>
                        </FormLabel>
                        <Typography sx={{ whiteSpace: 'pre-wrap', display: 'flex' }}>
                            { userDetail && userDetail.email ? userDetail.email: 'N.A' }
                            {
                                userDetail.emailStatus === false && userDetail.mailSendStatus === false ? 
                                <>
                                    <Button
                                        variant='outlined'
                                        size="small"
                                        sx={{
                                            paddingY: 0.05,
                                            textTransform: 'capitalize',
                                            marginLeft: "auto"
                                        }}
                                        onClick={ sendVerificationMail }
                                        endIcon={ 
                                            <CircularProgress 
                                                size={ "0.8rem" }
                                                sx={{ display: loader.verifyEmail }}
                                            />
                                        }
                                    >
                                        verify email
                                    </Button>
                                </>
                                :
                                <></>
                            }
                        </Typography>
                        
                        <Typography
                            sx={{ fontSize: '0.8rem' }}
                            color={ msgColor }
                        >
                            { message ? message : "" }
                        </Typography>
                    </FormControl>
                    
                    <FormControl>
                        <FormLabel 
                            sx={{ 
                                fontSize: '0.8rem' ,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <span>Phone</span>
                            <Box 
                                component={ "span" }
                                sx={{ marginLeft: "5px" }}
                            >
                                { 
                                    userDetail.phoneStatus ?  
                                    <VerifiedIcon
                                        color="success"
                                        sx={{ fontSize: "1rem", display: "flex" }}
                                    /> :
                                    <UnpublishedIcon
                                        color="error"
                                        sx={{ fontSize: "1rem", display: "flex" }}
                                    />
                                }
                            </Box>
                        </FormLabel>
                        <Typography sx={{ display: 'flex' }}>
                            { userDetail && userDetail.phone ? userDetail.phone: 'N.A' }
                            {
                                (
                                    userDetail.phoneStatus === false && userDetail.phone ? 
                                    <>
                                        <Button
                                            variant='outlined'
                                            size="small"
                                            sx={{
                                                paddingY: 0.05,
                                                textTransform: 'capitalize',
                                                marginLeft: "auto"
                                            }}
                                        >
                                            verify phone
                                        </Button>
                                    </>
                                    :
                                    <></>
                                )
                            }
                        </Typography>
                    </FormControl>

                    <FormControl>
                        <FormLabel sx={{ fontSize: '0.8rem' }}>Address</FormLabel>
                        <Typography>
                            { userDetail && userDetail.address ? userDetail.address: 'N.A' }
                        </Typography>
                    </FormControl>
                </Stack>
                <Button
                    color={ 'primary' }
                    variant={ 'contained' }
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    href={ '/#price_container' }
                    target={ '_blank' }
                >
                    <AccountBalanceWalletIcon/>
                    <Typography variant={ 'p' }>
                        { `Credits Left: ${ userDetail && userDetail.credits ? userDetail.credits: 0 }` }
                    </Typography>
                </Button>
            </Stack>
        </>
    );
}

export default UserDetailsCard;