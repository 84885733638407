import * as React from "react";
import MeeshaExt1 from '../../../meeshaext-1.png';
import MeeshaExt2 from '../../../meeshaext-2.png';
import MeeshaExt3 from '../../../meeshaext-4.png';
import MeeshaExt4 from '../../../meeshaext-5.png';
import { Typography, Box, Grid } from "@mui/material";

function Extension() {
  return (
    <>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 1:
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={{ fontWeight: "600", fontSize: "30px " }}
          >
            "Install Meesha"
            <br />
            <span style={{ fontWeight: "normal" }}>
              Add the Meesha Chrome extension to your browser.
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaExt1}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaExt2}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 2:
          </Typography>
          <br />
          <Typography
            variant="p"
            component={"p"}
            sx={{ fontWeight: "600", fontSize: "30px " }}
          >
            "Search for Influencers"
            <br />
            <span style={{ fontWeight: "normal" }}>
              Search for Influencers on their social media platform (Youtube &
              Instagram).
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 3:
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={{ fontWeight: "600", fontSize: "30px " }}
          >
            "Access Contact Details"
            <br />
            <span style={{ fontWeight: "normal" }}>
              View influencer profiles and obtain direct contact information.
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaExt3}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaExt4}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 4:
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={{ fontWeight: "600", fontSize: "30px " }}
          >
            "Collaborate with Confidence"
            <br />
            <span style={{ fontWeight: "normal" }}>
              Reach out to influencers and start collaborating on your marketing
              campaigns.
            </span>
          </Typography>
        </Box>
      </Grid>
    </>
  );
}

export default Extension;
