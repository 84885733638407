import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import reportServices from "../services/reportServices";

export const useFetchReports = () => {
  return useQuery({
    queryKey: ["fetchReports"],
    queryFn: () => reportServices.FetchReports(),
    staleTime: 1 * 60 * 1000,
  });
};

export const useFetchReportDetails = (params) => {
  return useQuery({
    queryKey: ["fetchReportDetails", params],
    queryFn: () => reportServices.FetchReportDetails(params),
    staleTime: 1 * 60 * 1000,
  });
};


// const useAddReportPost = () => {
//   const queryClient = useQueryClient();
  
//   return useMutation(({
//     mutationFn: (posts) => reportServices.AddReport(posts),
//     onMutate: (newPost) =>  {
//       queryClient.getQueriesData(["fetchReportDetails"]),
//       queryClient.setQueriesData(["fetchReportDetails"], (posts) => [newPost, ...(posts || [])] ) ;
//     },

//     // onError:,

//   }))

// };




export default { useFetchReports, useFetchReportDetails };
