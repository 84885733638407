import * as React from "react";
import { CssBaseline } from "@mui/material";
import HeaderSection from "../components/common/header/Header";
import LandingHeroSection from "../components/landing/hero/Hero";
import LandingPageBanner from "../components/landing/banner/Banner";
import LandingWhyusSection from "../components/landing/whyus/Whyus";
import LandingPricingSection from "../components/landing/pricing/Pricing";
import LandingStatsDisplay from "../components/landing/stats/StatsDisplay";
import SubscribeThroughEmail from "../components/common/subscribe/SubscribeEmail";
import LandingWorksSection from "../components/landing/workssection/WorksSection";
import LandingTestimonials from "../components/landing/testimonials/TestimonialsSection";

function LandingPage() {
  const [showContactForm, setShowContactForm] = React.useState(false);
  React.useEffect(() => {
    document.title = "Home Page";
  }, []);

  const handleShowContactForm = () => {
    setShowContactForm(true);
  };

  const handleCloseContactForm = () => {
    setShowContactForm(false);
  };

  return (
    <>
      <CssBaseline />
      <HeaderSection
        showNav={true}
        handleShowContactForm={handleShowContactForm}
        handleCloseContactForm={handleCloseContactForm}
        showContactForm={showContactForm}
      />
      <LandingHeroSection handleShowContactForm={handleShowContactForm} />
      {/* <LandingPageBanner /> */}
      <LandingWhyusSection />
      <LandingWorksSection />
      <LandingPricingSection />
      <LandingTestimonials />
      {/* <LandingStatsDisplay /> */}
      <SubscribeThroughEmail />
    </>
  );
}

export default LandingPage;
