import * as React from "react";
import { Container, CssBaseline, Link, Typography } from "@mui/material";
import { useParams, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import Logo from "../logo.png";
import SigninSection from "../components/authentication/signin/Signin";
import SignupSection from "../components/authentication/signup/Signup";

function AuthenticationPage() {
  const [cookies] = useCookies();
  const { type, code } = useParams();

  useEffect(() => {
    document.title = "Authenticate";
  }, []);

  return (
    <>
      {!cookies.user ||
      cookies.user === "undefined" ||
      !cookies.auth ||
      cookies.auth === "undefined" ? (
        <>
          <CssBaseline />
          <Container maxWidth="lg" sx={{ paddingY: 3 }}>
            <Typography
              variant="h4"
              component={"h4"}
              sx={{ color: "primary.main", marginBottom: 3 }}
            >
              <a
                href="/"
                style={{
                  textDecoration: "none",
                }}
              >
                <img src={Logo} width={"123px"} height={"40px"} alt="logo" />
              </a>
              {/* <Link href='/' underline='none'>Meesha</Link> */}
            </Typography>

            {type === "signin" && <SigninSection />}
            {type === "signup" && <SignupSection refCode={code} />}
          </Container>
        </>
      ) : (
        <Navigate to={"/filter/influencers"} />
      )}
    </>
  );
}

export default AuthenticationPage;
