import { Grid, Box, Stack, Typography, Button, Card, CardContent, CardActions } from '@mui/material';
import * as React from 'react';
import Banner from './images/instagram_laptop.jpg';

function InfluencerPageBanner(){
    const formLinks = {
        edit: 'https://forms.gle/xHTMsD5uf9VELj3c6',
        delete: 'https://forms.gle/xHTMsD5uf9VELj3c6',
        subscribe: 'https://forms.gle/xHTMsD5uf9VELj3c6'
    };

    return (
        <>
            <Grid 
                container
                sx={{ background: '#f5f7fa' }}
                position={ "relative" }
                paddingBottom={{ xs: 3, lg: 5 }}
            >
                <Grid 
                    item
                    xs={ 12 }
                >
                    <Box
                        sx={{
                            backgroundImage: `url(${ Banner })`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                        position="relative"
                    >
                        <Stack
                            sx={{
                                backgroundColor: 'rgba(42, 34, 34, 0.7)',
                                height: '35em',
                                paddingX: { xs: '1.5rem', md: '8rem' }
                            }}
                        >
                            <Typography
                                color={ '#FFFFFF' }
                                fontSize={{ xs: '2.5rem', md: '3rem' }}
                                textAlign='center'
                                marginTop={20}
                            >
                                Manage your Profile on&nbsp;
                                <Typography
                                    component={ 'span' }
                                    textTransform={ 'uppercase' }
                                    fontSize={ 'inherit' }    
                                >
                                    meesha
                                </Typography>
                            </Typography>
                            
                            <Typography
                                color={ '#FFFFFF' }
                                maxWidth={{ xs: '100%', md: '68%', lg: '50%' }}
                                marginX='auto'
                                textAlign={ 'justify' }
                                fontSize={{ xs: '1rem', md: '1.2rem' }}
                            >
                                As an Influencer you can request to update, hide or delete your contact
                                details from MEESHA.
                                You can also hire expert professionals from MEESHA
                                to manage your Influencer profile on Instagram and Youtube
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    lg={4}
                    alignItems={ 'center' }
                    justifyContent={{ xs: 'center', lg: 'end' }}
                    marginTop={{ xs: 3, lg: -14 }}
                    position='relative'
                    zIndex={1}
                >
                    <Card
                        sx={{ 
                            borderLeft: 'solid 4px #f05812',
                            width: { xs: '90%', lg: '75%' }
                        }}
                    >
                        <CardContent
                            sx={{ 
                                minHeight: { xs: 'auto', lg: 160 }
                            }}
                        >
                            <Typography
                                variant='h6'
                                fontWeight={600}
                                marginBottom={1}
                            >
                                Edit your details
                            </Typography>
                            <Typography
                                fontSize={ '0.9rem' }
                            >
                                if you wish to edit or correct your profile information,
                                please click on the below link and fill up the form&#46;
                                Changes will be made post verification of the details provided by you
                            </Typography>
                        </CardContent>

                        <CardActions
                            sx={{ paddingBottom: 3, paddingLeft: 2 }}
                        >
                            <Button
                                variant='contained'
                                color='primary'
                                href={ formLinks.edit }
                                size='small'
                                target='_blank'
                            >
                                Edit profile information
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid 
                    container
                    item
                    xs={12}
                    lg={4}
                    alignItems={ 'center' }
                    justifyContent={ 'center' }
                    marginTop={{ xs: 3, lg: -14 }}
                    position='relative'
                    zIndex={1}
                >
                    <Card
                        sx={{ 
                            borderLeft: 'solid 3px #f05812',
                            width: { xs: '90%', lg: '75%' }
                        }}
                    >
                        <CardContent
                            sx={{ minHeight: { xs: 'auto', lg: 160 } }}
                        >
                            <Typography
                                variant='h6'
                                fontWeight={600}
                                marginBottom={1}
                            >
                                Request for deleting your details
                            </Typography>
                            <Typography
                                fontSize={ '0.9rem' }
                            >
                                if you wish to remove or hide your contact details from Meesha&#44;
                                then you can do so by clicking on the below link and filling up the form&#46;
                                Your account details will be deleted upon verification
                            </Typography>
                        </CardContent>

                        <CardActions
                            sx={{ paddingBottom: 3, paddingLeft: 2 }}
                        >
                            <Button
                                variant='contained'
                                color='primary'
                                href={ formLinks.delete }
                                target='_blank'
                                size='small'
                            >
                                Delete my details
                            </Button>
                        </CardActions>
                    </Card>                  
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    lg={4}
                    alignItems={ 'center' }
                    justifyContent={{ xs: 'center', lg: 'start' }}
                    marginTop={{ xs: 3, lg: -14 }}
                    position='relative'
                    zIndex={1}
                >
                    <Card
                        sx={{ 
                            borderLeft: 'solid 3px #f05812',
                            width: { xs: '90%', lg: '75%' }
                        }}
                    >
                        <CardContent
                            sx={{ minHeight: { xs: 'auto', lg: 160 } }}
                        >
                            <Typography
                                variant='h6'
                                fontWeight={600}
                                marginBottom={1}
                            >
                                Hire MEESHA as your Contact Manager
                            </Typography>
                            <Typography
                                fontSize={ '0.9rem' }
                            >
                                if you are a growing channel, and you want to focus solely on 
                                content creation then let MEESHA manage your influencer channel at minimal
                                monthly charge. Simply fill up the form by clicking on the link below and
                                we will reach out to you
                            </Typography>
                        </CardContent>
                        <CardActions
                            sx={{ paddingBottom: 3, paddingLeft: 2 }}
                        >
                            <Button
                                variant='contained'
                                color='primary'
                                href={ formLinks.delete }
                                target='_blank'
                                size='small'
                            >
                                Request Callback
                            </Button>
                        </CardActions>
                    </Card>    
                </Grid>
            </Grid>
        </>
    )
}

export default InfluencerPageBanner;