import http from "./httpservices";

export async function getCities() {
  const { data } = await http.get("city/");
  return data;
}

export async function getLimitedCities() {
  const { data } = await http.get("city/limit/");
  return data;
}

export async function getCityById(stateId) {
  const { data } = await http.post("city/findbyId/", { stateId: stateId });
  return data;
}

export async function getInfluencerByCity(name) {
  const { data } = await http.get(`city/influencer/${name}`);
  return data;
}

export async function findByName(search) {
  const { data } = await http.post('city/findbyname', {"name": search});
  return data;
}

export default {
    getCities,
    getCityById,
    getLimitedCities,
    getInfluencerByCity,
    findByName,
};
