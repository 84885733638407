import { useQuery } from "@tanstack/react-query";
import filterServices from "../services/filterServices";


export const useFetchInfluencers = (filters, sorting, page, isContacts) => {
  return useQuery({
    queryKey: ["fetchInfluencer", filters, sorting, page, isContacts],
    queryFn: () => filterServices.FetchInfluencers(filters, sorting, page, isContacts),
    staleTime: 1 * 60 * 1000,
  });
};

export const useFetchInfluencersCount = (filters, isContacts) => {
  return useQuery({
    queryKey: ["fetchInfluencerCount", filters, isContacts],
    queryFn: () => filterServices.FetchInfluencersCount(filters, isContacts),
    staleTime: 1 * 60 * 1000,
  });
};

export default { useFetchInfluencers, useFetchInfluencersCount };
