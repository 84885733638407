import React from 'react';

function Google(props) {
    return (
        <div>
            google-site-verification: google38239dbf6de8a371.html
        </div>
    );
}

export default Google;