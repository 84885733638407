import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  Modal,
  Skeleton,
  TextareaAutosize,
  Typography,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";
import {
  Cloud,
  ControlPoint,
  Groups,
  ThumbUp,
  RemoveRedEye,
  West,
} from "@mui/icons-material";
import DashboardHeaderSection from "../common/dashboardheader/DashboardHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchReportDetails } from "../../react-query/hooks/useFetchReport";

import NoDataImage from "../../assets/images/no_data.png";
import reportServices from "../../react-query/services/reportServices";
import EnhancedTable from "./common/PostTable";
import ReportCards from "./Details/ReportCards";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ReportDetails = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [posts, setPosts] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAlertClose = () => setOpenAlert(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleBack = () => {
    navigate(-1);
  };

  const handleChange = (event) => {
    setPosts(event.target.value);
  };
  const { name, id } = useParams();

  const handleSubmitPosts = async () => {
    setLoading(true);
    // const regex =
    //   /(https:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+)/g;
    // const links = posts.match(regex);
    if (posts) {
      let params = {
        reportId: id,
        posts: posts.split("\n").map((link) => ({ link: link })),
      };
      reportServices
        .AddPostsByReport(params)
        .then(() => {
          // handleClose();
          window.location.reload();
        })
        .catch(() => {
          window.location.reload();
        });
    } else {
    }
  };

  const {
    isError,
    data: reports,
    isLoading,
    error,
  } = useFetchReportDetails({ id: id });

  let totalViews = 0;
  let totalLikes = 0;

  reports?.data.posts.length > 0 &&
    reports?.data.posts.map((report) => {
      totalLikes += parseInt(report?.like_count);
      totalViews += parseInt(report?.views);
    });
  // !isLoading && reports?.data.map(report => console.log(report));

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Posts Added Success Fully!
        </Alert>
      </Snackbar>
      <DashboardHeaderSection />
      <Box
        width={{ xs: "100%", lg: `calc(100% - 100px)` }}
        marginLeft={{ xs: "0px", lg: `80px` }}
        marginTop={8}
        padding={1}
        sx={{ height: "100vh", bgcolor: "#F0F2F5" }}
      >
        <Stack gap={2}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <West
                onClick={handleBack}
                sx={{ fontSize: "21px", cursor: "pointer" }}
              />
              &nbsp;
              <Typography variant="subtitle2" fontWeight={"bold"}>
                {name}{" "}
                <Chip
                  color="info"
                  label={!isLoading && reports?.data?.platform?.name}
                />
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "12px",
                marginRight: 2,
                // textTransform: "capitalize",
              }}
              onClick={handleOpen}
            >
              <ControlPoint sx={{ fontSize: "20px" }} />
              &nbsp; <b>POST</b>
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  textAlign={"center"}
                >
                  Add the links in below format
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <ul>
                    <li>https://instagram.com/p/CLlfKZYA2FA/</li>
                    <li>https://www.instagram.com/reel/CLlfKZYA2FA/</li>
                    <li>https://www.instagram.com/tv/CLlfKZYA2FA/</li>
                  </ul>
                </Typography>
                <TextareaAutosize
                  minRows={5}
                  maxRows={10}
                  placeholder="Add Post Links"
                  name="allposts"
                  onChange={handleChange}
                  value={posts}
                  style={{
                    width: "95%",
                    height: "100px",
                    marginTop: 1,
                    padding: "10px",
                  }}
                />
                <Box textAlign={"center"} marginTop={1}>
                  {loading ? (
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        fontSize: "14px",
                        marginRight: 2,
                        textTransform: "capitalize",
                      }}
                    >
                      Loading...
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{
                          fontSize: "12px",
                          marginRight: 2,
                          textTransform: "capitalize",
                        }}
                        onClick={handleSubmitPosts}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "12px",
                          marginRight: 2,
                          textTransform: "capitalize",
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Modal>
          </Box>
          <Box>
            <ReportCards
              isLoading={isLoading}
              reports={reports}
              totalLikes={totalLikes}
              totalViews={totalViews}
            />
          </Box>
          <Box>
            {isLoading ? (
              <Skeleton animation="wave" />
            ) : reports.data.posts.length > 0 ? (

              <EnhancedTable
                loading={isLoading}
                reports={!isLoading && reports.data.posts}
              />
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <img src={NoDataImage} height={300} />
                <Typography color={"red"} variant="h6">
                  No Post Found
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "12px",
                    marginRight: 2,
                    textTransform: "capitalize",
                  }}
                  onClick={handleOpen}
                >
                  <ControlPoint sx={{ fontSize: "16px" }} />
                  &nbsp;Add Post
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ReportDetails;
