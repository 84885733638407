import { Button, Typography, Card, CardMedia, CardContent, Grid } from '@mui/material';
import { Container } from '@mui/system';
import * as React from 'react';
import Stepone from '../images/step-1.png';
import Steptwo from '../images/step-2.png';
import Stepthree from '../images/step-3.png';
import ExtensionIcon from '@mui/icons-material/Extension';

function HowToViewContact(){
    return (
        <Container fixed>
            <Typography 
                variant='h6'
                color={ 'dark.light' }
                marginBottom={ 2 }
                sx={{ textDecoration: 'underline' }}
            >
                How to view contact using Meesha?
            </Typography>
            
            <Grid
                container
                spacing={ 2 }
            >
                <Grid item xs={ 12 } md={ 4 }>
                    <Card>
                        <CardMedia 
                            component={ "img" }
                            height="250"
                            src={ Stepone }
                            alt="first step"
                        />
                        <CardContent>
                            <Typography
                                fontWeight={ "bold" }
                            >
                                STEP 1
                            </Typography>
                            <Typography>
                                Search for a influencer using instagram search box and selecting the influencer
                                or search in the search box provided in dashboard page
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={ 12 } md={ 4 }>
                    <Card>
                        <CardMedia 
                            component={ "img" }
                            height="250"
                            src={ Steptwo }
                            alt="second step"
                        />
                        <CardContent>
                            <Typography
                                fontWeight={ "bold" }
                            >
                                STEP 2
                            </Typography>
                            <Typography>
                                Click on the Meesha button, if the contact details of the influencer is available
                                with us then you will see his/her details
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={ 12 } md={ 4 }>
                    <Card>
                        <CardMedia 
                            component={ "img" }
                            height="250"
                            src={ Stepthree }
                            alt="third step"
                        />
                        <CardContent>
                            <Typography
                                fontWeight={ "bold" }
                            >
                                STEP 3
                            </Typography>
                            <Typography>
                                You can check for influencer details by clicking on the View button.
                                If you have credits to view the contact then the details will be shown to you. 
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid 
                    item xs={12} 
                    display="flex" 
                    justifyContent={"center"}
                >
                    <Button
						variant= 'contained'
						startIcon= { <ExtensionIcon/> }
						sx= {{ borderRadius: 5 }}
						href="https://chrome.google.com/webstore/detail/meesha-connect-with-influ/hfjiamlingkieffkgcikgjomhdbgoald"
						target={ "_blank" }
					>
						Get our Chrome Extension
					</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HowToViewContact;