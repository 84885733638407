import React from "react";
import VendorForm from "./common/VendorForm";
import { useNavigate } from "react-router-dom";
import InfluencerForm from "./common/Form/InfluencerForm";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import BackButton from "../common/BackButton";

export default function PaymentForm({ campaignDetails, vendors }) {
  const [alignment, setAlignment] = React.useState("influencer");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const styles = {
    width: "80%",
    marginY: "2vh",
    marginX: "auto",
    boxShadow: 0,
    borderRadius: 0,
    padding: 5,
  };
  return (
    <>
      <Box
        width={{ xs: "100%", lg: `calc(100% - 80px)` }}
        marginLeft={{ xs: "0px", lg: `80px` }}
        marginTop={8}
        padding={1}
        backgroundColor={"#F0F2F5"}
        minHeight={"100vh"}
        height={"100%"}
      >
        <BackButton label={campaignDetails.name} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="influencer">Influencer</ToggleButton>
            <ToggleButton value="vendor">Vendor</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          {alignment === "vendor" ? (
            <VendorForm
              styles={styles}
              campaignDetails={campaignDetails}
              vendors={vendors}
            />
          ) : (
            <InfluencerForm
              campaignDetails={campaignDetails}
              vendors={vendors}
              styles={styles}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
