// import '@fontsource/source-sans-pro'
import "@fontsource/inter";
import "@fontsource/inter/900.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/400.css";

const PrimaryFontFamily = {
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(", "),
  },
};

export default PrimaryFontFamily;
