import { CssBaseline } from '@mui/material';
import * as React from 'react';

// import { Grid } from '@mui/material';
import Dashboard from '../components/userdashboard/dashboard/Dashboard';
// import SidebarComponent from '../components/common/sidebar/Sidebar';
import DashboardHeaderSection from '../components/common/dashboardheader/DashboardHeader';

function UserDashboardPage(){
    React.useEffect(() => {
        document.title = "Dashboard";
    }, []);

    return (
        <React.Fragment>
            <CssBaseline/>
            <DashboardHeaderSection/>
            <Dashboard/>
            {/* <Grid 
                container
            >
                <Grid item xs={ 2 }>
                    <SidebarComponent/>
                </Grid>
                
                <Grid
                    item
                    xs={12}
                >
                    <DashboardHeaderSection/>
                    <Dashboard/>
                </Grid>
            </Grid> */}
        </React.Fragment>
    )
}

export default UserDashboardPage;