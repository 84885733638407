import http from "./httpservices";

const endPoint = "payment-link/";

export async function GeneratePaymentLink(params) {
  const { data } = await http.post(`${endPoint}`, params);
  return data;
}

export async function generateCompanyLink(params) {
  const { data } = await http.post(`${endPoint}companylink`, params);
  return data;
}

export async function generateExpenseLink(params) {
  const { data } = await http.post(`${endPoint}expenselink`, params);
  return data;
}

export async function getPaymentLink(obj) {
  try {
    const { data } = await http.post(`${endPoint}getlink`, obj);
    return data;
  } catch (error) {
    return null;
  }
}

export default {
  GeneratePaymentLink,
};
 