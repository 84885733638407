import {
  Box,
  Radio,
  Typography,
  TextField,
  ListItem,
  Button,
  List,
  Autocomplete,
  Switch,
  SwipeableDrawer,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
} from "@mui/material";
import { useState, useEffect } from "react";
import paymentdashboardservice from "../../../../services/paymentdashboardservice";
import { useForm, Controller } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import Platforms from "../Platforms";
import Category from "../Category";
import campaignservice from "../../../../services/campaignservice";
import CustomizedSnackbars from "../CustomizedSnackbars";

const CampaignSchema = z.object({
  name: z
    .string({
      required_error: "Campaign name is required",
      invalid_type_error: "Campaign name is required",
    })
    .min(3, { message: "Category Name must contain at least 3 character(s)" }),
  totalPayment: z
    .string({ required_error: "Total Payment is required" })
    .min(1, { message: "Budget must be required" }),
  deliverables: z
    .string({ required_error: "Deliverables is required" })
    .min(1, { message: "Deliverables must be required" }),
  category: z
    .string({ required_error: "Category is required" })
    .min(1, { message: "Category must be required" }),
  platform: z
    .string({
      required_error: "Platform is required",
      invalid_type_error: "Platform name is required",
    })
    .min(1, { message: "Please Select the Platform" }),
  isOfficeExpense: z.boolean(),
});

function CampaignForm({
  handleOpenCampaignForm,
  handleCloseCampaignForm,
  openCampaignForm,
  handleSnackBarOpen,
  updateLatestCampaign,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: zodResolver(CampaignSchema) });

  const onSubmit = (formData) => {
    campaignservice
      .AddCampaign(formData)
      .then((response) => {
        updateLatestCampaign(response);
        reset(); // form reset
        handleCloseCampaignForm();
        handleSnackBarOpen();
      })
      .catch((error) => {
        handleSnackBarOpen(
          "error",
          error.response ? error.response.data : error.message
        );
      });
  };

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={openCampaignForm}
      onClose={handleCloseCampaignForm}
      onOpen={handleOpenCampaignForm}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ paddingX: 3, paddingTop: 3 }} role="presentation">
          <Typography variant="h6" textAlign={"center"} fontWeight={"bold"}>
            Add Campaign
          </Typography>
          <List>
            <ListItem disablePadding>
              <Category
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                control={control}
              />
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding>
              <TextField
                id="outlined-basic"
                label="Campaign*"
                variant="outlined"
                sx={{ width: 350 }}
                {...register("name")}
                helperText={errors.name?.message}
                error={errors.name ? true : false}
              />
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding>
              <TextField
                id="outlined-basic"
                label="Budget*"
                variant="outlined"
                sx={{ width: 350 }}
                {...register("totalPayment")}
                helperText={errors.totalPayment?.message}
                error={errors.totalPayment ? true : false}
              />
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding>
              <TextField
                id="outlined-multiline-static"
                label="Deliverables"
                rows={4}
                multiline
                sx={{ width: 350 }}
                {...register("deliverables")}
                helperText={errors.deliverables?.message}
                error={errors.deliverables ? true : false}
              />
            </ListItem>
          </List>
          <List>
            <ListItem disablePadding>
              <Platforms
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                control={control}
              />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="isOfficeExpense"
                      {...register("isOfficeExpense")}
                    />
                  }
                  label="Office Expense"
                />
              </FormGroup>
            </ListItem>
          </List>
          <Box sx={{ marginTop: 2, textAlign: "center" }}>
            <Button variant="outlined" type="submit">
              Add Campaigns
            </Button>
          </Box>
        </Box>
      </form>
    </SwipeableDrawer>
  );
}

export default CampaignForm;
