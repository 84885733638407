import React from 'react'
import { Box, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

export default function AllStatusTable() {
    const anchor = 'right';
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: 350 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            padding={2}
        >
            <Typography variant='h6' sx={{ fontWeight: 'bold', textAlign: 'center' }}>Payment Details</Typography>
            <TableContainer>
                <Table
                    sx={{ border: "1px solid #e0e0e0", marginTop: 1 }}
                    aria-label="simple table"
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Campaign</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Vendor</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Platform</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Influencer</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Deliverables</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Comment</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>POC</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Approved By</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Total Amount</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>GST Amount</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Payable Amount</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Invoice</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Phone</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>DOB</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>State</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Account Name</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Account Number</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>IFSC Code</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Pancard</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Paytm</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Remarks</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        </Box>
    );

    return (
        <>
            <TableContainer>
                <Table
                    sx={{ border: "1px solid #e0e0e0" }}
                    aria-label="simple table"
                    size="medium"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Pay</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Campaign</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Platform</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Inf / Ven</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Comment</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>POC</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>GST</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>%</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Payable</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Remarks</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ cursor: 'pointer' }} onClick={toggleDrawer(anchor, true)}>
                            <TableCell>1</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>3</TableCell>
                            <TableCell>4</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>1</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>3</TableCell>
                            <TableCell>4</TableCell>
                            <TableCell>5</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>6</TableCell>
                            <TableCell>6</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
            >
                {list(anchor)}
            </Drawer>
        </>
    )
}
