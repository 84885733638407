import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ApprovalIcon from "@mui/icons-material/Approval";
import PanToolIcon from "@mui/icons-material/PanTool";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { DoneAll } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function StatusCards({ stats }) {
  const navigate = useNavigate();

  console.log(stats, 'stats');

  return (
    <Box>
      <Grid container spacing={2} style={{ display: "flex" }}>
        <Grid item xs={2}>
          <Card
            sx={{ padding: 2, cursor: "pointer", borderRadius: 0, boxShadow: 0 }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {stats.totalPending}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Pending
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <AutorenewIcon sx={{ color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card
            sx={{ padding: 2, cursor: "pointer", borderRadius: 0, boxShadow: 0 }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {stats.totalApplied}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Applied
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <ApprovalIcon sx={{ color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card
            sx={{ padding: 2, cursor: "pointer", borderRadius: 0, boxShadow: 0 }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {stats.totalWaiting}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Waiting
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <PanToolIcon sx={{ color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card
            sx={{ padding: 2, cursor: "pointer", borderRadius: 0, boxShadow: 0 }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {stats.totalSuccess}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Success
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <DoneAll sx={{ color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card
            sx={{ padding: 2, cursor: "pointer", borderRadius: 0, boxShadow: 0 }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {stats.totalFailed}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Failed
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <SmsFailedIcon sx={{ color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card
            sx={{ padding: 2, cursor: "pointer", borderRadius: 0, boxShadow: 0 }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {stats.totalRejected}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Rejected
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <ThumbDownIcon sx={{ color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
