import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
  TextField,
  Autocomplete,
  ListItem,
  Button,
  ListItemText,
} from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import paymentdashboardservice from "../../services/paymentdashboardservice";

function Vendor({ totalVendor }) {
  const [vendorCategories, setVendorCategories] = React.useState([]);
  const [vendors, setVendors] = React.useState([]);
  const [platforms, setPlatforms] = React.useState([]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [viewState, setViewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  let [params, setParams] = useState({
    name: "",
    category: "",
    number: "",
    platform: "",
    url: "",
  });

  const toggleDrawer = (anchor1, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor1]: open });
  };

  const toggleViewDrawer = (anchor2, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setViewState({ ...viewState, [anchor2]: open });
  };

  const handleCategoryChange = (e, value) => {
    // let { name } = e.target;

    console.log(e, "name");

    setParams((prevState) => ({
      ...prevState,
      // [name]: value._id,
    }));
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleVendorSearch = async (value) => {
    const vendors = await paymentdashboardservice.FetchVendorSearch({
      name: value,
    });
    setVendors(vendors);
  }

  const Init = async () => {
    let vendorCategories =
      await paymentdashboardservice.FetchVendorCategories();
    vendorCategories = vendorCategories.map((obj) => ({
      ...obj,
      label: obj.name,
    }));
    setVendorCategories(vendorCategories);

    let platforms = await paymentdashboardservice.FetchPlatforms();
    platforms = platforms.map((obj) => ({ ...obj, label: obj.name }));
    setPlatforms(platforms);

    const vendors = await paymentdashboardservice.FetchVendors();
    setVendors(vendors);
  };

  useEffect(() => {
    Init();
  }, []);

  const vendorForm = (anchor1) => (
    <>
      <Box sx={{ paddingX: 3, paddingTop: 3 }} role="presentation">
        <Typography variant="h6" textAlign={"center"} fontWeight={"bold"}>
          Add Vendor
        </Typography>
        <List>
          <ListItem disablePadding>
            <TextField
              id="outlined-basic"
              label="Vendor*"
              variant="outlined"
              name="name"
              sx={{ width: 350 }}
              onChange={handleChange}
            />
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <Autocomplete
              disablePortal
              id="category-dropdown"
              options={vendorCategories}
              sx={{ width: 350 }}
              name="category"
              onChange={handleCategoryChange}
              renderInput={(params) => (
                <TextField {...params} label="Vendor Category" />
              )}
            />
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <TextField
              id="outlined-basic"
              label="Vendor Phone Number"
              variant="outlined"
              name="number"
              onChange={handleChange}
              sx={{ width: 350 }}
            />
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <Autocomplete
              disablePortal
              id="platform-dropdown"
              options={platforms}
              sx={{ width: 350 }}
              name="platform"
              onChange={handleCategoryChange}
              renderInput={(params) => (
                <TextField {...params} label="Choose Platform" />
              )}
            />
          </ListItem>
        </List>
        <List>
          <ListItem disablePadding>
            <TextField
              id="outlined-basic"
              label="Social Media Url"
              variant="outlined"
              sx={{ width: 350 }}
              name="url"
              onChange={handleChange}
              InputProps={{
                inputProps: {
                  type: "url",
                },
              }}
            />
          </ListItem>
        </List>
        <Box sx={{ marginTop: 2, textAlign: "center" }}>
          <Button variant="outlined" type="submit">
            Add Vendors
          </Button>
        </Box>
      </Box>
    </>
  );

  const vendorView = (anchor2) => (
    <>
      <Box sx={{ paddingX: 3, paddingTop: 3, width: '25vw' }} role="presentation">
        <List>
          <ListItem disablePadding>
            <TextField
              id="outlined-search"
              label="Search vendor..."
              type="search"
              sx={{ width: '25vw' }}
              onChange={(e) => handleVendorSearch(e.target.value)}
            />
          </ListItem>
        </List>
        <Divider sx={{ marginY: 1 }} />
        {vendors.length > 0 &&
          vendors.map((vendor) => (
            <List>
              <ListItem>
                <ListItemText>{vendor.name}</ListItemText>
              </ListItem>
            </List>
          ))}
      </Box>
    </>
  );

  const anchor1 = "right";
  const anchor2 = "right";

  return (
    <>
      <SwipeableDrawer
        anchor={anchor1}
        open={state[anchor1]}
        onClose={toggleDrawer(anchor1, false)}
        onOpen={toggleDrawer(anchor1, true)}
      >
        {vendorForm(anchor1)}
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor={anchor2}
        open={viewState[anchor2]}
        onClose={toggleViewDrawer(anchor2, false)}
        onOpen={toggleViewDrawer(anchor2, true)}
      >
        {vendorView(anchor2)}
      </SwipeableDrawer>

      <Grid item xs={6}>
        <Card sx={{ padding: 2, borderRadius: 0, boxShadow: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CardContent sx={{ padding: 1 }}>
              <Typography component="div" variant="h5">
                <Chip
                  label={totalVendor}
                  size="small"
                  variant="filled"
                  sx={{ backgroundColor: "#F9B572", color: "#ffffff" }}
                />
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                fontWeight={"bold"}
              >
                VENDORS
              </Typography>
            </CardContent>
            <Box sx={{ alignSelf: "center" }}>
              <Tooltip title="Add Vendor" onClick={toggleDrawer(anchor1, true)}>
                <AddCircleIcon sx={{ cursor: "pointer", color: "#FFD206" }} />
              </Tooltip>
              <Tooltip
                title="View Vendors"
                onClick={toggleViewDrawer(anchor2, true)}
              >
                <InfoIcon sx={{ cursor: "pointer", color: "#0AC380" }} />
              </Tooltip>
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
}

export default Vendor;
