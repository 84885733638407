import * as React from 'react';
import { Avatar, Button, Container, Grid, Typography, Paper, Stack, Table, TableContainer, TableRow, TableBody, TableHead, TableCell, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import LogoImage from "../../landing/images/icon-128.png";
import paymentservice from '../../../services/paymentservice';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DownloadInvoice(){
    const { id } = useParams();
    const [invoiceData, setInvoiceData] = useState({});

    useEffect(async () => {
        if(id.length > 0){
            let postdata = {
                order_number: atob(id)
            };
            const response = await paymentservice.fetchInvoiceDetails(postdata);
            if(response['success'] === true){
                response['data']['agency_details'][0]['state'] = response['statename'];
                setInvoiceData(response['data']);
            }
            else{
                // alert('Could not find invoice details');
                toast.error('Could not find invoice details');
                window.close();
            }
        }
        else{
            window.close();
        }
    }, []);

    const dateFormatter = (dateObj) => {
        dateObj = parseInt(dateObj);
        dateObj = new Date(dateObj);
        let year = dateObj.getFullYear();
        let month = (1 + dateObj.getMonth()).toString().padStart(2, '0');
        let day = dateObj.getDate().toString().padStart(2, '0');
    
        return month + '/' + day + '/' + year;
    }

    const downloadInvoice = () => {
        if(invoiceData){
            const input = document.getElementById('pdfdiv');  
            html2canvas(input)  
            .then((canvas) => {  
                let imgWidth = 200;  
                let imgHeight = canvas.height * imgWidth / canvas.width;  
                const imgData = canvas.toDataURL('image/png');  
                const pdf = new jsPDF('p', 'mm', 'a4')  
                pdf.addImage(imgData, 'JPEG', 5, 5, imgWidth, imgHeight);  
                pdf.save(`${ invoiceData['invoice_number'] }.pdf`);
            });
        }
    }
    
    return (
        <>
            <Container 
                maxWidth="md"
                sx={{
                    paddingTop: 2,
                    border: 'solid 1px',
                    paddingX: '0 !important'
                }}
                id="pdfdiv"
            >
                {/* header */}
                <Grid 
                    container
                    paddingRight={ 2 }
                >
                    <Grid item lg={1}>
                        <Avatar 
                            src={ LogoImage } 
                            variant='rounded' 
                            sx={{ marginX: "auto" }}
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <Typography
                            fontSize={ "1.5rem" }
                            fontWeight={ 600 }
                            color={ "primary" }
                        >
                            Meesha
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sx={{ textAlign: "right" }} marginLeft={ "auto" }>
                        <Typography variant='div'>
                            <address>
                                <strong>Creatify Media Pvt. Ltd</strong>
                                <div>
                                    Innov8, 3rd Floor
                                    Orchid Centre, Sector 53
                                    Gurugram, Haryana - 122022
                                </div>
                            </address>
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ marginY: 2.5 }}/>
                {/* Client Details */}
                <Grid 
                    container
                    paddingLeft={ 2 }
                    marginBottom={2.5}
                >
                    <Grid 
                        item
                        lg={6}
                    >
                        <Typography
                            fontSize={ "1.25rem" }
                        >
                            <span style={{color: '#808080'}}>Client Name:</span>&nbsp;{ invoiceData.user_details ? invoiceData.user_details[0].fullName : 'N.A' }
                        </Typography>
                        
                        <Typography>
                            <span style={{color: '#808080'}}>Invoice Date:</span>&nbsp; 
                            { 
                                invoiceData._id ? 
                                dateFormatter(parseInt(invoiceData._id.substring(0, 8), 16) * 1000) 
                                : 
                                'N.A' 
                            }
                        </Typography>
                        
                        <Typography>
                            <span style={{color: '#808080'}}>Order ID:</span>&nbsp;{ invoiceData ? invoiceData.razorpay_order_id : 'N.A' }
                        </Typography>

                        <Typography>
                            <span style={{color: '#808080'}}>Invoice Number:</span>&nbsp;{ invoiceData ? invoiceData.invoice_number : 'N.A' }
                        </Typography>
                    </Grid>

                    <Grid item lg={3} marginLeft={ "auto" }>
                        <Stack>
                            <Typography>
                                <span style={{color: '#808080'}}>Client Address:</span>
                            </Typography>
                            <address>
                                <Typography>
                                    {
                                        invoiceData.agency_details ?
                                        <>
                                            { invoiceData.agency_details[0].companyName }
                                            <br/>
                                            { invoiceData.agency_details[0].address }
                                            <br/>
                                            { invoiceData.agency_details[0].city },{ invoiceData.agency_details[0].state },{ invoiceData.agency_details[0].postalCode }
                                        </>
                                        :
                                        'N.A'
                                    }
                                </Typography>
                            </address>
                        </Stack>
                    </Grid>
                </Grid>

                {/* Invoice Title */}
                <Grid 
                    container
                    marginBottom={2.5}
                >
                    <Grid item lg={12}>
                        <Typography
                            fontSize={ '2.5rem' }
                            textAlign={ 'center' }
                            sx={{ letterSpacing: '3px' }}
                        >
                            INVOICE
                        </Typography>
                    </Grid>
                </Grid>

                {/* Items Summary */}
                <Grid 
                    container
                    marginBottom={2.5}
                    paddingX={2}
                >
                    <Grid item sm={12}>
                        <TableContainer
                            component={ Paper }
                        >
                            <Table>
                                <TableHead sx={{ background: '#4a4b4c' }}>
                                    <TableRow>
                                        <TableCell sx={{ color: '#fff', fontSize: '1rem' }}>Credits Purchased</TableCell>
                                        <TableCell sx={{ color: '#fff', fontSize: '1rem' }} align='center'>Price</TableCell>
                                        {/* <TableCell sx={{ color: '#fff', fontSize: '1rem' }} align='center'>Count</TableCell> */}
                                        <TableCell sx={{ color: '#fff', fontSize: '1rem' }} align='center'>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            { 
                                                invoiceData.plan_details ?
                                                JSON.parse(invoiceData.plan_details)['creditsProvided']
                                                :
                                                'N.A'
                                            }
                                        </TableCell>
                                        <TableCell align='center'>
                                            { 
                                                invoiceData.plan_details ?
                                                JSON.parse(invoiceData.plan_details)['amount']
                                                :
                                                'N.A'
                                            }
                                        </TableCell>
                                        {/* <TableCell align='center'>1</TableCell> */}
                                        <TableCell align='center'>
                                            { 
                                                invoiceData.plan_details ?
                                                JSON.parse(invoiceData.plan_details)['amount']
                                                :
                                                'N.A'
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Payment Method: { invoiceData.payment_method ? invoiceData.payment_method.toUpperCase() : 'N.A' }
                                        </TableCell>
                                        <TableCell sx={{ background: 'rgba(255, 193, 101, 0.5)', borderColor: 'rgba(255, 162, 24, 0)' }}>Sub Total</TableCell>
                                        <TableCell align='center' sx={{ background: 'rgba(255, 193, 101, 0.5)', borderColor: 'rgba(255, 162, 24, 0)' }}>
                                            { 
                                                invoiceData.plan_details ?
                                                JSON.parse(invoiceData.plan_details)['amount']
                                                :
                                                'N.A'
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ background: 'rgba(255, 193, 101, 0.5)', borderColor: 'rgba(255, 162, 24, 0)' }}>GST(18%)</TableCell>
                                        <TableCell align='center' sx={{ background: 'rgba(255, 193, 101, 0.5)', borderColor: 'rgba(255, 162, 24, 0)' }}>
                                            { 
                                                invoiceData.plan_details ?
                                                0.18 * parseInt(JSON.parse(invoiceData.plan_details)['amount'])
                                                :
                                                'N.A'
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ background: 'rgba(255, 193, 101, 0.5)', borderColor: 'rgba(255, 162, 24, 0)' }}>Grand Total</TableCell>
                                        <TableCell align='center' sx={{ background: 'rgba(255, 193, 101, 0.5)', borderColor: 'rgba(255, 162, 24, 0)' }}>
                                            {
                                                invoiceData.plan_details ?
                                                parseInt(JSON.parse(invoiceData.plan_details)['amount']) + (0.18 * parseInt(JSON.parse(invoiceData.plan_details)['amount']))
                                                :
                                                'N.A'
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                {/* Thankyou note */}
                <Grid 
                    container
                    marginBottom={2.5}
                    paddingX={2}
                >
                    <Grid 
                        item
                        lg={ 12 }
                    >
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            fontWeight={600}
                        >
                            <FavoriteIcon color='error'/>&nbsp;
                            Thank You for doing Business with us 
                        </Typography>
                    </Grid>
                </Grid>

                <Grid 
                    container
                    sx={{ background: '#efefef', paddingY: 3, paddingX: 2 }}
                >
                    <Grid item lg={4}>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color={'primary'}
                        >
                            <MailOutlineIcon />&nbsp;&nbsp;
                            <span>support@meesha.co.in</span>
                        </Typography>
                    </Grid>

                    <Grid item lg={4}>
                        <Typography
                            color={'primary'}
                            textAlign={ "center" }
                        >
                            GSTIN:&nbsp;&nbsp;
                            <span>06AAKFC4548H1ZD</span>
                        </Typography>
                    </Grid>

                    <Grid item lg={4}>
                        <Typography
                            color={'primary'}
                            textAlign={"end"}
                        >
                            PAN:&nbsp;&nbsp;
                            <span>AAKFC4548H</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Grid 
                container 
                justifyContent={"center"}
                marginTop={1}
                spacing={2}
            >
                <Grid item>
                    <Button 
                        variant='contained'
                        onClick={ downloadInvoice }
                    >
                        Download as PDF
                    </Button>
                </Grid>
                {/* <Grid item>
                    <Button 
                        variant='outlined'
                    >
                        Take Screenshot
                    </Button>
                </Grid> */}
            </Grid>
            <ToastContainer
                style={{
                    fontSize: '0.85rem'
                }}
                hideProgressBar={true}
                autoClose={2000}
            />
        </>
    )
}

export default DownloadInvoice;