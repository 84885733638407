import React from "react";
import {
  ArrowOutward,
  Bookmark,
  Category,
  ChildCare,
  Description,
  Email,
  Instagram,
  LocationOn,
  Phone,
  Verified,
} from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import {
  Link,
  Grid,
  ListItemText,
  ListItem,
  Avatar,
  List,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

function InstaCollapes({ profile, numbers }) {
  return (
    <Box
      sx={{
        backgroundColor: "#F0F2F5",
        borderRadius: 1,
        padding: 2,
        marginTop: 1
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={12} display={"flex"} justifyContent={"space-between"}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Avatar alt="avtar" src="/static/images/avatar/1.jpg" />
            <Typography variant="subtitle2" marginLeft={1}>
              <span style={{ fontWeight: "bold" }}>
                <Link
                  href={`https://www.instagram.com/${profile.username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: "none" }}
                >
                  {profile.username}
                </Link>
                &nbsp;
                {profile.is_verified ? (
                  <Verified sx={{ color: "#008DE9", fontSize: "12px" }} />
                ) : (
                  ""
                )}
              </span>
              &nbsp;
              <Instagram sx={{ verticalAlign: "middle" }} />
              <br />
              {profile.full_name}
            </Typography>
          </Box>
          <Box>
            <Tooltip title="Save">
              <Bookmark />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box
            bgcolor={"white"}
            borderRadius={1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <List>
              <ListItem>
                <Tooltip title="Location">
                  <LocationOn style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={`${profile.state ? profile.state + " " : ""}${profile.city ? profile.city : ""
                    }`}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Category">
                  <Category style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.categoryName}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="DOB">
                  <ChildCare style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.dob}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Followers">
                  <ArrowOutward style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.instaFollowers}
                />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <Tooltip title="External Url">
                  <LinkIcon style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.external_url}
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box bgcolor={"white"} borderRadius={1}>
            <List>
              <ListItem>
                <Tooltip title="Email">
                  <Email style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.email}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Phone">
                  <Phone style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={numbers.find((list) => list.username === profile.username) ? numbers.find((list) => list.username === profile.username).number : "8292******"}
                />
              </ListItem>
              <ListItem>
                <Table>
                  <TableBody>
                    {profile.manager && profile.manager.length > 0 && <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography variant="subtitle2">Manager</Typography>
                      </TableCell>
                    </TableRow>}
                    {profile.manager && profile.manager.length > 0 &&
                      profile.manager.map((manager, index) =>
                      (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="caption">{manager.manager_name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="caption">
                              {manager.manager_number}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                      )}
                  </TableBody>
                </Table>
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Box bgcolor={"white"} borderRadius={1}>
            <List>
              <ListItem>
                <Tooltip title="Bio">
                  <Description style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.biography}
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InstaCollapes;
