import React from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { ArrowDownward, Download } from "@mui/icons-material";

export default function FinanceInfluencerTable({ fontSize, fontStyle, campaigns, handleDownload, handleShow }) {
  return (
    // <Card>
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontStyle }}>#</TableCell>
            <TableCell sx={{ fontStyle }}>Campaign</TableCell>
            <TableCell sx={{ fontStyle }}>Count</TableCell>
            <TableCell sx={{ fontStyle }}>Platform</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns &&
            campaigns.map((campaign, index) => (
              <TableRow key={campaign._id} id={index}>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleShow(index, campaign._id)}>{index + 1}</TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleShow(index, campaign._id)}>{campaign.campaignName[0]}</TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleShow(index, campaign._id)}>{campaign.count}</TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleShow(index, campaign._id)}>{campaign.platformName[0]}</TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }}>
                  <Tooltip title='Download'>
                    <ArrowDownward fontSize="small" color="info" onClick={() =>
                      handleDownload(campaign._id, campaign.campaignName[0], false)} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    // </Card>
  );
}
