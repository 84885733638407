import http from "./httpservices";
import GetCookieByName from "../hooks/GetCookie";

async function signUpUser(postdata) {
  const { data } = await http.post("users/registeruser/", postdata);
  return data;
}

async function signInUser(postdata, headers = {}) {
  const { data } = await http.post("users/loginuser", postdata, headers);
  return data;
}

async function getUserDetails(postdata) {
  const { data } = await http.post("users/getDetails", postdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

async function viewedInfluencersList(getdata) {
  const { data } = await http.get("users/viewedInfluencers?" + getdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

async function requestedInfluencersList(getdata) {
  const { data } = await http.get("users/requestedInfluencers?" + getdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

async function changeUserPassword(postdata) {
  const { data } = await http.post("users/changePassword/", postdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

async function updateBillingDetails(postdata) {
  const { data } = await http.post("users/saveBillingDetails/", postdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

async function sendVerificationMail(postdata) {
  const { data } = await http.post("users/sendVerificationLink/", postdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });

  return data;
}

async function verifyUserEmail(postdata) {
  const { data } = await http.post("users/verify", postdata);
  return data;
}

async function contactForPrice(postdata) {
  const { data } = await http.post("users/contactforpricing/", postdata);
  return data;
}

async function fetchBillingDetails() {
  const { data } = await http.get("users/fetchTransactions", {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

async function sendResetPasswordLink(postdata) {
  const { data } = await http.post("users/resetPasswordLink", postdata);
  return data;
}

async function verifyResetPasswordOtp(postdata) {
  const { data } = await http.post("users/verifyResetPasswordOtp", postdata);
  return data;
}

async function resetUserPassword(postdata) {
  const { data } = await http.post("users/resetUserPassword", postdata);
  return data;
}

async function getStatesNames() {
  const { data } = await http.post(
    "external/statesListing",
    {},
    {
      headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
    }
  );

  return data;
}

async function sendLoginOTP(postdata) {
  const { data } = await http.post("users/sendEmailOtpForLogin", postdata);

  return data;
}

async function searchInfluencer(postdata) {
  const { data } = await http.post("users/influencer/search", postdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });

  return data;
}

async function getInstagramAccessToken(postdata) {
  const { data } = await http.post(
    "users/get_instagram_access_token",
    postdata
  );

  return data;
}

async function verifyEmailCode(postdata) {
  const { data } = await http.post("users/verifyEmailCode", postdata);

  return data;
}

async function verifyReferralCode(postdata) {
  const { data } = await http.post("users/verifyReferralCode", postdata);

  return data;
}

async function generateReferralCode(postdata) {
  const { data } = await http.post("users/generateReferralCode", postdata, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });

  return data;
}

export async function FetchExportCSVData(params) {
  const { data } = await http.post("users/exportcsv", params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

const usersEndpoints = {
  signUpUser,
  signInUser,
  getUserDetails,
  viewedInfluencersList,
  requestedInfluencersList,
  changeUserPassword,
  updateBillingDetails,
  sendVerificationMail,
  verifyUserEmail,
  contactForPrice,
  fetchBillingDetails,
  sendResetPasswordLink,
  verifyResetPasswordOtp,
  resetUserPassword,
  getStatesNames,
  sendLoginOTP,
  searchInfluencer,
  getInstagramAccessToken,
  verifyEmailCode,
  verifyReferralCode,
  generateReferralCode,
  FetchExportCSVData
};

export default usersEndpoints;
