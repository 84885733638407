import * as React from "react";
import {
  List,
  Stack,
  Divider,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Popover,
} from "@mui/material";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import Icon from "../../../meesha_icon.svg";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import {
  AccountCircle,
  Campaign,
  CurrencyRupee,
  Dashboard,
  EditNote,
  Logout,
  ManageHistory,
  Notes,
  Person,
  Settings,
} from "@mui/icons-material";
import SummarizeIcon from "@mui/icons-material/Summarize";
// const config = require("../../../config/settings.json");

/*global chrome*/

function SidebarComponent() {
  const [cookies, removeCookie] = useCookies(["user", "auth"]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const id = open ? "simple-popover" : undefined;

  const sidebar_options = [
    {
      icon: (
        <Tooltip title="Smart Dashboard" placement="right" arrow>
          <Dashboard />
        </Tooltip>
      ),
      redirect: "/filter/influencers",
    },
    {
      icon: (
        <Tooltip title="History" placement="right" arrow>
          <ManageHistory />
        </Tooltip>
      ),
      redirect: "/history",
    },
    {
      icon: (
        <Tooltip title="Profile" placement="right" arrow>
          <AccountCircle />
        </Tooltip>
      ),
      redirect: "/profile",
    },
    {
      icon: (
        <Tooltip title="Free Credits" placement="right" arrow>
          <RedeemOutlinedIcon />
        </Tooltip>
      ),
      redirect: "/referrals",
    },
    {
      icon: (
        <Tooltip title="Reports" placement="right" arrow>
          <SummarizeIcon />
        </Tooltip>
      ),
      redirect: "/reports",
    },
    {
      icon: (
        <Tooltip title="Payments" placement="right" arrow>
          <CurrencyRupee />
        </Tooltip>
      ),
      redirect: "/payments",
    },
  ];

  const handleLogout = (event) => {
    removeCookie("auth", "", {
      path: "/",
      expires: new Date(1691479246),
    });

    removeCookie("auth", "", {
      path: "/filter",
      expires: new Date(1691479246),
    });

    removeCookie("auth", "", {
      path: "/filter/influencers",
      expires: new Date(1691479246),
    });

    removeCookie("user", "", {
      path: "/",
      expires: new Date(1691479246),
    });

    try {
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID,
        {
          message: "logout_user",
        },
        function (responseFromExtension) {
          responseFromExtension = responseFromExtension || {};
        }
      );
    } catch (error) {
      console.log(error);
    }
    window.location.href = "/";
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack flexGrow={1}>
        <List>
          <ListItem disablePadding>
            <a
              href="/"
              style={{
                textDecoration: "none",
                margin: "0 auto",
                padding: "10px 0",
              }}
            >
              <img
                src={Icon}
                style={{
                  width: "30px",
                  height: "30px",
                }}
                alt="icon"
              />
            </a>
          </ListItem>
          <Divider />
          {sidebar_options.map((option, index) => (
            <ListItem
              disablePadding
              key={`sidebar-link${index}`}
              sx={{
                background:
                  option.redirect === window.location.pathname ? "#e03a00" : "",
                color:
                  option.redirect === window.location.pathname
                    ? "primary.contrastText"
                    : "",
              }}
            >
              <ListItemButton component={"a"} href={option.redirect}>
                <ListItemIcon
                  sx={{
                    justifyContent: { xs: "center" },
                    paddingY: { xs: 1, md: 0 },
                    color:
                      option.redirect === window.location.pathname
                        ? "primary.contrastText"
                        : "",
                  }}
                >
                  {option.icon}
                </ListItemIcon>
                <ListItemText primary={option.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>

      <Stack>
        <List onClick={handleClick} aria-describedby={id}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon
                sx={{
                  justifyContent: { xs: "center" },
                  paddingY: { xs: 1, md: 0 },
                }}
              >
                <Tooltip title="Settings" placement="right" arrow>
                  <Settings />
                </Tooltip>
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton to="/profile">
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText color="#FF5200" primary={cookies.user?.name} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Stack>
    </>
  );
}

export default SidebarComponent;
