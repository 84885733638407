import * as React from "react";
import {
  Button,
  Container,
  FormControl,
  ListItem,
  ListItemText,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  ListItemButton,
  Box,
} from "@mui/material";
import { MailOutline, PhoneIphone } from "@mui/icons-material";
import NearMeIcon from "@mui/icons-material/NearMe";
import FooterImage from "../../../footer.png";

function SubscribeThroughEmail(props) {
  return (
    <>
      <Container
        maxWidth={"fluid"}
        sx={{
          paddingX: { xs: 2, md: 5 },
          paddingY: 5,
          bgcolor: "badgeprimary.main",
          position: props.fixedtobottom ? "fixed" : "static",
          bottom: 0,
        }}
      >
        <Box
          maxWidth={"lg"}
          sx={{
            width: "1419px",
            height: "393px",
            borderRadius: "57px",
            backgroundColor: "#FCA88D",
            marginX: "auto",
            marginTop: "-242px",
            marginBottom: "52px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ marginY: "auto", marginLeft: 4 }}>
            <Typography
              variant="h3"
              sx={{ fontSize: "50px", fontWeight: "800", color: "#fff" }}
            >
              Flexible options to help you connect with right Influencer at the
              right price.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                fontSize: "28px",
                fontWeight: "400",
                marginTop: 2,
                borderRadius: "12px",
              }}
              href="/authenticate/signin"
            >
              Get Started with Meesha for Free
            </Button>
          </Box>
          <img src={FooterImage} alt="Footer" />
        </Box>
        <Grid container rowSpacing={{ xs: 3, md: 0 }}>
          <Grid item xs={6} md={4} paddingLeft={{ md: "10rem", xs: "0" }}>
            <Stack>
              <Typography
                variant="h6"
                color={"primary"}
                fontWeight={"800"}
                component={"a"}
                href="/"
                sx={{ textDecoration: "none" }}
                marginBottom={1}
              >
                MEESHA
                <br />
                Creatify Media Pvt. Ltd
                <br />
              </Typography>

              <Typography
                display={"flex"}
                gap={0.5}
                color={"#5b4843"}
                marginBottom={1}
              >
                <span style={{ display: "flex" }}>
                  <PhoneIphone />
                </span>
                <span>+91 8142051271</span>
              </Typography>

              <Typography
                display={"flex"}
                gap={0.5}
                color={"#5b4843"}
                marginBottom={1}
              >
                <span style={{ display: "flex" }}>
                  <MailOutline />
                </span>
                <span>support@meesha.co.in</span>
              </Typography>

              <Typography display={"flex"} gap={0.5} color={"#5b4843"}>
                <span style={{ display: "flex" }}>
                  <NearMeIcon />
                </span>
                <span>
                  3rd Floor, Innov8
                  <br />
                  Orchid Center, Sector 53
                  <br />
                  Gurugram, Haryana 122022
                </span>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={4} order={{ xs: -1, md: 0 }}>
            <Stack spacing={2} alignItems={"center"}>
              <Typography
                component={"h5"}
                variant={"h5"}
                sx={{ color: "dark.main" }}
              >
                Get Started with Meesha
              </Typography>
              <FormControl
                size="small"
                sx={{
                  bgcolor: "primary.contrastText",
                  borderRadius: 2,
                }}
              >
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <Button variant="contained" color="primary">
                        start for free
                      </Button>
                    </InputAdornment>
                  }
                  placeholder="Enter your work email"
                  sx={{ paddingY: 1 }}
                />
              </FormControl>
            </Stack>
          </Grid>

          <Grid
            container
            item
            xs={6}
            md={4}
            paddingLeft={{ md: "10rem", xs: "0" }}
            justifyContent={{ md: "start", xs: "end" }}
          >
            <Stack>
              <ListItem disablePadding disableGutters>
                <ListItemText
                  primary={"Other Links"}
                  primaryTypographyProps={{
                    variant: "h6",
                  }}
                />
              </ListItem>

              <ListItem disablePadding disableGutters>
                <ListItemButton sx={{ padding: 0 }} href={"/privacy_policy"}>
                  <ListItemText
                    primary={"Privacy Policy"}
                    primaryTypographyProps={{
                      color: "#5b4843",
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding disableGutters>
                <ListItemButton sx={{ padding: 0 }} href={"/terms_of_use"}>
                  <ListItemText
                    primary={"Terms and Conditions"}
                    primaryTypographyProps={{
                      color: "#5b4843",
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding disableGutters>
                <ListItemButton sx={{ padding: 0 }} href={"/about_us"}>
                  <ListItemText
                    primary={"About us"}
                    primaryTypographyProps={{
                      color: "#5b4843",
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                disableGutters
                sx={{ width: "fit-content" }}
              >
                <ListItemButton sx={{ padding: 0 }} href={"/policy/contactus"}>
                  <ListItemText
                    primary={"Contact Us"}
                    primaryTypographyProps={{
                      color: "#5b4843",
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                disableGutters
                sx={{ width: "fit-content" }}
              >
                <ListItemButton sx={{ padding: 0 }} href={"/policy/refund"}>
                  <ListItemText
                    primary={"Cancellation and Refund Policy"}
                    primaryTypographyProps={{
                      color: "#5b4843",
                    }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                disableGutters
                sx={{ width: "fit-content" }}
              >
                <ListItemButton sx={{ padding: 0 }} href={"/policy/shipping"}>
                  <ListItemText
                    primary={"Shipping and Delivery Policy"}
                    primaryTypographyProps={{
                      color: "#5b4843",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default SubscribeThroughEmail;
