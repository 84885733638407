import React from 'react'
import { Box, Chip, TextField, Tooltip } from "@mui/material";
import AllStatusTable from './common/AllStatusTable';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function PaymentStatus() {
    const navigate = useNavigate();

    return (
        <>
            <Box
                width={{ xs: "100%", lg: `calc(100% - 80px)` }}
                marginLeft={{ xs: "0px", lg: `80px` }}
                marginTop={8}
                padding={2}
                backgroundColor={"#F0F2F5"}
                minHeight={"100vh"}
                height={"100%"}
            >
                <Tooltip title="Go Back">
                    <ArrowBack sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                </Tooltip>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Chip
                        label="300"
                        size="small"
                        variant="filled"
                        sx={{ backgroundColor: "#F9B572", color: "#ffffff", alignSelf: 'center' }}
                    />
                    <TextField id="outlined-search" label="Search" type="search" size='small' />
                </Box>
                <Box marginTop={1}>
                    <AllStatusTable />
                </Box>
            </Box>
        </>
    )
}
