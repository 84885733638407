import http from "./httpservices";
import GetCookieByName from "../hooks/GetCookie";

export async function FetchCampaigns() {
  const { data } = await http.get("campaign/latest", {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function AddCampaign(params) {
  const { data } = await http.post("campaign", params, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export default {
  FetchCampaigns,
  AddCampaign
};
