import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
  TextField,
  ListItem,
  ListItemText,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import CampaignForm from "./common/Form/CampaignForm";
import CustomizedSnackbars from "./common/CustomizedSnackbars";

function Campaign({ updateLatestCampaign, totalCampaign }) {
  const navigate = useNavigate();

  const [openCampaignForm, setOpenCampaignForm] = useState(false);

  const handleOpenCampaignForm = () => setOpenCampaignForm(true);
  const handleCloseCampaignForm = () => setOpenCampaignForm(false);

  const [viewState, setViewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleViewDrawer = (anchor2, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setViewState({ ...viewState, [anchor2]: open });
  };

  const [openSnackBar, setSnackBar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState(
    "Campaign has been Created Successfully"
  );

  const handleSnackBarClose = () => setSnackBar(false);
  const handleSnackBarOpen = (severity, message) => {
    setSnackBarSeverity(severity || snackBarSeverity);
    setSnackBarMessage(message || snackBarMessage);
    setSnackBar(true);
  };

  const campaignView = (anchor2) => (
    <>
      <Box sx={{ paddingX: 3, paddingTop: 3 }} role="presentation">
        <List>
          <ListItem disablePadding>
            <TextField
              id="outlined-search"
              label="Search campaign..."
              type="search"
            />
          </ListItem>
        </List>
        <Divider sx={{ marginY: 1 }} />
        <List>
          <ListItem>
            <ListItemText>List 1</ListItemText>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText>List 1</ListItemText>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText>List 1</ListItemText>
          </ListItem>
        </List>
      </Box>
    </>
  );

  const anchor1 = "right";
  const anchor2 = "right";

  return (
    <>
      <CustomizedSnackbars
        handleSnackBarClose={handleSnackBarClose}
        open={openSnackBar}
        severity={snackBarSeverity}
        message={snackBarMessage}
      />
      <CampaignForm
        handleOpenCampaignForm={handleOpenCampaignForm}
        handleCloseCampaignForm={handleCloseCampaignForm}
        openCampaignForm={openCampaignForm}
        handleSnackBarOpen={handleSnackBarOpen}
        updateLatestCampaign={updateLatestCampaign}
      />
      <SwipeableDrawer
        anchor={anchor2}
        open={viewState[anchor2]}
        onClose={toggleViewDrawer(anchor2, false)}
        onOpen={toggleViewDrawer(anchor2, true)}
      >
        {campaignView(anchor2)}
      </SwipeableDrawer>

      <Grid item xs={6}>
        <Card sx={{ padding: 2, borderRadius: 0, boxShadow: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CardContent sx={{ padding: 1 }}>
              <Typography component="div" variant="h5">
                <Chip
                  label={totalCampaign}
                  size="small"
                  variant="filled"
                  sx={{ backgroundColor: "#F9B572", color: "#ffffff" }}
                />
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                fontWeight={"bold"}
              >
                CAMPAIGNS
              </Typography>
            </CardContent>
            <Box sx={{ alignSelf: "center" }}>
              <Tooltip title="Add Campaign" onClick={handleOpenCampaignForm}>
                <AddCircleIcon sx={{ cursor: "pointer", color: "#FFD206" }} />
              </Tooltip>
              <Tooltip
                title="View Campaigns"
                onClick={() => navigate("/payments/campaigns")}
              >
                <InfoIcon sx={{ cursor: "pointer", color: "#0AC380" }} />
              </Tooltip>
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
}

export default Campaign;
