import * as React from 'react';
import { Button, Typography, Stack, TextField, FormHelperText, CircularProgress } from '@mui/material';
import { useState } from 'react';
import usersEndpoints from '../../../../services/userServices';

function ShowOtpField(props){
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        let current_elem = event.target.name;
        if(event.target.value.length === 1 && current_elem.split('_')[2] < 6){
            // move forward
            let next_element = document.getElementsByName(`otp_input_${ parseInt(current_elem.split('_')[2]) + 1 }`)[0];
            next_element.focus();
        }
    }

    const handleBackMovement = (event) => {
        if(event.key === "Backspace"){
            let current_elem = event.target.name;
            if(event.target.value.length === 0 && current_elem.split('_')[2] > 1){
                // move backward
                let previous_element = document.getElementsByName(`otp_input_${ parseInt(current_elem.split('_')[2]) - 1 }`)[0];
                previous_element.focus();
            }
        }
    }

    const checkDigit = (event) => {
        let keyCode = event.keyCode || event.which;
        let prevent_input = true;
        
        if(keyCode >= 48 && keyCode <= 57){
            prevent_input = false;
        }
        
        if(prevent_input){
            event.returnValue = false;
            if(event.preventDefault){
                event.preventDefault();
            }
        }
    }

    const verifyOtp = async () => {
        let entered_otp = "";
        for(let i = 0; i < 6; i++){
            let element = document.getElementsByName(`otp_input_${ i + 1 }`)[0];
            entered_otp += element.value;
        }
        
        let postdata = {
            email: props.useremail,
            otp_value: entered_otp
        };
        
        setLoading(true);
        const response = await usersEndpoints.verifyResetPasswordOtp(postdata);
        setLoading(false);
        
        if(response['success'] === true){
            props.onChange(true);
        }
        else{
            props.onChange(false);
            setErrorMessage(response['message']);
        }
    }

    return(
        <>
            <Typography
                color={ "primary" }
                textAlign={ "left" }
                sx={{ marginBottom: 2 }}
            >
                Please enter the OTP sent on your Email Address
            </Typography>
            <Stack
                direction={ "row" }
                spacing={2}
                justifyContent={ "center" }
                marginBottom={1}
            >
                <TextField
                    variant='outlined'
                    type={ 'tel' }
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' }
                    }}
                    onChange={handleChange}
                    onKeyDown={handleBackMovement}
                    onKeyPress={checkDigit}
                    name="otp_input_1"
                    autoFocus
                />
                <TextField
                    variant='outlined'
                    type={ 'tel' }
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' }
                    }}
                    onChange={handleChange}
                    onKeyDown={handleBackMovement}
                    onKeyPress={checkDigit}
                    name="otp_input_2"
                />
                <TextField
                    variant='outlined'
                    type={ 'tel' }
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' }
                    }}
                    onChange={handleChange}
                    onKeyDown={handleBackMovement}
                    onKeyPress={checkDigit}
                    name="otp_input_3"
                />
                <TextField
                    variant='outlined'
                    type={ 'tel' }
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' }
                    }}
                    onChange={handleChange}
                    onKeyDown={handleBackMovement}
                    onKeyPress={checkDigit}
                    name="otp_input_4"
                />
                <TextField
                    variant='outlined'
                    type={ 'tel' }
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' }
                    }}
                    onChange={handleChange}
                    onKeyDown={handleBackMovement}
                    onKeyPress={checkDigit}
                    name="otp_input_5"
                />
                <TextField
                    variant='outlined'
                    type={ 'tel' }
                    inputProps={{
                        maxLength: 1,
                        style: { textAlign: 'center' }
                    }}
                    onChange={handleChange}
                    onKeyDown={handleBackMovement}
                    onKeyPress={checkDigit}
                    name="otp_input_6"
                />
            </Stack>
            <FormHelperText
                error
                sx={{ marginLeft: '0.25rem', marginBottom: '0.5rem' }}
            >
                { errorMessage }
            </FormHelperText>
            <Button 
                variant="contained"
                color="primary"
                sx={{ 
                    paddingX: 4, 
                    textTransform: "capitalize",
                    fontSize: '1rem',
                    borderRadius: '10px'
                }}
                endIcon={
                    <CircularProgress
                        size={ loading ? "0.9rem" : "0rem" }
                        color="light"
                    />
                }
                onClick={verifyOtp}
            >
                Verify Email
            </Button>
        </>
    )
}

export default ShowOtpField;