import * as React from "react";
import {
  Avatar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import usersEndpoints from "../../../services/userServices";
import removeMediaFromUrl from "../../../hooks/loadImage";

const config = require("../../../config/settings.json");

function RequestedContactsList() {
  const [cookies] = useCookies(["user"]);
  const [requestedInfluencers, setRequestedInfluencers] = useState([]);

  useEffect(() => {
    if (cookies.user) {
      fetchRequestedInfluencers();
    }
  }, []);

  const fetchRequestedInfluencers = async () => {
    let getdataurl = "_id=" + cookies.user._id;
    const response = await usersEndpoints.requestedInfluencersList(getdataurl);
    if (response["success"] && response["success"] === true) {
      if (response["data"]) {
        setRequestedInfluencers(response["data"]);
      }
    }
  };

  return (
    <>
      {/* <Box 
                component={ "div" }
            >
                <Typography 
                    variant={ 'h6' }
                    sx={{ fontWeight: '600' }}
                >
                    Your Requested Contacts
                </Typography>
            </Box> */}
      {/* <Divider/> */}
      {requestedInfluencers.length > 0 ? (
        <SimpleBar
          autoHide={true}
          style={{
            maxHeight: "90vh",
            boxShadow: "1px 1px 0px 0px rgb(0 0 0 / 20%)",
            borderRadius: "4px",
          }}
        >
          <List>
            {requestedInfluencers.map((influencer, index) => (
              <Box component={"div"} key={`requested_influencer_${index}`}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={removeMediaFromUrl(influencer.image)} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={influencer.fullname}
                    secondary={
                      <Typography
                        component={"a"}
                        href={`${config.INSTAGRAM_BASE}${influencer.handle}`}
                        target="_blank"
                        color={"primary.main"}
                      >
                        @{influencer.handle}
                      </Typography>
                    }
                  />
                </ListItem>
                {requestedInfluencers.length > 1 && <Divider />}
              </Box>
            ))}
          </List>
        </SimpleBar>
      ) : (
        <Typography>No Influencer Found</Typography>
      )}
    </>
  );
}

export default RequestedContactsList;
