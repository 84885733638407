import React, { useState, useEffect } from "react";
import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  ListItemButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Spinner } from "react-bootstrap";
import "./css/dashboard.css";
import List from "./list";
import CategoryList from "./categoryList";
import InfluencerList from "./influencerList";
import cityservice from "../../services/cityservice";
import categoryservice from "../../services/categoryservice";
import influencerservice from "../../services/influencerservice";

export const InfluencerContent = ({ inputText }) => {
  const [loading, setLoading] = useState(true);
  const [categoryContent, setCategoryContent] = useState([]);
  const [influencerContent, setInfluencerContent] = useState([]);
  const [foundInfluencers, setFoundInfluencers] = useState([]);
  const [foundCategories, setFoundCategories] = useState([]);
  const [foundCity, setFoundCity] = useState([]);

  const getInit = async () => {
    const responseInfluencerContent =
      await influencerservice.getLimitedInfluencers();

    setInfluencerContent(responseInfluencerContent.slice(1, 6));
    setFoundInfluencers(responseInfluencerContent.slice(1, 6));
    setLoading(false);

    const responceCity = await cityservice.getLimitedCities();
    setFoundCity(responceCity);
    const responseCategoryContent = await categoryservice.getCategories();
    setCategoryContent(responseCategoryContent);
    setFoundCategories(responseCategoryContent.slice(1, 6));
    setLoading(false);
  };

  useEffect(() => {
    getInit();
  }, []);

  useEffect(() => {
    debouncedSearch(inputText);
  }, [inputText]);

  const performSearch = async () => {
    if (inputText !== "") {
      console.log("if");
      const influencerByUsername = await influencerservice.findByUserName(
        inputText
      );
      const cityByName = await cityservice.findByName(inputText);
      const categoryByName = await categoryservice.findByName(inputText);
      setFoundInfluencers(influencerByUsername);
      setFoundCategories(categoryByName);
      setFoundCity(cityByName);
    } 
    // else {
    //   console.log("else", foundCity);
    //   setFoundInfluencers(influencerContent);
    //   setFoundCategories(categoryContent);
    //   setFoundCity(foundCity);
    // }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  // Debounce the performSearch function with a delay of 500ms
  const debouncedSearch = debounce(performSearch, 2000);

  return (
    <Box
      sx={{
        backgroundColor: "#F8F9FA",
        width: { lg: "45%" },
        marginX: "388px",
        padding: 3,
        textAlign: "left",
      }}
    >
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
          Cities
        </Typography>
        {loading ? (
          <>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress color="inherit" size={20} />
              <br />
              <Typography variant="caption">Loading...Cities</Typography>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ backgroundColor: "#fff", padding: 1 }}>
              {foundCity && foundCity.length > 0 ? (
                foundCity.map((city, index) => (
                  <Typography variant="body2" marginBottom={1} key={index}>
                    {city.name}
                  </Typography>
                ))
              ) : (
                <Typography variant="caption">No results found!</Typography>
              )}
            </Box>
          </>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
          Catogories
        </Typography>
        {loading ? (
          <>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress color="inherit" size={20} />
              <br />
              <Typography variant="caption">Loading...Categories</Typography>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ backgroundColor: "#fff", padding: 1 }}>
              {foundCategories && foundCategories.length > 0 ? (
                foundCategories.map((category, index) => (
                  <Typography variant="body2" marginBottom={1} key={index}>
                    {category.name}
                  </Typography>
                ))
              ) : (
                <Typography variant="caption">No results found!</Typography>
              )}
            </Box>
          </>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "800" }}>
          Influencers Profiles
        </Typography>
        {loading ? (
          <>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress color="inherit" size={20} />
              <br />
              <Typography variant="caption">Loading...Influencers</Typography>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ backgroundColor: "#fff", padding: 1 }}>
              {foundInfluencers && foundInfluencers.length > 0 ? (
                foundInfluencers.map((influencer, index) => (
                  <Typography variant="body2" marginBottom={1} key={index}>
                    {influencer.username}
                  </Typography>
                ))
              ) : (
                <Typography variant="caption">No results found!</Typography>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default InfluencerContent;
