import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Groups, Cloud, ThumbUp, Preview } from "@mui/icons-material";

function ReportCards({ isLoading, reports, totalLikes, totalViews }) {
  const data = [
    {
      name: "Influencers Live",
      icon: <Groups fontSize="large" color="warning" />,
      data: !isLoading && reports.data.posts.length,
    },
    {
      name: "Posts Live",
      icon: <Cloud fontSize="large" color="warning" />,
      data: !isLoading && reports.data.posts.length,
    },
    {
      name: "Total Likes",
      icon: <ThumbUp fontSize="large" color="warning" />,
      data: totalLikes,
    },
    {
      name: "Total Views",
      icon: <Preview fontSize="large" color="warning" />,
      data: totalViews,
    },
  ];

  return (
    <Grid container spacing={1}>
      {data.map((d, index) => (
        <Grid item lg={3} key={index}>
          <Box padding={2} bgcolor={"white"} sx={{ borderRadius: 2 }}>
            <Typography
              variant="caption"
              sx={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {d.name}
            </Typography>
            <Grid container>
              <Grid item lg={2}>
                {d.icon}
              </Grid>
              <Grid item lg={10}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {d.data}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default ReportCards;
