import React from "react";
import { Cookie, EditNote, Email, North, South } from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PreviewIcon from "@mui/icons-material/Preview";
import IconButton from "@mui/material/IconButton";
import InstaCollapes from "./instaCollapes";
import YoutubeCollapes from "./youtubeCollapes";
import "../css/dashboard.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function TableComponent({
  filters,
  numbers,
  profiles,
  ageFormatter,
  contactFormatter,
  categoryFormatter,
  handleCheckboxClick,
  credits,
  exportList,
  onSort,
  tableSort,
  userId,
}) {
  function Row({ profile, index }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <TableRow
          key={index}
          sx={{ borderBottom: "0.5px solid lightgray", padding: "10px" }}
        >
          <TableCell style={{ width: "5%" }}>
            <input
              type="checkbox"
              id={profile._id}
              title="Select"
              checked={exportList.find(
                (e) =>
                  e ===
                  (filters.platform === "Youtube"
                    ? profile.youtubeData.channel_id
                    : profile.username)
              )}
              style={{ width: "18px", height: "18px", cursor: "pointer" }}
              onClick={(event) =>
                handleCheckboxClick(
                  event,
                  filters.platform === "Youtube"
                    ? profile.youtubeData.channel_id
                    : profile.username,
                  profile._id
                )
              }
              disabled={
                !credits &&
                !numbers.find((list) => list.username === profile.username)
                  ?.number
              }
            />
          </TableCell>
          <TableCell style={{ width: "15%" }}>
            {filters.platform === "Youtube" ? (
              <a
                href={
                  profile.youtubeData?.handle
                    ? `https://www.youtube.com/${profile.youtubeData.handle}`
                    : profile.youtubeData?.channel_id
                      ? `https://www.youtube.com/channel/${profile.youtubeData.channel_id}`
                      : profile.youtubeData?.url
                        ? profile.youtubeData.url
                        : ""
                }
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#FF5200" }}
              >
                {profile.youtubeData?.handle
                  ? profile.youtubeData.handle
                  : profile.youtubeData?.channel_id
                    ? profile.youtubeData.channel_id
                    : profile.username}
              </a>
            ) : (
              <a
                href={`https://www.instagram.com/${profile.username}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#FF5200" }}
              >
                {profile.username}
              </a>
            )}
          </TableCell>
          <TableCell style={{ width: "10%" }}>{profile.gender}</TableCell>
          <TableCell style={{ width: "10%" }}>
            {ageFormatter(profile.dob)}
          </TableCell>
          <TableCell style={{ width: "15%" }}>
            {filters.platform === "Youtube"
              ? profile?.youtubeData?.statistics?.subscriberCount
              : profile.instaFollowers}
          </TableCell>
          <TableCell style={{ width: "10%" }}>
            {categoryFormatter(
              profile.category ? profile.category : profile.categoryName
            )}
          </TableCell>
          <TableCell style={{ width: "10%" }}>
            {profile.blog ? (
              <a
                href={profile.blog.blogUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#FF5200" }}
              >
                {profile.blog.blogTitle}
              </a>
            ) : (
              "NA"
            )}
            {/* {profile.blog ? profile.blog.blogTitle : "NA"} */}
          </TableCell>
          <TableCell style={{ textAlign: "end", width: "15%" }}>
            {contactFormatter(profile._id, profile.isNumber, profile.username)}
          </TableCell>
          <TableCell style={{ width: "10%" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleOpen}
            >
              <EditNote />
            </IconButton>

            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => navigate(`/notes/${userId}/${profile._id}`)} // /notes/user/influencer
            >
              <PreviewIcon />
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow key={`inner_${index}`}>
          <TableCell colSpan={11}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <InstaCollapes profile={profile} numbers={numbers} />
              {profile.isYoutube && <YoutubeCollapes profile={profile} />}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  const handleOpen = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Create a Note",
      html: `
              <input id="swal-input1" class="swal2-input" placeholder="Name" style="width: 80%; font-family: inherit;">
              <textarea id="swal-input2" class="swal2-textarea" placeholder="Description" style="width: 80%; font-family: inherit;"></textarea>
            `,
      focusConfirm: false,
      confirmButtonText: "Submit",
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
    });
    if (formValues) {
      Swal.fire(JSON.stringify(formValues));
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        size="small"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableHead>
          <TableRow key={"unique"}>
            <TableCell></TableCell>
            <TableCell style={{ fontWeight: "600" }}>
              {filters.platform === "Youtube" ? "Channel Name" : "User Name"}
            </TableCell>
            <TableCell
              style={{ fontWeight: "600" }}
              sx={{ cursor: "pointer" }}
              onClick={() => onSort("gender")}
            >
              {tableSort.gender ? (
                tableSort.gender === "asc" ? (
                  <North sx={{ fontSize: "12px" }} />
                ) : (
                  <South sx={{ fontSize: "12px" }} />
                )
              ) : null}
              Gender
            </TableCell>
            <TableCell
              style={{ fontWeight: "600" }}
              sx={{ cursor: "pointer" }}
              onClick={() => onSort("age")}
            >
              {tableSort.age ? (
                tableSort.age === "asc" ? (
                  <North sx={{ fontSize: "12px" }} />
                ) : (
                  <South sx={{ fontSize: "12px" }} />
                )
              ) : null}
              Age
            </TableCell>
            <TableCell
              style={{ fontWeight: "600" }}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                onSort(
                  filters.platform === "Youtube" ? "Subscribers" : "followers"
                )
              }
            >
              {tableSort.followers ? (
                tableSort.followers === "asc" ? (
                  <North sx={{ fontSize: "12px" }} />
                ) : (
                  <South sx={{ fontSize: "12px" }} />
                )
              ) : null}
              {filters.platform === "Youtube" ? "Subscribers" : "Followers"}
            </TableCell>
            <TableCell style={{ fontWeight: "600" }}>Category</TableCell>
            <TableCell style={{ fontWeight: "600" }}>Link</TableCell>
            <TableCell style={{ fontWeight: "600" }}></TableCell>
            {/* <TableCell style={{ fontWeight: '600' }}>Contact</TableCell> */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles.map((profile, index) => (
            <>
              <Row profile={profile} index={index} />
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
