import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  RadioGroup,
  Radio,
  Divider,
} from "@mui/material";

import * as React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import jwtDecode from "jwt-decode";
// import ReactLoginMS from 'react-ms-login';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import OfficeIcon from '../images/microsoft-office.svg';

import usersEndpoints from "../../../services/userServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { makeStyles } from '@material-ui/styles';
// const config = require("../../../config/settings.json");
/*global chrome*/
/*global google*/

// const useStyles = makeStyles({
//     microsoft_login_btn: {
//         padding: '0px',
//         background: '#FF5200',
//         border: 'none',
//         fontFamily: 'Google Sans, arial, sans-serif',
//         fontSize: '0.85rem',
//         borderRadius: '3px',
//         boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
//         fontWeight: '500',
//         cursor: 'pointer',
//         display: 'flex',
//         width: '205px',
//         letterSpacing: '1px'
//     },
//     icon_image: {
//         width: '2.25rem',
//         height: '2.2rem',
//         background: '#FFFFFF',
//         marginTop: '2px',
//         marginBottom: '-2px',
//         marginLeft: '2px',
//         borderRadius: '5px 0 0 5px',
//         padding: '5px'
//     },
//     btn_text: {
//         marginTop: 'auto',
//         marginBottom: 'auto',
//         marginLeft: '12px',
//         marginRight: '12px',
//         color: '#FFFFFF'
//     }
// });

function SigninSection() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user", "auth", "logindata"]);

  const [passwordType, setPasswordType] = useState("password");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const [loginMode, setLoginMode] = useState("otp");
  const [otpSent, setOtpSent] = useState(false);
  const [loaders, setLoaders] = useState({
    password_login_btn: false,
    send_otp_btn: false,
    verify_otp_btn: false,
  });

  const [redirectText, setRedirectText] = useState("");

  // const classes = useStyles();
  const SPACE_KEY = " ";
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_ID_SIGNIN;
  // const MICROSOFT_CLIENT_ID = process.env.REACT_APP_CLIENT_ID_MICROSOFT;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleGoogleLoginResponse = async (response) => {
    if (response.credential) {
      const details = jwtDecode(response.credential);
      if (details.hd) {
        // send token to server for verification and further login/registration process
        let headers = { headers: { "user-auth": response.credential } };

        const postdata = {
          mode: "gauth",
          page: "signin",
        };

        const loginData = await usersEndpoints.signInUser(postdata, headers);
        saveLoginData(loginData);
      } else {
        // alert('Please join using a business email address');
        toast.error("Please join using a business email address");
      }
    }
  };

  useEffect(() => {
    window.onGoogleLibraryLoad = () => {
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: (data) => handleGoogleLoginResponse(data),
        cancel_on_tap_outside: false,
      });

      google.accounts.id.renderButton(
        document.getElementById("google_sign_in"),
        {
          theme: "filled_blue",
          size: "large",
          text: "continue_with",
        }
      );

      google.accounts.id.prompt();
    };
  }, []);

  const onSubmit = (data) => {
    handleSignin(data);
  };

  const showInvalidValues = (data) => {};

  const handleChangePasswordType = () => {
    let type_attr = "";
    if (passwordType === "password") {
      type_attr = "text";
    } else {
      type_attr = "password";
    }
    setPasswordType(type_attr);
  };

  const handleSpaceInInput = (event) => {
    let keyCode = event.keyCode || event.which;
    keyCode = String.fromCharCode(keyCode);
    if (keyCode === SPACE_KEY) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  };

  const handleSignin = async (signindata) => {
    let postdata = {
      email: signindata.user_email,
      password: signindata.user_password ? signindata.user_password : "",
      otp_entered: signindata.otp_entered ? signindata.otp_entered : "",
      mode: loginMode,
    };

    if (loginMode === "normal") {
      setLoaders({ ...loaders, ...{ password_login_btn: true } });
    } else if (loginMode === "otp") {
      setLoaders({ ...loaders, ...{ verify_otp_btn: true } });
    }

    const response = await usersEndpoints.signInUser(postdata);

    setLoaders({
      ...loaders,
      ...{ password_login_btn: false, verify_otp_btn: false },
    });

    saveLoginData(response);
  };

  const saveLoginData = (response) => {
    if (response) {
      if (response["success"] === true) {
        // let cookieData = {
        //   name: response["loginData"]["fullname"],
        //   _id: response["loginData"]["user_id"],
        // };

        setCookie(
          "user",
          {
            name: response["loginData"]["fullname"],
            _id: response["loginData"]["user_id"],
            credits: response["loginData"]["credits"],
          },
          {
            path: "/",
            secure: false,
            maxAge: 2592000,
          }
        );
        setCookie(
          "user",
          {
            name: response["loginData"]["fullname"],
            _id: response["loginData"]["user_id"],
            credits: response["loginData"]["credits"],
          },
          {
            path: "/filter",
            secure: false,
            maxAge: 2592000,
          }
        );

        setCookie(
          "auth",
          { token: response["loginData"]["jwttoken"] },
          {
            path: "/",
            secure: false,
            maxAge: 2592000,
          }
        );

        try {
          chrome.runtime.sendMessage(
            process.env.REACT_APP_EXTENSION_ID,
            {
              message: "login_successful",
              loginData: response["loginData"],
            },
            function (responseFromExtension) {
              responseFromExtension = responseFromExtension || {};
              window.close();
            }
          );
        } catch (error) {
          console.log(error);
          setCookie("logindata", response["loginData"], {
            path: "/",
            secure: false,
            maxAge: 2592000,
          });
        }
        // navigate('/');
        // window.location.href = '/';
      } else {
        // alert(response['message']);
        toast.error(response["message"]);
        if (response["redirect"]) {
          createRedirectTimer(response["redirect"], 4000);
        }
      }
    }
  };

  const handleLoginModeChange = (event) => {
    setLoginMode(event.target.value);
  };

  const sendOtpToUser = async () => {
    const user_email = getValues("user_email");
    clearErrors("user_email");
    if (user_email.length === 0) {
      setError(
        "user_email",
        { message: "Please enter your registered email address" },
        { shouldFocus: true }
      );
      return;
    }

    let postdata = {
      email: user_email,
    };

    setLoaders({ ...loaders, ...{ send_otp_btn: true } });
    const response = await usersEndpoints.sendLoginOTP(postdata);
    setLoaders({ ...loaders, ...{ send_otp_btn: false } });

    if (response["success"] === true) {
      setOtpSent(true);
    } else {
      // alert(response['message']);
      toast.error(response["message"]);
      if (response["redirect"]) {
        createRedirectTimer(response["redirect"], 4000);
      }
    }
  };

  const createRedirectTimer = (path, time) => {
    if (!path) {
      path = "/";
    }

    if (time === 0) {
      window.location.href = path;
    }

    setTimeout(() => {
      setRedirectText(`Redirecting in ${time / 1000} seconds`);
      time = time - 1000;
      createRedirectTimer(path, time);
    }, 1000);
  };

  return (
    <>
      <Typography
        variant="h5"
        component={"h5"}
        sx={{ marginBottom: 3, textAlign: "center" }}
      >
        Hello again!
        <br />
        Login to your Meesha account
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item sm={12} md={5}>
          <form onSubmit={handleSubmit(onSubmit, showInvalidValues)}>
            <FormControl sx={{ width: "100%", marginBottom: 2 }}>
              <RadioGroup
                value={loginMode}
                onChange={handleLoginModeChange}
                row
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControlLabel
                  value="normal"
                  control={<Radio />}
                  label="Using Password"
                />
                <FormControlLabel
                  value="otp"
                  control={<Radio />}
                  label="Using OTP"
                />
              </RadioGroup>
            </FormControl>
            <FormControl sx={{ width: "100%", marginBottom: 2 }}>
              <TextField
                variant="outlined"
                id="email_address_input"
                placeholder="Enter your work email"
                autoFocus
                {...register("user_email", {
                  required: {
                    value: true,
                    message: "email field cannot be empty",
                  },
                  pattern: {
                    value:
                      /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/,
                    message: "Please provide a business email address",
                  },
                })}
                error={Boolean(errors.user_email)}
                helperText={errors.user_email?.message}
                autoComplete={"off"}
                onKeyPress={handleSpaceInInput}
              />
            </FormControl>
            {loginMode === "normal" ? (
              <>
                <FormControl sx={{ width: "100%", marginBottom: 0 }}>
                  <TextField
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleChangePasswordType}>
                            {passwordType === "password" ? (
                              <RemoveRedEyeOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter your password"
                    type={passwordType}
                    {...register("user_password", {
                      required: "Please select a password",
                    })}
                    error={Boolean(errors.user_password)}
                    helperText={errors.user_password?.message}
                    autoComplete={"off"}
                    onKeyPress={handleSpaceInInput}
                  />
                </FormControl>
                {loginMode === "normal" ? (
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"end"}
                    marginBottom={2}
                  >
                    <Button
                      variant="text"
                      sx={{
                        textTransform: "capitalize",
                        justifyContent: "flex-end",
                        fontSize: "1rem",
                      }}
                      href="/authenticate/reset-password/"
                    >
                      Forgot Password?
                    </Button>
                  </Stack>
                ) : (
                  <></>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    width: "100%",
                    paddingY: 1,
                    fontSize: "1rem",
                    marginBottom: 1,
                  }}
                  type={"submit"}
                  endIcon={
                    <CircularProgress
                      size={"0.9rem"}
                      color="light"
                      sx={{
                        display: loaders.password_login_btn ? "block" : "none",
                      }}
                    />
                  }
                >
                  Login
                </Button>
              </>
            ) : (
              <></>
            )}

            {loginMode === "otp" ? (
              <>
                {otpSent === true ? (
                  <>
                    <FormControl sx={{ width: "100%", marginBottom: 2 }}>
                      <TextField
                        variant="outlined"
                        placeholder="Enter OTP sent on your Email"
                        type={"tel"}
                        autoComplete={"off"}
                        onKeyPress={handleSpaceInInput}
                        inputProps={{
                          maxLength: 6,
                        }}
                        {...register("otp_entered", {
                          required: "Please enter OTP entered on your Email",
                        })}
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      sx={{
                        fontWeight: "600",
                        width: "100%",
                        paddingY: 1,
                        marginBottom: 1,
                      }}
                      type={"submit"}
                      endIcon={
                        <CircularProgress
                          size={"0.9rem"}
                          color="light"
                          sx={{
                            display: loaders.verify_otp_btn ? "block" : "none",
                          }}
                        />
                      }
                    >
                      verify and login
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        fontWeight: "600",
                        width: "100%",
                        paddingY: 1,
                        marginBottom: 1,
                      }}
                      type={"button"}
                      onClick={sendOtpToUser}
                      endIcon={
                        <CircularProgress
                          size={"0.9rem"}
                          color="light"
                          sx={{
                            display: loaders.send_otp_btn ? "block" : "none",
                          }}
                        />
                      }
                    >
                      Send OTP
                    </Button>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </form>
          <Divider sx={{ marginY: 2 }}>OR</Divider>
          <Stack
            id="google_sign_in"
            alignItems={"center"}
            marginBottom={5}
          ></Stack>

          <Button
            sx={{
              textTransform: "none",
              fontWeight: 600,
            }}
            fullWidth
            variant="outlined"
            size="large"
            href="/authenticate/signup"
          >
            Don't have an Account, Signup Here
          </Button>
          {/* <Divider sx={{ marginY: 2 }}>OR</Divider>
                    <Stack
                        alignItems={"center"}
                    >
                        <ReactLoginMS
                            clientId={ MICROSOFT_CLIENT_ID }
                            redirectUri={ `http://localhost:3001/authenticated` }
                            scopes={["user.read"]}
                            responseType="token"
                            btnContent={
                                <>
                                    <span>
                                        <img 
                                            src={ OfficeIcon }
                                            className={ classes.icon_image }
                                        />
                                    </span>
                                    <span
                                        className={ classes.btn_text }
                                    >
                                        Continue with Office
                                    </span>
                                </>
                            }
                            handleLogin={ (data) => console.log(data) }
                            cssClass={ `${ classes.microsoft_login_btn }` }
                        />
                    </Stack> */}
        </Grid>

        {redirectText && (
          <Grid item xs={12}>
            <Typography
              fontSize={"small"}
              textAlign={"center"}
              color={"error"}
              fontWeight={600}
            >
              {redirectText}
            </Typography>
          </Grid>
        )}
      </Grid>

      <ToastContainer
        style={{
          fontSize: "0.85rem",
        }}
        hideProgressBar={true}
        autoClose={2000}
      />
    </>
  );
}

export default SigninSection;
