import React from "react";
import {
  Bookmark,
  Category,
  DateRange,
  Description,
  Preview,
  Subscriptions,
  Verified,
  YouTube,
} from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import {
  Link,
  Grid,
  ListItemText,
  ListItem,
  Avatar,
  List,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";

function YoutubeCollapes({ profile }) {
  return (
    <Box
      sx={{
        backgroundColor: "#F0F2F5",
        borderRadius: 1,
        padding: 2,
        marginY: 1,
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={12} display={"flex"} justifyContent={"space-between"}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Avatar
              alt="avtar"
              src={
                profile.youtubeData.channel
                  ? profile.youtubeData.channel.info.avatar_url
                  : "/static/images/avatar/1.jpg"
              }
            />
            <Typography variant="subtitle2" marginLeft={1}>
              <span style={{ fontWeight: "bold" }}>
                {profile.youtubeData?.handle ? (
                  <Link
                    href={`https://www.youtube.com/c/${profile.youtubeData.handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textDecoration: "none" }}
                  >
                    {profile.youtubeData.handle}
                  </Link>
                ) : (
                  <Link
                    href={`https://www.youtube.com/channel/${profile.youtubeData.channel_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textDecoration: "none" }}
                  >
                    {profile.youtubeData.channel_id}
                  </Link>
                )}
                &nbsp;
                {profile.is_verified ? (
                  <Verified sx={{ color: "#008DE9", fontSize: "12px" }} />
                ) : (
                  ""
                )}
              </span>
              &nbsp;
              <YouTube sx={{ verticalAlign: "middle" }} color="red" />
            </Typography>
          </Box>
          <Box>
            <Tooltip title="Save">
              <Bookmark />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box bgcolor={"white"} borderRadius={1}>
            <List>
              <ListItem>
                <Tooltip title="Links">
                  <LinkIcon style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={"check"}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Category">
                  <Category style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={
                    profile.youtubeData.category
                      ? profile.youtubeData.category
                      : "NA"
                  }
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box bgcolor={"white"} borderRadius={1}>
            <List>
              <ListItem>
                <Tooltip title="Join Date">
                  <DateRange style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={new Date(
                    parseInt(profile.youtubeData?.statistics?.joinedDate)
                  ).toLocaleString()}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="View Count">
                  <Preview style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.youtubeData?.statistics?.viewCount}
                />
              </ListItem>
              <ListItem>
                <Tooltip title="Subscribers">
                  <Subscriptions style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.youtubeData?.statistics?.subscriberCount}
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Box bgcolor={"white"} borderRadius={1}>
            <List>
              <ListItem>
                <Tooltip title="About">
                  <Description style={{ width: "20px", height: "20px" }} />
                </Tooltip>
                &nbsp;
                <ListItemText
                  secondary={profile.youtubeData?.description}
                />
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default YoutubeCollapes;
