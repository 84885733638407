import * as React from 'react';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { Box, Grid, Tabs, Tab } from '@mui/material';
import PropTypes from 'prop-types';

import UserDetailsCard from './UserDetailsCard';
import RequestedContactsList from './RequestedContact';
// import UserSubscriptionDetails from './UserSubscriptionDetails';
import UserBillingDetails from './UserBillingDetails';
import UserInvoicesList from './UserInvoicesList';
import ChangePasswordComponent from './ChangePassword';
import usersEndpoints from '../../../services/userServices';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role={"tabpanel"}
            hidden={value !== index}
            id={`profile_tabpanel_${index}`}
            {...other}
        >
            {
                value === index &&
                (
                    <Box sx={{ p: 1 }}>
                        {children}
                    </Box>
                )
            }
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function Profile() {
    const [tabIndex, setTabIndex] = useState(0);
    const [userDetail, setuserDetail] = useState(null);

    const [cookies] = useCookies(["user"]);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const fetchUserDetails = async () => {
        if (cookies.user) {
            const response = await usersEndpoints.getUserDetails({});
            if (response['success'] && response['success'] === true) {
                let profileDetails = response['user'];
                let user = {
                    name: profileDetails['fullName'],
                    email: profileDetails['email'],
                    phone: profileDetails['phoneNumber'] ? `+91 ${profileDetails['phoneNumber']}` : '',
                    designation: '',
                    credits: response['credits'],
                    address: response['agency']['address'],
                    zipcode: response['agency']['postalCode'],
                    state: response['agency']['state'],
                    city: response['agency']['city'],
                    country: response['agency']['country'],
                    tax_id: response['agency']['gst'],
                    domainName: response['agency']['name'],
                    companyName: response['agency']['companyName'],
                    websiteLink: response['agency']['website'],
                    agency_id: response['user']['agency'],
                    emailStatus: response['user']['isEmailVerified'] ? response['user']['isEmailVerified'] : false,
                    phoneStatus: response['user']['isVerified'],
                    mailSendStatus: response['user']['isMailSent'] ? response['user']['isMailSent'] : false,
                    image: profileDetails['image'] || ""
                };
                setuserDetail(user);
            }
        }
    }

    return (
        <>
            <Box
                // maxWidth={ 'xl' }
                sx={{ height: '100vh', bgcolor: "#F0F2F5" }}
                width={{ xs: '100%', lg: `calc(100% - 80px)` }}
                marginLeft={{ xs: '0px', lg: `80px` }}
                marginTop={8}
            >
                {/* <Typography 
                    variant={ "h6" }
                >
                    Account Details
                </Typography> */}
                {/* <Divider/> */}
                <Grid
                    container
                >
                    <Grid item xs={12} md={4} marginTop={4} marginX={'auto'}>
                        <UserDetailsCard details={userDetail} />
                    </Grid>
                    <Grid item xs={12} md={7}>

                        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                        <Tabs value={tabIndex} onChange={handleChange}>
                            <Tab label="Requested Contacts" {...a11yProps(0)} />
                            {/* <Tab label="Subscription" { ...a11yProps(1) } /> */}
                            <Tab label="Billing Details" {...a11yProps(1)} />
                            <Tab label="Invoices" {...a11yProps(2)} />
                            <Tab label="Change Password" {...a11yProps(3)} />
                        </Tabs>
                        {/* </Box> */}
                        <TabPanel value={tabIndex} index={0}>
                            <RequestedContactsList />
                        </TabPanel>
                        {/* <TabPanel value={ tabIndex } index={ 1 }>
                                <UserSubscriptionDetails/>
                            </TabPanel> */}
                        <TabPanel value={tabIndex} index={1}>
                            <UserBillingDetails details={userDetail} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <UserInvoicesList />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <ChangePasswordComponent />
                        </TabPanel>

                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Profile;