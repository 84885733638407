import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import usersEndpoints from '../../../services/userServices';
import HeaderSection from '../../common/header/Header';

function InfluencerAuthentication(){
    const location = useLocation();
    const [influencerUsername, setInfluencerUsername] = useState("");

    useEffect(() => {
        const search = location.search;
        let code = new URLSearchParams(search).get('code');
        // if code is present then use this code to get the access token
        GetAccessToken(code);
    }, []);

    const GetAccessToken = async (code) => {
        const response = await usersEndpoints.getInstagramAccessToken({ code: code });
        if(response['success'] === true){
            setInfluencerUsername(response['username']);
        }
    }

    return (
        <Box>
            <HeaderSection showNav={true}/>
            {
                !influencerUsername && 
                <Typography
                    paddingLeft={3}
                    paddingTop={1}
                >
                    Logging Influencer...
                </Typography>
            }
            {
                influencerUsername &&
                <Typography 
                    color={ 'primary' }
                    paddingLeft={3}
                    paddingTop={1}
                >
                    Welcome { influencerUsername }
                </Typography>        
            }
        </Box>
    );
}

export default InfluencerAuthentication;