import * as React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  AppBar,
  Box,
  Typography,
  IconButton,
  Container,
  Menu,
  MenuItem,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Modal,
} from "@mui/material";

import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ContactusForm from "../contactusmodal/Contactus";
import usersEndpoints from "../../../services/userServices";
import Logo from "../../../logo.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*global chrome*/


const pages = [
  {
    name: "Pricing",
    url: "/#pricing_container",
  },
  {
    name: "Testimonials",
    url: "/#testimonials_container",
  },
  {
    name: "About",
    url: "/about_us",
  },
  {
    name: "Contact",
    url: "/policy/contactus",
  },
  // {
  //   name: "Blog",
  //   url: "/#price_container",
  // },
  // {
  //   name: "Influencer",
  //   url: "/influencer",
  // },
  // {
  //   name: "Contact Sales",
  // },
  // {
  //     name: 'Brands',
  //     url: '/brands'
  // }
];

function HeaderSection({
  handleShowContactForm,
  handleCloseContactForm,
  showContactForm,
  showNav,
}) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [cookies, removeCookie] = useCookies(["user"]);
  const [user, setUser] = useState({});
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
  };

  const handleLogout = (event) => {
    removeCookie("auth", "", {
      path: '/',
      expires: new Date(1691479246)
    })

    removeCookie("auth", "", {
      path: '/filter',
      expires: new Date(1691479246)
    });

    removeCookie("user", "", {
      path: '/',
      expires: new Date(1691479246)
    });
  }
  useEffect(() => {
    if (cookies.user && cookies.user !== "undefined" && cookies.user.name) {

      setLoggedIn(true);
      getUser();
    }else{
      handleLogout();
    }
  }, []);


  const getUser = async () => {
    const response = await usersEndpoints.getUserDetails();
    if (response["success"]) {
      setUser(response.user);
    } else if (response["success"] === false && response["logout"] === true) {
      // alert("Your session has expired, Try logging in back again");
      toast.error("Your session has expired, Try logging in back again");
      removeCookie("auth");
      removeCookie("user");
      try {
        chrome.runtime.sendMessage(
          process.env.REACT_APP_EXTENSION_ID,
          {
            message: "logout_user",
          },
          function (responseFromExtension) {
            responseFromExtension = responseFromExtension || {};
          }
        );
      } catch (error) {
        console.log(error);
      }
      window.location.href = "/";
    }
  };

  const handleMenuItemClick = (page) => {
    if (page.url) {
      window.open(page.url, "_blank");
    } else {
      handleShowContactForm();
    }
  };

  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: "#FFFFFF", color: "dark.main" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <a
              href="/"
              style={{
                textDecoration: "none",
              }}
            >
              <img
                src={Logo}
                style={{
                  width: "123px",
                  height: "40px",
                }}
                alt="logo"
              />
            </a>
            {showNav === true ? (
              <>
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      md: "none",
                      marginLeft: "auto",
                      marginY: "auto",
                    },
                  }}
                >
                  <IconButton
                    size="large"
                    onClick={handleOpenNavMenu}
                    color="dark"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ flexGrow: 1, display: { xs: "block", md: "none" } }}
                  >
                    {pages.map((page, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleMenuItemClick(page);
                        }}
                      >
                        <Typography textAlign="center">{page.name}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    marginLeft: "auto",
                  }}
                >
                  {pages.map((page, index) =>
                    page.url ? (
                      <Button
                        key={`nav_item_${index}`}
                        sx={{
                          color: "text.grey",
                          display: "block",
                          paddingY: 0,
                          textTransform: "capitalize",
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                        href={page.url}
                      >
                        {page.name}
                      </Button>
                    ) : (
                      <Button
                        key={`nav_item_${index}`}
                        sx={{
                          color: "text.grey",
                          display: "block",
                          paddingY: 0,
                          textTransform: "capitalize",
                          fontSize: "0.9rem",
                          marginRight: 2,
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          handleShowContactForm();
                        }}
                      >
                        {page.name}
                      </Button>
                    )
                  )}
                </Box>
              </>
            ) : (
              <></>
            )}
            {loggedIn === false ? (
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  href="/authenticate/signin"
                  sx={{ paddingX: 3 }}
                >
                  Login
                </Button>
                {/* <FormControl
                  size="small"
                  sx={{ bgcolor: "light.main", borderRadius: 1 }}
                >
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          href="/authenticate/signup"
                        >
                          Signup
                        </Button>
                      </InputAdornment>
                    }
                    placeholder="Enter your work email"
                  />
                </FormControl> */}
              </Box>
            ) : (
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography
                  component={"p"}
                  fontSize={"0.9rem"}
                  color={"primary"}
                  fontWeight={"600"}
                >
                  Hi, {cookies.user.name}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowRightAltIcon />}
                  href="/filter/influencers"
                >
                  Smart Dashboard
                </Button>
              </Stack>
            )}
          </Toolbar>
          <Modal
            open={showContactForm}
            children={<ContactusForm userdetails={user} />}
            onClose={() => {
              handleCloseContactForm();
            }}
          />
        </Container>
      </AppBar>

      <ToastContainer
        style={{
          fontSize: "0.85rem",
        }}
        hideProgressBar={true}
        autoClose={2000}
      />
    </>
  );
}

export default HeaderSection;
