import {
  Button,
  Card,
  CardContent,
  Container,
  Chip,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CardActions,
  CardMedia,
  Divider,
  Modal,
} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import * as React from "react";

// import PricingTableDisplay from "../../common/pricing/Pricing";
import useRazorpay from "react-razorpay";
import paymentservice from "../../../services/paymentservice";
import usersEndpoints from "../../../services/userServices";
import { useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
// import { useNavigate } from 'react-router-dom';
import LogoImage from "../images/icon-128.png";
import ContactusForm from "../../common/contactusmodal/Contactus";
import UserBillingDetails from "../../userprofile/profile/UserBillingDetails";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Eye from "../../../Eye.png";
import IntersectFirst from "../../../Intersect-1.png";
import IntersectSecond from "../../../Intersect-2.png";
import IntersectThird from "../../../Intersect-3.png";

/*global chrome*/
const freepricing = [
  "100 Credits",
  "Email addresses",
  "Phone numbers",
  "Export contacts",
];

const starterpricing = [
  "1000 Credits",
  "Email addresses",
  "Phone numbers",
  "Export contacts",
  // 'Prospecting'
];

const growthpricing = [
  "10000 Credits",
  "Email addresses",
  "Phone numbers",
  "Export contacts",
  "Enterprise support",
  // 'Prospecting',
  // 'Zoho integration',
  // 'Bulk show 25 contacts'
];

// const premiumpricing = [
// 	"1200 contacts/month",
// 	"Email addresses",
// 	"Phone numbers",
// 	"Export contacts",
// 	"Prospecting",
// 	"Team Collaboration",
// 	"Basic Analytics",
// 	"Enterprise Support",
// 	"Salesforce Integration",
// 	"Bulk show 100 contacts",
// ];

function LandingPricingSection() {
  const Razorpay = useRazorpay();
  const [user, setUser] = useState({});
  const [agencyDetails, setAgencyDetails] = useState({});
  const [showContactForm, setShowContactForm] = useState(false);
  const [showBillingDetailsModal, setShowBillingDetailsModal] = useState(false);
  const [cookies, removeCookie] = useCookies(["user", "auth"]);
  const [paymentDetails, setPaymentDetails] = useState({});
  // const navigate = useNavigate();

  useEffect(() => {
    Init();
  }, []);

  useEffect(() => {
    if (
      paymentDetails["payment"] &&
      paymentDetails["plan"] &&
      paymentDetails["amount"]
    ) {
      const billingDetailsUpdated = checkIfBillingDetailsProvided();
      if (billingDetailsUpdated) {
        handlePayment(
          paymentDetails["payment"],
          paymentDetails["plan"],
          parseInt(paymentDetails["amount"])
        );
      }
    }
  }, [agencyDetails]);

  const openContactForm = () => {
    setShowContactForm(true);
  };

  const closeContactForm = () => {
    setShowContactForm(false);
  };

  const Init = async () => {
    if (cookies.user && cookies.user !== "undefined") {
      const user = await usersEndpoints.getUserDetails();
      if (user.success) {
        setUser(user.user);
        setAgencyDetails({
          ...user.agency,
          ...{
            inModal: true,
            zipcode: user.agency.postalCode,
            tax_id: user.agency.gst,
            agency_id: user.user.agency,
          },
        });
      } else if (user["success"] === false && user["logout"] === true) {
        // alert("Your session has expired, Try logging in back again");
        toast.error("Your session has expired, Try logging in back again");
        removeCookie("auth");
        removeCookie("user");
        try {
          chrome.runtime.sendMessage(
            process.env.REACT_APP_EXTENSION_ID,
            {
              message: "logout_user",
            },
            function (responseFromExtension) {
              responseFromExtension = responseFromExtension || {};
            }
          );
        } catch (error) {
          console.log(error);
        }
        window.location.href = "/";
      }
    }
  };

  const handlePayment = async (paymentId, plan, money) => {
    const isBillingDetailsProvided = checkIfBillingDetailsProvided();
    if (!isBillingDetailsProvided) {
      setShowBillingDetailsModal(true);
      setPaymentDetails({
        payment: paymentId,
        plan: plan,
        amount: money,
      });
      return;
    }

    if (paymentId === "" || plan === "" || isNaN(parseInt(money))) {
      // alert('Invalid Payment Parameters');
      toast.error("Invalid Payment Parameters");
      return;
    }

    let credits_provided = 0;
    switch (plan.toLowerCase()) {
      case "starter":
        credits_provided = 100;
        break;
      case "basic":
        credits_provided = 1000;
        break;
      default:
        break;
    }
    // const user_confirmation = window.confirm(
    //   `You will get ${credits_provided} credits for Rs (${money} + 18% GST)\nDo you wish to proceed?`
    // );
    // if (!user_confirmation) {
    //   return;
    // }

    const params = {
      amount: money,
      plan: plan.toLowerCase(),
    };

    const order = await paymentservice.createRazorPayOrder(params); //  Create order on your backend
    if (order.success === true) {
      const options = {
        key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: money, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Meesha",
        description: plan,
        image: LogoImage,
        order_id: order.orderId, //order, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: async function (response) {
          let postdata = {
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
          };
          const resp = await paymentservice.verifyOrder(postdata);
          if (resp["success"] === true) {
            // alert(resp['message'] + "\n" + `${ resp['credits'] } credits have been added to your account`);
            toast.success(
              resp["message"] +
              "\n" +
              `${resp["credits"]} credits have been added to your account`
            );
            window.location.assign("/profile");
          } else {
            // alert(resp['message']);
            toast.error(resp["message"]);
          }
        },
        prefill: {
          name: user.fullName,
          email: user.email,
          contact: user.phoneNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF5200",
        },
        modal: {
          ondismiss: function () {
            setPaymentDetails({});
          },
        },
      };

      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", async function (response) {
        const save_error = await paymentservice.cancelOrder({
          paymentId: response.error.metadata.payment_id,
        });
        if (save_error["success"]) {
          // alert(response.error.description);
          toast.error(response.error.description);
        }
      });

      rzp1.open();
    } else {
      // alert(order.message);
      toast.error(order.message);
    }
  };

  const checkIfBillingDetailsProvided = () => {
    if (
      agencyDetails["address"] === undefined ||
      agencyDetails["address"] === "" ||
      agencyDetails["state"] === undefined ||
      agencyDetails["state"] === "" ||
      agencyDetails["city"] === undefined ||
      agencyDetails["city"] === "" ||
      agencyDetails["country"] === undefined ||
      agencyDetails["country"] === "" ||
      agencyDetails["companyName"] === undefined ||
      agencyDetails["companyName"] === "" ||
      agencyDetails["zipcode"] === undefined ||
      agencyDetails["zipcode"] === ""
    ) {
      return false;
    }
    return true;
  };

  const handleBillingDetailsModalClose = async () => { };

  const updateBillingModalFlag = async (updatedValue) => {
    setShowBillingDetailsModal(updatedValue);
    const response = await usersEndpoints.getUserDetails();
    if (response["success"] === true) {
      let new_details = agencyDetails;
      new_details = {
        ...new_details,
        ...{
          companyName: response.agency.companyName,
          address: response.agency.address,
          city: response.agency.city,
          state: response.agency.state,
          country: response.agency.country,
          zipcode: response.agency.postalCode,
          tax_id: response.agency.gst,
        },
      };
      setAgencyDetails(new_details);
    } else {
      setPaymentDetails({});
      // alert('Could not fetch updated details, try reloading the page');
      toast.error("Could not fetch updated details, try reloading the page");
    }
  };

  return (
    <Container maxWidth={"xl"} sx={{ marginTop: 15, marginBottom: 25 }} id="pricing_container">
      <Box sx={{ width: { lg: "80%" }, marginX: "auto", textAlign: "center" }}>
        <Typography
          variant="h4"
          component={"h1"}
          sx={{
            fontWeight: "900",
            fontSize: "50px",
          }}
        >
          Choose a Pricing Plan
        </Typography>
        <Typography
          variant="h3"
          component={"h1"}
          sx={{
            fontWeight: "900",
            fontSize: "60px",
            color: "#FF5100",
          }}
        >
          That Suits Your Needs
        </Typography>
        <Typography
          variant="p"
          component={"p"}
          sx={{
            fontWeight: "400",
            fontSize: "30px",
          }}
        >
          Flexible options to help you connect with the right influencers at the
          right price.
        </Typography>
      </Box>
      <Box
        component={"div"}
        maxWidth={"xl"}
        sx={{
          marginY: 10,
          width: "1407px",
          height: "549.266px",
          backgroundImage: `url(${Eye})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={7} paddingX={15} alignItems={"stretch"}>
          <Grid item xl={4}>
            <Card
              sx={{
                maxWidth: 345,
                borderRadius: "20px",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "377px",
                  height: "273.929px",
                  backgroundImage: `url(${IntersectFirst})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Typography
                  variant="h5"
                  component={"h6"}
                  sx={{
                    paddingX: "35%",
                    paddingY: "30%",
                    fontSize: "30px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  Free
                </Typography>
              </Box>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    // textAlign: "center",
                    // paddingTop: 4,`
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: 1,
                    // textTransform: "uppercase",
                  }}
                >
                  <DoneIcon />&nbsp;5 Credits<br />
                  {/* <DoneIcon />&nbsp;INR 0<br /> */}
                  <DoneIcon />&nbsp;Cost per credit: Free<br />
                  <CloseIcon color="error" />&nbsp;List Management<br />
                  <CloseIcon color="error" />&nbsp;List Export<br />
                  <DoneIcon />&nbsp;Access to Chrome Extension<br />
                  <CloseIcon color="error" />&nbsp;Access to Smart Dashboard
                </Typography>
                <Typography
                  variant="caption"
                  display={"block"}
                  color="text.secondary"
                  sx={{
                    lineHeight: "1",
                    textAlign: "center",
                    marginTop: 3,
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Ideal for businesses with occasional influencer marketing
                  needs or who prefer to pay as they go.
                </Typography>
              </CardContent>
              <CardActions
                variant="h6"
                component={"h6"}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {cookies.user && cookies.user !== "undefined" ? (
                  <Button
                    size="small"
                    sx={{
                      border: "1px solid #000",
                      paddingY: "5px",
                      paddingX: "50px",
                      fontSize: "20px",
                      fontWeight: "400",
                      borderRadius: "100px",
                      marginBottom: 2,
                    }}
                    disabled={true}
                  >
                    Activated
                  </Button>
                ) : (
                  <Button
                    sx={{
                      border: "1px solid #000",
                      paddingY: "5px",
                      paddingX: "50px",
                      fontSize: "20px",
                      fontWeight: "400",
                      borderRadius: "100px",
                      marginBottom: 2,
                    }}
                    size="small"
                    href="/authenticate/signup"
                  >
                    Get Started
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card
              sx={{
                maxWidth: 345,
                borderRadius: "20px",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "377px",
                  height: "273.929px",
                  backgroundImage: `url(${IntersectSecond})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Typography
                  variant="h5"
                  component={"h6"}
                  sx={{
                    paddingX: "37%",
                    paddingY: "30%",
                    fontSize: "30px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  Pro
                </Typography>
              </Box>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    // textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: 1,
                    // textTransform: "uppercase",
                  }}
                >
                  <DoneIcon />&nbsp;100 Credits<br />
                  <DoneIcon />&nbsp;INR 10,000<br />
                  <DoneIcon />&nbsp;Cost per credit: 100 INR<br />
                  <DoneIcon />&nbsp;List Management<br />
                  <DoneIcon />&nbsp;List Export<br />
                  <DoneIcon />&nbsp;Access to Chrome Extension<br />
                  <DoneIcon />&nbsp;Access to Smart Dashboard <br />
                </Typography>
                <Typography
                  variant="caption"
                  display={"block"}
                  color="text.secondary"
                  sx={{
                    lineHeight: "1",
                    textAlign: "center",
                    marginTop: 3,
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  A great starter plan for businesses looking to connect with a
                  moderate number of influencers.
                </Typography>
              </CardContent>
              <CardActions
                variant="h6"
                component={"h6"}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {cookies.user && cookies.user !== "undefined" ? (
                  <Button
                    size="small"
                    sx={{
                      border: "1px solid #000",
                      paddingY: "5px",
                      paddingX: "50px",
                      fontSize: "20px",
                      fontWeight: "400",
                      borderRadius: "100px",
                      marginBottom: 2,
                    }}
                    onClick={() => handlePayment("basic", "Basic", 10000)}
                  >
                    Select Plan
                  </Button>
                ) : (
                  <Button
                    size="small"
                    sx={{
                      border: "1px solid #000",
                      paddingY: "5px",
                      paddingX: "50px",
                      fontSize: "20px",
                      fontWeight: "400",
                      borderRadius: "100px",
                      marginBottom: 2,
                    }}
                    href="/authenticate/signup"
                  >
                    sign up
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xl={4}>
            <Card
              sx={{
                maxWidth: 345,
                borderRadius: "20px",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "377px",
                  height: "273.929px",
                  backgroundImage: `url(${IntersectThird})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Typography
                  variant="h5"
                  component={"h6"}
                  sx={{
                    paddingX: "33%",
                    paddingY: "30%",
                    fontSize: "30px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                  }}
                >
                  Scale
                </Typography>
              </Box>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    // textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: 1,
                    // textTransform: "uppercase",
                  }}
                >
                  {/* <DoneIcon />&nbsp;400 Credits<br />
                  <DoneIcon />&nbsp;INR 32,000<br />
                  <DoneIcon />&nbsp;Cost per credit: 80 INR <br /> */}
                  <DoneIcon />&nbsp;List Management<br />
                  <DoneIcon />&nbsp;List Export<br />
                  <DoneIcon />&nbsp;Access to Chrome Extension<br />
                  <DoneIcon />&nbsp;Access to Smart Dashboard <br />
                </Typography>
                <Typography
                  variant="caption"
                  display={"block"}
                  color="text.secondary"
                  sx={{
                    lineHeight: "1",
                    textAlign: "center",
                    marginTop: 3,
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Perfect for businesses aiming to scale their influencer
                  marketing campaigns and reach a wider audience.
                </Typography>
              </CardContent>
              <CardActions
                variant="h6"
                component={"h6"}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  size="small"
                  sx={{
                    border: "1px solid #000",
                    paddingY: "5px",
                    paddingX: "50px",
                    fontSize: "20px",
                    fontWeight: "400",
                    borderRadius: "100px",
                    marginBottom: 2,
                  }}
                  onClick={openContactForm}
                >
                  contact us
                </Button>
                {/* {cookies.user && cookies.user !== "undefined" ? (
                ) : (
                  <Button
                    size="small"
                    sx={{
                      border: "1px solid #000",
                      paddingY: "5px",
                      paddingX: "50px",
                      fontSize: "20px",
                      fontWeight: "400",
                      borderRadius: "100px",
                      marginBottom: 2,
                    }}
                    href="/authenticate/signup"
                  >
                    sign up
                  </Button>
                )} */}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={showContactForm}
        onClose={closeContactForm}
        children={<ContactusForm userdetails={user} />}
      />
      <Modal
        open={showBillingDetailsModal}
        onClose={handleBillingDetailsModalClose}
        children={
          <UserBillingDetails
            updateModal={updateBillingModalFlag}
            details={agencyDetails}
          />
        }
        onBackdropClick={() => updateBillingModalFlag(false)}
      />
    </Container >
    // <React.Fragment>
    //   <div style={{ background: "rgb(237, 237, 237)" }} id="price_container">
    //     <Container maxWidth="lg" sx={{ paddingY: 3 }}>
    //       <Typography
    //         variant="h4"
    //         component={"h4"}
    //         sx={{ textAlign: "center", fontWeight: 600, opacity: 0.85 }}
    //       >
    //         Pricing
    //       </Typography>

    //       <Grid
    //         container
    //         spacing={2}
    //         justifyContent={{ xs: "center" }}
    //         sx={{
    //           marginY: 2,
    //         }}
    //       >
    //         <Grid item xs={9} md={6} lg={3}>
    //           <Card
    //             variant={"outlined"}
    //             sx={{
    //               height: "100%",
    //               display: "flex",
    //               flexDirection: "column",
    //             }}
    //           >
    //             <CardContent>
    //               <Chip
    //                 component={"div"}
    //                 label="Starter"
    //                 color={"badgeprimary"}
    //                 sx={{ fontWeight: 600, borderRadius: 2, fontSize: 14 }}
    //               ></Chip>
    //               <Typography
    //                 variant="h3"
    //                 component={"div"}
    //                 sx={{ textAlign: "center" }}
    //               >
    //                 <span>&#8377;</span>
    //                 <span>300</span>
    //                 <div style={{ fontSize: 15, margin: "0.25rem 0" }}>
    //                   /credit
    //                 </div>
    //               </Typography>
    //               <List>
    //                 {freepricing.map((priceItem, index) => (
    //                   <ListItem key={"freeplan_" + index} disablePadding>
    //                     <ListItemIcon
    //                       sx={{ color: "primary.main", minWidth: 32 }}
    //                     >
    //                       <DoneAllIcon />
    //                     </ListItemIcon>
    //                     <ListItemText>
    //                       <Typography sx={{ fontSize: "0.875rem" }}>
    //                         {priceItem}
    //                       </Typography>
    //                     </ListItemText>
    //                   </ListItem>
    //                 ))}
    //                 <Divider />
    //               </List>
    //               <Typography
    //                 color={"primary"}
    //                 sx={{ textAlign: "center", marginTop: 2 }}
    //               >
    //                 Total: Rs 30,000 + (18% GST)
    //               </Typography>
    //             </CardContent>
    //             <CardActions sx={{ marginTop: "auto" }}>
    //               {cookies.user && cookies.user !== "undefined" ? (
    //                 <Button
    //                   variant="contained"
    //                   size="small"
    //                   sx={{ display: "flex", marginX: "auto" }}
    //                   onClick={() => handlePayment("starter", "Starter", 30000)}
    //                 >
    //                   purchase
    //                 </Button>
    //               ) : (
    //                 <Button
    //                   variant="contained"
    //                   size="small"
    //                   sx={{ display: "flex", marginX: "auto" }}
    //                   href="/authenticate/signup"
    //                 >
    //                   sign up
    //                 </Button>
    //               )}
    //             </CardActions>
    //           </Card>
    //         </Grid>
    //         <Grid item xs={9} md={6} lg={3}>
    //           <Card
    //             variant={"outlined"}
    //             sx={{
    //               height: "100%",
    //               display: "flex",
    //               flexDirection: "column",
    //             }}
    //           >
    //             <CardContent>
    //               <Chip
    //                 component={"div"}
    //                 label="Basic"
    //                 color={"badgeprimary"}
    //                 sx={{ fontWeight: 600, borderRadius: 2, fontSize: 14 }}
    //               ></Chip>
    //               <Typography
    //                 variant="h3"
    //                 component={"div"}
    //                 sx={{ textAlign: "center" }}
    //               >
    //                 <span>&#8377;</span>
    //                 <span>200</span>
    //                 <div style={{ fontSize: 15, margin: "0.25rem 0" }}>
    //                   /credit
    //                 </div>
    //               </Typography>
    //               <List>
    //                 {starterpricing.map((priceItem, index) => (
    //                   <ListItem key={`starterplan_${index}`} disablePadding>
    //                     <ListItemIcon
    //                       sx={{ color: "primary.main", minWidth: 32 }}
    //                     >
    //                       <DoneAllIcon />
    //                     </ListItemIcon>
    //                     <ListItemText>
    //                       <Typography sx={{ fontSize: "0.875rem" }}>
    //                         {priceItem}
    //                       </Typography>
    //                     </ListItemText>
    //                   </ListItem>
    //                 ))}
    //                 <Divider />
    //               </List>
    //               <Typography
    //                 color={"primary"}
    //                 sx={{ textAlign: "center", marginTop: 2 }}
    //               >
    //                 Total: Rs 2,00,000 + (18% GST)
    //               </Typography>
    //             </CardContent>
    //             <CardActions sx={{ marginTop: "auto" }}>
    //               {cookies.user && cookies.user !== "undefined" ? (
    //                 <Button
    //                   variant="contained"
    //                   size="small"
    //                   sx={{ display: "flex", marginX: "auto" }}
    //                   onClick={() => handlePayment("basic", "Basic", 200000)}
    //                 >
    //                   purchase
    //                 </Button>
    //               ) : (
    //                 <Button
    //                   variant="contained"
    //                   size="small"
    //                   sx={{ display: "flex", marginX: "auto" }}
    //                   href="/authenticate/signup"
    //                 >
    //                   sign up
    //                 </Button>
    //               )}
    //             </CardActions>
    //           </Card>
    //         </Grid>
    //         <Grid item xs={9} md={6} lg={3}>
    //           <Card
    //             variant={"outlined"}
    //             sx={{
    //               height: "100%",
    //               display: "flex",
    //               flexDirection: "column",
    //             }}
    //           >
    //             <CardContent>
    //               <Chip
    //                 component={"div"}
    //                 label="Plus"
    //                 color={"badgeprimary"}
    //                 sx={{ fontWeight: 600, borderRadius: 2, fontSize: 14 }}
    //               ></Chip>
    //               <Typography
    //                 variant="h3"
    //                 component={"div"}
    //                 sx={{ textAlign: "center" }}
    //               >
    //                 <span>&#8377;</span>
    //                 <span>150</span>
    //                 <div style={{ fontSize: 15, margin: "0.25rem 0" }}>
    //                   /credit
    //                 </div>
    //               </Typography>
    //               <List>
    //                 {growthpricing.map((priceItem, index) => (
    //                   <ListItem key={`growthplan_${index}`} disablePadding>
    //                     <ListItemIcon
    //                       sx={{ color: "primary.main", minWidth: 32 }}
    //                     >
    //                       <DoneAllIcon />
    //                     </ListItemIcon>
    //                     <ListItemText>
    //                       <Typography sx={{ fontSize: "0.875rem" }}>
    //                         {priceItem}
    //                       </Typography>
    //                     </ListItemText>
    //                   </ListItem>
    //                 ))}
    //                 <Divider />
    //               </List>
    //               {/* <Typography
    // 									color={ "primary" }
    // 									sx={{ textAlign: "center" }}
    // 								>
    // 									Total: Rs 10,00,000
    // 								</Typography> */}
    //             </CardContent>
    //             <CardActions sx={{ marginTop: "auto" }}>
    //               {cookies.user && cookies.user !== "undefined" ? (
    //                 <Button
    //                   variant="contained"
    //                   size="small"
    //                   sx={{ display: "flex", marginX: "auto" }}
    //                   onClick={openContactForm}
    //                 >
    //                   contact us
    //                 </Button>
    //               ) : (
    //                 <Button
    //                   variant="contained"
    //                   size="small"
    //                   sx={{ display: "flex", marginX: "auto" }}
    //                   href="/authenticate/signup"
    //                 >
    //                   sign up
    //                 </Button>
    //               )}
    //             </CardActions>
    //           </Card>
    //         </Grid>
    //       </Grid>
    //     </Container>
    //   </div>
    // </React.Fragment>
  );
}

export default LandingPricingSection;
