import React from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function StatusTable() {
  return (
    <>
      <Card sx={{ boxShadow: 0 }}>
        <TableContainer>
          <Table aria-label="simple table" size="lg">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Pay</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Campaign</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Platform</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Inf / Ven</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Comment</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>POC</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>GST</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>%</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Payable</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Remarks</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>2</TableCell>
                <TableCell>3</TableCell>
                <TableCell>4</TableCell>
                <TableCell>5</TableCell>
                <TableCell>6</TableCell>
                <TableCell>1</TableCell>
                <TableCell>2</TableCell>
                <TableCell>3</TableCell>
                <TableCell>4</TableCell>
                <TableCell>5</TableCell>
                <TableCell>6</TableCell>
                <TableCell>6</TableCell>
                <TableCell>6</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
