import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Demovideo from "../images/hero-video-comp.mp4";
import Chromeicon from "@mui/icons-material/LaptopChromebook";
import StartIcon from "@mui/icons-material/Start";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import Image from "../../../../src/Image.png";
import { useCookies } from "react-cookie";
import "./css/Hero.css";

function LandingHeroSection({ handleShowContactForm }) {
  const [cookies, removeCookie] = useCookies(["user", "auth"]);

  return (
    <Container maxWidth={"xl"} sx={{ marginTop: 20 }}>
      <Grid container>
        <Grid item lg={8}>
          <Box sx={{ paddingX: 6 }}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontWeight: 900,
                fontSize: "60px",
                marginBottom: 2,
              }}
            >
              Connect with the Right
              <br />
              Influencers, Effortlessly
            </Typography>
            <Typography
              variant="h6"
              component={"p"}
              sx={{ marginBottom: 2, fontSize: "28px" }}
            >
              <span style={{ fontWeight: "700" }}>Discover</span>,{" "}
              <span style={{ fontWeight: "700" }}>Engage</span>, and&nbsp;
              <span style={{ fontWeight: "700" }}>
                Collaborate with Top Influencers using <br />
                Meesha
              </span>{" "}
              - your ultimate influencer search and contact solution.
            </Typography>
            <Button
              variant="contained"
              // size="large"
              startIcon={<ContactPageIcon />}
              onClick={handleShowContactForm}
              sx={{
                fontWeight: "400",
                fontSize: "22px",
                textTransform: "capitalize",
              }}
            >
              Contact for Sales
            </Button>
            &nbsp; &nbsp;
            <Button
              variant="outlined"
              // size="large"
              endIcon={<StartIcon />}
              onClick={() =>
                (window.location.href = `${
                  cookies.user && cookies.user !== "undefined"
                    ? "/referrals"
                    : "/authenticate/signup"
                }`)
              }
              sx={{
                fontWeight: "400",
                fontSize: "22px",
                textTransform: "capitalize",
              }}
            >
              Get Free 5 Credits
            </Button>
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box
            sx={{
              width: "464.437px",
              height: "285.682px",
              backgroundColor: "#edd4c9",
              borderRadius: "175px",
              transform: "rotate(-105deg)",
              position: "relative",
              zIndex: -1,
            }}
          ></Box>
          <Box
            id="banner"
            sx={{
              width: "260.733px",
              height: "351.715px",
              position: "relative",
              top: "-52%",
              left: "25%",
              zIndex: 1,
            }}
          ></Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default LandingHeroSection;
