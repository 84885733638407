import * as React from 'react';
import { Box, Button, FormControl, TextField, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import ShowOtpField from './ShowOtpField';
import ResetPasswordInput from './ResetPasswordInput';
import usersEndpoints from '../../../../services/userServices';

function SendOtpEmail(){
    const { register, handleSubmit, watch, formState: { errors }, setError } = useForm();
    const [otpSend, setOtpSend] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            
        });

        return () => { subscription.unsubscribe() }
    }, [watch]);

    const onSubmit = (data) => {
        setLoading(true);
        
        fetch(
            `https://disposable.debounce.io/?email=${ data.user_email }`
        )
        .then((response) => response.json())
        .then((validationResponse) => {
            if(validationResponse['disposable'] == 'false'){
                sendResetPasswordLink(data.user_email);
            }
            else{
                setLoading(false);
                setError(
                    "user_email",
                    {
                        type: 'custom',
                        message: 'Please provide a business email address'
                    },
                    {
                        shouldFocus: true
                    }
                );
            }
        })
        .catch((error) => {
            setLoading(false);
            setOtpSend(false);
        });
    };

    const showInvalidValues = (data) => {};

    const sendResetPasswordLink = async (email) => {
        try{
            let postdata = {
                'reset_email': email
            };
            const response = await usersEndpoints.sendResetPasswordLink(postdata);
            setLoading(false);
            if(response['success'] == true){
                setOtpSend(true);
                setEmail(email);
            }
            else{
                setError(
                    "user_email", 
                    {
                        type: 'custom',
                        message: response['message']
                    },
                    {
                        shouldFocus: true
                    }
                );
                setOtpSend(false);
            }
        }
        catch(error){
            setError(
                "user_email",
                {
                    type: 'custom',
                    message: 'An error occurred while sending the email, Please try again after some time'
                }
            );
            setLoading(false);
            setOtpSend(false);
        }
    };

    const handleOnVerifiedOTP = (updatedValue) => {
        setOtpVerified(updatedValue);
    }

    return(
        <>
            <form 
                onSubmit={ handleSubmit(onSubmit, showInvalidValues) }
            >
                <FormControl
                    sx={{ 
                        width: '100%',
                        marginTop: 2.5,
                        marginBottom: 2
                    }}
                >
                    <TextField
                        variant='outlined'
                        placeholder='Enter your registered e-mail address'
                        autoFocus
                        {
                            ...register("user_email", {
                                required: "Email Address cannot be blank",
                                pattern: {
                                    value: /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/,
                                    message: "Please provide a business email address"
                                }
                            })
                        }
                        error={ Boolean(errors.user_email) }
                        helperText={ errors.user_email?.message }
                        disabled={ otpSend }
                    />
                </FormControl>

                <Button 
                    type="submit"
                    variant="contained"
                    sx={{ 
                        paddingX: 4, 
                        textTransform: "capitalize",
                        fontSize: '1rem',
                        borderRadius: '10px',
                        display: otpSend ? 'none' : 'inline-block'
                    }}
                >
                    send link
                    {
                        loading && <CircularProgress size={ '0.9rem' } color={ 'light' } sx={{ marginLeft: 1 }}/>
                    }
                </Button>
            </form>
            <Box>
                { otpSend && !otpVerified && <ShowOtpField useremail={email} onChange={handleOnVerifiedOTP}/>}
            </Box>
            <Box>
                { otpVerified && <ResetPasswordInput useremail={email}/> }
            </Box>
        </>
    );
}

export default SendOtpEmail;