import React, { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowDownward, CheckCircle, Delete, Error, FileUpload, Visibility } from "@mui/icons-material";
import IdToDate from "../common/date/IdToDate";
import paymentdashboardservice from "../../services/paymentdashboardservice";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function FinanceVendorDetailsTable({
  fontSize,
  fontStyle,
  paymentVendorData,
  handleTDS,
  renderFinancialPayment,
  handleFinancialPayment,
  handleRadioButton,
  handleSubmit,
  handleDelete,
  handleGstPay,
  handleFail,
  handleUpload,
  uploadGstScreenshot,
}) {

  const [paymentVendorDetails, setPaymentVendorDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleVendorView = async (id) => {
    const response = await paymentdashboardservice.getPaymentDetailsById({ id: id });
    setPaymentVendorDetails(response);
  }

  const toggleDrawer = (anchor, open, id) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    handleVendorView(id);
    setState({ ...state, [anchor]: open });
  };

  const anchor = 'right';

  const list = (anchor) => (
    <>
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400, padding: 2 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Typography variant="h6" fontWeight={'bold'}>
          Details
        </Typography>
        <Divider />
        <TableContainer sx={{ marginTop: 2 }}>
          <Table aria-label="simple table" size="medium">
            <TableBody>
              {paymentVendorDetails && paymentVendorDetails.map((payment) => (
                <>
                  <TableRow key={payment._id}>
                    <TableCell sx={{ fontStyle }}>Vendor</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.vendor ? payment.vendor.name : "---"}</TableCell>
                  </TableRow>
                  <TableRow key={payment._id}>
                    <TableCell sx={{ fontStyle }}>Influencer</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.influencer ? payment.influencer.username : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Platform</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.platform.length > 0 ? payment.platform.map((p) => p.name + " ") : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Campaign</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.campaign && payment.campaign.name ? payment.campaign.name : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Deliverables</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.deliverables ? payment.deliverables : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Comment</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.comment ? payment.comment : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>POC</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.createdby ? payment.createdby.fullName : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Approved By</TableCell>
                    {payment.approvedby ? (
                      <TableCell sx={{ fontSize }}>
                        {payment.approvedby.split(",")[0]
                          ? payment.approvedby.split(",")[0]
                          : "---"}
                        &nbsp;
                        {payment.approvedby.split(",")[1] ? (
                          <Typography variant="h6" sx={{ display: 'inline' }}>
                            <Chip size="small" label={`${new Date(
                              parseInt(payment.approvedby.split(",")[1])
                            ).toLocaleDateString(undefined, {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                              }`} />
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell sx={{ fontSize }}>---</TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Total Amount</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.cost ? payment.cost : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>GST Amount</TableCell>
                    <TableCell sx={{ fontSize }}>{payment.gstAmount ? payment.gstAmount : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Payable Amount</TableCell>
                    <TableCell sx={{ fontSize }}>
                      {payment.payableAmount ? payment.payableAmount : "---"}
                      {" "}
                      {payment.isClub && <Tooltip title="View Club Links">
                        <Visibility fontSize="small" onClick={handleOpen} sx={{ verticalAlign: 'middle', cursor: 'pointer' }} />
                      </Tooltip>}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>TDS</TableCell>
                    <TableCell sx={{ fontSize }}>
                      {handleTDS(
                        parseInt(payment.payableAmount),
                        payment.pancard,
                        renderFinancialPayment(payment.vendor._id)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Financial</TableCell>
                    <TableCell sx={{ fontSize }}>
                      {handleFinancialPayment(payment.influencer?._id)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Account Name</TableCell>
                    <TableCell>{payment.accountName ? payment.accountName : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Account Number</TableCell>
                    <TableCell>{payment.accountNo ? payment.accountNo : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>IFSC Code</TableCell>
                    <TableCell>{payment.ifscCode ? payment.ifscCode : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Pancard</TableCell>
                    <TableCell>{payment.pancard ? payment.pancard : "---"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontStyle }}>Paytm</TableCell>
                    <TableCell>{payment.paytmNumber ? payment.paytmNumber : "---"}</TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </>
  );

  return (
    <>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
      <Card>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontStyle }}>#</TableCell>
                <TableCell sx={{ fontStyle }}>Date</TableCell>
                <TableCell sx={{ fontStyle }}>Vendor</TableCell>
                <TableCell sx={{ fontStyle }}>Campaign</TableCell>
                <TableCell sx={{ fontStyle }}>Total</TableCell>
                {/* <TableCell sx={{ fontStyle }}>TDS</TableCell> */}
                <TableCell sx={{ fontStyle }}>GST</TableCell>
                <TableCell sx={{ fontStyle }}>Percentage</TableCell>
                <TableCell sx={{ fontStyle }}>Payable</TableCell>
                {/* <TableCell sx={{ fontStyle }}>Financial</TableCell> */}
                <TableCell sx={{ fontStyle }}>POC</TableCell>
                <TableCell sx={{ fontStyle }}>Invoice</TableCell>
                <TableCell sx={{ fontStyle }}>GST</TableCell>
                <TableCell sx={{ fontStyle }}>Status</TableCell>
                <TableCell sx={{ fontStyle }}>Type</TableCell>
                <TableCell sx={{ fontStyle }}>Action</TableCell>
                {/* <TableCell sx={{ fontStyle }}></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentVendorData && paymentVendorData.map((vendor, index) => (
                <TableRow key={vendor._id} id={`${index}-paymentVendorData`}>
                  <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{index + 1}</TableCell>
                  <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}><IdToDate id={vendor._id} /></TableCell>
                  {vendor.vendor ? (
                    <TableCell
                      title={vendor.vendor.name}
                      onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}
                    >
                      {vendor.vendor.name.substring(0, 5) + "..."}
                    </TableCell>
                  ) : (
                    <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>---</TableCell>
                  )}
                  {/* <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                    {vendor.campaign ? vendor.campaign.name : "---"}
                  </TableCell> */}
                  {
                    vendor.campaign ?
                      <TableCell title={vendor.campaign.name} onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                        {vendor.campaign.name.substring(0, 5) + '...'}
                      </TableCell>
                      :
                      <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                        ---
                      </TableCell>
                  }
                  <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                    {vendor.cost ? vendor.cost : "---"}
                  </TableCell>
                  {/* <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                  {handleTDS(
                    parseInt(vendor.payableAmount),
                    vendor.pancard,
                    renderFinancialPayment(vendor.vendor._id)
                  )}
                </TableCell> */}
                  <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{vendor.gstAmount ? vendor.gstAmount : "---"}</TableCell>
                  <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{vendor.percentage ? vendor.percentage : "---"}</TableCell>
                  <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>{vendor.payableAmount ? vendor.payableAmount : "---"}</TableCell>
                  {/* <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                  {handleFinancialPayment(vendor.influencer?._id)}
                </TableCell> */}
                  {vendor.createdby && vendor.createdby.fullName ?
                    <TableCell title={vendor.createdby.fullName} onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>
                      {vendor.createdby.fullName.substring(0, 6) + '...'}
                    </TableCell>
                    : <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>---</TableCell>
                  }
                  <TableCell sx={{ fontSize }}>
                    {vendor.invoice ? (
                      <Link
                        href={process.env.REACT_APP_BASE_URL + "/" + vendor.invoice}
                        className="text-primary"
                        target="_blank"
                        title="Download Invoice"
                        download
                      >
                        <ArrowDownward fontSize="small" color="success" />
                      </Link>
                    ) : (
                      <ArrowDownward fontSize="small" color="secondary" />
                    )}
                  </TableCell>
                  <TableCell sx={{ fontSize }}>
                    {vendor.gstFile ? (
                      <Link
                        href={process.env.REACT_APP_BASE_URL + "/" + vendor.gstFile}
                        className="text-primary"
                        target="_blank"
                        title="Download GST File"
                        download
                      >
                        <Avatar
                          alt="GST File"
                          src={process.env.REACT_APP_BASE_URL + "/" + vendor.gstFile}
                          sx={{
                            width: 25,
                            height: 25,
                            border: "2px solid #DC3545",
                            borderRadius: "50%",
                          }}
                        />
                      </Link>
                    ) : (
                      "---"
                    )}
                  </TableCell>
                  {vendor.payment_Status && !vendor.payment_Status.split(",") ? (
                    <TableCell
                      onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}
                    >
                      {vendor.payment_Status}
                    </TableCell>
                  ) : vendor.payment_Status && vendor.payment_Status.split(",") ? (
                    <TableCell
                      onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}
                    >
                      {vendor.payment_Status.split(",")[0]}
                      <br />
                      {vendor.payment_Status.split(",")[1]}
                    </TableCell>
                  ) : (
                    <TableCell onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }}>---</TableCell>
                  )}
                  <TableCell sx={{ fontSize }}>
                    {vendor.typeOfPayment === "P" ? (
                      "P"
                    ) : vendor.typeOfPayment === "L" ||
                      (vendor.paytmNumber !== "" && vendor.accountNo === "") ? (
                      "L"
                    ) : (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name={`${"typeOfPayment"}_${index}`}
                        >
                          <FormControlLabel value="P" onClick={() => handleRadioButton(vendor._id, "P")} control={<Radio size="small" />} label="P" />
                          <FormControlLabel value="L" onClick={() => handleRadioButton(vendor._id, "L")} control={<Radio size="small" />} label="L" />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </TableCell>
                  {/* <TableCell sx={{ fontSize }}>
                    {vendor.accountNo ? (
                      <Chip onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }} label={"Bank"} />
                    ) : (
                      <Chip onClick={toggleDrawer(anchor, true, vendor._id)} sx={{ ...fontSize, cursor: 'pointer' }} label={"Paytm"} />
                    )}
                  </TableCell> */}
                  <TableCell sx={{ fontSize }}>
                    {vendor.payment_Status === "" ? (
                      <>
                        <Tooltip title="Approve">
                          <CheckCircle fontSize="small" color="success" onClick={() =>
                            handleSubmit(
                              vendor._id,
                              true,
                              `${index}-paymentVendorData_status`,
                              "Under Process",
                              vendor.isGstRequested
                            )
                          } />
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Delete fontSize="small" color="error" onClick={() => handleDelete(vendor._id, vendor.isCompany)} />
                        </Tooltip>
                        {vendor.isGst && (
                          <>
                            &nbsp;&nbsp;
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className="form-check-input isGstPay"
                                  style={{ cursor: 'pointer' }}
                                  title="GST Pay"
                                  id={`${vendor._id}-gstPay`}
                                  onChange={() => handleGstPay(`${vendor._id}-gstPay`, vendor.isGstPay)}
                                />
                              }
                              label="GST Pay"
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Tooltip title="Approve">
                          <CheckCircle fontSize="small" onClick={() =>
                            handleSubmit(
                              vendor._id,
                              true,
                              `${index}-paymentVendorData_status`,
                              "Done",
                              vendor.isGstRequested
                            )
                          } />
                        </Tooltip>
                        <Tooltip title="Fail">
                          <Error fontSize="small" onClick={() =>
                            handleFail(vendor._id, vendor.isCompany, "Failed")
                          } />
                        </Tooltip>
                        {vendor.paymentScreenshot ? (
                          <Link
                            href={`${process.env.REACT_APP_BASE_URL}/${vendor.paymentScreenshot}`}
                            className="text-primary"
                            target="_blank"
                            title="Download Screenshot"
                            download
                          >
                            <Avatar
                              src={`${process.env.REACT_APP_BASE_URL}/${vendor.paymentScreenshot}`}
                              alt="Payment Screenshot"
                              sx={{
                                width: 25,
                                height: 25,
                                marginRight: '10px',
                                border: '2px solid #dc3545',
                              }}
                            />
                          </Link>
                        ) : (
                          <Tooltip title="Upload Payment Screenshot">
                            <FileUpload fontSize="small" onClick={() => handleUpload(vendor._id)} />
                          </Tooltip>
                        )}
                        {vendor.gstScreenshot ? (
                          <Link
                            href={`${process.env.REACT_APP_BASE_URL}/${vendor.gstScreenshot}`}
                            className="text-primary"
                            target="_blank"
                            title="Download Screenshot"
                            download
                          >
                            <Avatar
                              src={`${process.env.REACT_APP_BASE_URL}/${vendor.gstScreenshot}`}
                              alt="GST Screenshot"
                              sx={{
                                width: 25,
                                height: 25,
                                marginRight: '10px',
                                border: '2px solid #dc3545',
                              }}
                            />
                          </Link>
                        ) : vendor.isGstRequested ? (
                          <>
                            <Tooltip title="Upload GST Screenshot">
                              <FileUpload fontSize="small" onClick={() => uploadGstScreenshot(vendor._id)} />
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer >
      </Card>
    </>
  );
}
