import { useState } from "react";
import DashboardHeaderSection from "../common/dashboardheader/DashboardHeader";
import InfluencerSearch from "./InfluencerSearch";

function InfluencerFiltersSection() {
  return (
    <>
      <DashboardHeaderSection isSearchOpen={false} />
      <InfluencerSearch />
    </>
  );
}

export default InfluencerFiltersSection;
