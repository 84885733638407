import { Typography, Box, CircularProgress, Button } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import { useLocation } from 'react-router-dom';
// import HeaderSection from '../../common/header/Header';
import usersEndpoints from '../../../services/userServices';

function VerifyUserEmail(){
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('t');
    const [cookies] = useCookies(["user"]);
    const [error, setError] = useState(null);
    const [messageColor, setMessageColor] = useState("error");

    useEffect(() => {
        verifyUser();
    }, [verifyUser]);

    async function verifyUser(){
        if(token === undefined || token.length === 0){
            return setError("Missing parameters from Verification Link");
        }

        let postdata = {
            tokenurl: token
        };
        
        const response = await usersEndpoints.verifyUserEmail(postdata);
        setError(response['message'] ? response['message'] : null);
        if(response['success'] === true){
            setMessageColor("success");
        }
        else{
            setMessageColor("error");
        }
    }

    return (
        <>
            {/* <HeaderSection /> */}
            <Box 
                component = "div"
                sx={{
                    height: "calc(100vh - 4rem)",
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                {
                    error === null ?
                    (
                        <>
                            <Box
                                sx={{ marginBottom: 1, marginTop: 0 }}
                            >
                                <CircularProgress />
                            </Box>
                            <Typography
                                variant='h5'
                            >
                                Verifying Email...
                            </Typography>    
                        </>
                    )
                    :
                    (
                        <>
                            <Typography
                                variant='h5'
                                color={ messageColor }
                                sx={{ marginBottom: 3 }}
                            >
                                { error }
                            </Typography>
                            {
                                cookies.user && cookies.user !== "undefined" ?
                                (
                                    <Button
                                        variant="contained"
                                        color={ messageColor }
                                        href="/dashboard"
                                    >
                                        Dashboard
                                    </Button>
                                )
                                :
                                (
                                    <Button
                                        variant="contained"
                                        color={ messageColor }
                                        sx={{ paddingX: 5 }}
                                        href="/authenticate/signin"
                                    >
                                        Login
                                    </Button>
                                )
                            }
                        </>
                    )
                }
            </Box>
        </>
    );
}

export default VerifyUserEmail;