import * as React from 'react';
import { useState, useEffect } from 'react';
import { IconButton, Typography, Paper, Badge } from '@mui/material';
import { Table, TableContainer, TableRow, TableBody, TableHead, TableCell } from '@mui/material';
import usersEndpoints from '../../../services/userServices';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

// function createData(invoiceId, transactionDate, transactionStatus, amount, prevCredits, updatedCredits){
//     return { invoiceId, transactionDate, transactionStatus, amount, prevCredits, updatedCredits };
// }

// const rows = [
//     createData('wGKLVZmuPf', '01-05-2022', 'success', 3000, 40, 3040),
//     createData('iihek0I-Hc', '02-05-2022', 'success', 500, 2700, 3200),
//     createData('LQkBr3pLhG', '03-05-2022', 'failed', 5000, 1020, 1020),
//     createData('MDNoTh7Q3K', '04-05-2022', 'success', 1200, 40, 1240),
//     createData('U7Xp3-75YM', '05-05-2022', 'failed', 3000, 350, 350),
//     createData('LQkBr3pLhG', '03-05-2022', 'failed', 5000, 1020, 1020),
//     createData('MDNoTh7Q3K', '04-05-2022', 'success', 1200, 40, 1240),
//     createData('U7Xp3-75YM', '05-05-2022', 'failed', 3000, 350, 350),
//     createData('LQkBr3pLhG', '03-05-2022', 'failed', 5000, 1020, 1020),
//     createData('MDNoTh7Q3K', '04-05-2022', 'success', 1200, 40, 1240),
//     createData('U7Xp3-75YM', '05-05-2022', 'failed', 3000, 350, 350)
// ];

function UserInvoicesList(){
    const [transactionList, setTransactionList] = useState([]);

    useEffect(async () => {
        const response = await usersEndpoints.fetchBillingDetails();
        if(response['success'] === true){
            setTransactionList(response['data']);
        }
    }, []);

    const downloadInvoice = (transaction) => {
        window.open(`/billing/invoice/${ btoa(transaction.invoiceId) }`, '_blank');
    }

    return (
        <>
            {/* <Box 
                component={ "div" }
                sx={{ marginBottom: 2 }}
            >
                <Typography 
                    variant={ 'h6' }
                    sx={{ fontWeight: '600' }}
                >
                    Your Transactions
                </Typography>
            </Box> */}
            <TableContainer
                component={ Paper }
                sx={{ maxHeight: '90vh', marginTop: 2 }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell sx={{ fontWeight: '600' }}>Transaction ID</TableCell> */}
                            <TableCell sx={{ fontWeight: '600' }} align='center'>Transaction Date</TableCell>
                            <TableCell sx={{ fontWeight: '600' }} align='center'>Transaction Status</TableCell>
                            <TableCell sx={{ fontWeight: '600' }} align='center'>Amount</TableCell>
                            <TableCell sx={{ fontWeight: '600' }} align='center'>Previous Credits</TableCell>
                            <TableCell sx={{ fontWeight: '600' }} align='center'>Final Credits</TableCell>
                            <TableCell sx={{ fontWeight: '600' }} align='center'>Invoice</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            transactionList.length > 0 ?
                            transactionList.map((transaction, index) => (
                                <TableRow
                                    key={ `transaction-row-${ index }` }
                                >
                                    {/* <TableCell>{ transaction.invoiceId }</TableCell> */}
                                    <TableCell align='center'>{ transaction.transactionDate }</TableCell>
                                    <TableCell align='center'>
                                        <Badge 
                                            badgeContent={ transaction.transactionStatus.toLowerCase() === "captured" ? "successful" : transaction.transactionStatus.toLowerCase() } 
                                            color={ transaction.transactionStatus.toLowerCase() === "captured" ? 'success' : 'danger' }
                                        >
                                        </Badge>
                                    </TableCell>
                                    <TableCell align='center'>{ transaction.amount }</TableCell>
                                    <TableCell align='center'>{ transaction.prevCredits }</TableCell>
                                    <TableCell align='center'>{ transaction.updatedCredits }</TableCell>
                                    <TableCell align='center'>
                                        <IconButton
                                            color='primary'
                                            disabled={ transaction.transactionStatus.toLowerCase() === "captured" ? false : true }
                                            onClick={ () => downloadInvoice(transaction) }
                                        >
                                            <DownloadForOfflineIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                            :
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Typography
                                        color={ "error" }
                                        fontSize="inherit"
                                        textAlign={ "center" }
                                    >
                                        No transactions record found
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default UserInvoicesList;