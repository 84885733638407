import http from "./httpservices";
import GetCookieByName from "../hooks/GetCookie";

export async function getContactNumber(params) {
    const { data } = await http.post('plugin/viewInfluencerDetails', params, {
        headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
    });
    return data;
}

export async function requestContactNumber(params) {
    const { data } = await http.post('plugin/requestInfluencerDetails', params, {
        headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
    });
    return data;
}

export async function viewRequestedInfluencersNumbers() {
    const { data } = await http.get('plugin/viewRequestedInfluencers', {
        headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
    });
    return data;
}

export default {
    getContactNumber,
    requestContactNumber,
    viewRequestedInfluencersNumbers
};