import { TextField, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import paymentdashboardservice from "../../../services/paymentdashboardservice";
import { Controller } from "react-hook-form";

function Platforms({ getValues, setValue, errors, control }) {
  const [platforms, setPlatforms] = useState([]);

  const Init = async () => {
    let platforms = await paymentdashboardservice.FetchPlatforms();
    platforms = platforms.map((obj) => ({ ...obj, label: obj.name }));
    setPlatforms(platforms);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <Controller
      name="platform"
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <Autocomplete
          disablePortal
          id="platform-dropdown"
          options={platforms}
          sx={{ width: 350 }}
          value={
            platforms.find(
              (platform) => platform._id === getValues("platform")
            ) || null
          }
          onChange={(_, newValue) =>
            setValue("platform", newValue?._id || null)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              error={errors.platforms ? true : false}
              helperText={errors.platforms?.message}
              label="Platforms"
            />
          )}
        />
      )}
    />
  );
}

export default Platforms;
