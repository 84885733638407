import * as React from 'react';
import HeaderSection from '../components/common/header/Header';
import Terms from '../components/termsandconditions/Terms';
import SubscribeThroughEmail from '../components/common/subscribe/SubscribeEmail';

function TermsandConditions(){
    return (
        <>
            <HeaderSection showNav={ true }/>
            <Terms/>
            <SubscribeThroughEmail/>
        </>
    )
}

export default TermsandConditions;