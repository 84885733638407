import * as React from 'react';
import { Box, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, Divider } from '@mui/material';

import Image from 'material-ui-image';
import TermsAndConditionsImage from './images/terms_n_conditions.svg';

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

function Terms() {
    return (
        <Container
            maxWidth={'lg'}
            sx={{ paddingTop: '1rem', paddingBottom: 33}}
        >
            <Grid
                container
                spacing={5}
            >
                <Grid
                    item
                    xs={12}
                    lg={6}
                >
                    <Stack
                        spacing={2}
                    >
                        <Typography
                            color={'primary'}
                            fontWeight={'600'}
                            fontSize={'2rem'}
                        >
                            Terms and Conditions
                        </Typography>

                        <SimpleBar
                            autoHide={true}
                            style={{
                                maxHeight: '600px',
                                boxShadow: '1px 0px 2px 1px rgb(163 155 149 / 25%)',
                                padding: '1rem'
                            }}
                        >
                            <Stack
                                spacing={2}
                            >
                                {/* Introduction */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        Welcome to meesha.io!<br /><br />
                                        These terms and conditions outline the rules and regulations for the use of MEESHA's Website, located at https://meesha.io.<br /><br />
                                        By accessing this website, we assume you accept these terms and conditions. Do not continue to use meesha.io if you do not agree to take all of the terms and conditions stated on this page.
                                    </Typography>
                                </Box>
                                <Divider />

                                {/* Cookies */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='h6'
                                        fontWeight={'600'}
                                        fontSize={'1.2rem'}
                                    >
                                        Cookies
                                    </Typography>
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        The website uses cookies to help personalize your online experience. By accessing meesha.io, you agreed to use the required cookies.<br /><br />
                                        A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.<br /><br />
                                        We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional Cookies. There are some required Cookies that are necessary for the operation of our website. These cookies do not require your consent as they always work. Please keep in mind that by accepting required Cookies, you also accept third-party Cookies, which might be used via third-party provided services if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.
                                    </Typography>
                                </Box>
                                <Divider />

                                {/* License */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='h6'
                                        fontWeight={'600'}
                                        fontSize={'1.2rem'}
                                    >
                                        License
                                    </Typography>
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        Unless otherwise stated, MEESHA and/or its licensors own the intellectual property rights for all material on meesha.io. All intellectual property rights are reserved. You may access this from meesha.io for your own personal use subjected to restrictions set in these terms and conditions.
                                        <br /><br />
                                        You must not:
                                        <List disablePadding>
                                            <ListItem
                                                disableGutters
                                                disablePadding
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={"Copy or republish material from meesha.io"} />
                                            </ListItem>

                                            <ListItem
                                                disableGutters
                                                disablePadding
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={"Sell, rent, or sub-license material from meesha.io"} />
                                            </ListItem>

                                            <ListItem
                                                disableGutters
                                                disablePadding
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={"Reproduce, duplicate or copy material from meesha.io"} />
                                            </ListItem>

                                            <ListItem
                                                disableGutters
                                                disablePadding
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={"Redistribute content from meesha.io"} />
                                            </ListItem>
                                        </List>
                                        <br />
                                        This Agreement shall begin on the date hereof.<br />
                                        Parts of this website offer users an opportunity to post and exchange opinions and information in certain areas of the website. MEESHA does not filter, edit, publish or review Comments before their presence on the website. Comments do not reflect the views and opinions of MEESHA, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, MEESHA shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.<br /><br />
                                        MEESHA reserves the right to monitor all Comments and remove any Comments that can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.
                                        <br /><br />

                                        You warrant and represent that:
                                        <List disablePadding>
                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"You are entitled to post the Comments on our website and have all necessary licenses and consents to do so"}
                                                    sx={{ margin: 0 }}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party"}
                                                    sx={{ margin: 0 }}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material, which is an invasion of privacy."}
                                                    sx={{ margin: 0 }}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity."}
                                                    sx={{ margin: 0 }}
                                                />
                                            </ListItem>
                                        </List>
                                        <br />
                                        You hereby grant MEESHA a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.
                                    </Typography>
                                </Box>
                                <Divider />

                                {/* Hyperlinking to our content */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='h6'
                                        fontWeight={'600'}
                                        fontSize={'1.2rem'}
                                    >
                                        Hyperlinking to our Content
                                    </Typography>
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        The following organizations may link to our Website without prior written approval:
                                        <List
                                            disablePadding
                                        >
                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'Government agencies'}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'Search engines'}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'News organizations'}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and'}
                                                    sx={{ marginTop: 0 }}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={'System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.'}
                                                    sx={{ marginTop: 0 }}
                                                />
                                            </ListItem>
                                        </List>
                                        <br />
                                        These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
                                        <br /><br />
                                        We may consider and approve other link requests from the following types of organizations:
                                        <List
                                            disablePadding
                                        >
                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"commonly-known consumer and/or business information sources"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"dot.com community sites"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"associations or other groups representing charities"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"online directory distributors"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"internet portals"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"accounting, law, and consulting firms; and"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"educational institutions and trade associations."}
                                                />
                                            </ListItem>
                                        </List>
                                        <br />
                                        We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of MEESHA; and (d) the link is in the context of general resource information.<br /><br />
                                        These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.<br /><br />
                                        If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an email to MEESHA. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.<br /><br />
                                        Approved organizations may hyperlink to our Website as follows:

                                        <List
                                            disablePadding
                                        >
                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"By use of our corporate name; or"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"By use of the uniform resource locator being linked to; or"}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={"Using any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site."}
                                                    sx={{ marginTop: 0 }}
                                                />
                                            </ListItem>
                                        </List>
                                        <br />
                                        No use of MEESHA's logo or other artwork will be allowed for linking absent a trademark license agreement.
                                    </Typography>
                                </Box>
                                <Divider />

                                {/* Content Liability */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='h6'
                                        fontWeight={'600'}
                                        fontSize={'1.2rem'}
                                    >
                                        Content Liability
                                    </Typography>
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are raised on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                                    </Typography>
                                </Box>
                                <Divider />

                                {/* Reservation of Rights */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='h6'
                                        fontWeight={'600'}
                                        fontSize={'1.2rem'}
                                    >
                                        Reservation of Rights
                                    </Typography>
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                                    </Typography>
                                </Box>
                                <Divider />

                                {/* Removal of links from website */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='h6'
                                        fontWeight={'600'}
                                        fontSize={'1.2rem'}
                                    >
                                        Removal of links from our website
                                    </Typography>
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.
                                    </Typography>
                                </Box>
                                <Divider />

                                {/* Disclaimer */}
                                <Box
                                    component={"div"}
                                >
                                    <Typography
                                        variant='h6'
                                        fontWeight={'600'}
                                        fontSize={'1.2rem'}
                                    >
                                        Disclaimer
                                    </Typography>
                                    <Typography
                                        variant='p'
                                        sx={{
                                            lineHeight: 1.6,
                                            color: '#8d97ad'
                                        }}
                                    >
                                        To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                                        <List disablePadding>
                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="limit or exclude our or your liability for death or personal injury" />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="limit or exclude our or your liability for fraud or fraudulent misrepresentation" />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="limit any of our or your liabilities in any way that is not permitted under applicable law; or"
                                                    sx={{ marginTop: 0 }}
                                                />
                                            </ListItem>

                                            <ListItem
                                                disablePadding
                                                disableGutters
                                            >
                                                <ListItemIcon sx={{ minWidth: '30px', alignSelf: 'start' }}>
                                                    <KeyboardDoubleArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="exclude any of our or your liabilities that may not be excluded under applicable law."
                                                    sx={{ marginTop: 0 }}
                                                />
                                            </ListItem>
                                        </List>
                                        <br />
                                        The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.<br /><br />
                                        As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                                    </Typography>
                                </Box>
                            </Stack>
                        </SimpleBar>

                        {/* <Stack
                            direction={ 'row' }
                        >
                            <Button
                                variant='outlined'
                                color='error'
                                sx={{ borderRadius: '1.2rem', fontWeight: '600', borderWidth: '2px' }}
                            >
                                cancel
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                sx={{ borderRadius: '1.2rem', marginLeft: 'auto' }}
                            >
                                i agree
                            </Button>
                        </Stack> */}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={6}
                    marginTop={{ xs: '0', lg: '3rem' }}
                >
                    <Image
                        src={TermsAndConditionsImage}
                        style={{
                            maxWidth: '35rem'
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Terms;