import "./App.css";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import PrimaryFontFamily from "./components/common/Fonts";
import ColorPalette from "./components/common/Colors";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import LandingPage from "./layouts/Landing";
import AuthenticationPage from "./layouts/Authentication";
import UserDashboardPage from "./layouts/UserDashboard";
import UserProfilePage from "./layouts/UserProfile";
import PricingTableDisplay from "./components/common/pricing/Pricing";
import VerifyUserEmail from "./components/userprofile/profile/VerifyUserEmail";
import ForgetPassword from "./components/authentication/forgetpassword/ForgetPassword";
import DownloadInvoice from "./components/userprofile/profile/DownloadInvoice";
import AboutUsPage from "./layouts/Aboutus";
import Influencer from "./layouts/Influencer";
import InfluencerAuthentication from "./components/authentication/instagram/AuthSuccess";
// import Brands from './layouts/Brands';
import PrivacyPolicy from "./layouts/PrivacyPolicy";
import ContactSales from "./layouts/ContactSales";
import TermsandConditions from "./layouts/TermsnConditions";
import ReferFriend from "./layouts/Referrals";
import Influencers from "./layouts/InfluencerDashboard";
import FilterInfluencers from "./components/influencerDashboard/InfluencerFilters";
import Google from "./layouts/Google";
import SEOPage from "./layouts/Seo";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Reports from "./components/reports/Reports";
import ReportDetails from "./components/reports/ReportDetails";
import Notes from "./components/notes/Notes";
import Payments from "./layouts/PaymentDashboard";
import Campaigns from "./layouts/CampaignsDashboard";
import Status from "./components/payments/Status";
import PaymentLink from "./layouts/PaymentLink";
import CampaignDetails from "./layouts/CampaignDetails";
import Finance from "./layouts/Finance";
import { SnackbarProvider } from "notistack";

const queryClient = new QueryClient();

function App() {
  const theme = createTheme(deepmerge(PrimaryFontFamily, ColorPalette));

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);
  }, []);

  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Routes>
                {/* <TrackPageViews /> */}
                <Route
                  path="/authenticate/:type"
                  element={<AuthenticationPage />}
                />
                <Route
                  path="/authenticate/:type/:code"
                  element={<AuthenticationPage />}
                />
                <Route
                  path="/authenticate/reset-password/"
                  element={<ForgetPassword />}
                />
                <Route path="/history" element={<UserDashboardPage />} />
                <Route path="/profile" element={<UserProfilePage />} />
                <Route path="/pricing" element={<PricingTableDisplay />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/reports/:name/:id" element={<ReportDetails />} />
                <Route
                  path="google38239dbf6de8a371.html"
                  element={<Google />}
                />
                <Route path="/user/verify" element={<VerifyUserEmail />} />
                <Route
                  exact
                  path="/billing/invoice/:id"
                  element={<DownloadInvoice />}
                />
                <Route path="/influencer" element={<Influencer />} />
                <Route
                  path="/influencer-authentication/"
                  element={<InfluencerAuthentication />}
                />
                <Route path="/about_us" element={<AboutUsPage />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                <Route path="/policy/:type" element={<ContactSales />} />
                {/* <Route path="/brands" element={ <Brands/> }/> */}
                <Route path="/terms_of_use" element={<TermsandConditions />} />
                <Route path="/referrals" element={<ReferFriend />} />
                <Route path="/influencers" element={<Influencers />} />
                <Route
                  path="/filter/influencers"
                  element={<FilterInfluencers />}
                />
                <Route path="/notes/:user/:influencer" element={<Notes />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/payments/campaigns" element={<Campaigns />} />
                <Route path="/payments/status" element={<Status />} />
                <Route path="/payments/paymentlink" element={<PaymentLink />} />
                <Route
                  path="/payments/campaigns/details"
                  element={<CampaignDetails />}
                />
                <Route path="/payments/finance" element={<Finance />} />
                <Route path="/:username" element={<SEOPage />} />
                <Route path="/" element={<LandingPage />} />
                <Route path="*" element={<LandingPage />} />
              </Routes>
            </BrowserRouter>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ThemeProvider>
      </CookiesProvider>
    </SnackbarProvider>
  );
}

export default App;
