import InfluencerDashboardSection from "../components/influencerDashboard/InfluencerDashboard";

function InfluencerDashboard() {
  return (
    <>
      <InfluencerDashboardSection />
    </>
  );
}

export default InfluencerDashboard;
