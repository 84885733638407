import React from "react";
import { CssBaseline } from "@mui/material";
import PaymentStatus from "./PaymentStatus";
import DashboardHeaderSection from "../common/dashboardheader/DashboardHeader";

export default function Status() {
  return (
    <>
      <CssBaseline />
      <DashboardHeaderSection />
      <PaymentStatus />
    </>
  );
}
