import axios from "axios";
import GetCookieByName from "../../hooks/GetCookie";
// const config = require("../config/settings.json");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

if (Object.keys(GetCookieByName("auth")).length !== 0 ) {
  setToken(JSON.parse(GetCookieByName("auth"))["token"]);
}
function setToken(token) {
  axios.defaults.headers.common["x-auth-token"] = token;
}

axios.interceptors.response.use(null, (error) => {
  return Promise.reject(error);
});


const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  // cancel: axios.CancelToken
};

export default http;
