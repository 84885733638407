import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  Divider,
  Autocomplete,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { z } from "zod";
import {
  Clear,
  Handshake,
  Link,
  Loop,
  TextFields,
  TextFieldsOutlined,
} from "@mui/icons-material";
import Vendors from "../Vendors";
import CustomizedSnackbars from "../CustomizedSnackbars";
import paymentlinkservice from "../../../../services/paymentlinkservice";
import Swal from "sweetalert2";
import socialmediaservice from "../../../../services/socialmediaservice";
import Percentage from "../Percentage";

const PaymentFormSchema = z.object({
  campaign: z
    .string({
      required_error: "Campaign is required",
      invalid_type_error: "Campaign is required invalid_type_error",
    })
    .min(3, { message: "Not a Valid Campaign" }),
  instagramLink: z
    .string({
      required_error: "Instagram Link is required",
      invalid_type_error: "Instagram Link is required",
    })
    .url({ message: "Invalid Instagram Link" })
    .min(3, { message: "Not a Valid Instagram Link" }),
  username: z
    .string({
      required_error: "Username is required",
      invalid_type_error: "Username is required",
    })
    .min(3, { message: "Not a Valid Username" }),
  // date: z.string(),
  vendor: z.nullable(z.string()),
  comment: z.string(),
  deliverables: z
    .string({ required_error: "Deliverables is required" })
    .min(1, { message: "Deliverables must be required" }),
  totalAmount: z.string().refine(
    (val) => {
      if (val) {
        return /^[0-9]+$/.test(val);
      }
      return false;
    },
    { message: "Invalid amount" }
  ),
  includeGST: z.boolean(),
  gstAmount: z.nullable(z.string()),
  payableAmount: z.string(),
  influencer: z.string(),
  percentage: z.number({ required_error: "Select" }),
  // .string({
  //   required_error: "Select",
  //   invalid_type_error: "Select",
  // })
  // .min(1, { message: "Select" }),
});

export default function InfluencerForm({ campaignDetails, vendors, styles }) {
  const [urlLoading, setURLLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: zodResolver(PaymentFormSchema) });

  const onSubmit = async (formData) => {

    paymentlinkservice
      .GeneratePaymentLink(formData)
      .then(() => {
        handleSnackBarOpen();
        reset()
      })
      .catch((error) => {
        handleSnackBarOpen("error", error.message);
      });
  };
  useEffect(() => {
    setValue("campaign", campaignDetails.id);
    setValue("campaignName", campaignDetails.name);

    setValue("percentage", 100);
    setValue("gstAmount", null);
    setValue("includeGST", false);
  }, []);

  const [openSnackBar, setSnackBar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState(
    "Payment Link has been Created Successfully"
  );

  const handleSnackBarClose = () => setSnackBar(false);
  const handleSnackBarOpen = (severity, message) => {
    setSnackBarSeverity(severity || snackBarSeverity);
    setSnackBarMessage(message || snackBarMessage);
    setSnackBar(true);
  };

  const isValidSocialMediaUrl = (path) => {
    let url = path.toLowerCase();
    const regexes = {
      instagram: /^https?:\/\/(?:www\.)?instagram\.com\/([a-zA-Z0-9_.]+)\/?$/,
      youtube: /^https?:\/\/(?:www\.)?youtube\.com\/([a-zA-Z0-9.@_]+)\/?$/,
      telegram:
        /^(https?:\/\/(?:www\.)?(?:t\.me|telegram\.me)\/([a-zA-Z0-9_@.]+)\/?|https?:\/\/twitter\.com\/([a-zA-Z0-9_]+)\/?)$/,
      facebook: /^https?:\/\/(?:www\.)?facebook\.com\/([a-zA-Z0-9._@]+)\/?$/,
    };
    //twitter.com/_m_c_q
    https: for (const [platform, regex] of Object.entries(regexes)) {
      let match = url.match(regex);

      if (regex.test(url)) {
        return [platform, match[1]];
      }
    }

    return [false];
  };

  const handleDetectUrl = async (event) => {
    setURLLoading(true);
    const { value: urlString } = event.target;

    if (urlString) {
      const url = new URL(urlString);
      const isValidUrl = isValidSocialMediaUrl(urlString);
      if (!isValidUrl[0]) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Not a Valid ${url.hostname} Link`,
        });
        setValue("instagramLink", "");
        return;
      } else {
        const [platform, username] = isValidUrl;
        
        setValue("platform", platform);
        const resp = await socialmediaservice.UserDetails({
          url: urlString,
          campaignId: getValues("campaign"),
        });

        if (resp.status === 201) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: resp.data,
            timer: 3000,
          });
          setURLLoading(false);
          return;
        }

        const respData = resp.data;
        if (respData) {
          setValue("percentage", respData["percentage"]);
          setValue("totalAmount", respData["totalCost"]);
          setValue("deliverables", respData["deliverables"]);
          setValue("username", respData["username"]);
          setValue("influencer", respData["_id"]);
        }
        setURLLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomizedSnackbars
        handleSnackBarClose={handleSnackBarClose}
        open={openSnackBar}
        severity={snackBarSeverity}
        message={snackBarMessage}
      />
      <Card sx={styles}>
        <CardContent>
          <Stack spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <TextField
                id="outlined-basic"
                size="medium"
                label="Campaign*"
                value={campaignDetails.name}
                variant="outlined"
                sx={{ width: "50%" }}
                {...register("campaign")}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <Handshake />,
                }}
                helperText={errors.campaign?.message}
                error={errors.campaign ? true : false}
              />
              <TextField
                id="outlined-basic"
                size="medium"
                label="Social Media Link*"
                placeholder="eg: https://www.instagram.com/username/"
                InputLabelProps={{
                  shrink: true,
                }}
                // InputProps={{
                //   inputProps: {
                //     type: "url",
                //   },
                // }}
                // onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: urlLoading ? <Loop /> : <Link />,
                }}
                variant="outlined"
                sx={{ width: "50%" }}
                {...register("instagramLink")}
                onChange={handleDetectUrl}
                helperText={errors.instagramLink?.message}
                error={errors.instagramLink ? true : false}
              />
            </Stack>

            <Stack direction={"row"} spacing={2}>
              <TextField
                id="outlined-multiline-static"
                label="Deliverables*"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Deliverables for Reels or Post"
                multiline
                rows={7}
                sx={{ width: "50%" }}
                {...register("deliverables")}
                helperText={errors.deliverables?.message}
                error={errors.deliverables ? true : false}
              />
              <Stack direction={"column"} spacing={2} sx={{ width: "50%" }}>
                <Vendors
                  data={vendors}
                  setValue={setValue}
                  getValues={getValues}
                  control={control}
                />

                <TextField
                  id="outlined-basic"
                  size="medium"
                  label="Comment (If Applicable)"
                  variant="outlined"
                  // sx={{ width: "50%" }}
                  multiline
                  rows={1}
                  {...register("comment")}
                  placeholder="Comment for future reference"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <TextFieldsOutlined />,
                  }}
                  helperText={errors.comment?.message}
                  error={errors.comment ? true : false}
                />
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id="outlined-basic"
                    size="medium"
                    label="Total Amount*"
                    variant="outlined"
                    sx={{ width: "40%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="0"
                    {...register("totalAmount")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₹</InputAdornment>
                      ),
                    }}
                    helperText={errors.totalAmount?.message}
                    error={errors.totalAmount ? true : false}
                  />
                  <TextField
                    id="outlined-basic"
                    size="medium"
                    disabled
                    label="Payable Amount*"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={0}
                    sx={{ width: "30%" }}
                    {...register("payableAmount")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">₹</InputAdornment>
                      ),
                    }}
                  />
                  <Percentage
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                  />
                  {/* <FormControl sx={{ width: "30%" }}>
                    <InputLabel shrink={true} id="select-label">
                      Percentage
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      {...register("percentage")}
                      onChange={handlePercentage}
                    >
                      {Array.from(Array(getValues("percentage")).keys()).map(
                        (i) => (
                          <MenuItem key={i} value={`${i + 1}`}>
                            {i + 1}%
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {errors.percentage && (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "0.72rem",
                          marginLeft: "14px",
                          marginTop: "3px",
                        }}
                      >
                        {errors.percentage?.message}
                      </span>
                    )}
                  </FormControl> */}
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Box
              sx={{ textAlign: "center" }}
              // direction={"row"}
              // spacing={2}
              // divider={<Divider orientation="vertical" flexItem />}
            >
              <Button
                type="submit"
                disabled={Object.keys(errors).length !== 0}
                variant="contained"
                sx={{ marginRight: 2 }}
                color="info"
              >
                Add Another
              </Button>
              {/* <Divider orientation="vertical"  /> */}
              <Button
                type="submit"
                color="success"
                variant="contained"
                sx={{ marginRight: 2 }}
              >
                Submit
              </Button>
              {/* <Divider orientation="vertical"  /> */}
              <Button type="submit" color="warning" variant="contained">
                Submit with GST
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </form>
  );
}
