import * as React from 'react';
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react'; 
import { toast } from 'react-toastify';
import { Download } from '@mui/icons-material';

function ShowFilteredList(props){
    const [matchingInfluencers, setMatchingInfluencers] = useState([]);
    const CloseButton = ({ closeToast }) => (
        <IconButton
            onClick={
                () => {
                    window.open('https://chrome.google.com/webstore/detail/meesha-connect-with-influ/hfjiamlingkieffkgcikgjomhdbgoald', "_blank");
                    closeToast();
                    window.location.reload();
                }
            }
        >
            <Download/>
        </IconButton>
    );

    useEffect(() => {
        setMatchingInfluencers(props.users);
    }, [props.users]);

    return (
        <Paper
            sx={{ 
                height: 350,
                paddingX: 2, 
                paddingY: 1, 
                overflowY: 'scroll',
                overflowX: 'hidden',
                position: 'absolute',
                width: '42%'
            }}
        >
            <Typography
                variant='p'
                color={'dark.light'}
                fontSize={'small'}
            >
                Found Influencers&nbsp;({ matchingInfluencers.length })
            </Typography>
            
            <Divider/>
            
            <List>
                {
                    React.Children.toArray(
                        matchingInfluencers.map((influencer, index) => (
                            <>
                                <ListItem 
                                    onClick={ 
                                        () => {
                                            props.installed ?
                                                window.open(influencer.instagramUrl ? 
                                                    influencer.instagramUrl :
                                                    `https://www.instagram.com/${ influencer.username }`, 
                                                    "_blank"
                                                ) 
                                            :
                                            toast.error(
                                                `Meesha Extension is not Installed`,
                                                {
                                                    autoClose: false,
                                                    closeButton: CloseButton
                                                }
                                            )
                                        }
                                    }
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <ListItemAvatar>
                                        <Avatar src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                                    </ListItemAvatar>
                                    <ListItemText 
                                        primary={ influencer.username }
                                        secondary={ influencer.name ? influencer.name : influencer.username }
                                        primaryTypographyProps={{
                                            color: 'primary',
                                            fontSize: '0.875rem',
                                            fontWeight: '600'
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: '0.875rem'
                                        }}
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </>
                        ))
                    )
                }
            </List>
        </Paper>
    )
}

export default ShowFilteredList;