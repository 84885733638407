import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Modal,
  TextField,
  Typography,
  MenuItem,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import { ControlPoint, West } from "@mui/icons-material";
import DashboardHeaderSection from "../common/dashboardheader/DashboardHeader";
import ReportsTableComponent from "./common/ReportsTableComponent";
import { useNavigate } from "react-router-dom";

import { useFetchReports } from "../../react-query/hooks/useFetchReport";
import platformServices from "../../react-query/services/platformServices";
import reportServices from "../../react-query/services/reportServices";

import NoReport from "../../assets/images/no_reports.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Reports = () => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [reports, setReports] = useState([]);
  const [platforms, setPlatform] = useState([]);
  const [error, setError] = useState(false);

  const handleAlertClose = () => setOpenAlert(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const [params, setParams] = useState({
    name: "",
    platform: "",
  });

  const { isError, data, isLoading, isFetched } = useFetchReports();

  useEffect(() => {
    setReports(data?.data);
  }, [isLoading]);

  const handleChange = (e) => {
    let { name, value } = e.target;

    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const response = await reportServices.AddReport(params);
    if (response.status === 201) {
      setError(response.data);
    } else {
      setOpenAlert(true);
      handleClose();

      setReports([response.data, ...reports]);
    }
  };

  const Init = async () => {
    const response = await platformServices.FetchPlatforms();
    setPlatform(response);
  };

  useEffect(() => {
    Init();
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Report Added Success Fully!
        </Alert>
      </Snackbar>

      {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
      <DashboardHeaderSection />
      <Box
        width={{ xs: "100%", lg: `calc(100% - 80px)` }}
        marginLeft={{ xs: "0px", lg: `80px` }}
        marginTop={8}
        padding={1}
        sx={{ height: "100vh", bgcolor: "#F0F2F5" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h5" fontWeight={"bold"}>
              Reports
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="info"
            sx={{
              fontSize: "14px",
              marginRight: 2,
              textTransform: "capitalize",
            }}
            onClick={handleOpen}
          >
            <ControlPoint />
            &nbsp;Report
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                textAlign={"center"}
              >
                Create Report
              </Typography>
              <Box width={"100%"}>
                <TextField
                  id="standard-basic"
                  label="Name of the Report"
                  variant="standard"
                  sx={{ width: "100%" }}
                  name="name"
                  onChange={handleChange}
                />
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  marginTop={3}
                  sx={{
                    "& .MuiTextField-root": { width: "100%" },
                  }}
                >
                  <div>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Select"
                      defaultValue="Instagram"
                      helperText="Choose Platform"
                      width="100%"
                      name="platform"
                      onChange={handleChange}
                    >
                      {platforms.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Box>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  marginTop={3}
                  sx={{
                    "& .MuiTextField-root": { width: "100%" },
                  }}
                ></Box>
              </Box>
              <Box textAlign={"center"} marginTop={1}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    fontSize: "12px",
                    marginRight: 2,
                    textTransform: "capitalize",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>

              <Typography
                color={"red"}
                sx={{ textAlign: "center" }}
                variant="h6"
              >
                {error}
              </Typography>
            </Box>
          </Modal>
        </Box>
        <Box>
          {isLoading ? (
            <>
              <br />
              <br />
              <br />
              <br />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : reports && reports.length > 0 ? (
            <ReportsTableComponent
              loading={isLoading}
              reports={reports ? reports : []}
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <img src={NoReport} height={300} />
              <Typography color={"red"} variant="h6">
                No Report Found
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontSize: "12px",
                  marginRight: 2,
                  textTransform: "capitalize",
                }}
                onClick={handleOpen}
              >
                <ControlPoint sx={{ fontSize: "16px" }} />
                &nbsp;Create Report
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Reports;
