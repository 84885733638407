import * as React from 'react';
import { useEffect } from 'react';

// import HeaderSection from '../header/Header';
// import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
// import { Button, Container, Grid, Stack, Typography } from '@mui/material';

// import Baloon from "./images/baloon.png";
// import Aeroplane from "./images/aeroplane.png";
// import Rocket from "./images/rocket.png";
// import Satellite from "./images/satellite.png";

// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    'tableCell': {
        borderRight: '1px solid rgba(0, 0, 0, 0.15)'
    }
});

function PricingTableDisplay(){
    const classes = useStyles();
    useEffect(() => {
        window.location.assign("/#price_container");
        document.title = "Pricing";
    }, []);

    const all_features = [
        "credits",
        "email addresses",
        "phone numbers",
        "export contacts",
        "enterprise support"
    ];

    const starter_plan = [ 100, 1, 1, 1, 0, 0, 0, 0 ];

    const basic_plan = [ 1000, 1, 1, 1, 0, 0, 0, 0 ];

    const plus_plan = [ 10000, 1, 1, 1, 1, 0, 0, 0 ];

    // const premium_plan = [ 1, 1, 1, 1, 1, 1, 1, 1 ];

    return (
        <>
            {/* <HeaderSection showNav={ false }/> */}
            {/* <div 
                style={{ maxWidth: '100vw', background: '#ededed' }}
            >
                <Container>
                    <Grid
                        container 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center',
                            height: 'calc(100vh - 4rem)'
                        }}
                    >
                        <Grid
                            item 
                            xs={ 12 } lg={ 9 }
                        >
                            <TableContainer 
                                sx={{ 
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)'
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow 
                                            className={ classes.tableRow }
                                            sx={{ background: 'rgba(255, 255, 255, 100%)' }}
                                        >
                                            <TableCell className={ `${ classes.tableCell }` }></TableCell>
                                            <TableCell className={ `${ classes.tableCell }` }>
                                                <Stack
                                                    letterSpacing={ 1 }
                                                    alignItems={ 'center' }
                                                >
                                                    <img 
                                                        src={ Baloon }
                                                        style={{ width: '3rem', marginBottom: '1rem' }}
                                                        alt="free plan"
                                                    />
                                                    <Typography 
                                                        variant='p'
                                                        sx={{ fontWeight: '600' }}
                                                    >
                                                        STARTER
                                                    </Typography>
                                                    <Typography
                                                        variant='p'
                                                        sx={{ 
                                                            color: 'text.grey', 
                                                            fontWeight: '600', 
                                                            marginBottom: 2,
                                                            position: 'relative' 
                                                        }}
                                                    >
                                                        <span 
                                                            style={{
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            &#8377;
                                                        </span>
                                                        <span 
                                                            style={{ 
                                                                fontSize: '30px',
                                                                marginLeft: '2px'
                                                            }}
                                                        >
                                                            200
                                                        </span>
                                                        <span>/credit</span>
                                                    </Typography>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                    >
                                                        Purchase
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                            <TableCell className={ `${ classes.tableCell }` }>
                                                <Stack
                                                    letterSpacing={ 1 }
                                                    alignItems={ 'center' }
                                                >
                                                    <img 
                                                        src={ Aeroplane }
                                                        style={{ width: '3rem', marginBottom: '1rem' }}
                                                        alt="basic plan"
                                                    />
                                                    <Typography 
                                                        variant='p'
                                                        sx={{ fontWeight: '600' }}
                                                    >
                                                        BASIC
                                                    </Typography>
                                                    <Typography
                                                        variant='p'
                                                        sx={{ color: 'text.grey', fontWeight: '600', marginBottom: 2 }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            &#8377;
                                                        </span>
                                                        <span 
                                                            style={{ 
                                                                fontSize: '30px',
                                                                marginLeft: '2px'
                                                            }}
                                                        >
                                                            150
                                                        </span>
                                                        <span>/creditt</span>
                                                    </Typography>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                    >
                                                        Purchase
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                            <TableCell className={ `${ classes.tableCell }` }>
                                                <Stack
                                                    letterSpacing={ 1 }
                                                    alignItems={ 'center' }
                                                >
                                                    <img 
                                                        src={ Rocket }
                                                        style={{ width: '3rem', marginBottom: '1rem' }}
                                                        alt="plus plan"
                                                    />
                                                    <Typography 
                                                        variant='p'
                                                        sx={{ fontWeight: '600' }}
                                                    >
                                                        PLUS
                                                    </Typography>
                                                    <Typography
                                                        variant='p'
                                                        sx={{ color: 'text.grey', fontWeight: '600', marginBottom: 2 }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: '16px'
                                                            }}
                                                        >
                                                            &#8377;
                                                        </span>
                                                        <span
                                                            style={{ 
                                                                fontSize: '30px',
                                                                marginLeft: '2px'
                                                            }}
                                                        >
                                                            100
                                                        </span>
                                                        <span>/credit</span>
                                                    </Typography>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                    >
                                                        Purchase
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                <Stack
                                                    letterSpacing={ 1 }
                                                    alignItems={ 'center' }
                                                >
                                                    <img 
                                                        src={ Satellite }
                                                        style={{ width: '3rem', marginBottom: '1rem' }}
                                                        alt="premium plan"
                                                    />
                                                    <Typography 
                                                        variant='p'
                                                        sx={{ fontWeight: '600' }}
                                                    >
                                                        PREMIUM
                                                    </Typography>
                                                    <Typography
                                                        variant='p'
                                                        sx={{ color: 'text.grey', fontWeight: '600', marginBottom: 2 }}
                                                    >
                                                        $149 /month
                                                    </Typography>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                    >
                                                        Purchase
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            all_features.map((feature, index) => (
                                                <TableRow 
                                                    key={ `pricing_row_${ index }` }
                                                    sx={{ background: (index) % 2 === 0 ? '#f8f8f8' : '#FFF' }}
                                                >
                                                    <TableCell
                                                        sx={{ 
                                                            textTransform: 'capitalize'
                                                        }}
                                                        className={ `${ classes.tableCell }` }
                                                    >
                                                        { feature }
                                                    </TableCell>
                                                    <TableCell
                                                        align='center'
                                                        className={ `${ classes.tableCell }` }
                                                    >
                                                        {
                                                            index !== 0 
                                                            ?
                                                                starter_plan[index] === 1 ? 
                                                                <CheckCircleIcon sx={{ color: 'success.main' }}/> : 
                                                                <ClearIcon/> 
                                                            :
                                                                <Typography 
                                                                    component={ "p" }
                                                                    sx={{ 
                                                                        fontWeight: '600',
                                                                        opacity: 0.75
                                                                    }}
                                                                >
                                                                    { starter_plan[index] }
                                                                </Typography>
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align='center'
                                                        className={ `${ classes.tableCell }` }
                                                    >
                                                        {
                                                            index !== 0
                                                            ?
                                                                basic_plan[index] === 1 ? 
                                                                <CheckCircleIcon sx={{ color: 'success.main' }}/> :
                                                                <ClearIcon/>
                                                            :
                                                                <Typography 
                                                                    component={ "p" }
                                                                    sx={{ 
                                                                        fontWeight: '600',
                                                                        opacity: 0.75
                                                                    }}
                                                                >
                                                                    { basic_plan[index] }
                                                                </Typography>     
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align='center'
                                                        className={ `${ classes.tableCell }` }
                                                    >
                                                        {
                                                            index !== 0
                                                            ?
                                                                plus_plan[index] === 1 ? 
                                                                <CheckCircleIcon sx={{ color: 'success.main' }}/> :
                                                                <ClearIcon/>
                                                            :
                                                                <Typography 
                                                                    component={ "p" }
                                                                    sx={{ 
                                                                        fontWeight: '600',
                                                                        opacity: 0.75
                                                                    }}
                                                                >
                                                                    { plus_plan[index] }
                                                                </Typography>     
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align='center'
                                                    >
                                                        {
                                                            premium_plan[index] === 1 ? 
                                                            <CheckCircleIcon sx={{ color: 'success.main' }}/> :
                                                            <ClearIcon/>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}
        </>
    );
}

export default PricingTableDisplay;