import http from "./httpservices";
import GetCookieByName from "../../hooks/GetCookie";

export async function FetchPlatforms() {
  const { data } = await http.get("platforms/", {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export default {
  FetchPlatforms,
};
