import * as React from 'react';
import { Grid } from "@mui/material";
import HeaderSection from '../../common/header/Header';
import Image from 'material-ui-image';
import PasswordImage from "../images/forgot-password.png";
import ResetPasswordIntro from './components/ResetPasswordIntro';
import SendOtpEmail from './components/SendOtpEmail';

function ForgetPassword(){
    return (
        <Grid container>
            <HeaderSection />
            <Grid item 
                sm={6}
                display={{ sm: "block", xs: "none" }}
            >
                <Image 
                    src={ PasswordImage }
                />
            </Grid>
            <Grid 
                item xs={12} sm={6}
                margin="auto" 
                textAlign={ "center" } 
                paddingX={{ xs: "2rem", md: "5rem", lg: "10rem" }}
                paddingY={{ xs: "2rem" }}
            >
                <ResetPasswordIntro />
                <SendOtpEmail/>
            </Grid>
        </Grid>
    )
}

export default ForgetPassword;