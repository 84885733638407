import {
  Typography,
  Grid,
  Box,
  Button,
  Stack,
  Card,
  CardActions,
  CardContent,
  Item,
  Avatar,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import * as React from "react";

function SEOBody({ details }) {
  return (
    <>
      <Grid container spacing={2} padding={4} marginBottom={3}>
        <Grid item xs={12} lg={5}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent sx={{ display: "flex" }}>
              <Grid item xs={2}>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 60, height: 60 }}
                />
              </Grid>
              <Grid item xs={3} borderRight={2} marginRight={4}>
                <Typography variant="body1" marginBottom={-1}>
                  {details.name ? details.name : details.username}
                </Typography>
                <Typography variant="caption" color={"#C0C0C0"}>
                  @{details.username && details.username}
                </Typography>
              </Grid>
              {/* <Grid item xs={1}></Grid> */}
              <Grid item xs={7}>
                <Typography variant="body2">
                  {details.biography && details.biography}
                </Typography>
              </Grid>
            </CardContent>
            <CardContent sx={{ display: "flex" }}>
              <Grid item xs={2}>
                <Typography variant="caption" color={"#C0C0C0"}>
                  Posts
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>1,548</Typography>
                <Typography variant="caption" color={"#C0C0C0"}>
                  +0
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" color={"#C0C0C0"}>
                  Followers
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold", color: "#42b942" }}>
                  10,50415+
                </Typography>
                <Typography variant="caption" color={"#42b942"}>
                  +3,294,611 (1.3%)
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption" color={"#C0C0C0"}>
                  Following
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>441</Typography>
                <Typography variant="caption" color={"#42b942"}>
                  +14 (1.3%)
                </Typography>
              </Grid>
              <Grid item xs={3} py={3} sx={{ display: "flex" }}>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 40, height: 40 }}
                />
                &nbsp; &nbsp;
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 40, height: 40 }}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Typography variant="h6" fontWeight={600} marginBottom={1}>
                Lorem Ipsum
              </Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
                sit ipsam ducimus illum cumque quia. Nobis alias cupiditate illo
                suscipit vitae soluta similique dicta, rerum, perspiciatis
                illum, ad tempore labore.
                <br />
                <br />
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Saepe
                quo omnis reiciendis, fugiat, iure laborum consectetur tenetur
                similique laboriosam dignissimos dolores odio soluta excepturi
                dolorum eveniet maiores consequatur?
                <br />
                <br />
                Earum, tempora. Lorem ipsum dolor sit amet consectetur
                adipisicing elit.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Follower Growth
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Weekly Follower
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Total Engagement
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Average Rate
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Average Comment
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Weekly Rate
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Follower Ratio
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card sx={{ borderRadius: 4, padding: 2 }}>
            <CardContent>
              <Avatar sx={{ margin: "auto" }}>
                <AssignmentIcon />
              </Avatar>
              <Typography variant="h4" fontWeight={600} textAlign="center">
                145,821
              </Typography>
              <Typography textAlign="center" sx={{ fontSize: "0.8rem" }}>
                Comment Ratio
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default SEOBody;
