import React, { useState, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router";
import PaymentForm from "../components/payments/PaymentForm";
import paymentdashboardservice from "../services/paymentdashboardservice";
import DashboardHeaderSection from "../components/common/dashboardheader/DashboardHeader";

export default function PaymentLink() {
  const location = useLocation();
  const campaignDetails = location.state;
  const [vendors, setVendors] = useState([]);

  const Init = async () => {
    let vendors = await paymentdashboardservice.FetchVendors();
    vendors = vendors.map((obj) => ({
      ...obj,
      label: `${obj.name} | ${obj.category.name}`,
    }));
    setVendors(vendors);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <CssBaseline />
      <DashboardHeaderSection />
      <PaymentForm campaignDetails={campaignDetails} vendors={vendors} />
    </>
  );
}
