import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import Swal from "sweetalert2";

export default function NotesTableComponent({ notes, loading }) {
  const handleButtonClick = async (note) => {
    Swal.fire({
      title: note.name,
      text: note.description,
    });
  };

  return (
    <TableContainer>
      <Table sx={{ marginTop: 6 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Note Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Created On</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            notes.map((note, index) => (
              <TableRow key={index}>
                <TableCell>{note.name}</TableCell>
                <TableCell>
                  {note?.description.substring(0, 60) + "..."}
                </TableCell>
                <TableCell>16 Oct 2023</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      marginRight: 2,
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleButtonClick(note)}
                  >
                    <RemoveRedEye sx={{ fontSize: "16px" }} />
                    &nbsp;Preview
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
