import { Stack, Typography } from "@mui/material";
import Notification from './images/notification.svg';

import ReferralLinks from "./ReferralsLink";

function ReferralsBanner() {
    return (
        <Stack
            direction={'row'}
            // justifyContent={'center'}
            // marginX={'auto'}
            // borderRadius={2}
            marginTop={8}
            // boxShadow={1}
            paddingX={5}
            width={{ xs: '100%', lg: `calc(100% - 80px)` }}
            marginLeft={{ xs: '0px', lg: `80px` }}
            // maxWidth={'95%'}
            sx={{ height: '100vh', bgcolor: "#F0F2F5" }}
        >
            <Stack
                width={'50%'}
                display={{ xs: 'none', md: 'flex' }}
            >
                <img
                    src={Notification}
                    alt='refer a friend'
                />
            </Stack>
            <Stack
                marginTop={5}
                width={{ xs: '100%', md: '75%' }}
            >
                <Typography
                    variant={"h5"}
                    fontWeight={'600'}
                >
                    Refer and Earn
                </Typography>

                <Typography
                    marginBottom={2}
                >
                    Get 5 Credits for every friend you invite to Meesha.
                    <br />
                    You can earn upto <strong>50</strong> Credits
                </Typography>

                <ReferralLinks />
            </Stack>
        </Stack>
    )
}

export default ReferralsBanner;