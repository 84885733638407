import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function Vendors({ data, setValue, getValues, control }) {
  return (
    <Controller
      name="vendor"
      control={control}
      defaultValue={null}
      render={({ field }) => (
        <Autocomplete
          disablePortal
          id="vendor-dropdown"
          options={data}
          // sx={{ width: "50%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Select Vendors"
              label="Vendor"
            />
          )}
          value={
            data.find((vendor) => vendor._id === getValues("vendor")) || null
          }
          onChange={(_, newValue) => {
            setValue("vendor", newValue?._id || null);
          }}
        />
      )}
    />
  );
}

export default Vendors;
