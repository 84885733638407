import { Box } from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import HeaderSection from "../components/common/header/Header";
import SubscribeThroughEmail from "../components/common/subscribe/SubscribeEmail";
import SEOBody from "../components/seo/Seo";
import seoserives from "../services/seoserives";

function SEOPage({ match }) {
  const { username } = useParams();
  const [influecerDetails, setInfluecerDetails] = React.useState({});
  const Init = async () => {
    const response = await seoserives.FetchInfluencers({ username: username });
    setInfluecerDetails(response.user);
  };

  React.useEffect(() => {
    Init();
  }, []);

  return (
    <Box>
      <HeaderSection showNav={true} />
      <SEOBody details={influecerDetails} />
      <SubscribeThroughEmail />
    </Box>
  );
}

export default SEOPage;
