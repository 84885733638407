import * as React from 'react';
import { useEffect } from 'react';
import { Box, Button, Grid, Typography, FormControl, OutlinedInput, Select, MenuItem, FormHelperText, Divider, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';
import usersEndpoints from '../../../services/userServices';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserBillingDetails = React.forwardRef((props, ref) => {
    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const [loading, setLoading] = useState(false);
    const [statesListing, setStatesListing] = useState([]);

    useEffect(async () => {
        const states = await usersEndpoints.getStatesNames();
        if(states['success'] === true){
            setStatesListing(states['listing']);
        }
    }, []);

    useEffect(() => {
        
    }, [props]);

    const handleStateChange = (event) => {
        let state = event.target.value;
        props.details.state = state;
    };

    const onSubmit = (data) => {
        if(data.state === "0"){
            setError(
                "state",
                { type: 'custom', message: 'Please select your State' }, 
                { shouldFocus: true }
            );
            return;
        }

        saveBillingDetails(data);
    };

    const showInvalidValues = (data) => {
    
    };

    const saveBillingDetails = async (finalValues) => {
        setLoading(true);
        let postdata = {
            id: props.details.agency_id,
            companyName: finalValues.companyName,
            address: finalValues.address,
            postalcode: finalValues.zipcode,
            city: finalValues.city,
            state: finalValues.state,
            country: finalValues.country,
            tax_id: finalValues.tax_id
        };

        const response = await usersEndpoints.updateBillingDetails(postdata);
        setLoading(false);
        if(response['success'] === true){
            // alert("details updated");
            toast.success('Billing details have been updated');
            if(props.details.inModal){
                props.updateModal(false);
            }
        }
        else{
            // alert(response["message"]);
            toast.error(response["message"]);
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return(
        <Box 
            sx={ props.details.inModal ? style : {} }
            {...props} 
            ref={ref}
        >
            <Box
                component={ "div" }
                sx={{ marginBottom: 2 }}
            >
                {/* <Typography
                    variant={ 'h6' }
                    sx={{ fontWeight: '600' }}
                >
                    Billing Information
                </Typography> */}
                {
                    props.details.inModal ?
                    <>
                        <Divider sx={{ marginBottom: 1, borderColor: 'dark.main' }}/>
                        <Typography
                            variant='p'
                            color={'error'}
                        >
                            Please provide your Billing Details
                        </Typography>
                    </>
                    :
                    <></>
                }
            </Box>
            <form onSubmit={ handleSubmit(onSubmit, showInvalidValues) }> 
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                        <FormControl 
                            variant="outlined"
                            fullWidth
                        >
                            <OutlinedInput
                                placeholder="Company name"
                                defaultValue={ props.details.companyName ? props.details.companyName : '' }
                                {
                                    ...register(
                                        "companyName",
                                        {
                                            required: 'Company Name cannot be empty'
                                        }
                                    )
                                }
                                error={ Boolean(errors.companyName) }
                            />
                            <FormHelperText error>{ errors.companyName?.message }</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={ 8 }>
                        <FormControl 
                            variant="outlined"
                            fullWidth
                        >
                            <OutlinedInput
                                placeholder="Address"
                                defaultValue={ props.details.address ? props.details.address : '' }
                                {
                                    ...register(
                                        "address",
                                        {
                                            required: 'Please enter the company address',
                                            maxLength: {
                                                value: 30,
                                                message: 'Address cannot be more than 30 characters'
                                            }
                                        }
                                    )
                                }
                                error={ Boolean(errors.address) }
                            />
                            <FormHelperText error>{ errors.address?.message }</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 }>
                        <FormControl 
                            variant="outlined"
                            fullWidth
                        >
                            <OutlinedInput
                                placeholder="Postal code"
                                defaultValue={ props.details.zipcode ? props.details.zipcode : '' }
                                {
                                    ...register(
                                        "zipcode",
                                        {
                                            required: 'Please enter zip code for the company location',
                                            maxLength: {
                                                value: 6,
                                                message: 'Postal Code cannot be more than 6 characters'
                                            },
                                            pattern: {
                                                value: new RegExp('[0-9]'),
                                                message: 'Postal Code can only contains digits'
                                            }
                                        }
                                    )
                                }
                                error={ Boolean(errors.zipcode) }
                            />
                            <FormHelperText error>{ errors.zipcode?.message }</FormHelperText>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={ 12 } md={ 4 }>
                        <FormControl 
                            variant="outlined"
                            fullWidth
                        >
                            <OutlinedInput
                                placeholder="City"
                                defaultValue={ props.details.city ? props.details.city : '' }
                                {
                                    ...register(
                                        "city",
                                        {
                                            required: 'Please enter the city'
                                        }
                                    )
                                }
                                error={ Boolean(errors.city) }
                            />
                            <FormHelperText error>{ errors.city?.message }</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 }>
                        <FormControl 
                            variant="outlined"
                            fullWidth
                        >
                            <Select 
                                onChange={ handleStateChange }
                                defaultValue={ props.details.state ? props.details.state : "0" }
                                sx={{ color: props.details.state ? 'text.grey': 'text.primary' }}
                                {
                                    ...register(
                                        "state",
                                        {
                                            required: 'Please select a state'
                                        }
                                    )
                                }
                                error={ Boolean(errors.state) }
                            >
                                {
                                    statesListing.length > 0 &&
                                    statesListing.map((state, index) => (
                                        <MenuItem
                                            key={ `statename_${ index }` }
                                            value={ state._id }
                                        >
                                            { state.name }
                                        </MenuItem>
                                    ))
                                }
                                {/* <MenuItem value="0">State</MenuItem>
                                <MenuItem value="HR">Haryana</MenuItem>
                                <MenuItem value="DL">Delhi</MenuItem>
                                <MenuItem value="UP">Uttar Pradesh</MenuItem>
                                <MenuItem value="RJ">Rajasthan</MenuItem> */}
                            </Select>
                            <FormHelperText error>{ errors.state?.message }</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 }>
                        <FormControl 
                            variant="outlined"
                            fullWidth
                        >
                            <OutlinedInput
                                placeholder="Country"
                                value={ "India" }
                                readOnly
                                {
                                    ...register(
                                        "country"
                                    )
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={ 12 } md={ 12 }>
                        <FormControl 
                            variant="outlined"
                            fullWidth
                        >
                            <OutlinedInput
                                placeholder="GST Number(Optional)"
                                defaultValue={ props.details.tax_id ? props.details.tax_id : '' }
                                {
                                    ...register(
                                        "tax_id",
                                        {
                                            required: 'Please enter the GST number for the company'
                                        }
                                    )
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 }>
                        <Button
                            variant={ 'contained' }
                            color={ 'primary' }
                            fullWidth
                            type='submit'
                            endIcon={ loading && <CircularProgress size={ '0.9rem' } color="light"/> }
                        >
                            Submit Details
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
})

export default UserBillingDetails;