import { ArrowBack } from "@mui/icons-material";
import { Chip, Avatar, Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton({ label }) {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <Chip
        variant="outlined"
        avatar={
          <>
            <ArrowBack
              fontSize="small"
              sx={{ marginLeft: 1, cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Avatar>{label.charAt(0)}</Avatar>
          </>
        }
        label={label}
      />
    </Box>
  );
}

export default BackButton;
