import http from "./httpservices";
import GetCookieByName from "../../hooks/GetCookie";

const endPoint = "report";

export async function FetchReports() {
  return http.get(`${endPoint}`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
}

export async function FetchReportDetails(params) {
  return http.get(`${endPoint}/details`, {
    params: params,
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
}

export async function AddReport(postdata) {
  return await http.post(`${endPoint}`, postdata);
  
}


export async function AddPostsByReport(postdata) {
  const { data } = await http.put(`${endPoint}/update/posts`, postdata);
  return data;
}

export default {
  FetchReports,
  FetchReportDetails,
  AddReport,
  AddPostsByReport
};
