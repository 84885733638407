import React, { useEffect, useState } from "react";
import POCTable from "./common/POCTable";
import StatusCards from "./common/StatusCards";
import PaymentLink from "./common/PaymentLink";
import { Box, Grid, Stack } from "@mui/material";
import CampaignsList from "./common/CampaignsList";
import CategoryCards from "./CategoryCards";
import paymentdashboardservice from "../../services/paymentdashboardservice";

export default function Home() {
  const [stats, setStats] = useState({
    totalCampaign: 0,
    totalVendor: 0,
    totalLink: 0,
    totalSubordinates: 0,
    totalPending: 0,
    totalApplied: 0,
    totalFailed: 0,
    totalRejected: 0,
    totalSuccess: 0,
    totalWaiting: 0,
  });
  const [subordinates, setSubordinates] = useState([]);
  const [campaigns, setCampaigns] = React.useState([]);
  const [campaignsLoading, setCampaignsLoading] = React.useState(true);
  const [topPayments, setTopPayments] = useState([]);

  const InIt = async () => {
    const response = await paymentdashboardservice.FetchDashboardStats();

    setStats(response["stats"]);
    setSubordinates(response["subordinates"]);
    setTopPayments(response["top10payment"]);
  };

  useEffect(() => {
    InIt();
  }, []);

  const LatestCampaign = async () => {
    const response = await paymentdashboardservice.FetchLatestCampaigns();
    setCampaigns(response);
    setCampaignsLoading(false);
  };

  const updateLatestCampaign = (newCampaign) => {
    newCampaign["user"] = [newCampaign.createdby.fullName];
    setCampaigns((prevCampaigns) => [newCampaign, ...prevCampaigns]);
  };

  useEffect(() => {
    LatestCampaign();
  }, []);

  return (
    <div>
      <Box
        width={{ xs: "100%", lg: `calc(100% - 80px)` }}
        marginLeft={{ xs: "0px", lg: `80px` }}
        marginTop={8}
        paddingTop={1}
        backgroundColor={"#f0f2f5"}
      >
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Box sx={{ width: "100%" }}>
                <Stack spacing={4}>
                  <CategoryCards
                    totalCampaign={stats.totalCampaign}
                    totalVendor={stats.totalVendor}
                    updateLatestCampaign={updateLatestCampaign}
                  />
                  <StatusCards stats={stats} />
                  {subordinates.length > 0 && (
                    <POCTable subordinates={subordinates} />
                  )}

                  <PaymentLink topPayments={topPayments} />
                </Stack>
              </Box>
            </Grid>
            <CampaignsList loading={campaignsLoading} campaigns={campaigns} />
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
