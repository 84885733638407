import * as React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import Image from "material-ui-image";

import BannerImage from "../images/people.svg";
import { useCookies } from "react-cookie";
// import HsbcBrandImage from '../images/hsbc.png';
// import YelpBrandImage from '../images/yelp.png';
// import CnnBrandImage from '../images/cnn.png';
// import RandStadBrandImage from '../images/randstad.png';
// import RhBrandImage from '../images/roberthalf.png';

import ChromeIcon from "@mui/icons-material/LaptopChromebook";
import SignupIcon from "@mui/icons-material/AssignmentInd";

function LandingPageBanner() {
  const [cookies] = useCookies(["user"]);

  return (
    <>
      <Container maxWidth={"xl"} sx={{ marginTop: 15 }}>
        <Grid container>
          <Grid item lg={10}>
            <Box sx={{ paddingX: 6 }}>
              <Typography
                variant="h4"
                component="h1"
                sx={{ fontWeight: 900, fontSize: "60px", marginBottom: 2 }}
              >
                Features Section
              </Typography>
              <Typography
                variant="p"
                component="p"
                sx={{ fontWeight: 900, fontSize: "25px", marginBottom: 2 }}
              >
                Your hunt for the right Influencer ends here!
              </Typography>
              <Typography
                variant="h6"
                component={"p"}
                sx={{ fontWeight: 400, fontSize: "25px" }}
              >
                Meesha’s supreme understanding of the digital world enables it
                to function as a powerful search engine for your influencer
                search. The right talent, at the right cost!
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            lg={2}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              gap: "14px",
            }}
          >
            <Box
              sx={{
                width: "89px",
                height: "89px",
                border: "1px solid #000",
                borderRadius: "10px",
              }}
            />
            <Box
              sx={{
                width: "89px",
                height: "89px",
                border: "1px solid #000",
                borderRadius: "10px",
              }}
            />

            {/* <Typography
              variant="h3"
              component="h1"
              sx={{ fontWeight: 600, marginBottom: 2, paddingX: { xs: 2 } }}
            >
              Find out Content Creator's email and phone
            </Typography>
            <Typography
              variant="h6"
              component={"p"}
              sx={{ marginBottom: 2, paddingX: { xs: 2 } }}
            >
              Meesha lets you find professional contact details in seconds on
              Instagram and helps you connect with the people that matter to
              your Brand
            </Typography> */}
            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<ChromeIcon />}
              sx={{ marginLeft: 2 }}
              href="https://chrome.google.com/webstore/detail/meesha-connect-with-influ/hfjiamlingkieffkgcikgjomhdbgoald"
              target={"_blank"}
            >
              Download Chrome Extension
            </Button> */}
            {/* {cookies.user && cookies.user !== "undefined" ? (
              <></>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<SignupIcon />}
                  sx={{ marginRight: 2, marginLeft: 2 }}
                  href="/authenticate/signup"
                >
                  Sign up
                </Button>
              </>
            )} */}
          </Grid>
        </Grid>
      </Container>
      {/* <Box component={ 'section' }
                sx={{ paddingY: 5 }}
            >
                <Container maxWidth= "xl">
                    <Typography
                        variant= 'h6'
                        component={ 'p' }
                        sx={{ textAlign: 'center' }}
                    >
                        Trusted by over 73% of the Sales and Marketing Professionals
                        to Search for Influencers
                    </Typography>
                    <Grid container 
                        spacing={ 0 }
                        justifyContent= { 'center' }
                        alignItems= { 'center' }
                    >
                        <Grid item xs={ 3 } md={ 2 }>
                            <Image 
                                src={ YelpBrandImage } 
                                imageStyle={{ filter: 'grayscale(0)', width: '80%', height: '80%' }}
                                cover={ false }
                            />
                        </Grid>
                        <Grid item xs={ 3 } md={ 2 }>
                            <Image 
                                src={ HsbcBrandImage } 
                                imageStyle={{ filter: 'grayscale(0)', width: '80%', height: '80%' }}
                                cover={ false }
                            />
                        </Grid>
                        <Grid item xs={ 3 } md={ 2 }>
                            <Image 
                                src={ CnnBrandImage } 
                                imageStyle={{ filter: 'grayscale(0)', width: '80%', height: '80%' }}
                                cover={ false }
                            />
                        </Grid>
                        <Grid item xs={ 3 } md={ 2 }>
                            <Image 
                                src={ RandStadBrandImage } 
                                imageStyle={{ filter: 'grayscale(0)', width: '80%', height: '80%' }}
                                cover={ false }
                            />
                        </Grid>
                        <Grid item xs={ 3 } md={ 2 }>
                            <Image 
                                src={ RhBrandImage } 
                                imageStyle={{ filter: 'grayscale(0)', width: '80%', height: '80%' }}
                                cover={ false }
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}
    </>
  );
}

export default LandingPageBanner;
