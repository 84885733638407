import * as React from 'react';
import { 
    Button, 
    Grid, 
    Link, 
    Typography, 
    FormControl, 
    Stack, 
    IconButton, 
    Divider, 
    TextField, 
    Tooltip, 
    CircularProgress,
    Modal,
    Box
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useState, useEffect } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InfoIcon from '@mui/icons-material/Info';
import VerifiedIcon from '@mui/icons-material/Verified';
// import GoogleIcon from '@mui/icons-material/Google';

// import ChildrenImage from '../images/authentication.svg';
// import Image from 'material-ui-image';
import { useForm } from 'react-hook-form';
import usersEndpoints from '../../../services/userServices';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import HandleOtp from '../../common/otphandler/HandleOtp';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
// const config = require("../../../config/settings.json");

/*global chrome*/
/*global google*/
function SignupSection({ refCode }){
    // const [ otpTimer, setOtpTimer ] = useState(30);
    // const [ otpEnterSection, setOtpEnterSection ] = useState(false);
    const [passwordType, setPasswordType] = useState('password');
    const [loading, setLoading] = useState(false);
    const [showOtherFields, setShowOtherFields] = useState(false);
    const [emailOtp, setEmailOtp] = useState(false);
    const [emailModalDetails, setEmailModalDetails] = useState({});
    const [otpLoading, setOtpLoading] = useState(false);
    const [refCodeStatus, setRefCodeStatus] = useState(false);
    
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["user", "auth", "logindata"]);

    const SPACE_KEY = " ";
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_ID_SIGNIN;
    
    const { register, handleSubmit, watch, formState: { errors }, setError, getValues } = useForm();
    
    const onSubmit = async (data) => {
        setLoading(true);
        const check_disposable = await axios({
            url: `https://disposable.debounce.io/?email=${ data.user_email }`,
            method: 'GET'
        });
        
        if(check_disposable['data']['disposable'] === "false"){
            handleSignup(data);
        }
        else{
            setLoading(false);
            setError(
                'user_email',
                {
                    type: "custom",
                    message: "Email address should be a valid business address"
                },
                {
                    shouldFocus: true
                }
            );
        }
    };

    const showInvalidValues = (data) => {
    
    };

    const handleChangePasswordType = () => {
        let type_attr = '';
        if(passwordType === 'password'){
            type_attr = 'text';
        }
        else{
            type_attr = 'password';
        }
        setPasswordType(type_attr);
    };

    // const showHideOTPSection = (event) => {
    //     if(event.target.value.length === 10){
    //         setOtpEnterSection(true);
    //     }
    //     else{
    //         setOtpEnterSection(false);
    //     }
    // }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            
        });

        return () => { subscription.unsubscribe() }
    }, [watch]);

    useEffect(() => {
        if(refCode){
            verifyReferralCode();
        }
    }, [refCode]);

    const handleSpaceInInput = (event) => {
        let keyCode = event.keyCode || event.which;
        keyCode = String.fromCharCode(keyCode);
        if(keyCode === SPACE_KEY){
            event.returnValue = false;
            if(event.preventDefault){
                event.preventDefault();
            }
        }
    };

    // const handleMobileNumberInput = (event) => {
    //     let keyCode = event.keyCode || event.which;
    //     let prevent_input = true;
        
    //     if(keyCode >= 48 && keyCode <= 57){
    //         prevent_input = false;
    //     }
        
    //     if(prevent_input){
    //         event.returnValue = false;
    //         if(event.preventDefault){
    //             event.preventDefault();
    //         }
    //     }
    // };

    // const handleOtpInput = (event) => {
    //     const { id, maxLength, value } = event.target
    //     let target_id = id.split('_')[2];

    //     let keyCode = event.keyCode || event.which;
    //     if(keyCode === 8){
    //         if(value.length !== maxLength){
    //             target_id = parseInt(target_id) - 1;
    //             if(target_id > 0){
    //                 document.querySelector(`#otp_input_${ target_id }`).focus();
    //             }
    //         }
    //     }
    //     else{
    //         if(value.length === maxLength){
    //             target_id = parseInt(target_id) + 1;
    //             if(target_id < 7){
    //                 document.querySelector(`#otp_input_${ target_id }`).focus();
    //             }
    //         }
    //     }
    // };

    const handleSignup = async (data) => {
        let postdata = {
            "firstname": data.user_first_name,
            "lastname": data.user_last_name,
            "email": data.user_email,
            "phone": data.user_phone_number || '',
            "password": data.user_password,
            "isverified": false,
            'code': refCode ? refCode : ''
        };

        const response = await usersEndpoints.signUpUser(postdata);
        setLoading(false);
        saveSignupData(response);
    };

    const handleGoogleLoginResponse = async (response) => {
        if(response.credential){
            const details = jwtDecode(response.credential);
            if(details.hd){
                // send token to server for verification and further login/registration process
                let headers = { headers: { "user-auth": response.credential } };
                const postdata = {
                    mode: 'gauth',
                    page: 'signup',
                    code: refCode ? refCode : ''
                };

                const signup_info = await usersEndpoints.signInUser(postdata, headers);
                saveSignupData(signup_info);
            }
            else{
                // alert('Please join using a business email address');
                toast.error('Please join using a business email address');
            }
        }
    }

    useEffect(() => {
        window.onGoogleLibraryLoad = () => {
            google.accounts.id.initialize({
                client_id: GOOGLE_CLIENT_ID,
                callback: data => handleGoogleLoginResponse(data),
                cancel_on_tap_outside: false
            });
    
            google.accounts.id.renderButton(
                document.getElementById('google_sign_in'),
                {
                    theme: "filled_blue", 
                    size: "large",
                    text: "continue_with"
                }
            );
    
            google.accounts.id.prompt();
        }
    }, []);

    const saveSignupData = (response) => {
        if(response && response['success'] === true){
            let cookieData = {
                name: response['loginData']['fullname'],
                _id: response['loginData']['user_id']
            };
            
            setCookie("user", JSON.stringify(cookieData), {
                path: "/",
                secure: false,
                maxAge: 2592000
            });

            setCookie("auth", 
            { "token": response['loginData']['jwttoken'] },
            {
                path: "/",
                secure: false,
                maxAge: 2592000
            });

            try{
                chrome.runtime.sendMessage(
                process.env.REACT_APP_EXTENSION_ID, 
                {
                    message: 'login_successful',
                    loginData: response['loginData']
                },
                function(responseFromExtension){
                    responseFromExtension = responseFromExtension || {};
                    window.close();
                });
            }
            catch(error){
                setCookie("logindata", response['loginData'], { path: "/", secure: false, maxAge: 2592000 });
            }
            navigate('/');
        }
        else{
            // alert(response["message"]);
            toast.error(response['message']);
        }
    }

    const checkEmail = async () => {
        try{
            setOtpLoading(true);
            const formValues = getValues();
            setShowOtherFields(false);
            if(formValues['user_email']){
                const check_disposable = await axios({
                    url: `https://disposable.debounce.io/?email=${ formValues.user_email }`,
                    method: 'GET'
                });

                if(check_disposable['data']['disposable'] === "false"){
                    let postdata = {
                        email: formValues.user_email,
                        mode: 'signup'
                    };
                    
                    const response = await usersEndpoints.sendLoginOTP(postdata);
                    if(response['success'] === true){
                        toast.success(
                            'OTP has been successfully sent on your Email'
                        );
                        setEmailModalDetails({
                            email: formValues.user_email
                        });
                        setEmailOtp(true);
                    }
                    else{
                        toast.error(response['message'])
                    }
                }
            }
        }
        catch(error){
            toast.error(error.message);
        }
        finally{
            setOtpLoading(false);
        }
    }

    const verifyEmailOtp = async (otpCode) => {
        setEmailModalDetails({ ...emailModalDetails, ...{ verifying: true } });
        
        let postdata = {
            email: emailModalDetails['email'],
            code: otpCode
        };

        const response = await usersEndpoints.verifyEmailCode(postdata);
        setEmailModalDetails({ ...emailModalDetails, ...{ verifying: false } });
        if(response['success'] === true){
            toast.success(response['message']);
            setEmailOtp(false);
            setShowOtherFields(true);
        }
        else{
            toast.error(
                response['message']
            );
        }
    };

    const verifyReferralCode = async () => {
        const response = await usersEndpoints.verifyReferralCode({ code: refCode });
        setRefCodeStatus(response['success']);
        if(response['success'] === false){
            toast.error(
                "Referral Code is Expired/Invalid. You can still proceed with Registration and Get Welcome Bonus of 5 Credits",
                {
                    'position': 'top-left'
                }
            )
        }
    }

    return(
        <>
            <Stack 
                spacing={ 1 }
                sx={{ marginBottom: 2 }}
            >
                <Typography
                    variant='h5'
                    component={ 'h5' }
                    sx={{ textAlign: 'center' }}
                >
                    Welcome to Meesha
                </Typography>
                <Typography 
                    variant='p'
                    component={ 'p' }
                    sx={{ textAlign: 'center' }}
                >
                    Create an Account or <Link href="/authenticate/signin">Login</Link>
                </Typography>
            </Stack>
            <Grid 
                container 
                spacing={ 2 }
                justifyContent= 'center'
            >
                <Grid item sm={ 10 } md={ 5 }>
                    {
                        refCode && refCodeStatus &&
                        <Box
                            sx={{ 
                                paddingX: 1.5,
                                paddingY: 0.8,
                                background: '#d4ffd2',
                                borderRadius: '5px',
                                width: 'fit-content',
                                marginBottom: 2
                            }}
                            direction={'row'}
                            alignItems={'center'}
                            gap={1}
                            display={'flex'}
                        >
                            <DiscountOutlinedIcon 
                                fontSize='small'
                                sx={{
                                    opacity: 0.65
                                }}
                            />
                            
                            <Typography 
                                fontSize={'small'}
                                fontWeight={600}
                                sx={{ 
                                    opacity: 0.65
                                }}
                                display={'flex'}
                                gap={1}
                            >
                                <span>{ refCode }</span>
                                <span>Applied</span>
                            </Typography>
                        </Box>
                    }
                    
                    <form onSubmit={ handleSubmit(onSubmit, showInvalidValues) }>
                        <FormControl 
                            sx={{ width: '100%', marginBottom: 2 }}
                        >
                            <TextField
                                variant="outlined"
                                id='email_address_signup'
                                placeholder='Enter your work email'
                                { ...register("user_email", {
                                    required: {
                                        value: true,
                                        message: 'Email field cannot be empty'
                                    },
                                    pattern: {
                                        value: /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/,
                                        message: "Please provide a business email address"
                                    }
                                }) }
                                error={ Boolean(errors.user_email) }
                                helperText={ errors.user_email?.message }
                                autoComplete={ "off" }
                                onKeyPress={ handleSpaceInInput }
                                autoFocus
                                InputProps={{
                                    endAdornment: (
                                        showOtherFields === false ? 
                                        <Button
                                            size='sm'
                                            variant='contained'
                                            sx={{
                                                textTransform: 'capitalize'
                                            }}
                                            endIcon={
                                                otpLoading &&
                                                <CircularProgress 
                                                    size={ '0.875rem' }
                                                    color={ 'light' }
                                                />
                                            }
                                            onClick={ checkEmail }
                                        >
                                            verify
                                        </Button>
                                        :
                                        <VerifiedIcon
                                            color='success'
                                            sx={{
                                                fontSize: '1.2rem'
                                            }}
                                        />
                                    )
                                }}
                                disabled={ showOtherFields }
                            />
                        </FormControl>
                        {
                            showOtherFields &&
                            <Stack 
                                direction={ 'row' } 
                                spacing={ 2 }
                                sx={{ marginBottom: 2 }}
                            >
                                <FormControl 
                                    sx={{ width: '100%' }}
                                >
                                    <TextField
                                        variant='outlined'
                                        id='firstname_input'
                                        placeholder='First Name'
                                        {
                                            ...register("user_first_name", {
                                                required: 'Please provide your first name',
                                                maxLength: {
                                                    value: 30,
                                                    message: 'First name cannot be more than 30 characters'
                                                }
                                            })
                                        }
                                        error={ Boolean(errors.user_first_name) }
                                        helperText={ errors.user_first_name?.message }
                                        autoComplete={ "off" }
                                        onKeyPress={ handleSpaceInInput }
                                        sx={{ textTransform: 'capitalize' }}
                                    />
                                </FormControl>
                                <FormControl 
                                    sx={{ width: '100%' }}
                                >
                                    <TextField
                                        variant='outlined'
                                        id='lastname_input'
                                        placeholder='Last Name'
                                        {
                                            ...register("user_last_name", {
                                                required: 'Please provide your last name',
                                                maxLength: {
                                                    value: 30,
                                                    message: 'Last name cannot be more than 30 characters'
                                                }
                                            })
                                        }
                                        error={ Boolean(errors.user_last_name) }
                                        helperText={ errors.user_last_name?.message }
                                        autoComplete={ "off" }
                                        onKeyPress={ handleSpaceInInput }
                                        sx={{ textTransform: 'capitalize' }}
                                    />
                                </FormControl>
                            </Stack>
                        }
                        {/* <FormControl
                            sx={{ width: '100%', marginBottom: 2 }}
                        >
                            <TextField
                                variant='outlined'
                                id='mobile-number-signup'
                                placeholder='Your phone number'
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>+91</InputAdornment>
                                }}
                                type="tel"
                                {
                                    ...register("user_phone_number", {
                                        required: 'Please provide a valid phone number',
                                        pattern: {
                                            value: /^[0-9]{10}$/g,
                                            message: 'Phone number should contain only digits'
                                        }
                                    })
                                }
                                error={ Boolean(errors.user_phone_number) }
                                helperText={ errors.user_phone_number?.message }
                                inputProps={{
                                    maxLength: 10
                                }}
                                autoComplete={ "off" }
                                onKeyPress={ handleMobileNumberInput }
                                onChange={ showHideOTPSection }
                            />
                        </FormControl> */}

                        {/* <Box 
                            component='div'
                            display={ otpEnterSection === true ? 'block': 'none' }
                            sx={{ marginBottom: 2 }}
                        >
                            <Typography 
                                component={ 'p' }
                                sx={{ marginBottom: 2 }}
                            >
                                OTP Confirmation
                            </Typography>

                            <Stack
                                spacing={ 2 }
                                direction={ 'row' }
                                sx={{ marginBottom: 2 }}
                            >
                                <OutlinedInput
                                    placeholder='&#8211;'
                                    inputProps={{ 
                                        style: { textAlign: 'center' },
                                        maxLength: 1
                                    }}
                                    id="otp_input_1"
                                    onKeyPress={ handleMobileNumberInput }
                                    onKeyUp={ handleOtpInput }
                                />
                                <OutlinedInput
                                    placeholder='&#8211;'
                                    inputProps={{ 
                                        style: { textAlign: 'center' },
                                        maxLength: 1
                                    }}
                                    id="otp_input_2"
                                    onKeyPress={ handleMobileNumberInput }
                                    onKeyUp={ handleOtpInput }
                                />
                                <OutlinedInput
                                    placeholder='&#8211;'
                                    inputProps={{ 
                                        style: { textAlign: 'center' },
                                        maxLength: 1
                                    }}
                                    id="otp_input_3"
                                    onKeyPress={ handleMobileNumberInput }
                                    onKeyUp={ handleOtpInput }
                                />
                                <OutlinedInput
                                    placeholder='&#8211;'
                                    inputProps={{ 
                                        style: { textAlign: 'center' },
                                        maxLength: 1
                                    }}
                                    id="otp_input_4"
                                    onKeyPress={ handleMobileNumberInput }
                                    onKeyUp={ handleOtpInput }
                                />
                                <OutlinedInput
                                    placeholder='&#8211;'
                                    inputProps={{ 
                                        style: { textAlign: 'center' },
                                        maxLength: 1
                                    }}
                                    id="otp_input_5"
                                    onKeyPress={ handleMobileNumberInput }
                                    onKeyUp={ handleOtpInput }
                                />
                                <OutlinedInput
                                    placeholder='&#8211;'
                                    inputProps={{ 
                                        style: { textAlign: 'center' },
                                        maxLength: 1
                                    }}
                                    id="otp_input_6"
                                    onKeyPress={ handleMobileNumberInput }
                                    onKeyUp={ handleOtpInput }
                                />
                            </Stack>
                            <Typography variant='p' component={ 'p' }>
                                Didn't receive OTP, <Link>Resend in { otpTimer } seconds</Link>
                            </Typography>
                        </Box> */}

                        {
                            showOtherFields &&
                            <>
                                <FormControl
                                    sx={{ width: '100%', marginBottom: 2 }}
                                >
                                    <TextField
                                        variant='outlined'
                                        InputProps={{
                                            endAdornment: 
                                                <IconButton
                                                    onClick={ handleChangePasswordType }
                                                >
                                                    { passwordType === 'password' ? <RemoveRedEyeOutlinedIcon/> : <VisibilityOffOutlinedIcon /> }
                                                </IconButton>,
                                            startAdornment:
                                                <Tooltip title={ "Password should be atleast 8 characters in length and should contain atleast 1 lowercase letter, 1 uppercase letter and 1 number" }>
                                                    <IconButton>
                                                        <InfoIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                        }}
                                        placeholder='Choose a password'
                                        type={ passwordType }
                                        {
                                            ...register("user_password", {
                                                required: 'Please select a password',
                                                minLength: {
                                                    value: 8,
                                                    message: 'Password should contain atleast 8 characters'
                                                },
                                                maxLength: {
                                                    value: 20,
                                                    message: 'Password length cannot be more than 20'
                                                },
                                                pattern: {
                                                    value: new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])'),
                                                    message: 'Password should be of 8 characters in length and must contain atleast 1 Uppercase letter, 1 lowercase letter and 1 digit'
                                                }
                                            })
                                        }
                                        error={ Boolean(errors.user_password) }
                                        helperText={ errors.user_password?.message }
                                        autoComplete={ "off" }
                                        onKeyPress={ handleSpaceInInput }
                                    />
                                    {/* <FormHelperText
                                        sx={{ color: 'dark.main' }}
                                    >
                                        Password should be atleast 8 characters in length
                                        <br/>
                                        Password should contain atleast 1 lowercase letter, 1 uppercase letter and 1 number
                                    </FormHelperText> */}
                                </FormControl>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    sx={{ width: '100%', paddingY: 1, fontSize: '1rem', marginBottom: 2 }}
                                    type={ "submit" }
                                    endIcon={
                                        <CircularProgress 
                                            size={ loading ? "0.9rem" : "0rem" }
                                            color="light"
                                        />
                                    }
                                >
                                    Sign up
                                </Button> 
                            </>
                        }
                    </form>

                    <Divider sx={{ marginBottom: 2 }}>or signup with your work email</Divider>
                    
                    <Stack 
                        id='google_sign_in' 
                        alignItems={"center"}
                    />
                </Grid>
            </Grid>

            <Modal 
                open={ emailOtp }
                onClose={ () => setEmailOtp(false) }
                children={ <HandleOtp verifyOtp={verifyEmailOtp} details={ emailModalDetails }/> }
                onBackdropClick={ () => { return false } }
            />
            
            <ToastContainer
                style={{
                    fontSize: '0.85rem'
                }}
            />
        </>
    );
}

export default SignupSection;