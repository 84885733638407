import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Home from "../components/campaigns/Home";
import "../components/campaigns/css/Campaigns.css";
import paymentdashboardservice from "../services/paymentdashboardservice";
import DashboardHeaderSection from "../components/common/dashboardheader/DashboardHeader";

export const CampaignsDashboard = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = async (value) => {
    setLoading(true);
    const campaigns = await paymentdashboardservice.FetchCampaignSearch({
      name: value,
    });
    setCampaigns(campaigns);
    setLoading(false);
  };

  const handleLinkGenerate = (campaign, isDetail) => {
    if (campaign.paymentLimit === "0" && isDetail === false) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Payment Limit Exceeded",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    navigate(
      isDetail ? "/payments/campaigns/details" : "/payments/paymentlink",
      {
        state: {
          id: campaign._id,
          name: campaign.name,
          category: campaign.category[0].name,
          deliverables: campaign.deliverables,
          platform: campaign.platform[0].name,
          totalPayment: campaign.totalPayment,
          paymentLimit: campaign.paymentLimit,
          createdby: campaign.createdby.fullName,
        },
      }
    );
  };

  const editCampaign = async (id) => {
    const { value: totalPayment } = await Swal.fire({
      title: "Increase the Budget",
      input: "text",
      confirmButtonText: "Add",
      showCancelButton: true,
      inputPlaceholder: "Amount",
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });

    if (totalPayment) {
      await paymentdashboardservice.updateCampaignBudget({
        id: id,
        totalPayment: totalPayment,
      });
      await Swal.fire({
        position: "center",
        icon: "success",
        title: "Budget Updated Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      window.location.reload();
    }
  };

  const handleDelete = async (id, campaignName) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-success me-2",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let response = await paymentdashboardservice.deleteCampaign({
            campaignId: id,
            campaignName: campaignName,
          });

          if (response.status === 201) {
            Swal.fire({
              icon: "error",
              title: response.data,
              showConfirmButton: false,
              timer: 3000,
            });
            return;
          }
          setCampaigns(campaigns.filter((campaign) => campaign._id !== id));
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your file has been deleted.",
            "success"
          );
        }
      });
  };

  const Init = async () => {
    setLoading(true);
    const campaigns = await paymentdashboardservice.FetchCampaigns();
    setCampaigns(campaigns);
    setLoading(false);
  };

  useEffect(() => {
    Init();
  }, []);

  return (
    <>
      <CssBaseline />
      <DashboardHeaderSection />
      <Home
        loading={loading}
        campaigns={campaigns}
        handleLinkGenerate={handleLinkGenerate}
        handleChange={handleChange}
        editCampaign={editCampaign}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default CampaignsDashboard;
