import { Chip } from "@mui/material";
import React from "react";

function IdToDate({ id }) {
  return (
    <Chip
      sx={{ fontSize: '0.7rem', height: '15px' }}
      // size="small"
      color="error"
      variant="outlined"
      label={new Date(
        parseInt(id.substring(0, 8), 16) * 1000
      ).toLocaleDateString()}
    />
  );
}

export default IdToDate;
