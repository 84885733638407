import React, { useState } from 'react'
import { Box, Card, Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material'
import PropTypes from "prop-types";
import FinanceInfluencerTable from './FinanceInfluencerTable';
import FinanceVendorTable from './FinanceVendorTable';
import FinanceInfluencerDetailsTable from './FinanceInfluencerDetailsTable';
import FinanceVendorDetailsTable from './FinanceVendorDetailsTable';
import FinanceHeader from './common/FinanceHeader';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Home({
    fontSize,
    fontStyle,
    campaigns,
    vendors,
    handleDownload,
    paymentInfluencerData,
    paymentVendorData,
    handleTDS,
    renderFinancialPayment,
    handleFinancialPayment,
    handleRadioButton,
    handleSubmit,
    handleDelete,
    handleGstPay,
    handleFail,
    handleUpload,
    uploadGstScreenshot,
    handleShow,
    handleVendorShow,
    handleCampaignSearch,
    searchCampaign,
    searchVendor,
    handleVendorSearch,
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <Box sx={{ padding: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Stack>
                        {/* <Box>
                            {value === 0 ? <TextField
                                sx={{ width: "100%" }}
                                id="outlined-search"
                                label="Search Campaign..."
                                type="search"
                                size='small'
                                name='search'
                                value={searchCampaign}
                                onChange={(e) => handleCampaignSearch(e.target.value)}
                            /> : <TextField
                                sx={{ width: "100%" }}
                                id="outlined-search"
                                label="Search Vendor..."
                                type="search"
                                size='small'
                                name='search'
                                value={searchVendor}
                                onChange={(e) => handleVendorSearch(e.target.value)}
                            />}
                        </Box> */}
                        <Card>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Influencer" {...a11yProps(0)} />
                                    <Tab label="Vendor" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <FinanceInfluencerTable
                                    fontSize={fontSize}
                                    fontStyle={fontStyle}
                                    campaigns={campaigns}
                                    handleDownload={handleDownload}
                                    handleShow={handleShow} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <FinanceVendorTable
                                    fontSize={fontSize}
                                    fontStyle={fontStyle}
                                    vendors={vendors}
                                    handleDownload={handleDownload}
                                    handleVendorShow={handleVendorShow}
                                />
                            </CustomTabPanel>
                        </Card>
                    </Stack>
                </Grid>
                <Grid item xs={9}>
                    <Stack spacing={1}>
                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>1</Box>
                            <Box>
                                <TextField
                                    sx={{ width: "100%" }}
                                    id="outlined-search"
                                    label="Search Pancard"
                                    type="search"
                                    size='small'
                                />
                            </Box>
                            <Box>3</Box>
                        </Box> */}
                        <Box>
                            {value === 0 ?
                                <FinanceInfluencerDetailsTable
                                    fontSize={fontSize}
                                    fontStyle={fontStyle}
                                    paymentInfluencerData={paymentInfluencerData}
                                    handleTDS={handleTDS}
                                    renderFinancialPayment={renderFinancialPayment}
                                    handleFinancialPayment={handleFinancialPayment}
                                    handleRadioButton={handleRadioButton}
                                    handleSubmit={handleSubmit}
                                    handleDelete={handleDelete}
                                    handleGstPay={handleGstPay}
                                    handleFail={handleFail}
                                    handleUpload={handleUpload}
                                    uploadGstScreenshot={uploadGstScreenshot}
                                /> :
                                <FinanceVendorDetailsTable
                                    fontSize={fontSize}
                                    fontStyle={fontStyle}
                                    paymentVendorData={paymentVendorData}
                                    handleTDS={handleTDS}
                                    renderFinancialPayment={renderFinancialPayment}
                                    handleFinancialPayment={handleFinancialPayment}
                                    handleRadioButton={handleRadioButton}
                                    handleSubmit={handleSubmit}
                                    handleDelete={handleDelete}
                                    handleGstPay={handleGstPay}
                                    handleFail={handleFail}
                                    handleUpload={handleUpload}
                                    uploadGstScreenshot={uploadGstScreenshot}
                                />}
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}
