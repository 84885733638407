import * as React from 'react';
import { Typography, Container, Stack, Grid } from '@mui/material';

function RefundPolicy(){
    return (
        <>
            <Stack
                sx={{ 
                    minHeight: '15rem',
                    background: '#f5f7fa',
                }}
                justifyContent={ 'center' }
                alignItems={ 'center' }
            >
                <Typography
                    variant='h5'
                    fontWeight={ 600 }
                >
                    Cancellation and Refund Policy
                </Typography>

                <Typography
                    variant='p'
                    fontSize={ '0.95rem' }
                    sx={{ opacity: 0.7, letterSpacing: '0.5px' }}
                >
                    Last updated : October 2022
                </Typography>
            </Stack>
            
            <Grid
                container
                justifyContent={ 'center' }
                alignItems={ 'center' }
                paddingTop={5}
                paddingBottom={33}
            >
                <Grid 
                    item
                    md={5}
                    xs={12}
                >
                    <Typography
                        textAlign={'center'}
                        fontSize={ '1rem' }
                        lineHeight={ '1.6rem' }
                    >
                        Credits bought on Meesha are <strong>non refundable</strong> and <strong>non transferrable</strong> under any circumstances
                        <br/>
                        We request our users to proceed with purchase carefully
                        <br/>
                        If by any chance your balance got deducted and credits are not added, you can raise a issue
                        by navigating to the <a href='/policy/contactus'>Contact us</a> page
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default RefundPolicy;