import React, { useState } from "react";
import {
  Table,
  TableBody,
  Card,
  CardHeader,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Chip,
  Alert,
  Snackbar,
} from "@mui/material";
import { CardActions } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import IdToDate from "../../common/date/IdToDate";
import { CopyAll } from "@mui/icons-material";
import { useSnackbar } from "notistack";

export default function PaymentLink({ topPayments }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyClick = (pathName, isCompany) => {
    const link = `${window.location.protocol}//${window.location.host}/${
      isCompany ? "company" : "influencer"
    }/payment/${pathName}`;
    navigator.clipboard.writeText(link);
    enqueueSnackbar("Linked Copy !", {
      variant: 'success'
    });
  };

  return (
    <Box>
      <Card sx={{ borderRadius: 0, boxShadow: 0 }}>
        <CardHeader subheader={"Top 10 Payment Status"} />
        <TableContainer>
          <Table
            sx={{ border: "1px solid #e0e0e0" }}
            aria-label="simple table"
            size="lg"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>%</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Payable</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Campaign</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Inf/Ven</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>GST</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topPayments.length > 0 ? (
                topPayments.map((payment) => (
                  <TableRow>
                    <TableCell>{<IdToDate id={payment._id} />}</TableCell>
                    <TableCell>{payment.totalAmount}</TableCell>
                    <TableCell>{payment.percentage}</TableCell>
                    <TableCell>{payment.payableAmount}</TableCell>
                    <TableCell>{payment.campaignName}</TableCell>
                    <TableCell>{payment.influencerName}</TableCell>
                    <TableCell>
                      {payment.gstAmount === null ? "NA" : payment.gstAmount}
                    </TableCell>
                    <TableCell>
                      <Chip label={payment.status} color="info" />
                    </TableCell>
                    <TableCell>
                      {payment.status === "P" ? (
                        <CopyAll
                          onClick={() =>
                            handleCopyClick(payment.pathName, payment.company)
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      ) : (
                        "NA "
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {topPayments.length > 0 && (
          <CardActions>
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate("/payments/status")}
            >
              View all
            </Button>
          </CardActions>
        )}
      </Card>
    </Box>
  );
}
