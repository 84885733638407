import * as React from "react";
import Gif1 from "../../../../src/gif_1.gif";
import Gif2 from "../../../../src/gif_2.gif";
import { Container, Typography, Box, Grid } from "@mui/material";

function LandingWhyusSection() {
  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ width: { lg: "80%" }, marginX: "auto", textAlign: "center" }}>
        <Typography
          variant="h4"
          component={"h1"}
          sx={{
            fontSize: "60px",
            fontWeight: "900",
          }}
        >
          Streamline Your Influencer Search with{" "}
          <span style={{ color: "#FF5200" }}>Our Smart Dashboard</span>
        </Typography>
        <Typography
          variant="p"
          component={"p"}
          sx={{
            fontSize: "25px",
            fontWeight: "400",
            width: { lg: "80%" },
            marginX: "auto",
          }}
        >
          Save time and effort by finding the right influencers and their
          contact information in one convenient place.
        </Typography>
      </Box>
      <Grid container sx={{ marginTop: 5, marginX: 2 }}>
        <Grid lg={6}>
          <Box component={"div"}>
            <img
              // src={GirlImage}
              src={Gif2}
              alt="girl image"
              width={"185px"}
              height={"185px"}
            />
            <Typography
              variant="h6"
              component={"h6"}
              sx={{ fontWeight: "900", fontSize: "25px" }}
            >
              Advanced Search Filters
            </Typography>
            <Typography
              component={"p"}
              sx={{ fontWeight: "400", fontSize: "25px" }}
            >
              Refine your search using filters such as category, age, platform,
              gender, and location to find the most relevant influencers for
              your brand.
            </Typography>
          </Box>
        </Grid>
        <Grid lg={6}>
          <Box component={"div"}>
            <img
              // src={WomenImage}
              src={Gif1}
              alt="girl image"
              width={"185px"}
              height={"185px"}
            />
            <Typography
              variant="h6"
              component={"h6"}
              sx={{ fontWeight: "900", fontSize: "25px" }}
            >
              One-Stop Solution
            </Typography>
            <Typography
              component={"p"}
              sx={{ fontWeight: "400", fontSize: "25px" }}
            >
              Access influencer profiles and contact information directly from
              the dashboard, speeding up the search process and simplifying your
              workflow.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
{
  /* <Container maxWidth={"lg"} sx={{ paddingY: 5 }}>
        <Typography
          variant="h4"
          component={"h4"}
          sx={{
            width: { md: "90%", lg: "75%" },
            marginX: "auto",
            textAlign: { lg: "center", md: "left" },
          }}
        >
          Here's why many Brands are using
          <Box component={"span"} sx={{ color: "primary.main" }}>
            <span>&#x20;</span>Meesha<span>&#x20;</span>
          </Box>
          to search for Content Creator's on Social Media
        </Typography>

        <Grid container justifyContent={"center"}>
          <Grid item sm={6} lg={3} flexGrow={1}>
            <Image src={GiftImage} imageStyle={{ width: "50%", left: "25%" }} />
            <Box component={"div"} sx={{ marginTop: "-18%", paddingX: 2 }}>
              <Typography
                variant="h6"
                component={"h6"}
                sx={{ fontWeight: 600, textAlign: "center", opacity: 0.85 }}
              >
                Get Contacts for Life
              </Typography>
              <Typography
                component={"p"}
                sx={{ textAlign: "center", opacity: 0.75 }}
              >
                Once you view a contact, it will always be available on your
                Dashboard
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={6} lg={3}>
            <Image
              src={TargetImage}
              imageStyle={{ width: "50%", left: "25%" }}
            />
            <Box component={"div"} sx={{ marginTop: "-18%", paddingX: 2 }}>
              <Typography
                variant="h6"
                component={"h6"}
                sx={{ fontWeight: 600, textAlign: "center", opacity: 0.85 }}
              >
                Great Accuracy Rate
              </Typography>
              <Typography
                component={"p"}
                sx={{ textAlign: "center", opacity: 0.75 }}
              >
                Meesha regularly screens the contact details of its members and
                has the largest Hit Rate in the Industry
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={6} lg={3}>
            <Image
              src={BigDataImage}
              imageStyle={{ width: "50%", left: "25%" }}
            />
            <Box component={"div"} sx={{ marginTop: "-18%", paddingX: 2 }}>
              <Typography
                variant="h6"
                component={"h6"}
                sx={{ fontWeight: 600, textAlign: "center", opacity: 0.85 }}
              >
                Vast Data
              </Typography>
              <Typography
                component={"p"}
                sx={{ textAlign: "center", opacity: 0.75 }}
              >
                Get Access to a large database of Influencers available on
                Instagram
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={6} lg={3}>
            <Image
              src={SaveLeadImage}
              imageStyle={{ width: "50%", left: "25%" }}
            />
            <Box component={"div"} sx={{ marginTop: "-18%", paddingX: 2 }}>
              <Typography
                variant="h6"
                component={"h6"}
                sx={{ fontWeight: 600, textAlign: "center", opacity: 0.85 }}
              >
                Save Profiles
              </Typography>
              <Typography
                component={"p"}
                sx={{ textAlign: "center", opacity: 0.75 }}
              >
                Save leads from Instagram to your Dashboard. Organize them and
                Export to spreadsheet with one click
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container> */
}

export default LandingWhyusSection;
