import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  Card,
  TableContainer,
  TableHead,
  TableRow,
  CardHeader,
  Box,
} from "@mui/material";

export default function POCTable({ subordinates }) {
  console.log(subordinates);
  return (
    <Box>
      <Card sx={{ borderRadius: 0, boxShadow: 0}}>
        <CardHeader subheader={"Subordinates"} />
        <TableContainer>
          <Table
            sx={{ border: "1px solid #e0e0e0" }}
            aria-label="simple table"
            size="lg"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>POC</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Pending</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Applied</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Waiting</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Failed</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Rejected</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>2</TableCell>
                <TableCell>3</TableCell>
                <TableCell>4</TableCell>
                <TableCell>5</TableCell>
                <TableCell>6</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
}
