import { Box, Button, TextField, Typography, CircularProgress } from "@mui/material";
import * as React from 'react';
import { useState } from "react";

const HandleOtp = React.forwardRef((props, ref) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        borderTop: 'solid 5px orangered'
    };

    const [otpValue, setOtpValue] = useState("");
    
    const handleOtpInput = (event) => {
        setOtpValue(event.target.value);
    }

    return (
        <Box
            sx={ style } 
            {...props} 
            ref={ref}
            display={"flex"}
            flexDirection={"column"}
            alignItems={'center'}
            gap={2}
        >
            <Typography
                textAlign={'center'}
            >
                Enter OTP sent on
                <br/>
                { props.details.email }
            </Typography>

            <TextField
                variant="outlined"
                placeholder="______"
                inputMode={"numeric"}
                inputProps={{
                    maxLength: 6,
                    style: {
                        textAlign: 'center',
                        letterSpacing: 10
                    }
                }}
                autoFocus
                value={otpValue}
                onChange={ handleOtpInput }
            />
            
            <Button
                variant="contained"
                sx={{
                    textTransform: 'capitalize',
                    minWidth: '150px'
                }}
                onClick={ () => { props.verifyOtp(otpValue) } }
                endIcon={
                    props.details.verifying && <CircularProgress 
                        color="light" 
                        size={ '0.875rem' }
                    />
                }
            >
                verify otp
            </Button>
        </Box>
    )
})

export default HandleOtp;