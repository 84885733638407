import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Chip,
} from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import IdToDate from "../../common/date/IdToDate";

export default function ReportsTableComponent({ reports, loading }) {
  const handleButtonClick = (report) => {
    let url = `/reports/${report.name}/${report._id}`;
    window.location.href = url;
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: 6,
        marginLeft: 3,
        alignContent: "center",
        width: "90vw",
      }}
    >
      <Table
        // sx={{ marginTop: 6, textAlign: "center" }}
        aria-label="simple table"
        // size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              #
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Report Name
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Platform
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Date
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: "bold" }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            reports.map((report, index) => (
              <TableRow key={index} sx={{ padding: "5px" }}>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {index + 1}
                </TableCell>
                <TableCell align="center">{report.name}</TableCell>
                <TableCell align="center">{report?.platform?.name}</TableCell>
                <TableCell align="center">
                  {<IdToDate id={report._id} />}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    onClick={() => handleButtonClick(report)}
                  >
                    <RemoveRedEye />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
