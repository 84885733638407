import * as React from 'react';
import { Typography } from '@mui/material';

function ResetPasswordIntro(){
    return(
        <>
            <Typography 
                variant='h6'
                marginBottom={ 1.5 }
            >
                Forgot your Password?
            </Typography>
            <Typography>
                Enter the e-mail address that you used during signup and we will send you the
                instructions to reset your password
            </Typography>
        </>
    )
}

export default ResetPasswordIntro;