import * as React from 'react';
import { Typography , Stack, Grid } from '@mui/material';

function ShippingPolicy(){
    return (
        <>
            <Stack
                sx={{ 
                    minHeight: '15rem',
                    background: '#f5f7fa'
                }}
                justifyContent={ 'center' }
                alignItems={ 'center' }
            >
                <Typography
                    variant='h5'
                    fontWeight={ 600 }
                >
                    Shipping and Delivery Policy
                </Typography>

                <Typography
                    variant='p'
                    fontSize={ '0.95rem' }
                    sx={{ opacity: 0.7, letterSpacing: '0.5px' }}
                >
                    Last updated : October 2022
                </Typography>
            </Stack>
            
            <Grid
                container
                justifyContent={ 'center' }
                alignItems={ 'center' }
                paddingTop={7.5}
                paddingBottom={33}
            >
                <Grid 
                    item
                    md={5}
                    xs={12}
                >
                    <Typography
                        textAlign={'center'}
                        fontSize={ '1rem' }
                        lineHeight={ '1.6rem' }
                    >
                        Shipping is not applicable for business.
                        <br/>
                        Once credits are bought by User they are shown directly in your dashboard page.
                        <br/>
                        For each contact details viewed one credit will be charged from User account.
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default ShippingPolicy;