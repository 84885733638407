import React from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ArrowDownward, Download } from "@mui/icons-material";

export default function FinanceVendorTable({ fontSize, fontStyle, vendors, handleDownload, handleVendorShow }) {
  return (
    // <Card>
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontStyle }}>#</TableCell>
            <TableCell sx={{ fontStyle }}>Vendor</TableCell>
            <TableCell sx={{ fontStyle }}>Count</TableCell>
            <TableCell sx={{ fontStyle }}>Category</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vendors &&
            vendors.map((vendor, index) => (
              <TableRow key={vendor._id} id={`id_${index}_vendor`}>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleVendorShow(`id_${index}_vendor`, vendor.name[0], vendor._id)}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleVendorShow(`id_${index}_vendor`, vendor.name[0], vendor._id)}>{vendor.name[0]}</TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleVendorShow(`id_${index}_vendor`, vendor.name[0], vendor._id)}>{vendor.count}</TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }} onClick={() => handleVendorShow(`id_${index}_vendor`, vendor.name[0], vendor._id)}>{vendor.companyCategory[0]}</TableCell>
                <TableCell sx={{ ...fontSize, cursor: 'pointer' }}>
                  <Tooltip title='Download'>
                    <ArrowDownward fontSize="small" color="info" onClick={() =>
                      handleDownload(vendor._id, vendor.campaignName[0], false)} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    // </Card>
  );
}
