import React, { useEffect, useState } from "react";
import { CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import Details from "../components/campaigns/Details";
import DashboardHeaderSection from "../components/common/dashboardheader/DashboardHeader";
import Swal from "sweetalert2";
import paymentdashboardservice from "../services/paymentdashboardservice";

export default function CampaignDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const campaignDetails = location.state;
  const [campaignInfo, setCampaignInfo] = useState([]);

  const handleLinkGenerate = (campaign, isDetail) => {
    if (campaign.paymentLimit === "0" && isDetail === false) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Payment Limit Exceeded",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    navigate("/payments/paymentlink", {
      state: {
        id: campaign._id,
        name: campaign.name,
        category: campaign.category[0].name,
        deliverables: campaign.deliverables,
        platform: campaign.platform[0].name,
        totalPayment: campaign.totalPayment,
        paymentLimit: campaign.paymentLimit,
        createdby: campaign.createdby.fullName,
      },
    });
  };

  const getInit = async () => {
    const campaign = await paymentdashboardservice.CampaignDetailStatus({
      id: campaignDetails.id,
    });
    setCampaignInfo(campaign);
  };

  useEffect(() => {
    getInit();
  }, []);
  
  return (
    <>
      <CssBaseline />
      <DashboardHeaderSection />
      <Details
        campaignDetails={campaignDetails}
        handleLinkGenerate={handleLinkGenerate}
        campaignInfo={campaignInfo}
      />
    </>
  );
}
