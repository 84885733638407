import * as React from 'react';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, FormControl, OutlinedInput, Typography, InputAdornment, IconButton, Stack, FormHelperText } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import usersEndpoints from '../../../services/userServices';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChangePasswordComponent(){
    const [ passwordType, setPasswordType ] = useState('text');
    const [ passwordConditionsShow, setPasswordConditionsShow ] = useState(false);
    const [ passwordConditionVerify, setPasswordConditionsVerify ] = useState({});

    const [ oldPassword, setOldPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ errorMessage, setErrorMessage ] = useState({});

    const [ cookies, removeCookie ] = useCookies([ "user" ]);
    const navigate = useNavigate();

    useEffect(() => {
        let conditions = {
            lengthCondition: false,
            lowercase: false,
            uppercase: false,
            numeric: false
        };
        setPasswordConditionsVerify(conditions);

        let errors = {
            'oldpassword': '',
            'newpassword': '',
            'confirmpassword': ''
        };
        setErrorMessage(errors);
    }, []);

    const handleChangePasswordType = () => {
        let type_attr = '';
        if(passwordType === 'password'){
            type_attr = 'text';
        }
        else{
            type_attr = 'password';
        }
        setPasswordType(type_attr);
    };

    const showPasswordConditions = () => {
        setPasswordConditionsShow(true);
    };

    const hidePasswordConditions = () => {
        setPasswordConditionsShow(false);
    };

    const handleNewPasswordEntry = (event) => {
        let new_password = event.target.value;
        let updated_value = Object.assign({}, passwordConditionVerify);
        if(new_password.length >= 8){
            updated_value.lengthCondition = true;
        }
        else{
            updated_value.lengthCondition = false;
        }

        let checkLowercase = new_password.match(/[a-z]/);
        if(checkLowercase !== null){
            updated_value.lowercase = true;
        }
        else{
            updated_value.lowercase = false;
        }

        let checkUppercase = new_password.match(/[A-Z]/);
        if(checkUppercase !== null){
            updated_value.uppercase = true;
        }
        else{
            updated_value.uppercase = false;
        }

        let checkNumber = new_password.match(/[0-9]/);
        if(checkNumber !== null){
            updated_value.numeric = true;
        }
        else{
            updated_value.numeric = false;
        }
        setPasswordConditionsVerify(updated_value);
        
        let errorMessages = {
            oldpassword: '',
            newpassword: '',
            confirmpassword: ''
        };
        
        if(updated_value.lengthCondition === true && updated_value.lowercase === true && updated_value.uppercase === true && updated_value.numeric === true){
            setErrorMessage(errorMessages);
            setNewPassword(new_password);
        }
        else{
            errorMessages.newpassword = 'Password should contain atleast 8 characters and should have a lowercase, an uppercase and a digit';
            setErrorMessage(errorMessages);
        }
    };

    const handleOldPasswordChange = (event) => {
        let old_password = event.target.value;
        let errorMessages = {
            oldpassword: '',
            newpassword: '',
            confirmpassword: ''
        };

        if(old_password.length < 8){
            errorMessages.oldpassword = 'Password should contain atleast 8 characters';
            setErrorMessage(errorMessages);
        }
        else if(old_password.match(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])')) === null){
            errorMessages.oldpassword = 'Password should contain atleast 1 lowercase, 1 uppercase and 1 digit';
            setErrorMessage(errorMessages);
        }
        else{
            setErrorMessage(errorMessages);
            setOldPassword(old_password);
        }
    }

    const handleConfirmPasswordChange = (event) => {
        let confirm_password = event.target.value;
        let errorMessages = {
            oldpassword: '',
            newpassword: '',
            confirmpassword: ''
        };

        if(confirm_password !== newPassword){
            errorMessages.confirmpassword = 'Confirm password should match the new password';
            setErrorMessage(errorMessages);
        }
        else{
            setErrorMessage(errorMessages);
            setConfirmPassword(confirm_password);
        }
    }

    const changePassword = async (event) => {
        let postdata = {
            current_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
            user: cookies.user ? cookies.user._id : ''
        };

        const updatePassword = await usersEndpoints.changeUserPassword(postdata);
        if(updatePassword['success'] === true){
            removeCookie("user");
            navigate("/authenticate/signin");
        }
        else{
            // show error alert and reset form values
            // alert(updatePassword['message']);
            toast.error(updatePassword['message']);
        }
    };

    return(
        <>
            {/* <Box 
                component={ "div" }
                sx={{ marginBottom: 2 }}
            >
                <Typography
                    variant={ "h6" }
                    sx={{ fontWeight: '600' }}
                >
                    Change password
                </Typography>
            </Box> */}
            <Grid container spacing={ 2 } paddingY={2}>
                <Grid item xs={ 12 }>
                    <FormControl 
                        variant="outlined"
                        fullWidth
                    >
                        <OutlinedInput
                            placeholder="Current password"
                            onChange={ handleOldPasswordChange }
                        />
                        <FormHelperText error>{ errorMessage.oldpassword }</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={ 12 }>
                    <FormControl 
                        variant="outlined"
                        fullWidth
                    >
                        <OutlinedInput
                            placeholder="New password"
                            onFocus={ showPasswordConditions }
                            onBlur={ hidePasswordConditions }
                            onChange={ handleNewPasswordEntry }
                        />
                        <FormHelperText error>{ errorMessage.newpassword }</FormHelperText>
                    </FormControl>
                </Grid>

                <Stack 
                    direction={ 'row' }
                    spacing={ 3 }
                    sx={{ 
                        paddingLeft: 3, 
                        paddingTop: 2,
                        display: passwordConditionsShow === true ? 'flex' : 'none'
                    }}
                >
                    <Stack>
                        <Typography 
                            fontSize={ "small" }
                            sx={{ 
                                textDecoration: passwordConditionVerify['numeric'] === true ? 'line-through' : 'none' 
                            }}
                        >
                            One number
                        </Typography>
                        <Typography 
                            fontSize={ "small" }
                            sx={{ 
                                textDecoration: passwordConditionVerify['lengthCondition'] === true ? 'line-through' : 'none' 
                            }}
                        >
                            8 characters minimum
                        </Typography>
                    </Stack>

                    <Stack>
                        <Typography 
                            fontSize={ "small" }
                            sx={{ 
                                textDecoration: passwordConditionVerify['lowercase'] === true ? 'line-through' : 'none' 
                            }}
                        >
                            One lowercase letter
                        </Typography>
                        <Typography 
                            fontSize={ "small" }
                            sx={{ 
                                textDecoration: passwordConditionVerify['uppercase'] === true ? 'line-through' : 'none' 
                            }}
                        >
                            One uppercase letter
                        </Typography>
                    </Stack>
                </Stack>

                <Grid item xs={ 12 }>
                    <FormControl 
                        variant="outlined"
                        fullWidth
                    >
                        <OutlinedInput
                            placeholder="Confirm password"
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={ handleChangePasswordType }
                                    >
                                        { passwordType === 'password' ? <RemoveRedEyeOutlinedIcon/> : <VisibilityOffOutlinedIcon /> }
                                    </IconButton>
                                </InputAdornment>
                            }
                            type={ passwordType }
                            onChange={ handleConfirmPasswordChange }
                        />
                        <FormHelperText error>{ errorMessage.confirmpassword }</FormHelperText>
                    </FormControl>
                </Grid>
                
                <Grid item xs={ 12 } md={ 4 }>
                    <Button
                        variant={ 'contained' }
                        color={ 'primary' }
                        fullWidth
                        onClick={ changePassword }
                    >
                        Change Password
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default ChangePasswordComponent;