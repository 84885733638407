import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export default function VendorForm({ campaignDetails, vendors, styles }) {
  return (
    <>
      <Card
        sx={styles}
      >
        <CardContent>
          <Stack spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <Autocomplete
                disablePortal
                id="brand-dropdown"
                options={vendors}
                sx={{ width: "50%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Vendor*" />
                )}
              />
              <TextField
                id="outlined-basic"
                size="medium"
                label="Campaign*"
                value={campaignDetails.name}
                variant="outlined"
                sx={{ width: "50%" }}
                disabled
              />
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                id="outlined-multiline-static"
                label="Description*"
                multiline
                rows={4}
                sx={{ width: "100%" }}
              />
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <TextField
                id="outlined-basic"
                size="medium"
                label="Type of Expenses (If Applicable)"
                variant="outlined"
                sx={{ width: "100%" }}
              />
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Checkbox size="small" />
              <Typography variant="subtitle1">GST (If Applicable)</Typography>
            </Box>
            <Stack direction={"row"} spacing={2}>
              <TextField
                id="outlined-basic"
                size="medium"
                label="Total Amount*"
                variant="outlined"
                sx={{ width: "30%" }}
              />
              <TextField
                id="outlined-basic"
                size="medium"
                label="GST Amount"
                variant="outlined"
                sx={{ width: "30%" }}
              />
              <TextField
                id="outlined-basic"
                size="medium"
                label="Payable Amount*"
                variant="outlined"
                sx={{ width: "30%" }}
              />
              <FormControl sx={{ width: "10%" }}>
                <InputLabel id="demo-simple-select-label">%</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Percentage"
                >
                  <MenuItem value={1}>1%</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Box sx={{ marginTop: 2, textAlign: "center" }}>
              <Button variant="outlined">Submit</Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
