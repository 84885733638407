import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { CSVDownload } from "react-csv";
import DashboardHeaderSection from "../common/dashboardheader/DashboardHeader";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Menu,
  Chip,
  Pagination,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Switch,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import ContactsIcon from "@mui/icons-material/Contacts";
import MuiTableSkeleton from "../skeleton/MuiTableSkeleton";
import pluginrequests from "../../services/pluginrequests";
import usersEndpoints from "../../services/userServices";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import "./css/dashboard.css";
import {
  ConnectWithoutContact,
  ContactPhone,
  ExpandMore,
  Lock,
  QueryStats,
} from "@mui/icons-material";
import ReactGA from "react-ga4";
import TableComponent from "./common/tableComponent";
import ShowFilters from "./common/showFilters";
import {
  useFetchInfluencers,
  useFetchInfluencersCount,
} from "../../react-query/hooks/useFetchInfluencers";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const InfluencerFilters = () => {
  const [cookies, setCookie] = useCookies(["logindata"]);
  const inputRef = useRef(null);
  const [credits, setCredits] = useState(true);
  const [isChecked, setChecked] = useState(false);
  const [isContacts, setContacts] = useState(false);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [updateDashboardCredits, setUpdateDashboardCredits] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [username, setUsername] = useState([]);
  const [userData, setUserData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [requestNumbers, setRequestNumbers] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState(0);
  const [selectCheckbox, setSelectCheckbox] = useState(0);
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [minRange, setMinRange] = useState("");
  const [maxRange, setMaxRange] = useState("");
  const [minAgeRange, setMinAgeRange] = useState("");
  const [maxAgeRange, setMaxAgeRange] = useState("");
  const [minReelCostRange, setMinReelCostRange] = useState("");
  const [maxReelCostRange, setMaxReelCostRange] = useState("");
  const [tableSort, setTableSort] = useState({
    age: null,
    followers: null,
    gender: null,
  });
  const [followers, setFollowers] = useState({
    range: "",
    isCustom: false,
  });
  const [age, setAge] = useState({
    agerange: "",
  });
  const [reelCost, setReelCost] = useState({
    reelcostrange: "",
  });
  let [filters, setFilters] = useState({
    gender: null,
    platform: null,
    location: null,
    followers: null,
    age: null,
    reelCost: null,
    search: null,
    page: null,
    pageSize: null,
    topYoutubers: null,
    getNumbers: null,
  });

  const ANALYTICS_CATEGORIES = {
    FILTER: "Creator Filter",
    EXPORT: "Export",
    VIEW_CONTACT: "View Contact",
    VIEW_EMAIL: "View Email",
    REQUEST_CONTACT: "Request Contact",
  };

  const ANALYTICS_ACTIONS = {
    GENDER: "Gender",
    LOCATION: "Location",
    FOLLOWERS: "Followers",
    AGE: "Age",
    REEL_COST: "Reel Cost",
    PLATFORM: "Platform",
    SEARCH_TERM: "Keyword",
    REPORT_EXPORT: "List Export",
    CREATOR_NAME: "Creator Name",
    ONLY_NUMBER: "Phone Available",
    TOP_CREATORS_YT: "Top Creators Youtube",
    TOP_CREATORS_IN: "Top Creators Insta",
  };

  const handleContactRequestButton = async (name) => {
    ReactGA.event({
      category: ANALYTICS_CATEGORIES.REQUEST_CONTACT,
      action: ANALYTICS_ACTIONS.CREATOR_NAME,
      label: name,
    });

    const response = await pluginrequests.requestContactNumber({
      handle: `${name}` + ":",
    });

    if (response["success"]) {
      setUsername([...username, name]);
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: `${response["message"]}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response["message"],
      });
    }
  };

  const handleContactButton = async (influencerId, username) => {
    const foundNumber = numbers.find((list) => list.username === username);
    ReactGA.event({
      category: ANALYTICS_CATEGORIES.VIEW_CONTACT,
      action: ANALYTICS_ACTIONS.CREATOR_NAME,
      label: username,
    });

    if (foundNumber?.number) {
      Swal.fire(foundNumber.number);
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `${username} 1 credit burn to view the number`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await pluginrequests.getContactNumber({
            uniqueId: influencerId,
            handle: username,
          });
          if (response["success"]) {
            Swal.fire(
              "Done!",
              `1 credit burned for this username ${username}`,
              "success"
            );
            setUpdateDashboardCredits(true);
            setCookie("logindata");
            setUpdateDashboardCredits(false);
            Swal.fire(response.data.phone);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response["message"],
            });
          }
        }
      });
    }
  };

  const handleEmailButton = async (email, username) => {
    ReactGA.event({
      category: ANALYTICS_CATEGORIES.VIEW_EMAIL,
      action: ANALYTICS_ACTIONS.CREATOR_NAME,
      label: username,
    });

    Swal.fire({
      title: "Are you sure?",
      text: `${username} 1 credit burn to view the email`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Done!",
          `1 credit burned for this username ${username}`,
          "success"
        );
        Swal.fire(email);
      }
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleUserResponse = async () => {
    const result = await usersEndpoints.getUserDetails();
    setUserData(result);

    if (result && result.user && result.user.email) {
      ReactGA.set({
        user_email: result.user.fullName,
      });
    }
  };

  const handleCheckboxClick = async (event, value, id) => {
    setChecked(!isChecked);
    let foundNumber;

    if (filters.platform === "Youtube")
      foundNumber = numbers.find(
        (list) => list.youtubeData.channel_id === value
      );
    else foundNumber = numbers.find((list) => list.username === value);

    if (
      selectCheckbox === userData.user.balance &&
      !foundNumber?.number &&
      event.target.checked
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You don't have enough credits to select more rows.",
      });
      let checkbox = document.getElementById(id);
      checkbox.checked = false;
      return;
    }

    if (event.target.checked && !foundNumber?.number)
      setSelectCheckbox(selectCheckbox + 1);
    else if (!event.target.checked && !foundNumber?.number)
      setSelectCheckbox(selectCheckbox - 1);

    if (selectedRows === 25 && event.target.checked) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your limit is 25",
      });
      let checkbox = document.getElementById(id);
      checkbox.checked = false;
      return;
    }

    if (
      selectedRows < 26 &&
      event.target.checked &&
      !exportList.includes(value)
    ) {
      setSelectedRows(selectedRows + 1);
      setExportList([...exportList, value]);
    } else if (!event.target.checked) {
      setSelectedRows(selectedRows - 1);
      const newList = exportList.filter((item) => item !== value);
      setExportList(newList);
    }
  };

  const headers = [
    { label: "Username", key: "username" },
    { label: "Gender", key: "gender" },
    { label: "Email", key: "email" },
    { label: "Followers", key: "instaFollowers" },
    { label: "Bio", key: "biography" },
    { label: "Location", key: "state" },
    { label: "Category", key: "categoryName" },
    { label: "Contact", key: "number" },
  ];

  const youtubeHeaders = [
    { label: "Channel Name", key: "youtubeChannel" },
    { label: "Channel Url", key: "url" },
    { label: "Channel Id", key: "channelId" },
    { label: "Gender", key: "gender" },
    { label: "Email", key: "email" },
    { label: "Subscribers", key: "youtubeSubscribers" },
    { label: "About", key: "youtubeDescription" },
    { label: "Location", key: "state" },
    { label: "Category", key: "categoryName" },
    { label: "Contact", key: "number" },
  ];

  const handleExportClick = async () => {
    const response = await usersEndpoints.FetchExportCSVData({
      list: exportList,
      platform: filters.platform,
    });

    if (response["success"]) {
      setUpdateDashboardCredits(!updateDashboardCredits);
      if (response.balance <= 0) setCredits(false);
      setExportData(response.data);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response["message"],
      });
    }
  };

  const handleFilters = async (filterValue, filterType) => {
    let value = filterValue;
    if (filterType === "followers") {
      if (minRange || maxRange) {
        value = {
          range: `${minRange}-${maxRange}`,
          isCustom: true,
        };

        ReactGA.event({
          category: ANALYTICS_CATEGORIES.FILTER,
          action: ANALYTICS_ACTIONS.FOLLOWERS,
          label: value.range,
          value: 2,
        });
      } else {
        value = followers;
      }
    }

    if (filterType === "age") {
      if (minAgeRange || maxAgeRange) {
        value = {
          agerange: `${minAgeRange}-${maxAgeRange}`,
        };

        ReactGA.event({
          category: ANALYTICS_CATEGORIES.FILTER,
          action: ANALYTICS_ACTIONS.AGE,
          label: value.agerange,
          value: 2,
        });
      } else {
        value = age;
      }
    }

    if (filterType === "reelCost") {
      if (minReelCostRange || maxReelCostRange) {
        value = {
          reelcostrange: `${minReelCostRange}-${maxReelCostRange}`,
        };

        ReactGA.event({
          category: ANALYTICS_CATEGORIES.FILTER,
          action: ANALYTICS_ACTIONS.REEL_COST,
          label: value.reelcostrange,
          value: 2,
        });
      } else {
        value = reelCost;
      }
    }

    if (filterType === "location") {
      ReactGA.event({
        category: ANALYTICS_CATEGORIES.FILTER,
        action: ANALYTICS_ACTIONS.LOCATION,
        label: filterValue,
        value: 1,
      });
    }

    if (filterType === "gender") {
      ReactGA.event({
        category: ANALYTICS_CATEGORIES.FILTER,
        action: ANALYTICS_ACTIONS.GENDER,
        label: filterValue,
        value: 1,
      });
    }

    if (filterType === "platform") {
      ReactGA.event({
        category: ANALYTICS_CATEGORIES.FILTER,
        action: ANALYTICS_ACTIONS.PLATFORM,
        label: filterValue,
        value: 1,
      });
    }

    if (filterType === "topYoutubers") {
      ReactGA.event({
        category: ANALYTICS_CATEGORIES.FILTER,
        action: ANALYTICS_ACTIONS.TOP_CREATORS_YT,
        label: filterValue,
        value: 1,
      });
    }

    if (filterType === "getNumbers" && !isContacts) {
      setContacts(true);
    } else if (filterType === "getNumbers" && isContacts) setContacts(false);

    setFilters((prevArray) => ({
      ...prevArray,
      [filterType]: value,
    }));
  };

  const handleChange = (e) => {
    setMinRange("");
    setMaxRange("");
    let { name, value } = e.target;

    // value: 1 indicate that the filter is changed from shortcut options available
    // isCustom: false
    ReactGA.event({
      category: ANALYTICS_CATEGORIES.FILTER,
      action: ANALYTICS_ACTIONS.FOLLOWERS,
      label: e.target.value,
      value: 1,
    });

    setFollowers((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAgeRangeChange = (e) => {
    // value: 1 indicate that the filter is changed from shortcut options available
    // isCustom: false
    ReactGA.event({
      category: ANALYTICS_CATEGORIES.FILTER,
      action: ANALYTICS_ACTIONS.AGE,
      label: e.target.value,
      value: 1,
    });

    setMinAgeRange("");
    setMaxAgeRange("");

    let { name, value } = e.target;

    setAge((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReelCostRangeChange = (e) => {
    setMinReelCostRange("");
    setMaxReelCostRange("");
    // value: 1 indicate that the filter is changed from shortcut options available
    // isCustom: false
    ReactGA.event({
      category: ANALYTICS_CATEGORIES.FILTER,
      action: ANALYTICS_ACTIONS.REEL_COST,
      label: e.target.value,
      value: 1,
    });

    let { name, value } = e.target;
    setReelCost((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFollowersChange = (e) => {
    let { name, value } = e.target;

    if (name === "minrange") {
      setMinRange(value);
    } else {
      setMaxRange(value);
    }
  };

  const handleAgeChange = (e) => {
    let { name, value } = e.target;

    if (name === "minAgeRange") {
      setMinAgeRange(value);
    } else {
      setMaxAgeRange(value);
    }
  };

  const handleReelCostChange = (e) => {
    let { name, value } = e.target;

    if (name === "minReelCostRange") {
      setMinReelCostRange(value);
    } else {
      setMaxReelCostRange(value);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (event.target.value.charAt(0) !== "@") {
        // value: 2 indicates that the keyword search is done using biography
        ReactGA.event({
          category: ANALYTICS_CATEGORIES.FILTER,
          action: ANALYTICS_ACTIONS.SEARCH_TERM,
          label: event.target.value,
          value: 2,
        });

        setFilters((prevState) => ({
          ...prevState,
          search: filters.search
            ? [...filters.search, event.target.value]
            : [event.target.value],
        }));
      } else {
        // value: 1 indicates that the keyword search is done by prefixing @username
        ReactGA.event({
          category: ANALYTICS_CATEGORIES.FILTER,
          action: ANALYTICS_ACTIONS.SEARCH_TERM,
          label: event.target.value,
          value: 1,
        });

        setFilters((prevState) => ({
          ...prevState,
          search: [event.target.value],
        }));
      }
    }
  };

  const handleDelete = async (filter, index) => {
    if (filter === "gender") {
      setGender("");
    }

    if (inputRef.current) {
      inputRef.current.value = "";
    }

    if (filter === "search" && Array.isArray(filters[filter])) {
      // Make a copy of the current search array
      const updatedSearchArray = [...filters[filter]];

      // Remove the item at the specified index
      updatedSearchArray.splice(index, 1);

      // Update the state with the modified search array
      setFilters((prevState) => ({
        ...prevState,
        [filter]: updatedSearchArray.length === 0 ? "" : updatedSearchArray,
      }));
    } else {
      // For other filter categories, set the filter to an empty string
      setFilters((prevState) => ({
        ...prevState,
        [filter]: "",
      }));
    }
  };

  const handleClearFilters = async () => {
    window.location.reload();
  };

  const ageFormatter = (value) => {
    const ageYear = `${value ? Number(value.split("-")[0]) : "NA"}`;
    const currentYear = new Date().getFullYear();
    const bornAge = currentYear - ageYear;
    const result = bornAge >= 15 && bornAge <= 80 ? bornAge : "NA";
    return result;
  };

  const categoryFormatter = (value) => {
    let html = null;
    if (value && value.length) {
      html = value
        .split(",")
        .map((c) => (
          <Chip variant="elevated" label={c} sx={{ m: 0.5 }} size="small" />
        ));
    }

    return html ? html[0] : "";
  };

  const contactFormatter = (id, isNumber, name) => {
    const foundRequest =
      requestNumbers &&
      requestNumbers.influencer_handle &&
      requestNumbers.influencer_handle.find(
        (list) => list.split(":")[0] === name
      );

    const buttonCss = {
      textTransform: "capitalize",
      verticalAlign: "middle",
      margin: "7px 0",
      fontSize: "10px",
      fontWeight: "bold",
    };

    return (
      <>
        {isNumber && !numbers.find((list) => list.username === name) ? (
          <Button
            variant="outlined"
            size="small"
            sx={buttonCss}
            onClick={() => handleContactButton(id, name)}
          >
            Show Details <Lock sx={{ fontSize: "10px" }} />
          </Button>
        ) : (!isNumber && foundRequest) ||
          username.find((list) => list === name) ? (
          <Button variant="contained" size="small" sx={buttonCss} disabled>
            Requested
          </Button>
        ) : !isNumber && !foundRequest ? (
          <Button
            size="small"
            sx={buttonCss}
            onClick={() => handleContactRequestButton(name)}
          >
            Request Contact
          </Button>
        ) : (
          <Button
            variant="contained"
            size="small"
            sx={buttonCss}
            onClick={() => handleContactButton(id, name)}
          >
            Show Details
          </Button>
        )}
      </>
    );
  };

  const Init = async () => {
    const responseNumbers = await usersEndpoints.viewedInfluencersList();
    const requestResponse =
      await pluginrequests.viewRequestedInfluencersNumbers();
    setNumbers(responseNumbers["data"]);
    setRequestNumbers(requestResponse["data"]);
  };

  useEffect(() => {
    handleUserResponse();
  }, [isChecked]);

  useEffect(() => {
    if (exportData.length) {
      setInitiateDownload(true);
      ReactGA.event({
        category: ANALYTICS_CATEGORIES.EXPORT,
        action: ANALYTICS_ACTIONS.REPORT_EXPORT,
        label: `${exportData.length} creators`,
      });
    }
  }, [exportData]);

  useEffect(() => {
    // Enables user to download data more than once
    if (initiateDownload) {
      setInitiateDownload(false);
    }
  }, [initiateDownload]);

  useEffect(() => {
    Init();
  }, []);

  useEffect(() => {
    if (!isContacts) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        getNumbers: null,
      }));
    }
  }, [isContacts]);

  const {
    isError,
    data: profiles,
    isLoading: profilesLoading,
    isFetching,
  } = useFetchInfluencers(filters, tableSort, page, isContacts);

  const {
    data: count,
    error,
    isLoading: countLoading,
  } = useFetchInfluencersCount(filters, isContacts);

  const handleSort = (path) => {
    setTableSort((prevArray) => ({
      ...prevArray,
      [path]: tableSort[path] === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <>
      <DashboardHeaderSection
        inputRef={inputRef}
        isSearchOpen={false}
        updateCredits={updateDashboardCredits}
        handleEnterKeyPress={handleEnterKeyPress}
        isYoutube={filters.platform === "Youtube" ? true : false}
      />
      <Box
        width={{ xs: "100%", lg: `calc(100% - 80px)` }}
        marginLeft={{ xs: "0px", lg: `80px` }}
        marginTop={8}
        paddingTop={1}
        sx={{ backgroundColor: "#F0F2F5" }}
      >
        <Grid container>
          <Grid
            item
            lg={3}
            sx={{
              paddingX: 2,
            }}
          >
            <Box
              sx={{
                overflowY: "auto",
                height: "85vh",
                position: "-webkit-sticky",
                position: "sticky",
                top: "73px",
              }}
              className="filterScroll"
            >
              <Box bgcolor={"white"} sx={{ borderRadius: 1.5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 2,
                  }}
                >
                  <Typography variant="button" sx={{ fontWeight: "bold" }}>
                    Filters
                  </Typography>
                  <Typography
                    variant="button"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={handleClearFilters}
                  >
                    Clear All
                  </Typography>
                </Box>
                <ShowFilters filters={filters} handleDelete={handleDelete} />
              </Box>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Gender</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ minWidth: 80 }}>
                    <FormControl>
                      <RadioGroup name="gender">
                        <FormControlLabel
                          value="F"
                          onClick={() => handleFilters("F", "gender")}
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="M"
                          onClick={() => handleFilters("M", "gender")}
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="O"
                          onClick={() => handleFilters("O", "gender")}
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Location</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={"800"}
                      sx={{ fontSize: "0.7rem" }}
                    >
                      State / City
                    </Typography>
                    <Box marginTop={1}>
                      <TextField
                        id="outlined-basic"
                        label="Location"
                        variant="outlined"
                        size="small"
                        name="location"
                        onChange={(e) => setLocation(e.target.value)}
                      />
                      &nbsp;
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "0.7rem",
                          paddingY: 1,
                          textTransform: "capitalize",
                        }}
                        onClick={() => handleFilters(location, "location")}
                      >
                        Apply
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Followers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item width={145}>
                        <TextField
                          id="outlined-basic"
                          label="Min"
                          variant="outlined"
                          size="small"
                          name="minrange"
                          onChange={handleFollowersChange}
                        />
                      </Grid>
                      <Grid item width={145}>
                        <TextField
                          id="outlined-basic"
                          label="Max"
                          variant="outlined"
                          size="small"
                          name="maxrange"
                          onChange={handleFollowersChange}
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      variant="subtitle2"
                      fontWeight={"800"}
                      marginTop={2}
                      sx={{ fontSize: "12px" }}
                    >
                      Select By Influencer Size
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="influencerSize"
                        name="range"
                        onChange={handleChange}
                      >
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="2000-10000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Nano
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            2K-10K followers
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="10000-25000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Micro
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            10K-25K followers
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="25000-100000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Mid Tier
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            25K-100K followers
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="100000-250000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Macro
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            100K-250K followers
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="250000-1000000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Mega
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            250K-1M followers
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="1000000-10000000000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                A-Listers
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            1M+ followers
                          </Typography>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box
                    padding={1}
                    width={290}
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        paddingY: 0.9,
                        textTransform: "capitalize",
                        fontSize: "11px",
                      }}
                      onClick={() => handleFilters(followers, "followers")}
                    >
                      Apply
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Age</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item width={145}>
                        <TextField
                          id="outlined-basic"
                          label="Min Age"
                          variant="outlined"
                          size="small"
                          name="minAgeRange"
                          onChange={handleAgeChange}
                        />
                      </Grid>
                      <Grid item width={145}>
                        <TextField
                          id="outlined-basic"
                          label="Max Age"
                          variant="outlined"
                          size="small"
                          name="maxAgeRange"
                          onChange={handleAgeChange}
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      variant="subtitle2"
                      fontWeight={"800"}
                      marginTop={2}
                      sx={{ fontSize: "12px" }}
                    >
                      Select By Given Range
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="ageSize"
                        name="agerange"
                        onChange={handleAgeRangeChange}
                      >
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="18-30"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                18-30
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value="31-35"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                31-35
                              </Typography>
                            }
                          />
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="36-40"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                36-40
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value="41-45"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                41-45
                              </Typography>
                            }
                          />
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="46-50"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                46-50
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value="51-55"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                51-55
                              </Typography>
                            }
                          />
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="56-60"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                56-60
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value="61-65"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                61-65
                              </Typography>
                            }
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box
                    padding={1}
                    width={290}
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        paddingY: 0.9,
                        textTransform: "capitalize",
                        fontSize: "11px",
                      }}
                      onClick={() => handleFilters(age, "age")}
                    >
                      Apply
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Reel Cost</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item width={145}>
                        <TextField
                          id="outlined-basic"
                          label="Min Reel Cost"
                          variant="outlined"
                          size="small"
                          name="minReelCostRange"
                          onChange={handleReelCostChange}
                        />
                      </Grid>
                      <Grid item width={145}>
                        <TextField
                          id="outlined-basic"
                          label="Max Reel Cost"
                          variant="outlined"
                          size="small"
                          name="maxReelCostRange"
                          onChange={handleReelCostChange}
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      variant="subtitle2"
                      fontWeight={"800"}
                      marginTop={2}
                      sx={{ fontSize: "12px" }}
                    >
                      Select By Given Range
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="reelCostSize"
                        name="reelcostrange"
                        onChange={handleReelCostRangeChange}
                      >
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="1-1000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Nano
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            Barter / 1000
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="1000-5000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Micro
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            1000 - 5000
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="5000-15000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Mid Tier
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            5000 - 15000
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="15000-25000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Macro
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            15000 - 25000
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="30000-70000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                Mega
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            30000 - 70000
                          </Typography>
                        </Box>
                        <Box
                          width={290}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="10000-70000"
                            label={
                              <Typography variant="body2" color="textSecondary">
                                A-Listers
                              </Typography>
                            }
                          />
                          <Typography variant="caption" alignSelf={"center"}>
                            10000 - 70000
                          </Typography>
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box
                    padding={1}
                    width={290}
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        paddingY: 0.9,
                        textTransform: "capitalize",
                        fontSize: "11px",
                      }}
                      onClick={() => handleFilters(reelCost, "reelCost")}
                    >
                      Apply
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Platform</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ minWidth: 85 }}>
                    <FormControl size="small">
                      <RadioGroup name="platform">
                        <FormControlLabel
                          value="Instagram"
                          onClick={() => handleFilters("Instagram", "platform")}
                          control={<Radio />}
                          label="Instagram"
                        />
                        <FormControlLabel
                          value="Youtube"
                          onClick={() => handleFilters("Youtube", "platform")}
                          control={<Radio />}
                          label="Youtube"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid item lg={9}>
            <Box
              marginRight={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
                backgroundColor: "white",
                padding: "10px",
                position: "fixed",
                width: "-webkit-fill-available",
                zIndex: 1,
                borderRadius: 1.5,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Chip
                  label={count?.data.count}
                  icon={countLoading ? <CircularProgress size={16} /> : null}
                  color={countLoading ? "default" : "primary"}
                />
                &nbsp;&nbsp;
                <FormGroup>
                  <FormControlLabel
                    control={<Switch />}
                    label="Only Contacts"
                    size="small"
                    onChange={(ev) => {
                      ReactGA.event({
                        category: ANALYTICS_CATEGORIES.FILTER,
                        action: ANALYTICS_ACTIONS.ONLY_NUMBER,
                        label:
                          ev.target.checked === true ? "Active" : "Inactive",
                      });

                      handleFilters("Only Contacts", "getNumbers");
                    }}
                  />
                </FormGroup>
              </Box>
              <div>
                {filters.platform === "Youtube" && (
                  <>
                    <FormControl sx={{ minWidth: 150 }} size="small">
                      <InputLabel id="demo-simple-select-label">
                        Top Youtubers
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Top Youtubers"
                        name="topYoutubers"
                        onChange={(e) =>
                          handleFilters(e.target.value, "topYoutubers")
                        }
                      >
                        <MenuItem value={"Top 50"}>
                          <QueryStats sx={{ fontSize: "16px" }} />
                          &nbsp;Top 50
                        </MenuItem>
                        <MenuItem value={"Top 100"}>
                          <QueryStats sx={{ fontSize: "16px" }} />
                          &nbsp;Top 100
                        </MenuItem>
                        <MenuItem value={"Top 500"}>
                          <QueryStats sx={{ fontSize: "16px" }} />
                          &nbsp;Top 500
                        </MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp;
                  </>
                )}
                <Button
                  variant="outlined"
                  onClick={handleExportClick}
                  disabled={exportList.length === 0}
                >
                  Export to CSV
                </Button>
                {initiateDownload && (
                  <CSVDownload
                    data={exportData}
                    headers={
                      filters.platform === "Youtube" ? youtubeHeaders : headers
                    }
                    target="_blank"
                    filename={"influencer.csv"}
                  />
                )}
              </div>
            </Box>
            <Box marginTop={7.5} marginRight={2}>
              {profilesLoading ? (
                <MuiTableSkeleton />
              ) : profiles?.data?.influencers.length > 0 ? (
                <TableComponent
                  credits={credits}
                  numbers={numbers}
                  filters={filters}
                  profiles={profiles?.data?.influencers}
                  ageFormatter={ageFormatter}
                  contactFormatter={contactFormatter}
                  categoryFormatter={categoryFormatter}
                  handleEmailButton={handleEmailButton}
                  handleCheckboxClick={handleCheckboxClick}
                  handleContactRequestButton={handleContactRequestButton}
                  exportList={exportList}
                  onSort={handleSort}
                  tableSort={tableSort}
                  userId={cookies.user._id}
                />
              ) : (
                <Box>No Data</Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#F0F2F5",
            padding: "15px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Pagination
            page={page}
            color="secondary"
            variant="outlined"
            onChange={handlePageChange}
            count={Math.ceil(count?.data.count / 100)}
            sx={{ paddingRight: "70px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default InfluencerFilters;
