import React from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ApprovalIcon from "@mui/icons-material/Approval";
import PanToolIcon from "@mui/icons-material/PanTool";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { DoneAll } from "@mui/icons-material";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function StatusCards({ campaignInfo }) {
  const navigate = useNavigate();

  return (
    <Grid item xs={6}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card
            sx={{
              padding: 2,
              boxShadow: 0,
              cursor: "pointer",
            }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {(() => {
                    const filteredCampaigns = campaignInfo.filter(
                      (campaign) => campaign._id === "P"
                    );
                    if (filteredCampaigns.length > 0) {
                      return filteredCampaigns[0].details.length;
                    } else {
                      return 0;
                    }
                  })()}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Pending
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <AutorenewIcon sx={{ cursor: "pointer", color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              padding: 2,
              boxShadow: 0,
              cursor: "pointer",
            }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {(() => {
                    const filteredCampaigns = campaignInfo.filter(
                      (campaign) => campaign._id === "A"
                    );
                    if (filteredCampaigns.length > 0) {
                      return filteredCampaigns[0].details.length;
                    } else {
                      return 0;
                    }
                  })()}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Applied
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <ApprovalIcon sx={{ cursor: "pointer", color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              padding: 2,
              boxShadow: 0,
              cursor: "pointer",
            }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {(() => {
                    const filteredCampaigns = campaignInfo.filter(
                      (campaign) => campaign._id === "W"
                    );
                    if (filteredCampaigns.length > 0) {
                      return filteredCampaigns[0].details.length;
                    } else {
                      return 0;
                    }
                  })()}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Waiting
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <PanToolIcon sx={{ cursor: "pointer", color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              padding: 2,
              boxShadow: 0,
              cursor: "pointer",
            }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {(() => {
                    const filteredCampaigns = campaignInfo.filter(
                      (campaign) => campaign._id === "S"
                    );
                    if (filteredCampaigns.length > 0) {
                      return filteredCampaigns[0].details.length;
                    } else {
                      return 0;
                    }
                  })()}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Success
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <DoneAll sx={{ cursor: "pointer", color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              padding: 2,
              boxShadow: 0,
              cursor: "pointer",
            }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {(() => {
                    const filteredCampaigns = campaignInfo.filter(
                      (campaign) => campaign._id === "F"
                    );
                    if (filteredCampaigns.length > 0) {
                      return filteredCampaigns[0].details.length;
                    } else {
                      return 0;
                    }
                  })()}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Failed
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <SmsFailedIcon sx={{ cursor: "pointer", color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            sx={{
              padding: 2,
              boxShadow: 0,
              cursor: "pointer",
            }}
            onClick={() => navigate("/payments/status")}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CardContent sx={{ padding: 1 }}>
                <Typography component="div" variant="h6">
                  {(() => {
                    const filteredCampaigns = campaignInfo.filter(
                      (campaign) => campaign._id === "R"
                    );
                    if (filteredCampaigns.length > 0) {
                      return filteredCampaigns[0].details.length;
                    } else {
                      return 0;
                    }
                  })()}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Rejected
                </Typography>
              </CardContent>
              <Box sx={{ alignSelf: "center" }}>
                <ThumbDownIcon sx={{ cursor: "pointer", color: "#F9B572" }} />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
