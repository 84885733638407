import * as React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import HeaderSection from '../components/common/header/Header';
import SubscribeThroughEmail from '../components/common/subscribe/SubscribeEmail';
import ContactDetail from '../components/contactus/ContactDetails';
import RefundPolicy from '../components/contactus/RefundPolicy';
import ShippingPolicy from '../components/contactus/ShippingPolicy';

function ContactSales(){
    const { type } = useParams();
    
    return (
        <Box>
            <HeaderSection showNav={true}/>
            
            { type === "contactus" && <ContactDetail/> }
            { type === "refund" && <RefundPolicy/> }    
            { type === "shipping" && <ShippingPolicy/> }
            
            <SubscribeThroughEmail fixedtobottom={ false }/>
        </Box>
    )
}

export default ContactSales;