import * as React from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Paper,
} from "@mui/material";

import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import SiddharthImage from "../../../siddharth.png";
import DeveshImage from "../../../devesh.png";
import YogeshImage from "../../../yogesh.png";
import RenishaImage from "../../../renisha.png";
import DummyImage from "../../../dummy.png";
import StarColor from "../../../Star_1.png";
import StarFade from "../../../Star_2.png";

function LandingTestimonials() {
  return (
    <>
      <Container
        maxWidth={"xl"}
        sx={{ paddingTop: 10, paddingBottom: 35, backgroundColor: "#F1F1F1" }}
        id="testimonials_container"
      >
        <Box maxWidth={"xl"} sx={{ marginX: "auto" }}>
          <Typography
            variant={"h4"}
            component={"h1"}
            sx={{
              fontSize: "70px",
              fontWeight: "900",
              color: "#000",
              marginLeft: 5
            }}
          >
            Testimonials
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 3, marginBottom: 7 }}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "60%",
                  borderRadius: "105.5px",
                  backgroundColor: "#fff",
                  padding: 1,
                  display: "flex",
                }}
              >
                <img
                  src={SiddharthImage}
                  alt="Bani"
                  width={"109px"}
                  height={"106px"}
                />
                <Box sx={{ paddingLeft: 2, marginY: "auto" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "25px", fontWeight: "500" }}
                  >
                    Siddharth Raj Dengre
                  </Typography>
                  <Typography
                    variant="body2"
                    color={"text.secondary"}
                    sx={{
                      width: { lg: "95%" },
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    I was entirely frustrated by the lengthy process of connecting with an influencer. At first, you have to search for influencers, message them, and then wait for their replies. However, I now have Meesha's extension application, which provides me complete freedom in my search for an influencer. I can use it to filter influencers based on gender, location, and number of followers. It's an excellent tool for people like me who work with influencers and campaigns. I've already benefited from using this application. Now, it's your turn to explore it.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "60%",
                  borderRadius: "105.5px",
                  backgroundColor: "#fff",
                  padding: 1,
                  display: "flex",
                  marginTop: 3,
                }}
              >
                <img
                  src={YogeshImage}
                  alt="Bani"
                  width={"109px"}
                  height={"106px"}
                />
                <Box sx={{ paddingLeft: 2, marginY: "auto" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "25px", fontWeight: "500" }}
                  >
                    Yogesh Agarwal
                  </Typography>
                  <Typography
                    variant="body2"
                    color={"text.secondary"}
                    sx={{
                      width: { lg: "95%" },
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    I sincerely doubt that any other application matches the convenience offered by the Meesha application. The services provided by this platform are truly impressive. For instance, with just a single click, I can access an influencer's contact details. Even more remarkable is the application's adaptability - I can customize its settings to meet my specific requirements. This level of personalization and ease-of-use is rare, making Meesha an exceptional tool in the field.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "60%",
                  borderRadius: "105.5px",
                  backgroundColor: "#fff",
                  padding: 1,
                  display: "flex",
                }}
              >
                <img
                  src={DeveshImage}
                  alt="Bani"
                  width={"109px"}
                  height={"106px"}
                />
                <Box sx={{ paddingLeft: 2, marginY: "auto" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "25px", fontWeight: "500" }}
                  >
                    Devesh Thakur
                  </Typography>
                  <Typography
                    variant="body2"
                    color={"text.secondary"}
                    sx={{
                      width: { lg: "95%" },
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    I am thoroughly satisfied with the Meesha application. Its efficiency and time-saving features have made my interactions with influencers far more streamlined. I am deeply grateful for the convenience this tool provides. No matter which influencer I need to connect with, I can reliably obtain their contact information. This level of accessibility is unprecedented and greatly appreciated.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "60%",
                  borderRadius: "105.5px",
                  backgroundColor: "#fff",
                  padding: 1,
                  display: "flex",
                  marginTop: 3,
                }}
              >
                <img
                  src={RenishaImage}
                  alt="Bani"
                  width={"109px"}
                  height={"106px"}
                />
                <Box sx={{ paddingLeft: 2, marginY: "auto" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontSize: "25px", fontWeight: "500" }}
                  >
                    Renisha Shetty
                  </Typography>
                  <Typography
                    variant="body2"
                    color={"text.secondary"}
                    sx={{
                      width: { lg: "95%" },
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    In the space of influencer marketing, a crucial asset to have readily available is a repository of contact information. However, should you find yourself lacking such information, there exists a solution in the form of Meesha. Personal experience spanning half a year has demonstrated that Meesha consistently delivers exceptional performance. The substantial time-saving capabilities of this tool are undeniable. With a mere single click on the extension, the long-awaited contact details materialize before you. Reflecting on my interactions with Meesha, I'd not want anyone to think twice. It's efficient, user-friendly and something that you should already invest in.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default LandingTestimonials;
