import * as React from "react";
import { Box, Button, TextField, Typography, Stack, InputAdornment, FormLabel, FormControl, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import usersEndpoints from "../../../services/userServices";
import FormImage from "./images/form-submit.png";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactusForm = React.forwardRef((props, ref) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const [formSubmit, setFormSubmit] = useState(false);
    const [timeSlot, setTimeSlot] = useState(1);
    const [timeSlotinText, setTimeSlotinText] = useState("8:00am to 12pm");

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const removeHtmlChars = (originalText) => {
        const strippedString = originalText.replace(/(<([^>]+)>)/gi, "");
        return strippedString;
    };
    
    const onSubmit = async (data) => {
        data.fullname = removeHtmlChars(data.fullname);
        data.requestee_phone = removeHtmlChars(data.requestee_phone);
        let postdata = {
            "name": data.fullname,
            "email": data.requestee_email,
            "phone": data.requestee_phone,
            "time": timeSlotinText
        };
        const response = await usersEndpoints.contactForPrice(postdata);

        if(response['success'] === true){
            setFormSubmit(true);
            toast.success(response['message']); 
        }
        else{
            toast.error(response['message']);    
        }
        // alert(response['message']);
    };

    const showInvalidValues = (data) => {
    
    };

    const handleMobileNumberInput = (event) => {
        let keyCode = event.keyCode || event.which;
        let prevent_input = true;
        
        if(keyCode >= 48 && keyCode <= 57){
            prevent_input = false;
        }
        
        if(prevent_input){
            event.returnValue = false;
            if(event.preventDefault){
                event.preventDefault();
            }
        }
    };

    const handleTimeSlotChange = (event, child) => {
        setTimeSlot(event.target.value);
        setTimeSlotinText(child.props['data-attribute']);
    }

    return (
        <Box sx={ style } {...props} ref={ref}>
            <Stack
                justifyContent={ "center" }
                direction={ "row" }
                sx={{ marginBottom: 1 }}
            >
                <img 
                    src={ FormImage }
                    style={{ width: '140px' }}
                />
            </Stack>
            <Typography
                variant="h6"
                marginBottom={ 2 }
                sx={{ display: formSubmit ? "none" : "block" }}
            >
                Please provide us with your details 
                and we will get back to you
            </Typography>
            {
                formSubmit === false ?
                <>
                    <form onSubmit={ handleSubmit(onSubmit, showInvalidValues) }>
                        <Stack
                            spacing={ 2 }
                        >
                            <FormControl>
                                <FormLabel
                                    sx={{ color: 'text.primary' }}
                                >
                                    Name/Agency Name:
                                </FormLabel>
                                <TextField
                                    placeholder="Please provide your name or agency name"
                                    variant="outlined"
                                    type={ "text" }
                                    {
                                        ...register("fullname", {
                                            required: {
                                                value: true,
                                                message: "Please provide your Name or your Agency Name"
                                            }
                                        })
                                    }
                                    error={ Boolean(errors.fullname) }
                                    helperText={ errors.fullname ?. message }
                                    autoComplete="off"
                                    autoFocus
                                    defaultValue={ props.userdetails.fullName }
                                />
                            </FormControl>
                            
                            <FormControl>
                                <FormLabel
                                    sx={{ color: 'text.primary' }}
                                >
                                    Your Email:
                                </FormLabel>
                                <TextField
                                    placeholder="Enter your Email"
                                    variant="outlined"
                                    autoComplete="off"
                                    defaultValue={ props.userdetails.email }
                                    type={ "email" }
                                    {
                                        ...register("requestee_email", {
                                            required: {
                                                value: true,
                                                message: 'Please provide your email address'
                                            },
                                            pattern: {
                                                value: /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/,
                                                message: "Please provide a business email address"
                                            }
                                        })
                                    }
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel
                                    sx={{ color: 'text.primary' }}
                                >
                                    Your Phone Number:
                                </FormLabel>
                                <TextField
                                    placeholder="Enter your Phone Number"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'>+91</InputAdornment>
                                    }}
                                    type={ "tel" }
                                    {
                                        ...register("requestee_phone", {
                                            required: {
                                                value: true,
                                                message: 'Please provide your phone number'
                                            },
                                            pattern: {
                                                value: /^[0-9]{10}$/g,
                                                message: 'Phone number should contain only digits'
                                            }
                                        })
                                    }
                                    error={ Boolean(errors.requestee_phone) }
                                    helperText={ errors.requestee_phone ?. message }
                                    autoComplete="off"
                                    onKeyPress={ handleMobileNumberInput }
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    defaultValue={ props.userdetails.phoneNumber }
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel
                                    sx={{ color: 'text.primary' }}
                                >
                                    Ideal time to Contact:
                                </FormLabel>
                                <Select
                                    value={ timeSlot }
                                    onChange={ handleTimeSlotChange }
                                >
                                    <MenuItem 
                                        value={ 1 }
                                        data-attribute="8:00am to 12:00pm"
                                    >
                                        8:00am to 12:00pm
                                    </MenuItem>
                                    <MenuItem 
                                        value={ 2 }
                                        data-attribute="12:00pm to 4:00pm"
                                    >
                                        12:00pm to 4:00pm
                                    </MenuItem>
                                    <MenuItem 
                                        value={ 3 }
                                        data-attribute="5:00pm to 8:00pm"
                                    >
                                        5:00pm to 8:00pm
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                            >
                                Submit Details
                            </Button>
                        </Stack>
                    </form>
                </>
                :
                <>
                    <Typography 
                        variant="p"
                        color={ "text.grey" }
                    >
                        Your details have been shared with the Operations Team.
                        Our representative will soon reach out to you.
                    </Typography>
                </>
            }
        </Box>
    );
})

export default ContactusForm;