import * as React from 'react';
import { Stack, Typography } from '@mui/material';

function PrivacyPolicyHeading(){
    return (
        <Stack
            sx={{ 
                minHeight: '20vh',
                background: '#f5f7fa',
            }}
            justifyContent={ 'center' }
            alignItems={ 'center' }
            spacing={1}
            paddingY={2}
        >
            <Typography
                variant='h5'
                fontWeight={ 600 }
            >
                Privacy Policy
            </Typography>
            <Typography
                variant='p'
                fontSize={ '0.95rem' }
                sx={{ opacity: 0.7, letterSpacing: '0.5px' }}
            >
                Last updated : October 2022
            </Typography>
        </Stack>
    )
}

export default PrivacyPolicyHeading;