import * as React from "react";
import MeeshaWeb1 from '../../../meeshaweb-1.png';
import MeeshaWeb2 from '../../../meeshaweb-2.png';
import MeeshaWeb3 from '../../../meeshaweb-3.png';
import MeeshaWeb4 from '../../../meeshaweb-4.png';
import { Typography, Box, Grid } from "@mui/material";

function SmartDashBoard() {
  return (
    <>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 1:
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={{ fontWeight: "normal", fontSize: "30px" }}
          >
            If you're in search of influencers and have grown tired of the process of searching for influencer's Instagram profiles, messaging them, and then waiting for their replies, then the Meesha app is just for you. The first step is to add the Meesha Chrome extension to your browser. You will see on the top right corner there is an option of Smart Dashboard.
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaWeb1}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaWeb2}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 2:
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={{ fontWeight: "normal", fontSize: "30px" }}
          >
            Then, you'll be directed straight to the Smart Dashboard, where you can look for influencers details.
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 3:
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={{ fontWeight: "normal", fontSize: "30px" }}
          >
            You will be redirected to the next page, where you can obtain the influencer's number by clicking on the contact icon of the influencer you wish to connect with. Be aware, though, viewing an influencer's number will cost you one credit.
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaWeb3}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box
          component={"div"}
        >
          <img
            src={MeeshaWeb4}
            style={{
              width: '100%',
              height: '100%'
            }}
            alt='MeeshaWeb'
          />
        </Box>
      </Grid>
      <Grid item lg={6} sx={{ marginBottom: 3 }}>
        <Box component={"div"}>
          <Typography
            variant="h6"
            component={"h6"}
            sx={{ color: "#232230", fontWeight: "900", fontSize: "30px" }}
          >
            STEP 4:
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={{ fontWeight: "normal", fontSize: "30px" }}
          >
            Moreover, there are three filters you can use to refine your search: gender, location, and followers. Additionally, there is a feature to search within the influencers' bios.
          </Typography>
        </Box>
      </Grid>
    </>
  );
}

export default SmartDashBoard;
