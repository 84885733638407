import * as React from 'react';
import { useState } from 'react';
import { Box, Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';

import PrivacyPolicyHeading from './PrivacyPolicyHeading';
import SubscribeThroughEmail from '../common/subscribe/SubscribeEmail';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

function PrivacyPolicyDescription(){
    const [sectionHeadings, setSectionHeadings] = useState([
        "Who we are",
        "Personal Information we collect",
        "Why do we process your data",
        "Your Rights",
        "Links to other websites",
        "Information Security",
        "Legal Disclosure",
        "Contact Information"
    ]);
    
    const [sectionDescriptions, setSectionDescriptions] = useState([
        "meesha.io website is owned by MEESHA, which is a data controller of your personal data.We have adopted this Privacy Policy, which determines how we are processing the information collected by Meesha.io, which also provides the reasons why we must collect certain personal data about you. Therefore, you must read this Privacy Policy before using Meesha.io website.We take care of your personal data and undertake to guarantee its confidentiality and security.",
        "When you visit Meesha.io, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the installed cookies on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products you view, what websites or search terms referred you to the Site, and how you interact with the Site. We refer to this automatically-collected information as “Device Information.” Moreover, we might collect the personal data you provide to us (including but not limited to Name, Surname, Address, payment information, etc.) during registration to be able to fulfill the agreement.",
        "Our top priority is customer data security, and, as such, we may process only minimal user data, only as much as it is absolutely necessary to maintain the website. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding website usage. This statistical information is not otherwise aggregated in such a way that it would identify any particular user of the system. You can visit the website without telling us who you are or revealing any information, by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the website’s features, or you wish to receive our newsletter or provide other details by filling a form, you may provide personal data to us, such as your email, first name, last name, city of residence, organization, telephone number. You can choose not to provide us with your personal data, but then you may not be able to take advantage of some of the website’s features. For example, you won’t be able to receive our Newsletter or contact us directly from the website. Users who are uncertain about what information is mandatory are welcome to contact us support@meesha.co.in.",
    ]);

    const [activeIndex, setActiveIndex] = useState(-1);

    const scrollToSection = (index) => {
        if(index >= 0 && index < sectionHeadings.length){
            setActiveIndex(index);
            let section_id = `section_${ index }`;
            let element = document.getElementById(section_id);
            let offsetTop = element.offsetTop;
            if(offsetTop !== 0){
                window.scroll(0, offsetTop - 81);
            }
            else{
                element.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }
    
    return (
        <>
            <PrivacyPolicyHeading />
            <Container 
                maxWidth={ "lg" }
                sx={{ 
                    paddingTop: 2,
                    paddingBottom: 30
                }}
            >
                <Grid container>
                    <Grid 
                        item 
                        xs={12} 
                        lg={3}
                        position="relative"
                    >
                        <List
                            sx={{ position: 'sticky', top: '4.5rem' }}
                        >
                            {
                                sectionHeadings.map((heading, index) => (
                                    <ListItemButton
                                        key={ `heading_${ index }` }
                                        onClick={ () => { scrollToSection(index) } }
                                        selected={ activeIndex === index }
                                    >
                                        <ListItemText 
                                            primary={ heading }
                                            primaryTypographyProps={{
                                                fontWeight: '600',
                                                color: '#8d97ad',
                                                letterSpacing: '0.5px'
                                            }}
                                        />
                                    </ListItemButton>
                                ))
                            }
                        </List>
                    </Grid>
                    <Grid item xs={12} lg={9}
                    >
                        <Stack
                            spacing={2}
                            paddingY={1.5}
                            paddingX={1}
                        >
                            <Box
                                component={ "div" }
                                id={ 'section_0' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[0] }
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    { sectionDescriptions[0] }
                                </Typography>
                            </Box>

                            <Box
                                component={ "div" }
                                id={ 'section_1' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[1] }
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    { sectionDescriptions[1] }
                                </Typography>
                            </Box>

                            <Box
                                component={ "div" }
                                id={ 'section_2' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[2] }    
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    { sectionDescriptions[2] }
                                </Typography>
                            </Box>

                            <Box
                                component={ "div" }
                                id={ 'section_3' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[3] }    
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    { `If you are a resident, you have the following rights related to your personal data:` }
                                    <List>
                                        <ListItem 
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "The right to be informed." }/>
                                        </ListItem>

                                        <ListItem
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "The right of access." }/>
                                        </ListItem>

                                        <ListItem
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "The right to rectification." }/>
                                        </ListItem>

                                        <ListItem
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "The right to erasure." }/>
                                        </ListItem>

                                        <ListItem
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "The right to restrict processing." }/>
                                        </ListItem>

                                        <ListItem
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "The right to data portability." }/>
                                        </ListItem>

                                        <ListItem
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "The right to object" }/>
                                        </ListItem>

                                        <ListItem
                                            disableGutters 
                                            disablePadding
                                        >
                                            <ListItemIcon>
                                                <KeyboardDoubleArrowRightIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={ "Rights in relation to automated decision-making and profiling." }/>
                                        </ListItem>
                                    </List>
                                    {`If you would like to exercise this right, please contact us through the contact information below.`}
                                    {`Additionally, if you are a resident, we note that we are processing your information in order to fulfill contracts we might have with you (for example, if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information might be transferred outside of India.`}
                                </Typography>
                            </Box>

                            <Box
                                component={ "div" }
                                id={ 'section_4' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[4] }    
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    Our website may contain links to other websites that are not owned or controlled by us. Please be aware that we are not responsible for such other websites or third parties' privacy practices. We encourage you to be aware when you leave our website and read the privacy statements of each website that may collect personal information.
                                </Typography>
                            </Box>

                            <Box
                                component={ "div" }
                                id={ 'section_5' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[5] }    
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We keep reasonable administrative, technical, and physical safeguards to protect against unauthorized access, use, modification, and personal data disclosure in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.
                                </Typography>
                            </Box>

                            <Box
                                component={ "div" }
                                id={ 'section_6' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[6] }    
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                                </Typography>
                            </Box>

                            <Box
                                component={ "div" }
                                id={ 'section_7' }
                            >
                                <Typography
                                    variant='h6'
                                    fontWeight={ '600' }
                                    fontSize={ '1.2rem' }
                                >
                                    { sectionHeadings[7] }    
                                </Typography>
                                <Typography
                                    variant='p'
                                    sx={{ 
                                        lineHeight: 1.6,
                                        color: '#8d97ad'
                                    }}
                                >
                                    If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to support@meesha.co.in
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid> 
            </Container>
            <SubscribeThroughEmail/>
        </>
    )
}

export default PrivacyPolicyDescription;