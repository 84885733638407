import { CssBaseline } from "@mui/material";
import DashboardHeaderSection from "../components/common/dashboardheader/DashboardHeader";
// import SidebarComponent from "../components/common/sidebar/Sidebar";
import ReferralsBanner from "../components/referrals/ReferralsBanner";

function ReferFriend(){
    return (
        <>
            <CssBaseline/>
            <DashboardHeaderSection/>
            <ReferralsBanner/>
            {/* <Grid container>
                <Grid item xs={2}>
                    <SidebarComponent/>
                </Grid>
                <Grid 
                    item 
                    xs={12}
                >
                    <DashboardHeaderSection/>
                    <ReferralsBanner/>
                </Grid>
            </Grid> */}
        </>
    )
}

export default ReferFriend;