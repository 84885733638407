import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Accordion, AccordionDetails, AccordionSummary, Button, Drawer, TextField } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const anchor = 'left';

export default function FinanceHeader() {
    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClearFilters = async () => {
        window.location.reload();
    };

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor, open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor) => (
        <Box
            sx={{
                padding: 2
            }}
            className="filterScroll"
        >
            <Box bgcolor={"white"} sx={{ borderRadius: 1.5 }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 2,
                    }}
                >
                    <Typography variant="button" sx={{ fontWeight: "bold" }}>
                        Filters
                    </Typography>
                    <Typography
                        variant="button"
                        sx={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={handleClearFilters}
                    >
                        Clear All
                    </Typography>
                </Box>
                {/* <ShowFilters filters={filters} handleDelete={handleDelete} /> */}
            </Box>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Campaign</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Box marginTop={1}>
                            <TextField
                                id="outlined-basic"
                                label="Campaign"
                                variant="outlined"
                                size="small"
                                name="campaign"
                            // onChange={(e) => setLocation(e.target.value)}
                            />
                            &nbsp;
                            <Button
                                variant="contained"
                                sx={{
                                    fontSize: "0.7rem",
                                    paddingY: 1,
                                    textTransform: "capitalize",
                                }}
                            // onClick={() => handleFilters(location, "location")}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Vendor</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Box marginTop={1}>
                            <TextField
                                id="outlined-basic"
                                label="Vendor"
                                variant="outlined"
                                size="small"
                                name="vendor"
                            // onChange={(e) => setLocation(e.target.value)}
                            />
                            &nbsp;
                            <Button
                                variant="contained"
                                sx={{
                                    fontSize: "0.7rem",
                                    paddingY: 1,
                                    textTransform: "capitalize",
                                }}
                            // onClick={() => handleFilters(location, "location")}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Payment Status</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Box marginTop={1}>
                            <TextField
                                id="outlined-basic"
                                label="Payment Status"
                                variant="outlined"
                                size="small"
                                name="payment_Status"
                            // onChange={(e) => setLocation(e.target.value)}
                            />
                            &nbsp;
                            <Button
                                variant="contained"
                                sx={{
                                    fontSize: "0.7rem",
                                    paddingY: 1,
                                    textTransform: "capitalize",
                                }}
                            // onClick={() => handleFilters(location, "location")}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>GST Requests</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Box marginTop={1}>
                            <TextField
                                id="outlined-basic"
                                label="GST Requests"
                                variant="outlined"
                                size="small"
                                name="gstRequests"
                            // onChange={(e) => setLocation(e.target.value)}
                            />
                            &nbsp;
                            <Button
                                variant="contained"
                                sx={{
                                    fontSize: "0.7rem",
                                    paddingY: 1,
                                    textTransform: "capitalize",
                                }}
                            // onClick={() => handleFilters(location, "location")}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Pancard</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Box marginTop={1}>
                            <TextField
                                id="outlined-basic"
                                label="Pancard"
                                variant="outlined"
                                size="small"
                                name="pancard"
                            // onChange={(e) => setLocation(e.target.value)}
                            />
                            &nbsp;
                            <Button
                                variant="contained"
                                sx={{
                                    fontSize: "0.7rem",
                                    paddingY: 1,
                                    textTransform: "capitalize",
                                }}
                            // onClick={() => handleFilters(location, "location")}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>POC</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Box marginTop={1}>
                            <TextField
                                id="outlined-basic"
                                label="POC"
                                variant="outlined"
                                size="small"
                                name="createdby"
                            // onChange={(e) => setLocation(e.target.value)}
                            />
                            &nbsp;
                            <Button
                                variant="contained"
                                sx={{
                                    fontSize: "0.7rem",
                                    paddingY: 1,
                                    textTransform: "capitalize",
                                }}
                            // onClick={() => handleFilters(location, "location")}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );

    return (
        <>
            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
            >
                {list(anchor)}
            </Drawer>
            <Box>
                <AppBar position="static">
                    <Toolbar sx={{ backgroundColor: '#f0f2f5', color: '#000' }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={toggleDrawer(anchor, true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Payment Dashboard
                        </Typography>
                        {auth && (
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
}