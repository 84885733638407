import { Box } from '@mui/material';
import * as React from 'react';
import HeaderSection from '../components/common/header/Header';
import PrivacyPolicyDescription from '../components/privacypolicy/PrivacyPolicyDescription';

function PrivacyPolicy(){
    return (
        <Box>
            <HeaderSection showNav={true}/>
            <PrivacyPolicyDescription />            
        </Box>
    )
}

export default PrivacyPolicy;