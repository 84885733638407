import { Stack, IconButton, OutlinedInput, Typography, Card, Avatar, Divider, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FacebookOutlined, ForwardToInboxOutlined, Instagram } from "@mui/icons-material";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usersEndpoints from "../../services/userServices";

const copy  = require('clipboard-copy');

function ReferralLinks(){
    const [cookies] = useCookies();
    const [referralLink, setReferralLink] = useState('');
    const [isCopied, setisCopied] = useState(false);
    const [earnings, setEarnings] = useState(0);

    useEffect(() => {
        async function GetReferralLink(){
            if(cookies.auth && cookies.auth.token){
                const response = await usersEndpoints.generateReferralCode({});
                if(response['success'] === true){
                    setReferralLink(`${ process.env.REACT_APP_WEBSITE_BASE }authenticate/signup/${ response['code'] }`);
                    if(response['newCode']){
                        toast.success('Referral Code Generated Successfully');
                    }
                }
                else{
                    toast.error(response['message']);
                }
    
                if(response['referred']){
                    setEarnings(parseInt(response['referred']))
                }
            }
        }
        GetReferralLink();
    }, []);

    const copyLink = () => {
        let extraText = 'Hey, You can get direct contacts of any influencer on Meesha. Use my link, we both get 5 credits';
        extraText += '\n';
        
        copy(extraText + '\n' + referralLink);
        setisCopied(true);
        
        toast.success('Copied to Clipboard');
    }

    const shareLink = (medium) => {
        let target_url = '';
        switch(medium){
            case 'email':
                target_url = 'https://mail.google.com/';
                break;
            case 'instagram':
                target_url = 'https://www.instagram.com/';
                break;
            case 'facebook':
                target_url = 'https://www.facebook.com/';
                break;
            default:
                break;
        }

        if(target_url){
            window.open(target_url, '_blank');
        }
    }

    return (
        <>
            {
                cookies.user && cookies.auth ?
                <>
                    <Card
                        sx={{ 
                            maxWidth: 250,
                            marginBottom: 2
                        }}
                    >
                        <CardContent
                            sx={{ 
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingBottom: '16px !important'
                            }}
                        >
                            <Typography>
                                Your total Referrals
                            </Typography>
                            <Typography
                                fontWeight={'600'}
                            >
                                { earnings }
                            </Typography>
                        </CardContent>
                    </Card>

                    {
                        referralLink &&
                        <Stack>
                            <Typography
                                marginBottom={0.5}
                            >
                                Your Referral Link
                            </Typography>
                            
                            <OutlinedInput
                                value={referralLink}
                                disabled
                                fullWidth
                                size="small"
                                endAdornment={
                                    <IconButton
                                        size="small"
                                        onClick={copyLink}
                                    >
                                        <ContentCopyIcon fontSize={'0.8rem'}/>
                                    </IconButton>
                                }
                                sx={{
                                    bgcolor: isCopied ? 'hsl(120, 100%, 93%)' : 'white',
                                    borderColor: isCopied ? '#00C642' : 'none'
                                }}
                            />

                            <Stack
                                alignItems={'center'}
                                direction={'row'}
                                spacing={2}
                                marginTop={2}
                            >
                                <Typography>
                                    Share on
                                </Typography>

                                <Avatar
                                    sx={{ bgcolor: '#ffeee7' }}
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => shareLink('email')}
                                    >
                                        <ForwardToInboxOutlined/>
                                    </IconButton>
                                </Avatar>
                                <Divider orientation="vertical"/>
                                
                                <Avatar
                                    sx={{ bgcolor: '#ffeee7' }}
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => shareLink('instagram')}
                                    >
                                        <Instagram/>
                                    </IconButton>
                                </Avatar>
                                <Divider orientation="vertical"/>
                                
                                <Avatar
                                    sx={{ bgcolor: '#ffeee7' }}
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => shareLink('facebook')}
                                    >
                                        <FacebookOutlined/>
                                    </IconButton>
                                </Avatar>
                            </Stack>
                        </Stack>
                    }
                </>
                :
                <Navigate to={'/'}/>
            }
        </>
    )
}

export default ReferralLinks;