import http from "./httpservices";
import GetCookieByName from "../hooks/GetCookie";

export async function getLimitedInfluencers() {
  const { data } = await http.get("influencer/showinfluencerlimit", {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function getInfluencersCount() {
  const { data } = await http.get(`influencer/influencerscount`, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export async function findByUserName(search) {
  const { data } = await http.post("influencer/findbyusername", {
    username: search,
  }, {
    headers: { "x-auth-token": JSON.parse(GetCookieByName("auth"))["token"] },
  });
  return data;
}

export default {
  getLimitedInfluencers,
  getInfluencersCount,
  findByUserName,
};
